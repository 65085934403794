import { Instance, types } from "mobx-state-tree"

export const ScriptPromptSectionModel = types.maybe(
  types.model("ScriptPromptSection").props({
    name: types.maybe(types.string),
    description: types.maybe(types.string),
  }),
)

export const ScriptPromptModel = types.maybe(
  types.model("ScriptPrompt").props({
    name: types.maybe(types.string),
    id: types.maybe(types.identifier),
    description: types.maybe(types.string),
    sections: types.maybe(types.array(ScriptPromptSectionModel)),
  }),
)

export type ScriptPromptSection = Instance<typeof ScriptPromptSectionModel>
export type ScriptPrompt = Instance<typeof ScriptPromptModel>
