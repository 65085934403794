import React from "react"
import { Button as BaseButton } from "@mui/material"
import { getStyles } from "./button.styles"
import { WebAppTheme } from "pitch45-common/theme/app-theme.types"
import { CSSObject, useTheme } from "@emotion/react"
import { Icon, Icons, Loop45Icons } from "../icon/icon"
import Text from "../text/text"
import { textPresets } from "../text/text.presets"

export type ButtonPreset =
  | "primary"
  | "secondary"
  | "quick-action"
  | "clear"
  | "clear-header"
  | "outlined"
  | "narrow"
  | "link"

export interface ButtonProps {
  preset: ButtonPreset
  label?: string
  icon?: Icons | Loop45Icons
  disabled?: boolean
  onClick?: () => void
  type?: "submit"
  containerStyle?: CSSObject | CSSObject[]
  labelStyle?: CSSObject
  iconStyle?: CSSObject
}

export function Button(props: ButtonProps) {
  const theme = useTheme() as WebAppTheme
  const { BUTTON, BODY, LABEL, ICON } = getStyles(props, theme)
  const { preset, label, icon, disabled, containerStyle, labelStyle, iconStyle, onClick, ...rest } =
    props

  return (
    <BaseButton {...rest} css={[BUTTON, containerStyle]} disabled={disabled} onClick={onClick}>
      <div css={BODY}>
        {icon && (
          <Icon
            icon={icon}
            size={preset === "quick-action" ? "large" : "small"}
            css={[ICON, iconStyle]}
          />
        )}
        {label && (
          <Text preset={textPresets.small} css={[LABEL, labelStyle]}>
            {label}
          </Text>
        )}
      </div>
    </BaseButton>
  )
}

export default Button
