import { Instance, types } from "mobx-state-tree"
import { StringEnum } from "../utils/string-enum-type"
import { LibraryItemType } from "./library-item"

export enum LibraryTagType {
  Resource = "Resource",
  OrganizationName = "OrganizationName",
  GroupName = "GroupName",
}

export const LibraryTagModel = types.model("Tag").props({
  name: types.identifier,
  tagType: types.maybe(StringEnum(LibraryTagType)),
  filterType: types.maybe(StringEnum(LibraryItemType)),
})

export type LibraryTag = Instance<typeof LibraryTagModel>
