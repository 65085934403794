// Padding, margins, z-indices, and more

export const HEADER_HEIGHT = "70px"

export const z = {
  FULLSCREEN_MODAL: 50,
  OVERLAY: 2,
}

export const mediaQueryBreakpoints = {
  MOBILE: 480,
  TABLET: 1024,
  SM_DESKTOP: 1280,
  LG_DESKTOP: 1281,
}

export const mediaQueries = {
  MOBILE: `@media (max-width: ${mediaQueryBreakpoints.MOBILE}px)`,
  TABLET: `@media (max-width: ${mediaQueryBreakpoints.TABLET}px)`,
  SM_DESKTOP: `@media  (max-width: ${mediaQueryBreakpoints.SM_DESKTOP}px)`,
  LG_DESKTOP: `@media (min-width: ${mediaQueryBreakpoints.LG_DESKTOP}px)`,
}

export const THUMBNAIL_SIZES = {
  table: 40,
  default: 100,
  featured: 240,
  carousel: 275,
}
