import { flow } from "mobx"
import { toGenerator, types, getParent, IStateTreeNode, Instance } from "mobx-state-tree"
import { withEnvironment } from "./extensions/with-environment"
import { BlockedUserApi } from "../services/api/blocked-user-api"
import { RootStoreModel } from "./root-store"
import { Pitch } from "../models/pitch"
import { PublicUser } from "../models/public-user"
import { Entity } from "../models/entity"

export enum BlockType {
  BlockedBySelf,
  BlockedByOtherUser,
  BlockedByEither,
}

export const BlockedUserStoreModel = types
  .model("BlockedUserStore")
  .props({
    blockedUserIds: types.array(types.string),
    blockedByUserIds: types.array(types.string),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    setBlockedUsers: function (userIds) {
      self.blockedUserIds.replace(userIds)
    },
    setBlockedByUsers: function (userIds) {
      self.blockedByUserIds.replace(userIds)
    },
  }))
  .actions((self) => ({
    fetchBlockedUsers: flow(function* () {
      const blockedUserApi = new BlockedUserApi(self.environment.api)
      const result = yield* toGenerator(blockedUserApi.getBlockedUsers())

      self.setBlockedUsers(result.blockedUserIds)
      self.setBlockedByUsers(result.blockedByUserIds)

      return true
    }),
  }))
  .actions((self) => ({
    blockUser: flow(function* (user: PublicUser | Entity) {
      const blockedUserApi = new BlockedUserApi(self.environment.api)
      yield blockedUserApi.blockUser(user.id)
      self.fetchBlockedUsers()
    }),
    unblockUser: flow(function* (user: PublicUser | Entity) {
      const blockedUserApi = new BlockedUserApi(self.environment.api)
      yield blockedUserApi.unblockUser(user.id)
      self.fetchBlockedUsers()
    }),
  }))
  .views((self) => ({
    isUserBlocked(blockType: BlockType, user: PublicUser | Entity): boolean {
      if (blockType === BlockType.BlockedBySelf) {
        return self.blockedUserIds.includes(user.id)
      } else if (blockType === BlockType.BlockedByOtherUser) {
        return self.blockedByUserIds.includes(user.id)
      } else if (blockType === BlockType.BlockedByEither) {
        return self.blockedUserIds.includes(user.id) || self.blockedByUserIds.includes(user.id)
      } else {
        return false
      }
    },
    isPitchBlocked(blockType: BlockType, pitch: Pitch): boolean {
      if (!pitch.userProfile) {
        return false
      }
      if (blockType === BlockType.BlockedBySelf) {
        return self.blockedUserIds.includes(pitch.userProfile?.id)
      } else if (blockType === BlockType.BlockedByOtherUser) {
        return self.blockedByUserIds.includes(pitch.userProfile?.id)
      } else if (blockType === BlockType.BlockedByEither) {
        return (
          self.blockedUserIds.includes(pitch.userProfile?.id) ||
          self.blockedByUserIds.includes(pitch.userProfile?.id)
        )
      } else {
        return false
      }
    },
  }))

export type BlockedUserStore = Instance<typeof BlockedUserStoreModel>
export const withBlockedUserStore = (self: IStateTreeNode) => ({
  views: {
    get blockedUserStore(): BlockedUserStore {
      return getParent<Instance<typeof RootStoreModel>>(self).blockedUserStore
    },
  },
})
