import { ApiResponse } from "apisauce"
import { Api } from "./api"
import { ApiError } from "./api-problem"
import { Script } from "../../models/script"
import { ScriptPrompt } from "../../models/script-prompt"

const API_PATH = "api/Script/"

export type ScriptsResult = { scripts: Script[] }

export type ScriptPromptsResult = { prompts: ScriptPrompt[] }

export class ScriptApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async getUserScripts(): Promise<ScriptsResult> {
    const response: ApiResponse<ScriptsResult> = await this.api.apisauce.get(API_PATH + "all")
    if (!response.ok || !response.data) {
      throw new ApiError("ScriptApi.getUserScripts", response)
    }
    return response.data
  }

  async getScriptPrompts(): Promise<ScriptPromptsResult> {
    const response: ApiResponse<ScriptPromptsResult> = await this.api.apisauce.get(
      API_PATH + "prompts",
    )
    if (!response.ok || !response.data) {
      throw new ApiError("ScriptApi.getScriptPrompts", response)
    }
    return response.data
  }

  async saveScript(script: Script): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.put(
      API_PATH + `${script.id}`,
      script,
    )
    if (!response.ok) {
      throw new ApiError("ScriptApi.saveScript", response)
    }
  }

  async deleteScript(scriptId: string): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.delete(API_PATH + `${scriptId}`)
    if (!response.ok) {
      throw new ApiError("ScriptApi.deleteScript", response)
    }
  }
}
