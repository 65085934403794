import { Instance, types } from "mobx-state-tree"

export const ThemeColorModel = types.maybe(
  types.model("ThemeColor").props({
    primary: types.string,
    background: types.string,
    card: types.string,
    notification: types.string,
    border: types.string,
    text: types.string,
    transparent: types.string,
    halfTransparentBackground: types.string,
    backgroundHighlight: types.string,
    secondaryText: types.string,
    dimText: types.string,
    error: types.string,
  }),
)

export const TypographyStyleModel = types.maybe(
  types.model("TypographyStyle").props({
    fontFamily: types.string,
    fontWeight: types.union(types.string, types.number),
  }),
)

export const ThemeTypographyModel = types.maybe(
  types.model("ThemeTypography").props({
    logo: TypographyStyleModel,
    light: TypographyStyleModel,
    thin: TypographyStyleModel,
    regular: TypographyStyleModel,
    medium: TypographyStyleModel,
    semibold: TypographyStyleModel,
    bold: TypographyStyleModel,
  }),
)

export const EntityThemeModel = types.model("EntityTheme").props({
  fillColor: types.string,
  buttonColor: types.string,
  buttonTextColor: types.string,
  accentColor: types.string,
})

export type EntityTheme = Instance<typeof EntityThemeModel>
