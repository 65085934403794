import { Instance, types } from "mobx-state-tree"
import { StringEnum } from "../utils/string-enum-type"

export enum EntityType {
  User = "User",
  Organization = "Organization",
  Group = "Group",
}

export const EntityModel = types.model("EntityModel").props({
  id: types.string,
  name: types.string,
  type: types.maybe(StringEnum(EntityType)),
  assetId: types.maybe(types.string),
  parentId: types.maybeNull(types.string),
})

export type Entity = Instance<typeof EntityModel>
