import { Instance, types } from "mobx-state-tree"
import { DateTime } from "../utils/datetime-type"
import { SyncModel } from "../models/sync"
import { StringEnum } from "../utils/string-enum-type"
import { ResourceType } from "./resource"
import { EntityModel, EntityType } from "./entity"
import { PitchActionType } from "./pitch"
import { ReactionType } from "./reaction"

export enum UserActivityType {
  PitchSubmissionSubmitted = "pitch_submission.submitted",
  PitchSubmissionReceived = "pitch_submission.received",
  MessagePitchReceived = "message.pitch.received",
  PlaylistPitchAdded = "playlist.pitch_added",
  PostCreated = "post.created",
  MembershipRequestAccepted = "membership_request.accepted",
  GroupUserCreated = "group_user.created",
  AssignmentCreated = "assignment.created",
  ActivityNotificationCreated = "activity_notification.created",
  PitchPublished = "pitch.published",
  ReactionCreated = "reaction.created",
  PitchViewTrending = "pitch.view_trending",
  WelcomePitch = "pitch.welcome",
  ProfilePitchAdded = "profile_pitch.added",
}

export enum ActivitySource {
  Default = "Default",
  ResourceSubscription = "ResourceSubscription",
  AutoPost = "AutoPost",
}

interface ActivityUser {
  id: string
  name: string
  thumbnailAssetId?: string
  tagline?: string
}

export interface ActivityPitch {
  id: string
  name: string
  thumbnailAssetId?: string
  archived: boolean
}

export interface ActivityPlaylist {
  id: string
  name: string
  thumbnailAssetId?: string
}

export interface ActivityReaction {
  id: string
  reactionType: ReactionType
}

interface ActivityPost {
  id: string
  name: string
  thumbnailAssetId?: string
  resourceId: string
  resourceType: ResourceType
}

interface ActivityEntity {
  id: string
  name: string
  thumbnailAssetId?: string
  type?: string
}

export interface MessagePitchReceivedEntities {
  sender: ActivityUser
  pitch: ActivityPitch
  conversationId: string
}

export interface PlaylistPitchAddedEntities {
  pitchOwner: ActivityUser
  pitch: ActivityPitch
  playlist: ActivityPlaylist
  user?: ActivityUser
}

export interface ReactionCreatedEntities {
  user: ActivityUser
  pitch: ActivityPitch
  reaction: ActivityReaction
  entity: ActivityEntity
}

export interface WelcomePitchEntities {
  pitch: ActivityPitch
  entity: ActivityEntity
}

export interface PitchViewTrendingEntities {
  pitch: ActivityPitch
  entity: ActivityEntity
  trendingThreshold: number
}

export interface PostCreatedEntities {
  user: ActivityUser
  entity: ActivityEntity
  post: ActivityPost
}

export interface MembershipRequestAcceptedEntities {
  entity: ActivityEntity
}

export interface GroupUserCreatedEntities {
  user: ActivityUser
  entity: ActivityEntity
}

export interface AssignmentEntities {
  entity: ActivityEntity
}

export interface PitchPublishedEntities {
  pitch: ActivityPitch
  playlist?: ActivityPlaylist
  entity?: ActivityEntity
  pitchActionType?: PitchActionType
}

export interface ProfilePitchAddedEntities {
  user: ActivityUser
  pitch: ActivityPitch
  entities: ActivityEntity[]
}

export interface ActivityNotificationCreatedEntities {
  title?: string
  message?: string
  iconName?: string
  routeName?: string
  routeParameters?: string
}

export const ActivityModel = types
  .model("Activity")
  .props({
    id: types.identifier,
    activitySource: types.maybe(StringEnum(ActivitySource)),
    activitySourceEntity: types.maybe(EntityModel),
    activityType: types.maybe(StringEnum(UserActivityType)),
    activityEntitiesJson: types.maybe(types.string),
    openedUtc: types.maybeNull(DateTime),
    createdUtc: DateTime,
    sync: types.optional(SyncModel, () => ({})),
  })
  .views((self) => ({
    get isOpened() {
      return self.openedUtc !== null
    },
    toEntity(entity: ActivityUser | ActivityEntity, entityType: EntityType) {
      return {
        id: entity.id,
        name: entity.name || "",
        assetId: entity.thumbnailAssetId,
        type: entityType,
      }
    },
  }))

export type Activity = Instance<typeof ActivityModel>
