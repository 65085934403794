import { Instance, types, flow, toGenerator } from "mobx-state-tree"
import { withEnvironment } from "./extensions/with-environment"
import { AppConfigModel } from "../models/app-config"
import { AppConfigApi } from "../services/api/app-config-api"
import lt from "semver/functions/lt"

export const AppConfigStoreModel = types
  .model("AppConfigStore")
  .props({
    appConfig: types.maybe(AppConfigModel),
    showNewVersionAvailable: types.maybe(types.boolean),
    cachedMaxVersion: types.maybe(types.string),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    setShowNewVersionAvailable: (show: boolean) => {
      self.showNewVersionAvailable = show
    },
  }))
  .actions((self) => ({
    setCachedMaxVersion: (maxVersion: string) => {
      if (self.cachedMaxVersion) {
        // if we get a new max version greater than what we have seen before
        // set indicator for new max version available
        if (lt(self.cachedMaxVersion, maxVersion) && lt(self.environment.appVersion, maxVersion)) {
          self.setShowNewVersionAvailable(true)
        }
      } else {
        // if we have not seen a max version before, and the current version is less than the max version
        // set indicator for new max version available
        if (lt(self.environment.appVersion, maxVersion)) {
          self.setShowNewVersionAvailable(true)
        }
      }
      self.cachedMaxVersion = maxVersion
    },
  }))
  .actions((self) => ({
    fetchAppConfig: flow(function* () {
      const appConfigApi = new AppConfigApi(self.environment.api)
      const result = yield* toGenerator(
        appConfigApi.getAppConfig(self.environment.appVersion, self.environment.platform),
      )
      self.appConfig = result.appConfig
      if (self.appConfig.maxAppVersion && self.appConfig.maxAppVersionStoreUrl) {
        self.setCachedMaxVersion(self.appConfig.maxAppVersion)
      }
      return self.appConfig
    }),
  }))
  .views((self) => ({
    shouldForceAppStoreUpdate(): boolean {
      return Boolean(
        self.appConfig?.minimumAllowedAppVersion &&
          lt(self.environment.appVersion, self.appConfig.minimumAllowedAppVersion),
      )
    },
  }))

export type AppConfigStore = Instance<typeof AppConfigStoreModel>
