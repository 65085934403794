import { UserAffiliation, UserAffiliationType } from "../models/user-affiliation"

const renderEducationByline = (ua) => {
  if (ua.areaOfStudy && ua.gradYear) {
    return `${ua.areaOfStudy}, ${ua.gradYear.toString()}`
  } else if (ua.areaOfStudy) {
    return ua.areaOfStudy
  } else if (ua.gradYear) {
    return ua.gradYear.toString()
  } else {
    return ""
  }
}

const renderExperienceByline = (ua) => {
  if (ua.title) {
    return ua.title
  } else {
    return ""
  }
}

export const renderUserAffiliationByline = (ua: UserAffiliation) => {
  switch (ua.type) {
    case UserAffiliationType.Education:
      return renderEducationByline(ua)
    case UserAffiliationType.Experience:
      return renderExperienceByline(ua)
    default:
      throw new Error("Unknown user affiliation type")
  }
}

export const getAffiliationTitle = (userAffiliation?: UserAffiliation) => {
  if (!userAffiliation) {
    return ""
  }

  if (userAffiliation.type === UserAffiliationType.Experience) {
    const experienceByline = renderExperienceByline(userAffiliation)
    return `${experienceByline ? `${experienceByline}, ` : ""}${userAffiliation.affiliation?.name}`
  } else if (userAffiliation.type === UserAffiliationType.Education) {
    const educationByline = renderEducationByline(userAffiliation)
    return `${userAffiliation.affiliation?.name}${educationByline ? `, ${educationByline}` : ""}`
  }

  return ""
}
