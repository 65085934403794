import { Instance, types, flow, toGenerator } from "mobx-state-tree"
import { withEnvironment } from "./extensions/with-environment"
import { Activity, ActivityModel } from "../models/activity"
import { ActivityApi } from "../services/api/activity-api"
import { differenceInDays } from "date-fns"
import { sortBy, reduce } from "lodash-es"

export const ActivityStoreModel = types
  .model("ActivityStore")
  .props({
    numNewActivities: types.maybe(types.number),
    userActivity: types.map(ActivityModel),
    hasNewActivity: types.maybe(types.boolean),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    putUserActivities(userActivities: Activity[]) {
      return userActivities.map((a) => self.userActivity.put(a))
    },
    resetHasNewActivity() {
      self.hasNewActivity = false
    },
  }))
  .actions((self) => ({
    fetchNumNewActivities: flow(function* () {
      const activityApi = new ActivityApi(self.environment.api)
      const result = yield* toGenerator(activityApi.fetchNumNewActivities())
      self.numNewActivities = reduce(
        result.countsByType,
        function (result, value) {
          return result + value
        },
        0,
      )
      if (self.numNewActivities > 0) {
        self.hasNewActivity = true
      }
      return self.numNewActivities
    }),
    markActivityFeedViewed: flow(function* () {
      const activityApi = new ActivityApi(self.environment.api)
      yield activityApi.markActivityFeedViewed()
      self.numNewActivities = 0
    }),
    fetchUserActivity: flow(function* (cursor?: Date) {
      const activityApi = new ActivityApi(self.environment.api)
      const result = yield* toGenerator(activityApi.getUserActivity(cursor))
      return self.putUserActivities(result.userActivities)
    }),
    markUserActivityOpened: flow(function* (activityId: string) {
      const activityApi = new ActivityApi(self.environment.api)
      yield activityApi.markActivityOpened({ activityId })
      const existing = self.userActivity.get(activityId)
      if (existing) {
        existing.openedUtc = new Date()
        self.userActivity.set(activityId, existing)
      }
    }),
    markUserActivityUnread: flow(function* (activityId: string) {
      const activityApi = new ActivityApi(self.environment.api)
      yield activityApi.markActivityUnread({ activityId })
      const existing = self.userActivity.get(activityId)
      if (existing) {
        existing.openedUtc = null
        self.userActivity.set(activityId, existing)
      }
    }),
    deleteActivity: flow(function* (activityIds: string[]) {
      const activityApi = new ActivityApi(self.environment.api)
      yield activityApi.delete(activityIds)
      for (const activityId of activityIds) {
        const activity = self.userActivity.get(activityId)
        if (activity) {
          self.userActivity.put(activity)
        }
      }
    }),
    undoDeleteActivity: flow(function* (activityIds: string[]) {
      const activityApi = new ActivityApi(self.environment.api)
      yield activityApi.undoDelete(activityIds)
    }),
    markAllAsRead: flow(function* ({ userId }) {
      const activityApi = new ActivityApi(self.environment.api)
      yield activityApi.markAllAsRead({ userId })
    }),
    undoMarkAllAsRead: flow(function* ({ userId }) {
      const activityApi = new ActivityApi(self.environment.api)
      yield activityApi.undoMarkAllAsRead({ userId })
    }),
  }))
  .views((self) => ({
    get allUserActivity() {
      return sortBy(Array.from(self.userActivity.values()), (a) => a.createdUtc).reverse()
    },
  }))
  .views((self) => ({
    get newUserActivity() {
      return self.allUserActivity.filter((activity) => !activity.isOpened)
    },
    get todaysUserActivity() {
      return self.allUserActivity.filter(
        (activity) =>
          activity.isOpened &&
          activity.createdUtc &&
          // TODO very inconsistent math and probably doesn't work with UTC very well
          differenceInDays(Date.now(), activity.createdUtc) === 0,
      )
    },
    get yesterdaysUserActivity() {
      return self.allUserActivity.filter(
        (activity) =>
          activity.isOpened &&
          activity.createdUtc &&
          differenceInDays(Date.now(), activity.createdUtc) === 1,
      )
    },
    get olderUserActivity() {
      return self.allUserActivity.filter(
        (activity) =>
          activity.isOpened &&
          activity.createdUtc &&
          differenceInDays(Date.now(), activity.createdUtc) > 1,
      )
    },
  }))

export type ActivityStore = Instance<typeof ActivityStoreModel>
