import { Instance, types } from "mobx-state-tree"
import { DateTime } from "../utils/datetime-type"
import { StringEnum } from "../utils/string-enum-type"
import { EntityModel } from "./entity"

export enum LibraryItemType {
  User = "User",
  Organization = "Organization",
  Playlist = "Playlist",
  Submissions = "Submissions",
  Saved = "Saved",
  Group = "Group",
}

/**
 * Library Item Model
 */
export const LibraryItemModel = types.model("LibraryItem").props({
  /**
   * The id of the underlying resource. If the library item is
   * a playlist, then this is the playlist id.
   */
  resourceId: types.identifier,

  title: types.string,

  resourceType: types.maybe(StringEnum(LibraryItemType)),

  /**
   * typeName is the display name for the type of library item.
   * Usually this is just the string version of the type enum.
   */
  resourceTypeName: types.string,

  entity: types.maybe(EntityModel),

  pitchCount: types.maybe(types.number),

  /**
   * Thumbnail image asset ids. If this is not provided, then iconName is used
   * to render an icon instead.
   */
  thumbnailAssetIds: types.maybe(types.array(types.string)),

  pinnedUtc: types.maybeNull(DateTime),

  lastViewedUtc: types.maybeNull(DateTime),

  createdUtc: types.maybeNull(DateTime),
})

export type LibraryItem = Instance<typeof LibraryItemModel>
