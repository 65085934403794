import { Instance, types } from "mobx-state-tree"
import { withEnvironment } from "./extensions/with-environment"
import { withSessionStore } from "./session-store"
import { StringEnum } from "../utils"
import { GalleryViewType } from "../models/gallery-types"

export const UserPreferencesStoreModel = types
  .model("UserPreferencesStore")
  .props({
    viewType: types.optional(StringEnum(GalleryViewType), GalleryViewType.List),
  })
  .extend(withEnvironment)
  .extend(withSessionStore)
  .actions((self) => ({
    setViewType: (viewType: GalleryViewType) => {
      self.viewType = viewType
    },
  }))
  .views((self) => ({
    getViewType() {
      return self.viewType as GalleryViewType
    },
  }))
export type UserPreferencesStore = Instance<typeof UserPreferencesStoreModel>
