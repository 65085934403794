import { getParent, Instance, IStateTreeNode, types } from "mobx-state-tree"
import { withEnvironment } from "./extensions/with-environment"
import { withMessagingStore } from "../stores/messaging-store"
import { withSessionStore } from "./session-store"
import { hasSameEntities } from "../utils/has-same-entities"
import { toJS } from "mobx"
import logger from "../logging/logger"
import { RootStoreModel } from "./root-store"
import { ShareSearchResultModel, ShareSearchResultSnapshotOut } from "../models/share-search-result"

/**
 * This store is used for searching for users to share to. Since you can share to an
 * existing conversation, or just select users, we use a ShareSearchResult object to
 * represent each result
 */
export const ShareSearchStoreModel = types
  .model("ShareSearchStore")
  .props({
    message: types.optional(types.string, ""),

    pageSize: types.optional(types.number, 10),
    pageNumber: types.optional(types.number, 1),

    selectedResults: types.optional(types.array(ShareSearchResultModel), []),

    totalCount: types.maybe(types.number),
  })
  .extend(withEnvironment)
  .extend(withSessionStore)
  .extend(withMessagingStore)
  .views((self) => ({
    get selectedUsers() {
      return self.selectedResults.map((result) => result?.entities)
    },
    isSelected(searchResult: ShareSearchResultSnapshotOut) {
      return self.selectedResults.some((r) => hasSameEntities(r.entities, searchResult.entities))
    },
  }))
  .actions((self) => ({
    reset() {
      self.selectedResults.clear()
      self.message = ""
    },
    setMessage: (message: string) => {
      self.message = message
    },
    addToSelectedResults(result) {
      self.selectedResults.push(result)
    },
    removeFromSelectedResults(result) {
      self.selectedResults.replace(
        self.selectedResults.filter((sr) => !hasSameEntities(sr.entities, result.entities)),
      )
    },
  }))
  .actions((self) => ({
    selectSearchResult: (searchResult: ShareSearchResultSnapshotOut) => {
      const entities =
        searchResult.entities
          ?.map((e) => toJS(e))
          .filter((e) => e.id !== self.sessionStore.currentUser?.id) || []

      if (searchResult.conversationId || entities.length > 0) {
        self.selectedResults.push({
          conversationId: searchResult.conversationId,
          entities,
        })
      } else {
        logger.logWarning("Trying to select unselectable search result")
      }
    },
    unselectSearchResult: (searchResult: ShareSearchResultSnapshotOut) => {
      self.removeFromSelectedResults(searchResult)
    },
  }))
  .actions((self) => ({
    toggleSearchResult: (searchResult: ShareSearchResultSnapshotOut) => {
      if (self.isSelected(searchResult)) {
        self.unselectSearchResult(searchResult)
      } else {
        self.selectSearchResult(searchResult)
      }
    },
  }))

export type ShareSearchStore = Instance<typeof ShareSearchStoreModel>
export const withShareSearchStore = (self: IStateTreeNode) => ({
  views: {
    get shareSearchStore(): ShareSearchStore {
      return getParent<Instance<typeof RootStoreModel>>(self).shareSearchStore
    },
  },
})
