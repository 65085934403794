export enum GalleryViewType {
  List = "list",
  Grid = "grid-view",
}

export enum GallerySortType {
  Alphabetical = "Alphabetical",
  RecentlyAdded = "RecentlyAdded",
  RecentlyViewed = "RecentlyViewed",
}

export const getAlternateViewType = (vt: GalleryViewType) =>
  vt === GalleryViewType.Grid ? GalleryViewType.List : GalleryViewType.Grid
