import {
  flow,
  getParent,
  Instance,
  IStateTreeNode,
  toGenerator,
  types,
  cast,
} from "mobx-state-tree"
import { withEnvironment } from "./extensions/with-environment"
import {
  createFieldModel,
  createBooleanField,
  FormModel,
  createField,
  createBooleanFieldModel,
} from "../models/form"
import {
  UserAffiliationType,
  UserAffiliation,
  UserAffiliationModel,
} from "../models/user-affiliation"
import { UserAffiliationApi, UserAffiliationRequest } from "../services/api/user-affiliation-api"
import { DateTime } from "../utils/datetime-type"
import { withAffiliationStore } from "./affiliation-store"
import { RootStoreModel } from "./root-store"
import { sortBy } from "lodash-es"

export const UserAffiliationFormModel = FormModel.props({
  title: createFieldModel({
    length: { maximum: 256 },
  }),
  affiliationId: createFieldModel({}),
  affiliationName: createFieldModel({
    presence: { allowEmpty: false, message: "^Name cannot be blank" },
  }),
  isPrimary: createBooleanFieldModel({}),
  type: createFieldModel({}),
  areaOfStudy: createFieldModel({}),
}).views((self) => ({
  get fields() {
    return [
      self.title,
      self.affiliationId,
      self.affiliationName,
      self.isPrimary,
      self.type,
      self.areaOfStudy,
    ]
  },
}))

export const UserAffiliationStoreModel = types
  .model("UserAffiliationStore")
  .props({
    userAffiliations: types.map(UserAffiliationModel),
    userAffiliationForm: types.maybe(UserAffiliationFormModel),
    startDate: types.maybe(DateTime),
    endDate: types.maybe(DateTime),
    gradYear: types.maybe(types.integer),
  })
  .extend(withEnvironment)
  .extend(withAffiliationStore)
  .actions((self) => ({
    putUserAffiliation(userAffiliation: UserAffiliation) {
      if (userAffiliation.affiliation) {
        self.affiliationStore.putAffiliation(userAffiliation.affiliation)
      }

      userAffiliation.affiliation = userAffiliation.affiliation?.id as any
      self.userAffiliations.put(userAffiliation)
      return self.userAffiliations.get(userAffiliation.id)
    },
    putUserAffiliations(userAffiliations: UserAffiliation[]): UserAffiliation[] {
      const userAffiliationModels: UserAffiliation[] = []
      userAffiliations.forEach((userAffiliation) => {
        if (userAffiliation.affiliation) {
          self.affiliationStore.putAffiliation(userAffiliation.affiliation)
          userAffiliation.affiliation = userAffiliation.affiliation.id as any
        }
        self.userAffiliations.put(userAffiliation)
        const userAffiliationModel = self.userAffiliations.get(userAffiliation.id)
        if (userAffiliationModel) {
          userAffiliationModels.push(userAffiliationModel)
        }
      })
      return userAffiliationModels
    },
    initializeNewUserAffiliation: () => {
      self.userAffiliationForm = UserAffiliationFormModel.create({
        title: createField("title", ""),
        affiliationId: createField("affiliationId", ""),
        affiliationName: createField("affiliationName", ""),
        isPrimary: createBooleanField("isPrimary", false),
        type: createField("type", UserAffiliationType.Experience),
        areaOfStudy: createField("areaOfStudy", ""),
      })
    },
    initializeExistingUserAffiliation: (userAffiliationId: string) => {
      const existingUserAffiliation = self.userAffiliations.get(userAffiliationId)
      if (!existingUserAffiliation) {
        throw new Error("initializeExistingUserAffiliation: existingUserAffiliation not found")
      }
      const affiliation = self.affiliationStore.affiliations.get(
        existingUserAffiliation?.affiliation?.id,
      )
      if (!affiliation) {
        throw new Error("initializeExistingUserAffiliation: affiliation not found")
      }
      self.userAffiliationForm = cast({
        title: createField("title", existingUserAffiliation.title),
        affiliationId: createField("affiliationId", affiliation?.id),
        affiliationName: createField("affiliationName", affiliation?.name),
        isPrimary: createBooleanField("isPrimary", existingUserAffiliation.isPrimary),
        type: createField("type", existingUserAffiliation.type),
        areaOfStudy: createField("areaOfStudy", existingUserAffiliation.areaOfStudy),
      })
      self.startDate = existingUserAffiliation.startDate
      self.endDate = existingUserAffiliation.endDate
      self.gradYear = existingUserAffiliation.gradYear
    },
    setUserAffiliationTitle: (title: string) => {
      self.userAffiliationForm?.title.setValue(title)
    },
    setAffiliationId: (affiliationId: string) => {
      self.userAffiliationForm?.affiliationId.setValue(affiliationId)
    },
    setAffiliationName: (affiliationName: string) => {
      self.userAffiliationForm?.affiliationName.setValue(affiliationName)
    },
    setAsPrimary: () => {
      self.userAffiliationForm?.isPrimary.setValue(true)
    },
    setUserAffiliationType: (type: UserAffiliationType) => {
      self.userAffiliationForm?.type.setValue(type)
    },
    setAreaOfStudy: (areaOfStudy: string) => {
      self.userAffiliationForm?.areaOfStudy.setValue(areaOfStudy)
    },
    setStartDate: (startDate: Date) => {
      self.startDate = startDate
    },
    setEndDate: (endDate: Date) => {
      self.endDate = endDate
    },
    setGradYear: (gradYear: number) => {
      self.gradYear = gradYear
    },
    resetForm: () => {
      self.userAffiliationForm = undefined
      self.startDate = undefined
      self.endDate = undefined
      self.gradYear = undefined
    },
  }))
  .actions((self) => ({
    fetchUserAffiliations: flow(function* (userId: string) {
      const userAffiliationApi = new UserAffiliationApi(self.environment.api)
      const result = yield* toGenerator(userAffiliationApi.getUserAffiliations({ userId }))
      return self.putUserAffiliations(result.userAffiliations)
    }),
    createUserAffiliation: flow(function* (request: UserAffiliationRequest) {
      const userAffiliationApi = new UserAffiliationApi(self.environment.api)
      const result = yield* toGenerator(userAffiliationApi.createUserAffiliation(request))
      return self.putUserAffiliation(result.userAffiliation)
    }),
    update: flow(function* (userAffiliationId: string, request: UserAffiliationRequest) {
      const userAffiliationApi = new UserAffiliationApi(self.environment.api)
      yield userAffiliationApi.updateUserAffiliation(userAffiliationId, request)
    }),
    updatePrimaryUserAffiliation: flow(function* (userAffiliationId: string) {
      const userAffiliationApi = new UserAffiliationApi(self.environment.api)
      yield userAffiliationApi.makePrimary(userAffiliationId)
    }),
    delete: flow(function* (userAffiliationId: string) {
      const userAffiliationApi = new UserAffiliationApi(self.environment.api)
      yield userAffiliationApi.deleteUserAffiliation(userAffiliationId)
      self.userAffiliations.delete(userAffiliationId)
    }),
  }))
  .views((self) => ({
    getUserAffiliations(userId: string) {
      return sortBy(
        Array.from(self.userAffiliations.values()).filter((ua) => ua.userId === userId),
        (a) => a.ordinal,
      )
    },
    getPrimaryUserAffiliation(userId: string) {
      const primary = Array.from(self.userAffiliations.values()).find(
        (ua) => ua.userId === userId && ua.isPrimary,
      )
      if (primary && primary.affiliation?.id) {
        const affiliation = self.affiliationStore.affiliations.get(primary.affiliation?.id)
        return { ...primary, affiliation: affiliation }
      }
      return null
    },
  }))
  .actions((self) => ({
    move: flow(function* (userAffiliationId: string, itemOrder: { key: string }[]) {
      const userAffiliationApi = new UserAffiliationApi(self.environment.api)
      const foundIndex = itemOrder.findIndex(({ key }) => key === userAffiliationId)
      if (foundIndex === -1) {
        throw new Error("Couldn't find user affiliation")
      }

      let nextAffiliationId: string | undefined
      if (foundIndex < itemOrder.length - 1) {
        nextAffiliationId = itemOrder[foundIndex + 1].key
      }

      const currentOrder =
        sortBy([...self.userAffiliations.values()], (e) => e.ordinal)?.map((p) => p?.id) || []

      // change the order of the affiliations in the store
      let ordinal = 1
      itemOrder.forEach((element) => {
        const objToUpdate = self.userAffiliations.get(element.key)
        if (objToUpdate) {
          objToUpdate.ordinal = ordinal
        }
        ordinal++
      })

      try {
        yield userAffiliationApi.move(userAffiliationId, nextAffiliationId)
      } catch (err) {
        // reset to original order
        let ordinal = 1
        currentOrder.forEach((element) => {
          const objToUpdate = self.userAffiliations.get(element)
          if (objToUpdate) {
            objToUpdate.ordinal = ordinal
          }
          ordinal++
        })
        throw err
      }
    }),
  }))

export type UserAffiliationStore = Instance<typeof UserAffiliationStoreModel>
export const withUserAffiliationStore = (self: IStateTreeNode) => ({
  views: {
    get userAffiliationStore(): UserAffiliationStore {
      return getParent<Instance<typeof RootStoreModel>>(self).userAffiliationStore
    },
  },
})
