import React, { ReactNode } from "react"
import { CSSObject, useTheme } from "@emotion/react"
import { Text } from "../../text/text"
import { AppTheme } from "pitch45-common/theme/app-theme.types"
import { textPresets } from "../../text/text.presets"
import { Checkbox, FormControlLabel } from "@mui/material"
import { color } from "pitch45-common/theme/shared-colors"

interface CheckboxInputProps {
  label: string | ReactNode
  description?: string
  value: boolean
  disabled?: boolean
  onChange: (newValue: boolean) => void
}

function getStyles(theme: AppTheme, disabled?: boolean) {
  const styles = {
    CONTAINER: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    CHECKBOX_SELECTOR: {
      color: theme.colors.text,
      alignSelf: "flex-start",
      "&.Mui-disabled": {
        color: theme.colors.dimText,
      },
    },
    CHECKBOX_LABEL: {
      color: disabled ? theme.colors.dimText : theme.colors.text,
      whiteSpace: "normal",
    },
  }
  return styles as { [key in keyof typeof styles]: CSSObject }
}

export const CheckboxInput = (props: CheckboxInputProps) => {
  const { label, value, onChange, disabled, description, ...rest } = props
  const theme = useTheme() as AppTheme
  const styles = getStyles(theme, disabled)

  return (
    <div css={styles.CONTAINER} {...rest}>
      <FormControlLabel
        control={
          <Checkbox
            css={styles.CHECKBOX_SELECTOR}
            checked={value}
            onChange={() => onChange(!value)}
            disabled={disabled}
            sx={{
              "&.Mui-checked": {
                color: color.primary,
              },
            }}
          />
        }
        label={
          <div css={{ display: "flex", flexDirection: "column" }}>
            <Text css={styles.CHECKBOX_LABEL} preset={textPresets.mediumSemibold}>
              {label}
            </Text>
            {description && (
              <Text css={styles.CHECKBOX_LABEL} preset={textPresets.small}>
                {description}
              </Text>
            )}
          </div>
        }
      />
    </div>
  )
}

export default CheckboxInput
