import React from "react"
import { CSSObject } from "@emotion/react"
import Avatar from "@mui/material/Avatar"
import Chip from "@mui/material/Chip"
import { Icon, Icons, Text } from "../../ui"
import { color } from "../../theme/color"
import { textPresets } from "../text/text.presets"

interface PillProps {
  label: string
  entityLogo?: string
  chipStyleOverrides?: CSSObject
  containerStyleOverrides?: CSSObject
  rightIconColor?: string
  rightIconName?: Icons
  onRightIconClick?: (event: React.MouseEvent<HTMLElement>) => void
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  variant?: "filled" | "outlined"
}

function getStyles() {
  const styles = {
    PILL_CONTAINER: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
    },
  }
  return styles as { [key in keyof typeof styles]: CSSObject }
}

export function Pill(props: PillProps) {
  const {
    label,
    entityLogo,
    chipStyleOverrides,
    containerStyleOverrides,
    rightIconColor,
    rightIconName,
    onRightIconClick,
    onClick,
    variant,
  } = props
  const styles = getStyles()

  return (
    <div css={[styles.PILL_CONTAINER, containerStyleOverrides]} onClick={onClick}>
      <Chip
        key={label}
        avatar={entityLogo ? <Avatar alt={label} src={entityLogo} /> : undefined}
        label={
          <Text preset={textPresets.small} truncate>
            {label}
          </Text>
        }
        onDelete={onRightIconClick}
        deleteIcon={rightIconName ? <Icon icon={rightIconName} size="small" /> : undefined}
        variant={variant ?? "outlined"}
        sx={{
          ...chipStyleOverrides,
          "& .MuiSvgIcon-root": { height: "15px", color: rightIconColor ?? color.border },
        }}
      />
    </div>
  )
}

export default Pill
