import { Instance, types } from "mobx-state-tree"
import { DateTime } from "../utils/datetime-type"
import { StringEnum } from "../utils/string-enum-type"

export enum ReactionType {
  AllSet = "AllSet",
  GreatJob = "GreatJob",
  Outstanding = "Outstanding",
  BigIdea = "BigIdea",
  LetsConnect = "LetsConnect",
}

export const getReactionIcon = (reactionType: ReactionType, { filled }: { filled: boolean }) => {
  switch (reactionType) {
    case ReactionType.AllSet:
      return filled ? "all-set-filled" : "all-set-outlined"
    case ReactionType.BigIdea:
      return filled ? "big-idea-filled" : "big-idea-outlined"
    case ReactionType.Outstanding:
      return filled ? "outstanding-filled" : "outstanding-outlined"
    case ReactionType.GreatJob:
      return filled ? "great-job-filled" : "great-job-outlined"
    case ReactionType.LetsConnect:
      return filled ? "lets-connect-filled" : "lets-connect-outlined"
  }
}

export const getReactionCopy = (reactionType: ReactionType) => {
  switch (reactionType) {
    case ReactionType.AllSet:
      return "All Set"
    case ReactionType.BigIdea:
      return "Big Idea"
    case ReactionType.Outstanding:
      return "Outstanding"
    case ReactionType.GreatJob:
      return "Great Job"
    case ReactionType.LetsConnect:
      return "Let's Connect"
  }
}

export const ReactionModel = types.model("Reaction").props({
  id: types.identifier,
  userId: types.maybe(types.string),
  pitchId: types.maybe(types.string),
  reactionType: types.maybe(StringEnum(ReactionType)),
  createdUtc: types.maybe(DateTime),
  updatedUtc: types.maybe(DateTime),
})

export type Reaction = Instance<typeof ReactionModel>
