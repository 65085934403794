import { Instance, types } from "mobx-state-tree"

export const AppConfigModel = types.model({
  id: types.string,
  applyToVersionOrAbove: types.string,
  platform: types.string,
  // Configuration values
  minimumAllowedAppVersion: types.string,
  storeUrl: types.string,
  maxAppVersion: types.maybe(types.string),
  maxAppVersionStoreUrl: types.maybe(types.string),
})

export type AppConfig = Instance<typeof AppConfigModel>
