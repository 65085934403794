import { types, getParent, Instance } from "mobx-state-tree"
import { Form } from "./form-model"

export const StringFieldModel = types
  .model("StringField")
  .props({
    name: types.string,
    value: types.maybe(types.string),
    initialValue: types.maybe(types.string),
    error: types.maybe(types.string),
    touched: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get rules() {
      return {}
    },
    get isValid() {
      return !self.error
    },
    get isDirty() {
      return self.value !== self.initialValue
    },
  }))
  .actions((self) => ({
    onChange(newValue) {
      self.value = newValue
      ;(getParent(self) as Form).validate()
    },
    onBlur() {
      self.touched = true
      ;(getParent(self) as Form).validate()
    },
    setTouched(touched) {
      self.touched = touched
    },
    setError(error) {
      self.error = error
    },
    setValue(value) {
      self.value = value
    },
    reset() {
      self.initialValue = self.value
      self.touched = false
      self.error = undefined
    },
  }))
  .views((self) => ({
    get textInputProps() {
      return {
        value: self.value || "",
        error: self.error,
        onChangeText: self.onChange,
        onBlur: self.onBlur,
      }
    },
  }))

export const createField = (name: string, initialValue?: string): any => {
  return {
    name,
    value: initialValue,
    initialValue: initialValue,
  }
}

export const createFieldModel = (rules: any) => {
  return StringFieldModel.views(() => ({
    get rules() {
      return rules
    },
  }))
}

export type StringField = Instance<typeof StringFieldModel>
