import { ApiResponse } from "apisauce"
import { Api } from "./api"
import { ApiError } from "./api-problem"
import { Pitch } from "../../models/pitch"
import logger from "pitch45-common/logging/logger"
import { Conversation, Message } from "../../models/messaging"

const API_PATH = "api/Messaging/"

export type ConversationResult = { conversation: Conversation }

export type ConversationsResult = { conversations: Conversation[] }

export type MessagesResult = { messages: Message[] }

// Messages on the client store the data as an object instead of a string
const convertToClientMessage = (m: Message) => {
  if (m.data && (m.data as string).length) {
    try {
      m.data = JSON.parse(m.data as string)
    } catch {
      logger.log("Error parsing message data", { messageData: m.data })
      m.data = undefined
    }
  } else {
    m.data = undefined
  }
  return m
}

const mapConversation = (c: Conversation) => {
  if (c.lastMessage) {
    c.lastMessage = convertToClientMessage(c.lastMessage)
  }
  return c
}

export class MessagingApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async createConversation(userIds, message: Message): Promise<ConversationResult> {
    const response: ApiResponse<Conversation> = await this.api.apisauce.post(
      API_PATH + "conversation/start",
      {
        userIds,
        message: { ...message, data: JSON.stringify(message.data) },
      },
    )

    if (!response.ok || !response.data) {
      throw new ApiError("MessagingApi.createConversation", response)
    }

    return { conversation: response.data }
  }

  async getConversationById({ conversationId }): Promise<ConversationResult> {
    const response: ApiResponse<Conversation> = await this.api.apisauce.get(
      `${API_PATH}conversation/${conversationId}`,
    )

    if (!response.ok || !response.data) {
      throw new ApiError("MessagingApi.getConversationById", response)
    }

    return { conversation: mapConversation(response.data) }
  }

  async sendMessage({ message }: { message: Message }): Promise<void> {
    const response: ApiResponse<Pitch[]> = await this.api.apisauce.post(API_PATH + "send", {
      ...message,
      data: JSON.stringify(message.data),
    })
    if (!response.ok) {
      throw new ApiError("MessagingApi.sendMessage", response)
    }
  }

  async getExistingConversation({ userIds }): Promise<ConversationResult> {
    const response: ApiResponse<{ conversation: Conversation }> = await this.api.apisauce.post(
      API_PATH + "conversation/existing",
      userIds,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("MessagingApi.getExistingConversation", response)
    }
    return { conversation: response.data.conversation }
  }

  async getAllConversations(entityId?: string): Promise<ConversationsResult> {
    const response: ApiResponse<ConversationsResult> = await this.api.apisauce.get(
      API_PATH + "conversations/all" + (entityId ? `?entityId=${entityId}` : ""),
    )
    if (!response.ok || !response.data) {
      throw new ApiError("MessagingApi.getAllConversations", response)
    }
    return { conversations: response.data.conversations.map(mapConversation) }
  }

  async getMessages({
    conversationId,
    limit,
    cursor,
  }: {
    conversationId: string
    limit: number
    cursor?: string
  }): Promise<MessagesResult> {
    const response: ApiResponse<MessagesResult> = await this.api.apisauce.get(
      `${API_PATH}messages/${conversationId}`,
      { limit, cursor },
    )

    if (!response.ok || !response.data) {
      throw new ApiError("MessagingApi.getMessages", response)
    }

    // Data field is stored as a string on server
    return { messages: response.data.messages.map(convertToClientMessage) }
  }

  async getUnreadConversationIds(): Promise<{ conversationIds: string[] }> {
    const response: ApiResponse<{ conversationIds: string[] }> = await this.api.apisauce.get(
      `${API_PATH}conversation/unread`,
    )

    if (!response.ok || !response.data) {
      throw new ApiError("MessagingApi.getUnreadConversationIds", response)
    }

    return { conversationIds: response.data.conversationIds }
  }

  async updateLastViewedMessage({
    conversationId,
    lastMessageId,
  }: {
    conversationId: string
    lastMessageId: string
  }): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.post(
      `${API_PATH}conversation/${conversationId}/lastViewed/${lastMessageId}`,
    )
    if (!response.ok) {
      throw new ApiError("MessagingApi.updateLastViewedMessage", response)
    }
  }

  async acceptConversation({ conversationId }: { conversationId: string }): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.put(
      `${API_PATH}conversation/${conversationId}/accept`,
    )
    if (!response.ok) {
      throw new ApiError("MessagingApi.acceptConversation", response)
    }
  }

  async rejectConversation({ conversationId }: { conversationId: string }): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.put(
      `${API_PATH}conversation/${conversationId}/reject`,
    )
    if (!response.ok) {
      throw new ApiError("MessagingApi.rejectConversation", response)
    }
  }
}
