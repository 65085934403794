import { InMemoryWebStorage, UserManager, WebStorageStateStore } from "oidc-client-ts"
import { OAuthTokenResponse } from "pitch45-common/utils/oauth"
import logger from "pitch45-common/logging/logger"

// enable internal oidc-logging:
// import { Log } from "oidc-client-ts"
// Log.setLogger(console)
// Log.setLevel(Log.DEBUG)

class WebOAuthProvider {
  userManager: UserManager
  redirectUri = `${process.env.NEXT_PUBLIC_WEB_APP_BASE_URL}/auth/silent-refresh.html`
  onSilentRenew?: (accessToken) => void

  constructor() {
    const scopes = ["https://scopes.loop45.com/api", "openid"]

    this.userManager = new UserManager({
      authority: process.env.NEXT_PUBLIC_AUTH_ISSUER_URL!,
      client_id: "com.loop45.web",
      redirect_uri: this.redirectUri,
      silent_redirect_uri: this.redirectUri,
      response_type: "code",
      scope: scopes.join(" "),
      accessTokenExpiringNotificationTimeInSeconds: 30,
      includeIdTokenInSilentRenew: false,
      userStore: new WebStorageStateStore({ store: new InMemoryWebStorage() }),
      metadata: {
        issuer: process.env.NEXT_PUBLIC_AUTH_ISSUER_URL!,
        authorization_endpoint: `${process.env.NEXT_PUBLIC_AUTH_ISSUER_URL}/connect/authorize`,
        token_endpoint: `${process.env.NEXT_PUBLIC_AUTH_ISSUER_URL}/connect/token`,
        userinfo_endpoint: `${process.env.NEXT_PUBLIC_AUTH_ISSUER_URL}/connect/userinfo`,
        end_session_endpoint: `${process.env.NEXT_PUBLIC_AUTH_ISSUER_URL}/connect/endsession`,
        jwks_uri: `${process.env.NEXT_PUBLIC_AUTH_ISSUER_URL}/.well-known/openid-configuration/jwks`,
        check_session_iframe: `${process.env.NEXT_PUBLIC_AUTH_ISSUER_URL}/connect/checksession`,
        revocation_endpoint: `${process.env.NEXT_PUBLIC_AUTH_ISSUER_URL}/connect/revocation`,
        introspection_endpoint: `${process.env.NEXT_PUBLIC_AUTH_ISSUER_URL}/connect/introspect`,
      },
    })

    // oidc-client-js breaks function scope in some scenarios
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this
    this.userManager.events.addAccessTokenExpiring(function () {
      logger.log("api token expiring")
      self.userManager
        .signinSilent()
        .then(function (user) {
          logger.log("api silent renew success")
          if (self.onSilentRenew && user) {
            self.onSilentRenew(user.access_token)
          }
        })
        .catch(function (e) {
          logger.logError(new Error("api silent renew error"), { originalError: e })
          if (self.onSilentRenew) {
            self.onSilentRenew(undefined)
          }
        })
    })
  }

  async authorize() {
    try {
      const user = await this.userManager.signinSilent()
      if (!user) {
        throw new Error("userManager.signinSilent return undefined user")
      }
      return {
        accessToken: user.access_token,
      }
    } catch (e) {
      logger.log("not logged in to authorization server")
      throw e
    }
  }

  refresh(): Promise<OAuthTokenResponse> {
    throw new Error("no refresh tokens in web")
  }

  revoke() {
    this.userManager.clearStaleState()
    return this.userManager
      .getUser()
      .then((user) =>
        this.userManager.signoutRedirect({
          // need the id_token to skip the logout screen that the auth server attempts to provide
          id_token_hint: user?.id_token,
          post_logout_redirect_uri: `${process.env.NEXT_PUBLIC_WEB_APP_BASE_URL}/login`,
        }),
      )
      .catch((e) => {
        logger.logError(new Error("Error getting user to logout"), { originalError: e })
      })
  }
}

export default WebOAuthProvider
