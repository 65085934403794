export const webTransitions = {
  duration: {
    fast: "150ms",
    default: "300ms",
    slow: "500ms",
  },
  easing: {
    linear: "linear",
    easeInOut: "ease-in-out",
    easeIn: "ease-in",
    easeOut: "ease-out",
  },
}
