import { createTheme } from "@mui/material/styles"
import { AppTheme } from "pitch45-common/theme/app-theme.types"

export const themeMui = createTheme({
  typography: {
    fontFamily: "JosefinSans",
  },
})

export const getMergedTheme = (appTheme: AppTheme) => {
  return {
    ...appTheme,
    webTypography: appTheme.typography,
    ...themeMui,
  }
}
