import { ApiResponse } from "apisauce"
import { Api } from "./api"
import { ApiError } from "./api-problem"
import { Assignment, AssignmentParams } from "../../models/assignment"

const API_PATH = "api/Assignment"

export type AssignmentResult = { assignments: Assignment[] }

const getUserIdsField = (assignment: AssignmentParams) => {
  return assignment.assignToCurrentAndFutureMembers || assignment.assignToCurrentMembers
    ? []
    : assignment.users.map((u) => u.id)
}

export class AssignmentApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async createPlaylistAssignment(playlistId: string, assignment: AssignmentParams): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.post(
      `${API_PATH}/playlist/${playlistId}`,
      {
        ...assignment,
        userIds: getUserIdsField(assignment),
      },
    )
    if (!response.ok) {
      throw new ApiError("AssignmentApi.createAssignment", response)
    }
  }

  async getAssignment(resourceId: string): Promise<AssignmentResult> {
    const response: ApiResponse<AssignmentResult> = await this.api.apisauce.get(
      `${API_PATH}/resource/${resourceId}`,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("AssignmentApi.getAssignment", response)
    }
    return response.data
  }

  async updateAssignment(assignment: AssignmentParams): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.patch(
      `${API_PATH}/${assignment.id}`,
      {
        ...assignment,
        userIds: getUserIdsField(assignment),
      },
    )
    if (!response.ok) {
      throw new ApiError("AssignmentApi.updateAssignment", response)
    }
  }

  async deleteAssignment(assignmentId: string): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.delete(
      `${API_PATH}/${assignmentId}`,
    )
    if (!response.ok) {
      throw new ApiError("AssignmentApi.deleteAssignment", response)
    }
  }
}
