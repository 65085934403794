import { useState } from "react"

export function useFormField<T>(initialValue?: T) {
  const [_value, setValue] = useState<T | undefined>(initialValue)
  const [error, setError] = useState<string>("")

  // Provide a way to set a validator
  // Doing this after the intialization allows validator functions
  // to depend on other fields
  let validator: (value?: T) => boolean = () => true
  const setValidator = (newValidator: (value?: T) => boolean) => {
    validator = newValidator
  }

  const validate = (value?: T) => validator(value)

  // wrapper for setValue that calls the fields validator
  const setValueAndValidate = (newValue?: T) => {
    setValue(newValue)
    validate(newValue)
  }

  return {
    get value() {
      return _value
    },
    setValue: setValueAndValidate,
    error,
    setError,
    setValidator,
    validate,
  }
}
