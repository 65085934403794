import { ApiResponse } from "apisauce"
import { Api } from "./api"
import { ApiError } from "./api-problem"
import { Program, ProgramType } from "../../models/program"

const API_PATH = "api/Program/"

export type ProgramResult = { program: Program }

export class ProgramApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async getProgram(playlistId): Promise<ProgramResult> {
    const response: ApiResponse<Program> = await this.api.apisauce.get(API_PATH + `${playlistId}`)
    if (!response.ok || !response.data) {
      throw new ApiError("ProgramApi.getProgram", response)
    }
    return {
      program: response.data,
    }
  }

  async createProgram(program: {
    id: string
    entityId: string
    name: string
    description?: string
    type: ProgramType
  }): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.post(API_PATH, program)
    if (!response.ok) {
      throw new ApiError("ProgramApi.createProgram", response)
    }
  }

  async deleteProgram({ programId }): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.delete(API_PATH + programId)
    if (!response.ok) {
      throw new ApiError("ProgramApi.deleteProgram", response)
    }
  }

  async updateProgram(program: { id: string; name: string; description?: string }): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.patch(API_PATH + program.id, {
      name: program.name,
      description: program.description,
    })
    if (!response.ok) {
      throw new ApiError("ProgramApi.updateProgram", response)
    }
  }

  async addPlaylists({
    programId,
    playlistIds,
  }: {
    programId: string
    playlistIds: string[]
  }): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.post(
      API_PATH + `${programId}/playlist/add`,
      playlistIds,
    )
    if (!response.ok) {
      throw new ApiError("ProgramApi.addPlaylists", response)
    }
  }

  async removePlaylists({
    programId,
    playlistIds,
  }: {
    programId: string
    playlistIds: string[]
  }): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.post(
      API_PATH + `${programId}/playlist/remove`,
      playlistIds,
    )
    if (!response.ok) {
      throw new ApiError("ProgramApi.removePlaylists", response)
    }
  }
}
