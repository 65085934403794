import { Instance, types } from "mobx-state-tree"
import { StringEnum } from "../utils/string-enum-type"
import { DateTime } from "../utils/datetime-type"
import { AffiliationModel } from "./affiliation"

export enum UserAffiliationType {
  Experience = "Experience",
  Education = "Education",
}

export const UserAffiliationModel = types.model("UserAffiliation").props({
  id: types.identifier,
  userId: types.string,
  affiliation: types.maybe(types.safeReference(AffiliationModel)),
  title: types.string,
  startDate: types.maybe(types.Date),
  endDate: types.maybe(types.Date),
  type: StringEnum(UserAffiliationType),
  gradYear: types.maybe(types.integer),
  areaOfStudy: types.maybe(types.string),
  isPrimary: types.boolean,
  ordinal: types.maybe(types.number),
  createdUtc: types.maybe(DateTime),
})

export type UserAffiliation = Instance<typeof UserAffiliationModel>
