import React from "react"
import { CSSObject, useTheme } from "@emotion/react"

import { WebAppTheme } from "pitch45-common/theme/app-theme.types"
import { unitSpacing } from "../../theme/spacing"
import IconButton from "@mui/material/IconButton"
import ContentCopy from "@mui/icons-material/ContentCopy"
import { color } from "pitch45-common/theme"
import { iconSizing } from "../../theme"

function getStyles(theme: WebAppTheme) {
  const styles = {
    CONTAINER: {
      position: "relative",
      border: `1px solid ${theme.colors.border}`,
      borderRadius: "50px",
      padding: unitSpacing.half,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    INPUT: {
      color: theme.colors.text,
      background: "transparent",
      border: "none",
      marginLeft: unitSpacing.unit,
      flex: 1,
    },
  }
  return styles as { [key in keyof typeof styles]: CSSObject }
}

interface CopyableInputProps {
  value: string
  onSuccess: (value: string) => void
  onError: (value: string) => void
}

export const CopyableInput = ({ value, onSuccess, onError, ...rest }: CopyableInputProps) => {
  const theme = useTheme() as WebAppTheme
  const { CONTAINER, INPUT } = getStyles(theme)
  const copyValueToClipboard = () => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        onSuccess(value)
      })
      .catch(() => {
        onError(value)
      })
  }

  return (
    <div {...rest} css={CONTAINER}>
      <input css={INPUT} value={value} disabled />
      <IconButton onClick={copyValueToClipboard} sx={{ marginLeft: "auto" }}>
        <ContentCopy sx={{ color: color.primary, fontSize: iconSizing.medium }} />
      </IconButton>
    </div>
  )
}

export default CopyableInput
