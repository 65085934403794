import { getParent, Instance, IStateTreeNode, types } from "mobx-state-tree"

import { withEnvironment } from "./extensions/with-environment"
import { withUserStore } from "./user-store"
import { withOrganizationStore } from "./organization-store"
import { withGroupStore } from "./group-store"
import { RootStoreModel } from "./root-store"

export const EntityStoreModel = types
  .model("EntityStore")
  .props({
    lastSelectedEntityId: types.maybe(types.string),
  })
  .extend(withEnvironment)
  .extend(withUserStore)
  .extend(withOrganizationStore)
  .extend(withGroupStore)
  .actions((self) => ({
    setLastSelectedEntityId(entityId?: string) {
      self.lastSelectedEntityId = entityId
    },
  }))

export type EntityStore = Instance<typeof EntityStoreModel>
export const withEntityStore = (self: IStateTreeNode) => ({
  views: {
    get entityStore(): EntityStore {
      return getParent<Instance<typeof RootStoreModel>>(self).entityStore
    },
  },
})
