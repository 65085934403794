import { IStateTreeNode, Instance, getParent, types } from "mobx-state-tree"
import { withEnvironment } from "./extensions/with-environment"
import { withSessionStore } from "./session-store"
import { omit } from "lodash-es"
import { Permission } from "../models/session"
import { Entity } from "../models/entity"
import {
  ContentVisibility,
  internalVisibilityOptions,
  externalVisibilityOptions,
  privateVisibilityOptions,
} from "../models/content-visibility"
import { Group } from "../models/group"
import { OrganizationVisibilityStatus } from "../models/organization"
import { RootStoreModel } from "./root-store"

export const ContentPermissionStoreModel = types
  .model("ContentPermissionStore")
  .extend(withEnvironment)
  .extend(withSessionStore)
  .views((self) => ({
    getUserContentVisibilityOptions() {
      return [ContentVisibility.External, ContentVisibility.Private]
    },
    getManagedContentVisibilities: function (entity: Entity | undefined) {
      if (!entity) {
        return []
      }

      const visibilities: (ContentVisibility | string)[] = []
      if (self.sessionStore.hasPermission(entity.id, Permission.ManageExternalContent)) {
        visibilities.push(ContentVisibility.External)
      }
      if (self.sessionStore.hasPermission(entity.id, Permission.ManageInternalContent)) {
        visibilities.push(ContentVisibility.Internal)
      }
      if (self.sessionStore.hasPermission(entity.id, Permission.ManagePrivateContent)) {
        visibilities.push(ContentVisibility.Private)
      }
      return visibilities
    },
  }))
  .views((self) => ({
    getGroupContentVisibilityOptions(group: Group | undefined) {
      const visibilities = self.getManagedContentVisibilities(group?.toEntity())
      if (group?.visibility !== OrganizationVisibilityStatus.External) {
        return omit(visibilities, ContentVisibility.External)
      }
      return visibilities
    },
    hasViewPermission(entity: Entity | undefined, visibility?: ContentVisibility | string) {
      if (!entity || !visibility) {
        return false
      }

      if (entity.id === self.sessionStore.currentUser?.id) {
        return true
      }

      if (externalVisibilityOptions.includes(visibility as ContentVisibility)) {
        return true
      } else if (
        internalVisibilityOptions.includes(visibility as ContentVisibility) &&
        self.sessionStore.hasPermission(entity.id, Permission.ViewInternalContent)
      ) {
        return true
      } else if (
        privateVisibilityOptions.includes(visibility as ContentVisibility) &&
        self.sessionStore.hasPermission(entity.id, Permission.ViewPrivateContent)
      ) {
        return true
      }
      return false
    },
    hasViewChildrenPermission(entity: Entity | undefined, visibility: ContentVisibility | string) {
      if (!entity || !visibility) {
        return false
      }

      if (entity.id === self.sessionStore.currentUser?.id) {
        return true
      }

      const externalView = [
        ContentVisibility.External,
        ContentVisibility.InternalAddExternalViewChildren,
        ContentVisibility.PrivateAddExternalViewChildren,
      ]

      const internalView = [
        ContentVisibility.Internal,
        ContentVisibility.ExternalAddInternalViewChildren,
        ContentVisibility.PrivateAddInternalViewChildren,
      ]

      const privateView = [
        ContentVisibility.Private,
        ContentVisibility.InternalAddPrivateViewChildren,
        ContentVisibility.ExternalAddPrivateViewChildren,
      ]

      if (entity.id === self.sessionStore.currentUser?.id) {
        return true
      }

      if (externalView.includes(visibility as ContentVisibility)) {
        return true
      } else if (
        internalView.includes(visibility as ContentVisibility) &&
        self.sessionStore.hasPermission(entity.id, Permission.ViewInternalContent)
      ) {
        return true
      } else if (
        privateView.includes(visibility as ContentVisibility) &&
        self.sessionStore.hasPermission(entity.id, Permission.ViewPrivateContent)
      ) {
        return true
      }
      return false
    },
    hasInsertChildrenPermission(
      entity: Entity | undefined,
      visibility: ContentVisibility | string,
    ) {
      if (!entity || !visibility) {
        return false
      }

      if (entity.id === self.sessionStore.currentUser?.id) {
        return true
      }

      const externalView = [
        ContentVisibility.External,
        ContentVisibility.ExternalAddInternalViewChildren,
        ContentVisibility.ExternalAddPrivateViewChildren,
      ]

      const internalView = [
        ContentVisibility.Internal,
        ContentVisibility.InternalAddExternalViewChildren,
        ContentVisibility.InternalAddPrivateViewChildren,
      ]

      const privateView = [
        ContentVisibility.Private,
        ContentVisibility.PrivateAddExternalViewChildren,
        ContentVisibility.PrivateAddInternalViewChildren,
      ]

      if (entity.id === self.sessionStore.currentUser?.id) {
        return true
      }

      if (externalView.includes(visibility as ContentVisibility)) {
        return true
      } else if (
        internalView.includes(visibility as ContentVisibility) &&
        self.sessionStore.hasPermission(entity.id, Permission.ViewInternalContent)
      ) {
        return true
      } else if (
        privateView.includes(visibility as ContentVisibility) &&
        self.sessionStore.hasPermission(entity.id, Permission.ViewPrivateContent)
      ) {
        return true
      }
      return false
    },
    hasManagePermission(entity: Entity | undefined, visibility: ContentVisibility | string) {
      if (!entity || !visibility) {
        return false
      }

      if (entity.id === self.sessionStore.currentUser?.id) {
        return true
      }

      if (
        externalVisibilityOptions.includes(visibility as ContentVisibility) &&
        self.sessionStore.hasPermission(entity.id, Permission.ManageExternalContent)
      ) {
        return true
      } else if (
        internalVisibilityOptions.includes(visibility as ContentVisibility) &&
        self.sessionStore.hasPermission(entity.id, Permission.ManageInternalContent)
      ) {
        return true
      } else if (
        privateVisibilityOptions.includes(visibility as ContentVisibility) &&
        self.sessionStore.hasPermission(entity.id, Permission.ManagePrivateContent)
      ) {
        return true
      }
      return false
    },
  }))

export type ContentPermissionStore = Instance<typeof ContentPermissionStoreModel>
export const withContentPermissionStore = (self: IStateTreeNode) => ({
  views: {
    get contentPermissionStore(): ContentPermissionStore {
      return getParent<Instance<typeof RootStoreModel>>(self).contentPermissionStore
    },
  },
})
