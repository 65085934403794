import { Instance, types } from "mobx-state-tree"

export enum ManagedRole {
  OrganizationAdmin = "OrganizationAdmin",
  OrganizationMember = "OrganizationMember",
  OrganizationOwner = "OrganizationOwner",
  GroupAdmin = "GroupAdmin",
  GroupMember = "GroupMember",
  GroupCreator = "GroupCreator",
}

export enum RoleLabel {
  OrganizationOwner = "Organization Owner",
  OrganizationAdmin = "Organization Admin",
  OrganizationMember = "Member",
  OrganizationGuest = "Guest",
  GroupAdmin = "Group Director",
  GroupCreator = "Group Creator",
  GroupMember = "Group Participant",
}

/**
 * OrganizationRole model.
 */
export const OrganizationRoleModel = types.model("OrganizationRole").props({
  id: types.identifier,
  organizationId: types.string,
  name: types.maybe(types.string),
  managedRole: types.maybe(types.string),
  isGroupRole: types.maybe(types.boolean),
})

export type OrganizationRole = Instance<typeof OrganizationRoleModel>
