export const unitSpacing = {
  quarter: "0.25rem",
  half: "0.5rem",
  halfPlus: "0.75rem",
  unit: "1rem",
  unitPlus: "1.5rem",
  double: "2rem",
  doubleQuarter: "2.25rem",
  quad: "4rem",
  quadPlus: "6em",
}

export const semanticSpacing = {
  /**
   * Default
   */
  default: unitSpacing.unit,
  /**
   * Left and Right padding with 0 vertical padding
   */
  horizontal: {
    normal: `0px ${unitSpacing.unit}`,
    narrow: `0px ${unitSpacing.half}`,
    expanded: `0px ${unitSpacing.unitPlus}`,
  },
  /**
   * Top and bottom padding with 0 horizontal padding
   */
  vertical: {
    normal: `${unitSpacing.unit} 0px`,
    narrow: `${unitSpacing.half} 0px`,
    expanded: `${unitSpacing.double} 0px`,
  },
  /**
   * Padding for card elements
   */
  card: unitSpacing.unit,
  /**
   * Padding for modal elements
   */
  modal: unitSpacing.unitPlus,
  /**
   * Table padding
   */
  table: `${unitSpacing.unit} ${unitSpacing.unitPlus}`,
  /**
   * Narrow padding for list elements
   */
  narrow: `${unitSpacing.quarter} ${unitSpacing.half}`,
  /**
   * Padding for list elements
   */
  list: `${unitSpacing.half} ${unitSpacing.half}`,
  /**
   * Padding for button component
   */
  button: {
    primary: `0px ${unitSpacing.unit}`,
    narrow: `0px ${unitSpacing.half}`,
  },
  /**
   * 2:1 Horizontal to vertical padding
   */
  horizontalPlus: `${unitSpacing.unit} ${unitSpacing.double}`,
  /**
   * 2:1 Vertical to horizontal padding
   */
  verticalPlus: `${unitSpacing.double} ${unitSpacing.unit}`,
  /**
   * Double default padding
   */
  double: unitSpacing.double,
}
