import { Instance, types } from "mobx-state-tree"
import { withEnvironment } from "./extensions/with-environment"

export const PitchTipsStoreModel = types
  .model("PitchTips")
  .props({
    completedTips: types.map(types.boolean),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    setTipCompleted: (tipId: string, completedValue: boolean) => {
      self.completedTips.set(tipId, completedValue)
    },
  }))

export type PitchTipsStore = Instance<typeof PitchTipsStoreModel>
