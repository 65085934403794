// types
import { ApiResponse } from "apisauce"
import { Api } from "./api"
import { ApiError } from "./api-problem"
import {
  EntitySubscription,
  Invoice,
  PaymentProfile,
  BillingInfo,
  SubscriptionPlan,
  PaymentMethod,
  UserSubscription,
} from "../../models/payments"

type EntitySubscriptionResult = {
  entitySubscription: EntitySubscription
  upcomingInvoice: Invoice
  paymentMethod: PaymentMethod
}
type UserSubscriptionResult = {
  userSubscription: UserSubscription
}
type PaymentProfileResult = { paymentProfile: PaymentProfile }
type BillingInfoResult = { billingInfo: BillingInfo }
type InvoicesResult = {
  invoices: Invoice[]
}
type SubscriptionPlansResult = { subscriptionPlans: SubscriptionPlan[] }
type SubscriptionPlanResult = { subscriptionPlan: SubscriptionPlan }
type CustomerPortalResult = { redirectUrl: string }
type CheckoutSessionResult = { redirectUrl: string }
type HasValidSubscriptionResult = { isValid: boolean }

const API_PATH = "api/Payments/"

export class PaymentsApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  getCustomerPortalUrl(entityId) {
    return `${this.api.url}/${API_PATH}customerPortal/${entityId}`
  }

  async createCustomerPortal(entityId: string): Promise<CustomerPortalResult> {
    const response: ApiResponse<CustomerPortalResult> = await this.api.apisauce.post(
      `${API_PATH}customerPortal/${entityId}`,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("PaymentsApi.createCustomerPortal", response)
    }
    return response.data
  }

  async createCheckoutSession(entityId: string, planId: string): Promise<CheckoutSessionResult> {
    const response: ApiResponse<CheckoutSessionResult> = await this.api.apisauce.post(
      `${API_PATH}checkout/${entityId}/${planId}`,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("PaymentsApi.createCheckoutSession", response)
    }
    return response.data
  }

  async getSubscriptionPlans(entityId: string): Promise<SubscriptionPlansResult> {
    const response: ApiResponse<SubscriptionPlansResult> = await this.api.apisauce.get(
      `${API_PATH}plans/${entityId}`,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("PaymentsApi.getSubscriptionPlans", response)
    }
    return response.data
  }

  async getDefaultOrgSubscriptionPlan(): Promise<SubscriptionPlanResult> {
    const response: ApiResponse<SubscriptionPlanResult> = await this.api.apisauce.get(
      `${API_PATH}plans/default`,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("PaymentsApi.getDefaultOrgSubscriptionPlan", response)
    }
    return response.data
  }

  async getEntitySubscription(entityId): Promise<EntitySubscriptionResult> {
    const response: ApiResponse<EntitySubscriptionResult> = await this.api.apisauce.get(
      `${API_PATH}entitySubscription/${entityId}`,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("PaymentsApi.getEntitySubscription", response)
    }
    return response.data
  }

  async getHasValidEntitySubscription(entityId: string): Promise<boolean> {
    const response: ApiResponse<HasValidSubscriptionResult> = await this.api.apisauce.get(
      `${API_PATH}entitySubscription/${entityId}/valid`,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("PaymentsApi.getHasValidSubscription", response)
    }
    return response.data.isValid
  }

  async getUserSubscription(): Promise<UserSubscriptionResult> {
    const response: ApiResponse<UserSubscriptionResult> = await this.api.apisauce.get(
      `${API_PATH}userSubscription`,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("PaymentsApi.getUserSubscription", response)
    }
    return response.data
  }

  async getPaymentProfile(entityId): Promise<PaymentProfileResult> {
    const response: ApiResponse<PaymentProfileResult> = await this.api.apisauce.get(
      `${API_PATH}profile/${entityId}`,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("PaymentsApi.getPaymentProfile", response)
    }
    return response.data
  }

  async getInvoices(entityId): Promise<InvoicesResult> {
    const response: ApiResponse<InvoicesResult> = await this.api.apisauce.get(
      `${API_PATH}invoice/${entityId}`,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("PaymentsApi.getInvoices", response)
    }
    return response.data
  }

  async getBillingInfo(entityId): Promise<BillingInfoResult> {
    const response: ApiResponse<BillingInfoResult> = await this.api.apisauce.get(
      `${API_PATH}customer/${entityId}`,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("PaymentsApi.getBillingInfo", response)
    }
    return response.data
  }

  async createCustomer(entityId: string, billingInfo: BillingInfo): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.post(
      `${API_PATH}customer/${entityId}`,
      billingInfo,
    )
    if (!response.ok) {
      throw new ApiError("PaymentsApi.createCustomer", response)
    }
  }

  async updateCustomer(entityId: string, billingInfo: BillingInfo): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.put(
      `${API_PATH}customer/${entityId}`,
      billingInfo,
    )
    if (!response.ok) {
      throw new ApiError("PaymentsApi.updateCustomer", response)
    }
  }

  async deleteCustomer(entityId: string): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.delete(
      `${API_PATH}customer/${entityId}`,
    )
    if (!response.ok) {
      throw new ApiError("PaymentsApi.deleteCustomer", response)
    }
  }

  async deleteSubscription(entityId: string): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.delete(
      `${API_PATH}subscription/${entityId}`,
    )
    if (!response.ok) {
      throw new ApiError("PaymentsApi.deleteSubscription", response)
    }
  }
}
