import React, { createContext } from "react"
import { color } from "../../theme/color"
import { Alert, AlertTitle, Snackbar } from "@mui/material"
import { getStyles } from "./toast.styles"
import Check from "@mui/icons-material/Check"
import ErrorOutline from "@mui/icons-material/ErrorOutline"
import InfoOutlined from "@mui/icons-material/InfoOutlined"
import WarningOutlined from "@mui/icons-material/WarningOutlined"
import { fontSizing } from "../../theme"

export enum ToastTypes {
  Info = "info",
  Success = "success",
  Error = "error",
  Warning = "warning",
}

interface ToastContextParams {
  show: (message: string, options: ToastOptionProps) => void
  hide: () => void
}

const initialValue = {
  show: () => {},
  hide: () => {},
}

export const ToastContext = createContext<ToastContextParams>(initialValue)

export interface ToastOptionProps {
  title?: string
  type?: ToastTypes
  withIcon?: boolean
  customIcon?: React.ReactElement
  duration?: number
}

export interface ToastProps {
  open: boolean
  message: string
  options?: ToastOptionProps
  hide: () => void
}

export function Toast(props: ToastProps) {
  const { open, message, options, hide } = props
  const { CONTAINER, ALERT } = getStyles(props)

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={open}
      autoHideDuration={options?.duration || 3000}
      onClose={hide}
      css={CONTAINER}
    >
      <Alert
        onClose={hide}
        css={ALERT}
        severity={options?.type}
        icon={options?.withIcon || options?.customIcon}
        iconMapping={{
          success: options?.customIcon || <Check fontSize="medium" css={{ color: color.text }} />,
          error: options?.customIcon || (
            <ErrorOutline fontSize="medium" css={{ color: color.text }} />
          ),
          warning: options?.customIcon || (
            <WarningOutlined fontSize="medium" css={{ color: color.text }} />
          ),
          info: options?.customIcon || (
            <InfoOutlined fontSize="medium" css={{ color: color.text }} />
          ),
        }}
      >
        {options?.title && (
          <AlertTitle css={{ fontSize: fontSizing.mediumPlus }}>{options?.title}</AlertTitle>
        )}
        {message}
      </Alert>
    </Snackbar>
  )
}

export default Toast
