import { QueryClient } from "@tanstack/react-query"
import { ApiConfig } from "pitch45-common/services/api/api-config"
import logger from "pitch45-common/logging/logger"
import { ConsoleLogger } from "pitch45-common/logging/console"
import { SentryLogger } from "../services/logging/sentry"
import { PinoLogger } from "../services/logging/pino"
import WebOAuthProvider from "../services/authentication/oauth"
import storage from "./storage"
import { Environment } from "pitch45-common/models/environment"

// define variable as declared in next.config.js (for web, already declared for mobile)
// eslint-disable-next-line no-var
declare var __DEV__: boolean

export function createWebEnvironment(queryClient?: QueryClient, oauthProvider?: WebOAuthProvider) {
  const apiConfig: ApiConfig = {
    timeout: 10000,
    url: process.env.NEXT_PUBLIC_API_URL!,
    uploader: Promise.reject,
    oauthProvider,
  }
  // TODO hacky
  const authApiConfig: ApiConfig = {
    timeout: 10000,
    url: process.env.NEXT_PUBLIC_AUTH_ISSUER_URL!,
    // TODO don't need this stuff
    uploader: Promise.reject,
  }

  logger.addLogger("sentry", new SentryLogger())
  if (!__DEV__) {
    logger.addLogger("pino", new PinoLogger())
  } else {
    logger.addLogger(
      "console",
      new ConsoleLogger({
        logWarnings: true,
        logErrors: true,
      }),
    )
  }

  const options = {
    apiConfig,
    storage,
    platform: "web" as any,
    authApiConfig,
    persistStores: false,
    publicAssetBaseUrl: process.env.NEXT_PUBLIC_PUBLIC_ASSETS_CLOUDFRONT_BASE_URL!,
    onLogout: () => {
      // clear react-query cache
      queryClient?.clear()
    },
  }

  const env = new Environment(options)
  env.setup()
  return env
}
