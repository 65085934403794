import { flow, Instance, toGenerator, types } from "mobx-state-tree"
import { withEnvironment } from "./extensions/with-environment"
import { LibraryApi, LibraryFilterRequest } from "../services/api/library-api"
import { LibraryItemModel, LibraryItem, LibraryItemType } from "../models/library-item"
import { LibraryTagModel, LibraryTagType, LibraryTag } from "../models/library-tag"
import { StringEnum } from "../utils/string-enum-type"
import { withPlaylistStore } from "./playlist-store"
import { PlaylistModel } from "../models/playlist"
import { GallerySortType } from "../models/gallery-types"

interface PaginationOptions {
  pageNumber: number
  pageSize: number
}

export function libraryItemCompare(sortType: GallerySortType | string) {
  const sortMapping = {
    [GallerySortType.RecentlyAdded]: (a: LibraryItem, b: LibraryItem) =>
      (b.createdUtc?.getTime() ?? 0) - (a.createdUtc?.getTime() ?? 0),
    [GallerySortType.RecentlyViewed]: (a: LibraryItem, b: LibraryItem) =>
      (b.lastViewedUtc?.getTime() ?? 0) - (a.lastViewedUtc?.getTime() ?? 0),
    [GallerySortType.Alphabetical]: (a: LibraryItem, b: LibraryItem) =>
      a.title?.localeCompare(b.title) ?? 0,
  }
  const result = sortMapping[sortType]
  if (!result) {
    throw new Error("Unable to return libraryItemCompare function for sort type " + sortType)
  }
  return result
}

export const LibraryStoreModel = types
  .model("Library")
  .props({
    tags: types.map(LibraryTagModel),
    selectedTags: types.array(types.safeReference(LibraryTagModel, { acceptsUndefined: false })),
    libraryItems: types.map(LibraryItemModel),
    sortType: types.optional(
      types.maybe(StringEnum(GallerySortType)),
      GallerySortType.RecentlyAdded,
    ),
    searchQuery: types.optional(types.string, ""),
    showTextSearch: types.optional(types.boolean, false),
    playlists: types.array(types.safeReference(PlaylistModel)),
  })
  .extend(withPlaylistStore)
  .extend(withEnvironment)
  .actions((self) => ({
    putLibraryItems(libraryItems: LibraryItem[]) {
      const libraryItemModels: LibraryItem[] = []
      for (const libraryItem of libraryItems) {
        libraryItemModels.push(self.libraryItems.put(libraryItem))
      }
      return libraryItemModels
    },
    putLibraryTags(libraryTags: LibraryTag[]) {
      const libraryTagModels: LibraryTag[] = []
      for (const libraryTag of libraryTags) {
        libraryTagModels.push(self.tags.put(libraryTag))
      }
      return libraryTagModels
    },
  }))
  .actions((self) => {
    const libraryApi = new LibraryApi(self.environment.api)

    function convertTagsToFilter(
      tags: LibraryTag[],
      sortType?: GallerySortType | string,
    ): LibraryFilterRequest {
      const filters = tags.reduce(
        (filter, tag) => {
          if (!tag.filterType) {
            return filter
          }
          switch (tag.tagType) {
            case LibraryTagType.Resource:
              filter.resourceTypes.add(tag.filterType)
              break
            case LibraryTagType.OrganizationName:
              filter.entityNames.add(tag.name)
              break
            case LibraryTagType.GroupName:
              filter.entityNames.add(tag.name)
              break
            default:
              throw new Error("Error building library filters. Unknown tag type")
          }
          return filter
        },
        { resourceTypes: new Set<LibraryItemType | string>(), entityNames: new Set<string>() },
      )
      return {
        resourceTypes: [...filters.resourceTypes],
        entityNames: [...filters.entityNames],
        sortType,
      }
    }

    return {
      fetchLibraryItems: flow(function* (
        tags: LibraryTag[],
        sortType?: GallerySortType | string,
        searchQuery = "",
        pageOptions?: PaginationOptions,
      ) {
        const filter = convertTagsToFilter(tags, sortType)
        filter.pageNumber = pageOptions?.pageNumber ?? 1
        filter.pageSize = pageOptions?.pageSize ?? 10
        filter.query = searchQuery
        // If we have a search query, don't search on any tags
        if (searchQuery.length) {
          filter.resourceTypes = []
          filter.entityNames = []
        }
        const filterResult = yield* toGenerator(libraryApi.getLibraryItems(filter))
        return {
          ...filterResult,
          results: self.putLibraryItems(filterResult.results),
        }
      }),
    }
  })
  .actions((self) => {
    const libraryApi = new LibraryApi(self.environment.api)
    return {
      fetchLibraryTags: flow(function* () {
        const result = yield* toGenerator(libraryApi.getTags())
        return self.putLibraryTags(result.tags)
      }),
      selectTagFilter: flow(function* (tag: LibraryTag) {
        if (!self.selectedTags.some((t) => t.name === tag.name)) {
          self.selectedTags.push(tag)
        }
      }),
      unselectTagFilter: flow(function* (t: LibraryTag) {
        self.selectedTags.remove(t)
      }),
      unselectAllTagFilters: flow(function* () {
        self.selectedTags.clear()
      }),
      toggleLibraryItemPin: flow(function* (l: LibraryItem) {
        const originalPinnedUtc = l.pinnedUtc
        try {
          if (!l.pinnedUtc) {
            l.pinnedUtc = new Date()
            yield libraryApi.pinLibraryItems([l])
          } else {
            l.pinnedUtc = null
            yield libraryApi.unpinLibraryItems([l])
          }
        } catch (e) {
          l.pinnedUtc = originalPinnedUtc
          throw e
        }
      }),
      setSortType: flow(function* (sortType: GallerySortType | string) {
        self.sortType = sortType
      }),
      fetchPlaylists: flow(function* () {
        const result = yield* toGenerator(libraryApi.getPlaylists())
        const playlists = self.playlistStore.putPlaylists(result.playlists)
        self.playlists.replace(result.playlists.map((o) => o.id) as any)
        return playlists
      }),
    }
  })
  .actions((self) => {
    const libraryApi = new LibraryApi(self.environment.api)
    return {
      add: flow(function* (resourceId: string, resourceType: LibraryItemType) {
        yield libraryApi.add(resourceId, resourceType)
      }),
      remove: flow(function* (resourceId: string, resourceType: LibraryItemType) {
        yield libraryApi.remove(resourceId, resourceType)
      }),
      fetchLibraryItemsByIds: flow(function* (resourceIds: string[]) {
        const result = yield* toGenerator(libraryApi.getLibraryItems({ resourceIds }))
        return self.putLibraryItems(result.results)
      }),
    }
  })
  .actions((self) => ({
    enableTextSearch() {
      self.searchQuery = ""
      self.setSortType(GallerySortType.RecentlyAdded)
      self.unselectAllTagFilters()
      self.showTextSearch = true
    },
    disableTextSearch() {
      self.searchQuery = ""
      self.showTextSearch = false
    },
    setSearchQuery(query: string) {
      self.searchQuery = query
    },
  }))

export type LibraryStore = Instance<typeof LibraryStoreModel>
