import { ApiResponse } from "apisauce"
import { GallerySortType } from "../../models/gallery-types"
import { LibraryItem, LibraryItemType } from "../../models/library-item"
import { LibraryTag } from "../../models/library-tag"
import { Api } from "./api"
import { ApiError } from "./api-problem"
import { Playlist } from "../../models/playlist"

const API_PATH = "api/library/"

export interface LibraryFilterRequest {
  resourceTypes?: (LibraryItemType | string)[]
  entityNames?: string[]
  sortType?: GallerySortType | string
  resourceIds?: string[]
  pageNumber?: number
  pageSize?: number
  query?: string
}

const RESOURCE_API_PATH = {
  [LibraryItemType.User]: "user",
  [LibraryItemType.Playlist]: "playlist",
  [LibraryItemType.Saved]: "playlist",
  [LibraryItemType.Submissions]: "playlist",
  [LibraryItemType.Organization]: "org",
  [LibraryItemType.Group]: "group",
}

type LibraryTagsResult = { tags: LibraryTag[] }

export type LibraryItemsResult = {
  results: LibraryItem[]
  pageNumber: number
  numPages: number
  totalCount: number
}

function getApiPath(libraryItemType: LibraryItemType) {
  const path = RESOURCE_API_PATH[libraryItemType]
  if (!path) {
    throw new Error("Unknown library item type")
  }
  return `${API_PATH}${path}`
}

export class LibraryApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  public async add(resourceId: string, resourceType: LibraryItemType): Promise<void> {
    const response: ApiResponse<{ libraryItem: LibraryItem }> = await this.api.apisauce.post(
      getApiPath(resourceType),
      resourceId,
    )
    if (!response.ok) {
      throw new ApiError("LibraryApi.add", response)
    }
  }

  public async remove(resourceId: string, resourceType: LibraryItemType): Promise<void> {
    const response: ApiResponse<unknown> = await this.api.apisauce.delete(
      `${getApiPath(resourceType)}/${resourceId}`,
    )
    if (!response.ok) {
      throw new ApiError("LibraryApi.remove", response)
    }
  }

  async getTags(): Promise<LibraryTagsResult> {
    const response: ApiResponse<{ tags: LibraryTag[] }> = await this.api.apisauce.get(
      API_PATH + "all/tag",
    )
    if (!response.ok || !response.data) {
      throw new ApiError("LibraryApi.getTags", response)
    }
    return { tags: response.data.tags }
  }

  async getLibraryItems(filter?: LibraryFilterRequest): Promise<LibraryItemsResult> {
    const response: ApiResponse<LibraryItemsResult> = await this.api.apisauce.post(API_PATH, filter)
    if (!response.ok || !response.data) {
      throw new ApiError("LibraryApi.getLibraryItems", response)
    }
    return response.data
  }

  async pinLibraryItems(items: LibraryItem[]): Promise<void> {
    const response: ApiResponse<unknown> = await this.api.apisauce.patch(
      API_PATH + "pin",
      items.map((i) => i.resourceId),
    )
    if (!response.ok) {
      throw new ApiError("LibraryApi.pinLibraryItems", response)
    }
  }

  async unpinLibraryItems(items: LibraryItem[]): Promise<void> {
    const response: ApiResponse<unknown> = await this.api.apisauce.patch(
      API_PATH + "unpin",
      items.map((i) => i.resourceId),
    )
    if (!response.ok) {
      throw new ApiError("LibraryApi.unpinLibraryItems", response)
    }
  }

  async getPlaylists() {
    const response: ApiResponse<{ playlists: Playlist[] }> = await this.api.apisauce.get(
      API_PATH + "playlist",
    )
    if (!response.ok || !response.data) {
      throw new ApiError("LibraryApi.getPlaylists", response)
    }
    return { playlists: response.data.playlists }
  }
}
