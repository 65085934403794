import { Instance, types } from "mobx-state-tree"
import { StringEnum } from "../utils/string-enum-type"
import { OrganizationType } from "./organization"

export enum ManualReviewStatus {
  Pending = "Pending",
  Accepted = "Accepted",
  Rejected = "Rejected",
}

export const AffiliationModel = types.model("Affiliation").props({
  id: types.identifier,
  name: types.string,
  website: types.maybe(types.string),
  domainName: types.maybe(types.string),
  logoAssetId: types.maybe(types.string),
  status: types.maybe(StringEnum(ManualReviewStatus)),
  groupId: types.maybe(types.string),
  type: StringEnum(OrganizationType),
})

export type Affiliation = Instance<typeof AffiliationModel>
