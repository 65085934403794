import { ApiResponse } from "apisauce"
import { UserAffiliationType, UserAffiliation } from "../../models/user-affiliation"
import { Api } from "./api"
import { ApiError } from "./api-problem"

const API_PATH = "api/UserAffiliation/"

export interface UserAffiliationRequest {
  title?: string
  affiliationId?: string
  affiliationName?: string
  startDate?: Date
  endDate?: Date
  isPrimary?: boolean
  areaOfStudy?: string
  gradYear?: number
  type?: UserAffiliationType
}

export type UserAffiliationResult = {
  userAffiliation: UserAffiliation
}

export type UserAffiliationsResult = {
  userAffiliations: UserAffiliation[]
}

export class UserAffiliationApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async getUserAffiliations({ userId }): Promise<UserAffiliationsResult> {
    const response: ApiResponse<UserAffiliation[]> = await this.api.apisauce.get(
      `${API_PATH}user/${userId}`,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("UserAffiliationApi.getUserAffiliations", response)
    }
    return { userAffiliations: response.data }
  }

  async createUserAffiliation(
    userAffiliationRequest: UserAffiliationRequest,
  ): Promise<UserAffiliationResult> {
    const response: ApiResponse<UserAffiliation> = await this.api.apisauce.post(
      API_PATH,
      userAffiliationRequest,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("UserAffiliationApi.createUserAffiliation", response)
    }
    return { userAffiliation: response.data }
  }

  async updateUserAffiliation(
    userAffiliationId,
    userAffiliation: UserAffiliationRequest,
  ): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.patch(
      `${API_PATH}${userAffiliationId}`,
      userAffiliation,
    )
    if (!response.ok) {
      throw new ApiError("UserAffiliationApi.updateUserAffiliation", response)
    }
  }

  async makePrimary(userAffiliationId): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.put(
      `${API_PATH}${userAffiliationId}/primary`,
    )
    if (!response.ok) {
      throw new ApiError("UserAffiliationApi.makePrimary", response)
    }
  }

  async deleteUserAffiliation(userAffiliationId): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.delete(
      `${API_PATH}${userAffiliationId}`,
    )
    if (!response.ok) {
      throw new ApiError("UserAffiliationApi.deleteUserAffiliation", response)
    }
  }

  async move(userAffiliationId: string, nextUserAffiliationId?: string): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.patch(
      `${API_PATH}${userAffiliationId}/move`,
      {
        nextUserAffiliationId,
      },
    )
    if (!response.ok) {
      throw new ApiError("UserAffiliationApi.move", response)
    }
  }
}
