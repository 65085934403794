import { CSSObject } from "@emotion/react"
import { fontSizing, typography } from "../../../web/theme/typography"
import { color } from "pitch45-common/theme/shared-colors"
import { AppTheme } from "pitch45-common/theme/app-theme.types"

enum CommonPresets {
  hugeLight = "hugeLight",
  huge = "huge",
  hugeSemibold = "hugeSemibold",
  large = "large",
  largeLight = "largeLight",
  largeSemibold = "largeSemibold",
  mediumLight = "mediumLight",
  medium = "medium",
  mediumPlus = "mediumPlus",
  mediumPlusSemibold = "mediumPlusSemibold",
  mediumSemibold = "mediumSemibold",
  small = "small",
  smallLight = "smallLight",
  smallSemibold = "smallSemibold",
  tiny = "tiny",
  tinySemibold = "tinySemibold",
  tinyBold = "tinyBold",
  tinyDimSemibold = "tinyDimSemibold",
  tinyErrorSemibold = "tinyErrorSemibold",
  tinyPrimarySemibold = "tinyPrimarySemibold",
  button = "button",
  logo = "logo",
}

enum ExtraPresets {
  mediumPrimary = "mediumPrimary ",
  smallPrimarySemibold = "smallPrimarySemibold ",
  tinySecondarySemibold = "tinySecondarySemibold ",
  tinyLight = "tinyLight ",
  tinyAllCapsSemibold = "tinyAllCapsSemibold ",
  smallAllCapsSemibold = "smallAllCapsSemibold ",
  mini = "mini ",
  miniSemibold = "miniSemibold ",
  miniLight = "miniLight ",
  mediumLight = "mediumLight ",
  enormousLight = "enormousLight ",
  massive = "massive",
}

export const textPresets = { ...CommonPresets, extra: ExtraPresets }
export type TextPresets = CommonPresets | ExtraPresets

export const getTextPresets = function (theme: AppTheme, preset: TextPresets): CSSObject {
  /**
   * All text will start off looking like this.
   */
  const BASE = {
    ...typography.regular,
    color: theme.colors.text,
    fontSize: 14,
    whiteSpace: "normal" as const,
    lineHeight: 1.4,
  }
  const MINI = { fontSize: fontSizing.mini }
  const TINY = { fontSize: fontSizing.tiny }
  const SMALL = { fontSize: fontSizing.small }
  const MEDIUM = { fontSize: fontSizing.medium }
  const MEDIUM_PLUS = { fontSize: fontSizing.mediumPlus }
  const LARGE = { fontSize: fontSizing.large }
  const HUGE = { fontSize: fontSizing.huge }
  const ENORMOUS = { fontSize: fontSizing.enormous }
  const MASSIVE = { fontSize: fontSizing.massive }

  switch (preset) {
    case CommonPresets.hugeLight:
      return { ...BASE, ...HUGE, ...typography.light }
    case CommonPresets.huge:
      return { ...BASE, ...HUGE, ...typography.regular }
    case CommonPresets.hugeSemibold:
      return { ...BASE, ...HUGE, ...typography.semibold }
    case CommonPresets.large:
      return { ...BASE, ...LARGE, ...typography.regular }
    case CommonPresets.largeLight:
      return { ...BASE, ...LARGE, ...typography.light }
    case CommonPresets.largeSemibold:
      return { ...BASE, ...LARGE, ...typography.semibold }
    case CommonPresets.medium:
      return { ...BASE, ...MEDIUM, ...typography.regular }
    case CommonPresets.mediumLight:
      return { ...BASE, ...MEDIUM, ...typography.light }
    case CommonPresets.mediumPlus:
      return { ...BASE, ...MEDIUM_PLUS, ...typography.regular }
    case CommonPresets.mediumPlusSemibold:
      return { ...BASE, ...MEDIUM_PLUS, ...typography.semibold }
    case CommonPresets.mediumSemibold:
      return { ...BASE, ...MEDIUM, ...typography.semibold }
    case CommonPresets.small:
      return { ...BASE, ...SMALL, ...typography.regular }
    case CommonPresets.smallLight:
      return { ...BASE, ...SMALL, ...typography.light }
    case CommonPresets.smallSemibold:
      return { ...BASE, ...SMALL, ...typography.semibold }
    case CommonPresets.tiny:
      return { ...BASE, ...TINY, ...typography.regular }
    case CommonPresets.tinySemibold:
      return { ...BASE, ...TINY, ...typography.semibold }
    case CommonPresets.tinyBold:
      return { ...BASE, ...TINY, ...typography.bold }
    case CommonPresets.tinyDimSemibold:
      return { ...BASE, ...TINY, ...typography.semibold, color: theme.colors.dimText }
    case CommonPresets.tinyErrorSemibold:
      return { ...BASE, ...TINY, ...typography.semibold, color: theme.colors.error }
    case CommonPresets.tinyPrimarySemibold:
      return { ...BASE, ...TINY, ...typography.semibold, color: theme.colors.primary }
    case CommonPresets.button:
      return { ...BASE, ...MEDIUM, ...typography.bold, textTransform: "uppercase" }
    case CommonPresets.logo:
      return { ...BASE, ...MEDIUM_PLUS, ...typography.logo }
    case ExtraPresets.mediumPrimary:
      return { ...BASE, ...MEDIUM, ...typography.regular, color: theme.colors.primary }
    case ExtraPresets.smallPrimarySemibold:
      return { ...BASE, ...SMALL, ...typography.semibold, color: theme.colors.primary }
    case ExtraPresets.tinySecondarySemibold:
      return { ...BASE, ...TINY, ...typography.semibold, color: color.secondaryText }
    case ExtraPresets.tinyLight:
      return { ...BASE, ...TINY, ...typography.light }
    case ExtraPresets.tinyAllCapsSemibold:
      return { ...BASE, ...TINY, ...typography.semibold, textTransform: "uppercase" }
    case ExtraPresets.smallAllCapsSemibold:
      return { ...BASE, ...SMALL, ...typography.semibold, textTransform: "uppercase" }
    case ExtraPresets.mini:
      return { ...BASE, ...MINI }
    case ExtraPresets.miniSemibold:
      return { ...BASE, ...MINI, ...typography.semibold }
    case ExtraPresets.miniLight:
      return { ...BASE, ...MINI, ...typography.light }
    case ExtraPresets.enormousLight:
      return { ...BASE, ...ENORMOUS, ...typography.light }
    case ExtraPresets.massive:
      return { ...BASE, ...MASSIVE, ...typography.regular }
    default:
      return BASE
  }
}
