import numeral from "numeral"
/**
 * Pluralize a string based on count
 */

export const pluralizeOnCount = (value: number, baseWord: string, includeValue = true) => {
  return (
    (includeValue ? `${numeral(value).format("0,0")} ` : "") +
    (value === 1 ? baseWord : baseWord + "s")
  )
}
