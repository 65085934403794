import { Instance, types } from "mobx-state-tree"
import { DateTime } from "../utils/datetime-type"
import { StringEnum } from "../utils/string-enum-type"
import { ResourceType } from "./resource"
import { UserActivityType } from "./activity"
import logger from "../logging/logger"

export enum PostType {
  Resource = "Resource",
  Activity = "Activity",
}

export const PostResource = types.model("PostResource").props({
  id: types.string,
  name: types.string,
  thumbnailAssetId: types.maybe(types.string),
  archived: types.boolean,
})

export const PostUser = types.model("PostUser").props({
  id: types.string,
  name: types.string,
  thumbnailAssetId: types.maybe(types.string),
})

export const ResourcePostDisplayData = types.model("ResourcePostDisplayData").props({
  user: PostUser,
  resource: PostResource,
})

export const getParsedJson = (post: Post) => {
  try {
    const parsedJSON = JSON.parse(post.activityEntitiesJson!)
    if (!parsedJSON) {
      throw new Error("Parsing activityEntitiesJson resulted in null or undefined")
    } else {
      return parsedJSON
    }
  } catch {
    logger.logError(new Error("Invalid activity post"), {
      postId: post.id,
    })
  }
}

export const PostModel = types.model("Post").props({
  id: types.identifier,
  userId: types.string,
  entityId: types.string,
  postType: types.maybe(StringEnum(PostType)),
  // For Resource Posts
  resourceId: types.maybe(types.string),
  resourceType: types.maybe(StringEnum(ResourceType)),
  displayData: types.maybe(ResourcePostDisplayData),
  // For Activity Posts
  activityType: types.maybe(StringEnum(UserActivityType)),
  activityEntitiesJson: types.maybe(types.string),
  createdUtc: types.maybe(DateTime),
})

export type Post = Instance<typeof PostModel>
