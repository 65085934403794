import { Instance, onSnapshot, types, applySnapshot } from "mobx-state-tree"
import { withEnvironment } from "./extensions/with-environment"
import { withSessionStore } from "./session-store"
import { autorun } from "mobx"
import logger from "../logging/logger"

/**
 * For properties that we want to survive after a user logs in and out
 */
const MOBILE_SETTINGS_STORAGE_KEY = "settings"

/**
 * This store is persisted separately from the root store, so that it can be
 * saved and restored across login sessions.
 */
export const MobileSettingsStoreModel = types
  .model("MobileSettings")
  .props({
    seenRecordingLengthTooltip: types.optional(types.boolean, false),
    seenSwipingInstructionalOverlay: types.optional(types.boolean, false),
    pinnedGroupIds: types.optional(types.array(types.string), []),
    collapsedHomeEntityIds: types.optional(types.map(types.boolean), {}),
    showEmptyPinnedGroupSection: types.optional(types.boolean, true),
    isPinnedGroupsExpanded: types.optional(types.boolean, true),
  })
  .extend(withEnvironment)
  .extend(withSessionStore)
  .volatile(() => ({
    disposer: undefined as unknown as () => void | undefined,
  }))
  .actions((self) => ({
    afterCreate() {
      // load persistent settings any time the user id changes
      self.disposer = autorun(() => {
        const userId = self.sessionStore.session?.userId
        if (userId) {
          self.environment.storage
            .load(`${MOBILE_SETTINGS_STORAGE_KEY}.${userId}`)
            .then((snapshot) => {
              if (snapshot) {
                logger.log(`loading mobile settings for user ${userId}`)
                applySnapshot(self, snapshot)
              }
            })
        }
      }, {})
      // persist this specific store any time a value changes
      onSnapshot(self, (snapshot) => {
        const userId = self.sessionStore.session?.userId
        if (userId) {
          logger.log(`saving mobile settings for user ${userId}`)
          self.environment.storage.save(`${MOBILE_SETTINGS_STORAGE_KEY}.${userId}`, snapshot)
        }
      })
    },
    beforeDestroy() {
      self.disposer()
    },
    setSeenRecordingLengthTooltip() {
      self.seenRecordingLengthTooltip = true
    },
    setSeenSwipingInstructionalOverlay() {
      self.seenSwipingInstructionalOverlay = true
    },
    pinGroup(groupId: string) {
      self.pinnedGroupIds.remove(groupId) // make sure there aren't any duplicates
      self.pinnedGroupIds.unshift(groupId)
    },
    unpinGroup(groupId: string) {
      self.pinnedGroupIds.remove(groupId)
    },
    toggleCollapsedHomeEntityId(entityId: string) {
      if (self.collapsedHomeEntityIds.get(entityId)) {
        self.collapsedHomeEntityIds.delete(entityId)
      } else {
        self.collapsedHomeEntityIds.set(entityId, true)
      }
    },
    togglePinnedGroupsExpanded() {
      self.isPinnedGroupsExpanded = !self.isPinnedGroupsExpanded
    },
    hideEmptyPinnedGroupSection() {
      self.showEmptyPinnedGroupSection = false
    },
  }))

export type MobileSettingsStore = Instance<typeof MobileSettingsStoreModel>
