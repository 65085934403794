import { Api } from "./api"
import { ApiError } from "./api-problem"
import { ApiResponse } from "apisauce"
import {
  NotificationCategory,
  NotificationSettings,
} from "../../stores/notification-settings-store"
import { ResourceType } from "../../models/resource"
import { ContentVisibility } from "../../models/content-visibility"
import { DateTime } from "../../utils"

const API_PATH = "api/NotificationSettings/"

type Resource = {
  id: string
  type: ResourceType
  name: string
  visibility: ContentVisibility
  thumbnailAssetIds: string[]
  createdUtc: typeof DateTime
  updatedUtc: typeof DateTime
  description: string
  entityId: string
}

type UserChannel = {
  email: boolean
  push: boolean
}

export type NotificationSettingsResult = {
  notificationSettings: NotificationSettings
  resource?: Resource
  channel?: UserChannel
}

export class NotificationSettingsApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async getUserNotificationSettings(
    userId: string,
    notificationCategory: NotificationCategory,
  ): Promise<NotificationSettingsResult> {
    const response: ApiResponse<NotificationSettingsResult> = await this.api.apisauce.get(
      `${API_PATH}${userId}`,
      { notificationCategory },
    )
    if (!response.ok || !response.data) {
      throw new ApiError("NotificationSettingsApi.getUserNotificationSettings", response)
    }
    return response.data
  }

  async getAllUserNotificationSettings(userId: string): Promise<NotificationSettings[]> {
    const response: ApiResponse<NotificationSettings[]> = await this.api.apisauce.get(
      `${API_PATH}${userId}/all`,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("NotificationSettingsApi.getAllUserNotificationSettings", response)
    }
    return response.data
  }

  async getUserNotificationSettingsByUserNotificationId(
    userNotificationId: string,
    notificationCategory: NotificationCategory,
    resourceId?: string,
  ) {
    const response: ApiResponse<NotificationSettingsResult & { email: string }> =
      await this.api.apisauce.get(`${API_PATH}notification/${userNotificationId}`, {
        notificationCategory,
        resourceId,
      })
    if (!response.ok || !response.data) {
      throw new ApiError(
        "NotificationSettingsApi.getUserNotificationSettingsByUserNotificationId",
        response,
      )
    }
    return response.data
  }

  async createNotificationSettings(
    userId: string,
    notificationCategory: NotificationCategory,
    organizationId: string | null,
    groupId: string | null,
    sendEmail: boolean,
    sendPush: boolean,
  ): Promise<NotificationSettingsResult> {
    const response: ApiResponse<NotificationSettingsResult> = await this.api.apisauce.post(
      `${API_PATH}${userId}`,
      {
        organizationId,
        notificationCategory,
        groupId,
        sendEmail,
        sendPush,
      },
    )
    if (!response.ok || !response.data) {
      throw new ApiError("NotificationSettingsApi.createNotificationSettings", response)
    }
    return response.data
  }

  async updateNotificationSettings(
    notificationSettingsId: string,
    sendEmail: boolean,
    sendPush: boolean,
  ): Promise<NotificationSettingsResult> {
    const response: ApiResponse<NotificationSettingsResult> = await this.api.apisauce.patch(
      `${API_PATH}${notificationSettingsId}`,
      {
        sendEmail,
        sendPush,
      },
    )
    if (!response.ok || !response.data) {
      throw new ApiError("NotificationSettingsApi.createNotificationSettings", response)
    }
    return response.data
  }

  async unsubscribeFromEmail(
    userNotificationId: string,
    notificationCategory: NotificationCategory,
    resourceId?: string,
  ) {
    const url = resourceId
      ? `${API_PATH}unsubscribe/${notificationCategory}/${userNotificationId}?resourceId=${resourceId}`
      : `${API_PATH}unsubscribe/${notificationCategory}/${userNotificationId}`
    const response: ApiResponse<void> = await this.api.apisauce.post(url)
    if (!response.ok) {
      throw new ApiError("NotificationSettingsApi.unsubscribeFromEmail", response)
    }
  }

  async unsubscribeFromAllEmails(userNotificationId: string) {
    const response: ApiResponse<void> = await this.api.apisauce.post(
      `${API_PATH}unsubscribe/${userNotificationId}/all`,
    )
    if (!response.ok) {
      throw new ApiError("NotificationSettingsApi.unsubscribeFromAllEmails", response)
    }
  }
}
