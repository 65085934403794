import React, { useState, useCallback } from "react"
import Toast, { ToastContext, ToastOptionProps } from "./toast"

export function ToastProvider({ children }) {
  const [open, setOpen] = useState<boolean>(false)
  const [message, setMessage] = useState<string>("")
  const [options, setOptions] = useState({})

  const show = useCallback((message, options: ToastOptionProps) => {
    setOpen(true)
    setMessage(message)
    setOptions(options)
  }, [])

  const hide = useCallback(() => {
    setOpen(false)
  }, [])

  return (
    <>
      <ToastContext.Provider value={{ show, hide }}>
        {children}
        <Toast open={open} message={message} options={options} hide={hide} />
      </ToastContext.Provider>
    </>
  )
}
