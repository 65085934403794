// recommendations from https://kentcdodds.com/blog/get-a-catch-block-error-message-with-typescript

import axios from "axios"
import { ApiError } from "../services/api/api-problem"

export function getErrorMessage(error: unknown) {
  if (error instanceof Error) return error.message
  return String(error)
}

export function getAxiosErrorCode(error: unknown) {
  // https://github.com/axios/axios/issues/5031
  if (axios.isAxiosError(error)) return error.code
  return String(error)
}

export function getApiErrorProblemKind(error: unknown) {
  if (error instanceof ApiError) return error.problem.kind
  return String(error)
}
