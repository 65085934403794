import { SplitSdk } from "@splitsoftware/splitio-react"

// Instantiate a singleton of the SDK
export default SplitSdk({
  core: {
    authorizationKey: process.env.NEXT_PUBLIC_SPLIT_IO_AUTHORIZATION_KEY!,
    // this "default" key is for non-logged in users, we instantiate the client with the userId on login
    key: "default",
  },
  streamingEnabled: true,
  debug: false,
  sync: {
    impressionsMode: "NONE",
  },
})
