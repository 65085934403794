import { Instance, types } from "mobx-state-tree"
import { StringEnum } from "../utils/string-enum-type"
import { OrganizationEntitlementsModel } from "./organization-entitlements"
import { UserEntitlementsModel } from "./user-entitlements"

export enum Permission {
  ViewInternalContent = "ViewInternalContent",
  ViewPrivateContent = "ViewPrivateContent",
  ManageExternalContent = "ManageExternalContent",
  ManageParentContent = "ManageParentContent",
  ManageInternalContent = "ManageInternalContent",
  ManagePrivateContent = "ManagePrivateContent",
  DeletePosts = "DeletePosts",
  ViewGroup = "ViewGroup",
  ManageGroup = "ManageGroup",
  ViewUsers = "ViewUsers",
  ManageUsers = "ManageUsers",
  ViewContentAnalytics = "ViewContentAnalytics",
  ViewParentContent = "ViewParentContent",
  ManageOwners = "ManageOwners",
  ViewOrganization = "ViewOrganization",
  ManageOrganization = "ManageOrganization",
  DeleteOrganization = "DeleteOrganization",
  ManageGroups = "ManageGroups",
  ViewGroups = "ViewGroups",
  ViewGroupAnalytics = "ViewGroupAnalytics",
  ManageBilling = "ManageBilling",
  ViewBilling = "ViewBilling",
  ViewAnalytics = "ViewAnalytics",
}

/**
 * Session model.
 */
export const SessionModel = types.maybe(
  types.model("Session").props({
    userId: types.maybe(types.string),
    entityPermissions: types.map(types.array(StringEnum(Permission))),
    userEntitlements: types.maybe(UserEntitlementsModel),
    organizationEntitlements: types.map(OrganizationEntitlementsModel),
  }),
)

export type Session = Instance<typeof SessionModel>
