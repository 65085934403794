import { mediaQueryBreakpoints } from "pitch45-common/theme"
import { useState, useEffect } from "react"

export const MAX_CONTENT_WIDTH = "1280px"

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState<{
    width: number
    height: number
  }>({ width: 0, height: 0 })
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener("resize", handleResize)
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize)
  }, [])
  return windowSize
}

export function useMediaBreakpoints() {
  const size = useWindowSize()
  if (size.width) {
    return {
      isMobile: size.width <= mediaQueryBreakpoints.MOBILE,
      isTablet: size.width <= mediaQueryBreakpoints.TABLET,
      isDesktop: size.width > mediaQueryBreakpoints.TABLET,
    }
  } else {
    return undefined
  }
}
