export interface ThirdPartyLogger {
  identify(userId?: string | null): void
  log(message: string, extra?: { [key: string]: any }): void
  track(event: string, extra?: { [key: string]: any }): void
  logWarning(
    error: string | Error,
    extra?: { [key: string]: any },
    tags?: { [key: string]: string },
  ): void
  logError(
    error: string | Error,
    extra?: { [key: string]: any },
    tags?: { [key: string]: string },
  ): void
}

const loggers: { [name: string]: ThirdPartyLogger } = {}

const logger = {
  addLogger(name: string, logger: ThirdPartyLogger) {
    loggers[name] = logger
  },

  identify(userId?: string | null): void {
    Object.values(loggers).forEach((l) => l.identify(userId))
  },

  track(event: string, extra?: { [key: string]: any }): void {
    Object.values(loggers).forEach((l) => l.track(event, extra))
  },

  log(message: string, extra?: { [key: string]: any }): void {
    Object.values(loggers).forEach((l) => l.log(message, extra))
  },

  logWarning(
    error: string | Error,
    extra?: { [key: string]: any },
    tags?: { [key: string]: string },
  ): void {
    Object.values(loggers).forEach((l) => l.logWarning(error, extra, tags))
  },

  logError(
    error: string | Error,
    extra?: { [key: string]: any },
    tags?: { [key: string]: string },
  ): void {
    Object.values(loggers).forEach((l) => l.logError(error, extra, tags))
  },
}

export default logger
