import { ApiResponse } from "apisauce"
import { Affiliation } from "../../models/affiliation"
import { OrganizationType } from "../../models/organization"
import { Api } from "./api"
import { ApiError } from "./api-problem"

const API_PATH = "api/Affiliation/"

export interface AffiliationRequest {
  name: string
  website?: string
  logoAssetId?: string
}

export type AffiliationSearchQuery = {
  pageNumber?: number
  pageSize?: number
  sortByCreatedUtcAsc?: boolean
  sortByNameAsc?: boolean
  textQuery?: string
  type?: OrganizationType
}

export type AffiliationsResult = {
  affiliations: Affiliation[]
}

export type AffiliationResult = {
  affiliation: Affiliation
}

export type AffiliationsSearchResult = {
  pageNumber: number
  numPages: number
  totalCount: number
  results: Affiliation[]
}

export class AffiliationApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async getByStatus({ status }): Promise<AffiliationsResult> {
    const response: ApiResponse<Affiliation[]> = await this.api.apisauce.get(API_PATH, {
      status,
    })

    if (!response.ok || !response.data) {
      throw new ApiError("AffiliationApi.getByStatus", response)
    }

    return { affiliations: response.data }
  }

  async getAffiliationById({ affiliationId }): Promise<AffiliationResult> {
    const response: ApiResponse<Affiliation> = await this.api.apisauce.get(
      `${API_PATH}${affiliationId}`,
    )

    if (!response.ok || !response.data) {
      throw new ApiError("AffiliationApi.getAffiliationById", response)
    }

    return { affiliation: response.data }
  }

  async createAffiliation(affiliation: AffiliationRequest): Promise<AffiliationResult> {
    const response: ApiResponse<Affiliation> = await this.api.apisauce.post(API_PATH, affiliation)
    if (!response.ok || !response.data) {
      throw new ApiError("AffiliationApi.createAffiliation", response)
    }

    return { affiliation: response.data }
  }

  async searchAffiliations(query: AffiliationSearchQuery): Promise<AffiliationsSearchResult> {
    const response: ApiResponse<AffiliationsSearchResult> = await this.api.apisauce.post(
      `${API_PATH}search`,
      query,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("AffiliationApi.searchAffiliations", response)
    }

    return response.data
  }
}
