export interface OAuthTokenResponse {
  accessToken: string
  refreshToken?: string
}
export interface OAuthProvider {
  authorize(...args: any): Promise<OAuthTokenResponse>
  refresh(refreshToken: string): Promise<OAuthTokenResponse>
  revoke(refreshToken: string | null): Promise<void>
}

export default {}
