import { Api } from "./api"
import { ApiError } from "./api-problem"
import { ApiResponse } from "apisauce"
import { keysToEnum } from "../../utils"
import { ManagedRole } from "../../models/organization-role"
import { Pitch } from "../../models/pitch"
import { ProgramType } from "../../models/program"

const API_PATH = "api/analytics"

type TimePointResult = {
  time: string
  value: number
}

export enum TimeBucket {
  Day = "Day",
  Month = "Month",
}

export type TimeSeriesResult = {
  timeSeriesData: { data: TimePointResult[]; timeBucket: TimeBucket }
  timeframeCount: number
  cumulativeCount: number
}

export type PitchViewCountResult = {
  pitch: Pitch
  timeframeCount: number
  cumulativeCount: number
}

export type VideoViewsAnalyticsResult = {
  videoViewsChart?: TimeSeriesResult
  appVideoViewsChart?: TimeSeriesResult
  webVideoViewsChart?: TimeSeriesResult
  mostViewedPitches: PitchViewCountResult[]
}

export type VideoPublishAnalyticsResult = {
  videosPublishedChart: TimeSeriesResult
  latestPublishedPitches: Pitch[]
}

export type VideoAnalyticsResult = {
  videoViews: VideoViewsAnalyticsResult
  videosPublished: VideoPublishAnalyticsResult
}

export type GroupAnalyticsResult = {
  programCounts: { [key: string]: number }
  memberCounts: { [key: string]: number }
  mostViewedLast7Days?: PitchViewCountResult
  mostViewedLast30Days?: PitchViewCountResult
  mostViewedThisYear?: PitchViewCountResult
  mostViewedAllTime?: PitchViewCountResult
}

export type AnalyticsFilterRequest = {
  entityIds: string[]
  timezoneId: string
}

export type VideoAnalyticsFilterRequest = AnalyticsFilterRequest & {
  startUtc: Date
  endUtc: Date
  splitByViewType: boolean
  isCumulative: boolean
}

export type UserPitchStatsResult = {
  viewCount: number
}

export class AnalyticsApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async getVideoAnalytics(filter: VideoAnalyticsFilterRequest): Promise<VideoAnalyticsResult> {
    const response: ApiResponse<VideoAnalyticsResult> = await this.api.apisauce.post(
      `${API_PATH}/video`,
      filter,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("AnalyticsApi.getVideoAnalytics", response)
    }
    return response.data
  }

  async getGroupAnalytics(filter: AnalyticsFilterRequest): Promise<GroupAnalyticsResult> {
    const response: ApiResponse<GroupAnalyticsResult> = await this.api.apisauce.post(
      `${API_PATH}/group`,
      filter,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("AnalyticsApi.getGroupAnalytics", response)
    }
    return {
      ...response.data,
      // convert keys like "groupAdmin" to "GroupAdmin" so we can use it as ManagedRole.GroupAdmin
      memberCounts: keysToEnum(ManagedRole, response.data.memberCounts),
      programCounts: keysToEnum(ProgramType, response.data.programCounts),
    }
  }

  async getUserPitchStats({ pitchId }: { pitchId: string }): Promise<UserPitchStatsResult> {
    const response: ApiResponse<UserPitchStatsResult> = await this.api.apisauce.get(
      `${API_PATH}/user/video/${pitchId}`,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("AnalyticsApi.getUserPitchStats", response)
    }
    return response.data
  }
}
