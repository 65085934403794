import { Instance, types } from "mobx-state-tree"
import { EntityType } from "./entity"

export const UserProfileModel = types
  .model("UserProfileModel")
  .props({
    id: types.string,
    name: types.string,
    location: types.maybe(types.string),
    tagline: types.maybe(types.string),
    avatarAssetId: types.maybe(types.string),
  })
  .views((self) => ({
    toEntity() {
      return {
        id: self.id,
        name: self.name,
        assetId: self.avatarAssetId,
        type: EntityType.User,
        parentId: null,
      }
    },
  }))

export type UserProfile = Instance<typeof UserProfileModel>
