import React from "react"
import { CircularProgress } from "@mui/material"
import { color } from "pitch45-common/theme"
import Button, { ButtonProps } from "./button"
import { MIN_BUTTON_WIDTH } from "./button.styles"

interface AsyncButtonProps extends ButtonProps {
  loading: boolean
}

export function AsyncButton({ loading, ...rest }: AsyncButtonProps) {
  return !loading ? (
    <Button {...rest} />
  ) : (
    <div css={{ height: 30, width: MIN_BUTTON_WIDTH, textAlign: "center" }}>
      <CircularProgress size={26} sx={{ color: color.primary }} />
    </div>
  )
}

export default AsyncButton
