import { ContentVisibility } from "../models/content-visibility"
import { useFormField } from "./use-form-field"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useEffect } from "react"
import newId from "./new-id"
import { isValid as isValidDate } from "date-fns"
import { Asset } from "../models/asset"
import { AutoPostType } from "../models/auto-post-type"
import { Entity } from "../models/entity"
import { Playlist, PlaylistParams } from "../models/playlist"
import { SyncStatus } from "../models/sync"
import { useStores } from "../stores/root-store-context"

interface PlaylistCreateEditParams {
  entity?: Entity
  playlistId?: string
  onCreateSuccess?: (playlistId: string) => void
  onCreateError?: () => void
  onUpdateSuccess?: (playlistId: string) => void
  onUpdateError?: (playlistId: string) => void
  assetUploader: (asset: Asset) => Promise<Asset | undefined>
}

const playlistVisibilityMapping = {
  [ContentVisibility.External]: [ContentVisibility.External, ContentVisibility.External],
  [ContentVisibility.Internal]: [ContentVisibility.Internal, ContentVisibility.Internal],
  [ContentVisibility.Private]: [ContentVisibility.Private, ContentVisibility.Private],
  [ContentVisibility.ExternalAddInternalViewChildren]: [
    ContentVisibility.External,
    ContentVisibility.Internal,
  ],
  [ContentVisibility.ExternalAddPrivateViewChildren]: [
    ContentVisibility.External,
    ContentVisibility.Private,
  ],
  [ContentVisibility.InternalAddExternalViewChildren]: [
    ContentVisibility.Internal,
    ContentVisibility.External,
  ],
  [ContentVisibility.InternalAddPrivateViewChildren]: [
    ContentVisibility.Internal,
    ContentVisibility.Private,
  ],
  [ContentVisibility.PrivateAddExternalViewChildren]: [
    ContentVisibility.Private,
    ContentVisibility.External,
  ],
  [ContentVisibility.PrivateAddInternalViewChildren]: [
    ContentVisibility.Private,
    ContentVisibility.Internal,
  ],
}

export function splitPlaylistVisibility(visibility: ContentVisibility | string) {
  const result = playlistVisibilityMapping[visibility]
  return {
    addChildrenVisibility: result[0],
    viewChildrenVisibility: result[1],
  }
}

export function combinePlaylistVisibility({
  addChildrenVisibility,
  viewChildrenVisibility,
}: {
  addChildrenVisibility: ContentVisibility | string
  viewChildrenVisibility: ContentVisibility | string
}) {
  if (
    !(
      addChildrenVisibility === ContentVisibility.External ||
      addChildrenVisibility === ContentVisibility.Internal ||
      addChildrenVisibility === ContentVisibility.Private
    )
  ) {
    throw new Error("Invalid add children visibility")
  }
  if (
    !(
      viewChildrenVisibility === ContentVisibility.External ||
      viewChildrenVisibility === ContentVisibility.Internal ||
      viewChildrenVisibility === ContentVisibility.Private
    )
  ) {
    throw new Error("Invalid add children visibility")
  }
  return Object.entries(playlistVisibilityMapping).find(
    ([, value]) => value[0] === addChildrenVisibility && value[1] === viewChildrenVisibility,
  )?.[0] as ContentVisibility
}

export function usePlaylistForm({
  entity,
  playlistId,
  playlistParams,
  onCreateSuccess,
  onCreateError,
  onUpdateSuccess,
  onUpdateError,
  assetUploader,
}: { playlistParams?: PlaylistParams } & PlaylistCreateEditParams) {
  const { playlistStore } = useStores()

  const { addChildrenVisibility, viewChildrenVisibility } = splitPlaylistVisibility(
    playlistParams?.visibility || ContentVisibility.External,
  )

  const fields = {
    name: useFormField<string>(playlistParams?.name),
    description: useFormField<string>(playlistParams?.description),
    coverAssetId: useFormField<string>(playlistParams?.coverAssetId),
    coverAsset: useFormField<Asset>(),
    closedUtc: useFormField<Date>(playlistParams?.closedUtc),
    visibility: useFormField<ContentVisibility | string>(addChildrenVisibility),
    playlistPitchVisibility: useFormField<ContentVisibility | string>(viewChildrenVisibility),
    autoPostType: useFormField<AutoPostType | string>(playlistParams?.autoPostType),
    entityId: useFormField<string>(playlistParams?.entityId),
  }

  fields.name.setValidator((name?: string) => {
    if (!name || name.trim().length === 0) {
      fields.name.setError("Please enter a name")
      return false
    }
    fields.name.setError("")
    return true
  })

  fields.closedUtc.setValidator((deadline?: Date) => {
    if (deadline && !isValidDate(deadline)) {
      fields.closedUtc.setError("Please select a valid date")
      return false
    }
    // only validate future date for new playlists
    if (!playlistId && deadline && deadline < new Date()) {
      fields.closedUtc.setError("Please select a deadline in the future")
      return false
    }
    fields.closedUtc.setError("")
    return true
  })

  fields.entityId.setValidator((entityId?: string) => {
    if (!entityId) {
      fields.entityId.setError("Please select an entity")
      return false
    }
    fields.entityId.setError("")
    return true
  })

  const validate = () => {
    const results = [
      fields.name.validate(fields.name.value),
      fields.entityId.validate(fields.entityId.value),
      fields.closedUtc.validate(fields.closedUtc.value),
    ]
    return results.every((result) => result)
  }

  const isValid = Object.values(fields).every((field) => !field.error)

  const initializeValues = (playlist: Playlist) => {
    const { addChildrenVisibility, viewChildrenVisibility } = splitPlaylistVisibility(
      playlist.visibility ?? ContentVisibility.External,
    )
    fields.name.setValue(playlist.name)
    fields.description.setValue(playlist.description)
    fields.coverAssetId.setValue(playlist.coverAssetId)
    fields.closedUtc.setValue(playlist.closedUtc)
    fields.visibility.setValue(addChildrenVisibility)
    fields.playlistPitchVisibility.setValue(viewChildrenVisibility)
    fields.autoPostType.setValue(playlist.autoPostType)
    fields.entityId.setValue(playlist.entity.id)
  }

  const { data: playlist, isLoading: isPlaylistLoading } = useQuery(
    ["fetchPlaylist", playlistId],
    () => playlistStore.fetchPlaylist(playlistId!),
    {
      enabled: Boolean(playlistId),
    },
  )

  const playlistEntity = playlist?.entity || entity

  useEffect(() => {
    if (!playlistId) {
      if (!entity) {
        throw new Error("Entity id is required to create playlists")
      }
      fields.entityId.setValue(entity.id)
    } else {
      if (playlist) {
        initializeValues(playlist)
      }
    }
  }, [playlist, playlistId, entity])

  const createPlaylistMutation = useMutation(
    async () => {
      const id = newId().id
      if (fields.coverAsset.value && fields.coverAsset.value.sync.status === SyncStatus.New) {
        await assetUploader(fields.coverAsset.value)
      }
      const visibility = combinePlaylistVisibility({
        addChildrenVisibility: fields.visibility.value ?? ContentVisibility.External,
        viewChildrenVisibility: fields.playlistPitchVisibility.value ?? ContentVisibility.External,
      })
      fields.closedUtc.value?.setSeconds(0)
      await playlistStore.createPlaylist({
        id,
        name: fields.name.value,
        description: fields.description.value,
        coverAssetId: fields.coverAsset.value?.id,
        closedUtc: fields.closedUtc.value,
        autoPostType: fields.autoPostType.value,
        entityId: fields.entityId.value,
        visibility,
      })
      return id
    },
    {
      onSuccess(playlistId: string) {
        onCreateSuccess?.(playlistId)
      },
      onError() {
        onCreateError?.()
      },
    },
  )

  const updatePlaylistMutation = useMutation(
    async () => {
      if (fields.coverAsset.value && fields.coverAsset.value.sync.status === SyncStatus.New) {
        await assetUploader(fields.coverAsset.value)
      }
      const visibility = combinePlaylistVisibility({
        addChildrenVisibility: fields.visibility.value ?? ContentVisibility.External,
        viewChildrenVisibility: fields.playlistPitchVisibility.value ?? ContentVisibility.External,
      })
      await playlistStore.updatePlaylist({
        id: playlistId,
        name: fields.name.value,
        description: fields.description.value,
        coverAssetId: fields.coverAsset.value?.id || fields.coverAssetId.value,
        closedUtc: fields.closedUtc.value,
        autoPostType: fields.autoPostType.value,
        entityId: fields.entityId.value,
        visibility,
      })
    },
    {
      onSuccess() {
        onUpdateSuccess?.(playlistId!)
      },
      onError() {
        onUpdateError?.(playlistId!)
      },
    },
  )

  return {
    ...fields,
    isValid,
    validate,
    createPlaylistMutation,
    updatePlaylistMutation,
    isPlaylistLoading,
    playlistEntity,
  }
}
