import { flow, Instance, types, toGenerator } from "mobx-state-tree"
import { withEnvironment } from "./extensions/with-environment"
import { withOrganizationStore } from "./organization-store"
import { withGroupStore } from "../stores/group-store"
import { RegistrationCodeApi } from "../services/api/registration-code-api"

export const RegistrationCodeModel = types.model("RegistrationCode").props({
  id: types.identifier,
  groupId: types.maybe(types.string),
  code: types.maybe(types.string),
  isValid: types.maybe(types.boolean),
})

export type RegistrationCode = Instance<typeof RegistrationCodeModel>

export const RegistrationCodeStoreModel = types
  .model("RegistrationCodeStore")
  .props({
    registrationCodes: types.map(RegistrationCodeModel),
  })
  .extend(withEnvironment)
  .extend(withOrganizationStore)
  .extend(withGroupStore)
  .actions((self) => ({
    fetchRegistrationCodeById: flow(function* (registrationCodeId: string) {
      const registrationCodeApi = new RegistrationCodeApi(self.environment.api)
      const result = yield* toGenerator(
        registrationCodeApi.getRegistrationCodeById(registrationCodeId),
      )
      self.registrationCodes.put(result.registrationCode)
      if (result.organization) {
        self.organizationStore.putOrganizations([result.organization])
      }
      if (result.group) {
        self.groupStore.putGroups([result.group])
      }
      return self.registrationCodes.put(result.registrationCode)
    }),
    fetchRegistrationCodeByGroup: flow(function* (groupId: string) {
      const registrationCodeApi = new RegistrationCodeApi(self.environment.api)
      const result = yield* toGenerator(registrationCodeApi.getRegistrationCodeByGroup(groupId))
      return self.registrationCodes.put(result)
    }),
    fetchRegistrationCodeLinkByGroup: flow(function* (groupId: string) {
      const registrationCodeApi = new RegistrationCodeApi(self.environment.api)
      return yield* toGenerator(registrationCodeApi.getRegistrationCodeLinkByGroup(groupId))
    }),
    regenerateGroupRegistrationCode: flow(function* (groupId: string) {
      const registrationCodeApi = new RegistrationCodeApi(self.environment.api)
      yield registrationCodeApi.regenerateGroupRegistrationCode(groupId)
    }),
    disableGroupRegistrationCode: flow(function* (groupId: string) {
      const registrationCodeApi = new RegistrationCodeApi(self.environment.api)
      yield registrationCodeApi.disableGroupRegistrationCode(groupId)
    }),
    claimRegistration: flow(function* (invitationId: string) {
      const registrationCodeApi = new RegistrationCodeApi(self.environment.api)
      yield registrationCodeApi.claimRegistration(invitationId)
    }),
  }))

export type RegistrationCodeStore = Instance<typeof RegistrationCodeStoreModel>
