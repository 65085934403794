import * as React from "react"
import MuiDrawer from "@mui/material/Drawer"
import { CSSObject } from "@emotion/react"
import { styled, Theme } from "@mui/material/styles"
import { unitSpacing } from "../../theme/spacing"
import { IconButton } from "@mui/material"
import Close from "@mui/icons-material/Close"
import { webTransitions } from "pitch45-common/theme/transitions"
import { iconSizing } from "../../theme"

export const OPEN_DRAWER_WIDTH = 500

interface PanelProps {
  anchor: "top" | "left" | "bottom" | "right"
  containerStyle?: CSSObject
  open: boolean
  onClose: () => void
  children: React.ReactNode
}

function getStyles() {
  const styles = {
    CLOSE_ICON: {
      padding: 0,
      position: "absolute",
      top: unitSpacing.unitPlus,
      right: unitSpacing.unitPlus,
    },
  }
  return styles as { [key in keyof typeof styles]: CSSObject }
}

const openedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: webTransitions.easing.linear,
    duration: webTransitions.duration.fast,
  }),
  overflowX: "hidden",
  overflowY: "auto",
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: webTransitions.easing.linear,
    duration: webTransitions.duration.fast,
  }),
  border: 0,
  overflowX: "hidden",
  // TODO for consistency, use one of our mediaQueries instead
  [theme.breakpoints.up("xs")]: {
    width: 0,
  },
})

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    whiteSpace: "nowrap",
    height: "0",
    ...(open && {
      "& .MuiDrawer-paper": openedMixin(theme),
      "& .MuiDrawer-docked": {
        width: 0,
      },
    }),
    ...(!open && {
      "& .MuiDrawer-paper": closedMixin(theme),
      "& .MuiDrawer-docked": {
        width: 0,
      },
    }),
  }),
)

export function Panel(props: PanelProps) {
  const { open, onClose, containerStyle, anchor, children } = props
  const styles = getStyles()

  return (
    <Drawer
      variant="permanent"
      anchor={anchor}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: open && OPEN_DRAWER_WIDTH,
          maxWidth: "100%",
          boxShadow: "-5px -2px 5px rgba(191, 205, 216, 0.5)", // hex value is equivalent to semi-transparent color.palette.silver
          ...containerStyle,
        },
      }}
    >
      <IconButton sx={styles.CLOSE_ICON} onClick={() => onClose()}>
        <Close sx={{ fontSize: iconSizing.large }} />
      </IconButton>
      {open && children}
    </Drawer>
  )
}

export default Panel
