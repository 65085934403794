import logger from "../logging/logger"
import { ApiError, logResponseError } from "../services"
import { UploadError, logUploadError } from "../services/api/upload-error"

/**
 * Helper function to try to extract useful tags from an error to make them
 * more useful on Sentry. Should be used when the error may be API or upload related.
 */
export default function (error: any) {
  if ((error as ApiError).response) {
    logResponseError(error)
  } else if ((error as UploadError).uploadResponse) {
    logUploadError(error)
  } else {
    logger.logError(error) // string | Error
  }
}
