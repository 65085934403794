import { Instance, types, flow, toGenerator } from "mobx-state-tree"
import { observable, action } from "mobx"
import { StringEnum } from "../utils/string-enum-type"

export enum SyncStatus {
  New = "New",
  NotSynced = "NotSynced",
  Synced = "Synced",
}

export const SyncModel = types
  .model("SyncModel")
  .props({
    progress: types.maybe(types.number),
    status: types.optional(StringEnum(SyncStatus), SyncStatus.New),
  })
  .actions((self) => ({
    setStatus(status: SyncStatus) {
      self.status = status
    },
  }))
  .actions((self) => ({
    setProgress(progress: number | undefined) {
      self.progress = progress
    },
    unsync() {
      self.status = self.status !== SyncStatus.New ? SyncStatus.NotSynced : SyncStatus.New
    },
  }))
  .extend((self) => {
    const observablePromise = observable.box<Promise<any>>(undefined)
    let progressInterval

    const reset = action(() => {
      observablePromise.set(undefined)
      self.setProgress(undefined)
      clearInterval(progressInterval)
    })

    return {
      actions: {
        run: flow(function* <T>(sync: () => Promise<T>) {
          if (observablePromise.get()) {
            throw new Error("Syncing is already in progress")
          }

          const syncPromise = sync()
          syncPromise.catch(() => {
            // make sure to reset even if there is a non-api error
            reset()
          })

          observablePromise.set(syncPromise)

          // handle the api response
          let result: T
          try {
            result = yield* toGenerator(syncPromise)
            const kind = (result as any)?.kind
            if (!kind || kind === "ok") {
              self.setStatus(SyncStatus.Synced)
            }
          } catch (e) {
            reset()
            throw e
          }

          reset()

          return result
        }),
      },
      views: {
        get syncing() {
          return Boolean(observablePromise.get())
        },
      },
    }
  })

export type Sync = Instance<typeof SyncModel>
