import { Instance, types } from "mobx-state-tree"

export const OrganizationUserRoleModel = types.model("OrganizationUserRoleModel").props({
  entityId: types.maybe(types.string),
  name: types.maybe(types.string),
  organizationRoleId: types.maybe(types.string),
})

export const OrganizationUserModel = types.model("OrganizationUser").props({
  id: types.identifier,
  organizationId: types.string,
  userId: types.maybeNull(types.string),
  name: types.maybe(types.string),
  email: types.maybe(types.string),
  phone: types.maybe(types.string),
  roles: types.maybe(types.array(OrganizationUserRoleModel)),
})

export const UserMembershipModel = types.maybe(
  types.model("UserMembership").props({
    entityId: types.identifier,
    userId: types.maybe(types.string),
    organizationRoleId: types.maybe(types.string),
    parentId: types.maybeNull(types.string),
    organizationUserId: types.maybeNull(types.string),
  }),
)

export type OrganizationUser = Instance<typeof OrganizationUserModel>
export type UserMembership = Instance<typeof UserMembershipModel>
export type OrganizationUserRole = Instance<typeof OrganizationUserRoleModel>
