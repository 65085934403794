import React, { ReactNode } from "react"
import { CSSObject, useTheme } from "@emotion/react"
import { Text } from "../../text/text"
import { AppTheme, WebAppTheme } from "pitch45-common/theme/app-theme.types"
import { TextPresets, textPresets } from "../../text/text.presets"
import { semanticSpacing, unitSpacing } from "../../../theme/spacing"
import { FormControlLabel, Radio, RadioGroup, Stack } from "@mui/material"
import { Icon, Icons, Loop45Icons } from "../../../ui"
import { color } from "pitch45-common/theme/shared-colors"

interface RadioInputOption {
  value: string
  label: ReactNode | string
  description?: string
  showSecondaryOption?: boolean
  secondaryOption?: ReactNode
  iconId?: Icons | Loop45Icons
}

interface RadioInputProps {
  options: RadioInputOption[]
  value: string
  disabled?: boolean
  onChange: (newValue: string) => void
  optionsCss?: CSSObject
  containerCss?: CSSObject
  labelPreset?: TextPresets
}

function getStyles(theme: AppTheme, disabled?: boolean) {
  const styles = {
    CONTAINER: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    LABEL_CONTAINER: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      marginLeft: unitSpacing.half,
    },
    RADIO_SELECTOR: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      marginLeft: unitSpacing.half,
      alignSelf: "center",
      color: theme.colors.text,
      "&.Mui-disabled": {
        color: theme.colors.dimText,
      },
      "&.Mui-disabled.Mui-checked": {
        color: theme.colors.dimText,
      },
      "&.Mui-checked": {
        color: theme.colors.primary,
      },
    },
    RADIO_OPTION_ICON: {
      width: "14px",
      height: "14px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    RADIO_OPTION_ICON_BORDER: {
      border: `1px solid ${color.border}`,
      borderRadius: "50%",
      padding: "3px",
    },
    RADIO_LABEL: {
      marginTop: "4px",
      color: disabled ? theme.colors.dimText : theme.colors.text,
    },
    RADIO_DESCRIPTION: {
      color: disabled ? theme.colors.dimText : theme.colors.text,
      whiteSpace: "normal",
    },
  }
  return styles as { [key in keyof typeof styles]: CSSObject }
}

export const RadioInput = (props: RadioInputProps) => {
  const { options, value, onChange, disabled, optionsCss, containerCss, labelPreset } = props
  const theme = useTheme() as WebAppTheme
  const styles = getStyles(theme, disabled)

  return (
    <div css={styles.CONTAINER}>
      <RadioGroup value={value} onChange={(e) => onChange(e.target.value)} css={containerCss}>
        {options.map((option) => (
          <div key={option.value} css={{ marginTop: semanticSpacing.vertical.narrow }}>
            <FormControlLabel
              key={option.value}
              value={option.value}
              sx={{ padding: semanticSpacing.vertical.narrow, marginLeft: "-18px", ...optionsCss }}
              control={<Radio css={styles.RADIO_SELECTOR} disabled={disabled} />}
              label={
                <div>
                  <Stack direction="row" spacing={1} alignItems="center">
                    {option.iconId && (
                      <div css={styles.RADIO_OPTION_ICON_BORDER}>
                        <Icon
                          icon={option.iconId}
                          color={theme.colors.text}
                          css={styles.RADIO_OPTION_ICON}
                        />
                      </div>
                    )}
                    <Stack>
                      {typeof option.label === "string" ? (
                        <Text css={styles.RADIO_LABEL} preset={labelPreset ?? textPresets.medium}>
                          {option.label}
                        </Text>
                      ) : (
                        option.label
                      )}
                      {option.description && (
                        <Text css={styles.RADIO_DESCRIPTION} preset={textPresets.smallLight}>
                          {option.description}
                        </Text>
                      )}
                    </Stack>
                  </Stack>
                </div>
              }
            />
            {option.showSecondaryOption && option.secondaryOption}
          </div>
        ))}
      </RadioGroup>
    </div>
  )
}

export default RadioInput
