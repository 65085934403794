import React from "react"
import { CSSObject, useTheme } from "@emotion/react"

import { AppTheme } from "pitch45-common/theme/app-theme.types"

import { Text } from "../text/text"
import { textPresets } from "../text/text.presets"
import EntityAvatar from "../entity-avatar/entity-avatar"
import { unitSpacing } from "../../theme/spacing"

interface EntityNameplateProps {
  typeLabel: string
  name: string
  avatarUri?: string
  pageTitle?: string
  subTitle?: string
  hideAvatar?: boolean
}

function getStyles(theme: AppTheme) {
  const styles = {
    CONTAINER: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    AVATAR_CONTAINER: {
      flexShrink: 0,
    },
    LABEL_CONTAINER: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      marginLeft: unitSpacing.halfPlus,
    },
    TITLE: {
      marginTop: unitSpacing.quarter,
    },
    SUBTITLE: {
      color: theme.colors.dimText,
      marginTop: unitSpacing.quarter,
    },
  }
  return styles as { [key in keyof typeof styles]: CSSObject }
}

export const EntityNameplate = (props: EntityNameplateProps) => {
  const { avatarUri, name, typeLabel, pageTitle, subTitle, hideAvatar, ...rest } = props
  const theme = useTheme() as AppTheme
  const styles = getStyles(theme)

  return (
    <div {...rest}>
      <div css={styles.CONTAINER}>
        {!hideAvatar && (
          <div css={styles.AVATAR_CONTAINER}>
            <EntityAvatar uri={avatarUri} width={!pageTitle ? 80 : 20} name={name} />{" "}
          </div>
        )}
        <div css={[styles.LABEL_CONTAINER, hideAvatar && { marginLeft: 0 }]}>
          {!pageTitle && (
            <Text
              preset={textPresets.tinySemibold}
              css={[styles.TITLE, { textTransform: "uppercase" }]}
            >
              {typeLabel}
            </Text>
          )}
          <Text preset={!pageTitle ? textPresets.largeSemibold : textPresets.smallSemibold}>
            {name}
          </Text>
          {!pageTitle && subTitle && (
            <Text css={styles.SUBTITLE} preset={textPresets.smallSemibold}>
              {subTitle}
            </Text>
          )}
        </div>
      </div>
      {pageTitle && (
        <Text preset={textPresets.hugeSemibold} css={styles.TITLE}>
          {pageTitle}
        </Text>
      )}
    </div>
  )
}

export default EntityNameplate
