import { Instance, types } from "mobx-state-tree"
import { StringEnum } from "../utils/string-enum-type"
import logger from "../logging/logger"
import { Timeline, TimelineModel } from "./timeline"
import { Pitch, PitchActionType } from "./pitch"
import { DateTime } from "../utils"

export enum StreamableSummaryStatus {
  Waiting = "Waiting",
  Processing = "Processing",
  Ready = "Ready",
  ProcessingFailed = "ProcessingFailed",
  WaitingTimedOut = "WaitingTimedOut",
}

export enum StudioPitchInfoStatus {
  Ready = "Ready",
  Failed = "Failed",
  Processing = "Processing",
  NotUploaded = "NotUploaded",
  Uploading = "Uploading",
}

export const StudioPitchInfoModel = types
  .model("StudioPitchInfo")
  .props({
    streamableStatus: types.maybe(StringEnum(StreamableSummaryStatus)),
    timeline: types.maybe(types.safeReference(TimelineModel)),
    actionType: types.maybe(StringEnum(PitchActionType)),
    actionTargetId: types.maybe(types.string),
    actionEntityId: types.maybe(types.string),
    scheduledUtc: types.maybeNull(DateTime),
    assignmentUserId: types.maybe(types.string),
    deferring: types.maybe(types.boolean),
  })
  .actions((self) => ({
    setActionType(actionType: PitchActionType) {
      self.actionType = actionType
    },
    setActionEntityId(actionEntityId: string) {
      self.actionEntityId = actionEntityId
    },
    setActionTargetId(actionTargetId: string) {
      self.actionTargetId = actionTargetId
    },
    setTimeline(timeline: Timeline) {
      self.timeline = timeline
    },
    setStreamableStatus(streamableStatus: StreamableSummaryStatus) {
      self.streamableStatus = streamableStatus
    },
  }))
  .views((self) => ({
    get status(): StudioPitchInfoStatus {
      if (self.timeline?.isSyncing) {
        if (self.streamableStatus === StreamableSummaryStatus.Ready) {
          // TODO I think this is a local error state
          logger.logError(new Error("Streamable status is ready but timeline is syncing"))
          return StudioPitchInfoStatus.Ready
        }
        return StudioPitchInfoStatus.Uploading
      } else if (self.streamableStatus === StreamableSummaryStatus.Ready) {
        return StudioPitchInfoStatus.Ready
      } else if (self.streamableStatus === StreamableSummaryStatus.ProcessingFailed) {
        return StudioPitchInfoStatus.Failed
      } else if (self.streamableStatus === StreamableSummaryStatus.WaitingTimedOut) {
        return StudioPitchInfoStatus.NotUploaded
      } else if (self.streamableStatus === StreamableSummaryStatus.Processing) {
        return StudioPitchInfoStatus.Processing
      } else {
        if (self.timeline?.isSynced) {
          return StudioPitchInfoStatus.Processing
        } else {
          return StudioPitchInfoStatus.NotUploaded
        }
      }
    },
  }))

export type StudioPitchInfo = Instance<typeof StudioPitchInfoModel>

export type StudioPitch = Pitch & StudioPitchInfo
