import { ApiResponse } from "apisauce"
import { Api } from "./api"
import { ApiError } from "./api-problem"
import { AppConfig } from "../../models/app-config"

type AppConfigResult = { appConfig: AppConfig }

const API_PATH = "api/AppConfig/"

export class AppConfigApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async getAppConfig(appVersion: string, platform: string): Promise<AppConfigResult> {
    const response: ApiResponse<AppConfigResult> = await this.api.apisauce.get(
      `${API_PATH}${platform}/${appVersion}`,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("AppConfigApi.getAppConfig", response)
    }

    return response.data
  }
}
