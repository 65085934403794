import {
  getParent,
  getParentOfType,
  getSnapshot,
  Instance,
  SnapshotIn,
  types,
} from "mobx-state-tree"
import { SyncModel, SyncStatus } from "./sync"
import { StringEnum } from "../utils/string-enum-type"

export interface VideoProperties {
  duration: number
  fileName: string
  extension: string
  width: number
  height: number
  rotation: number
}

export enum AssetType {
  Video = "Video",
  Image = "Image",
  Audio = "Audio",
  Text = "Text",
}

export enum TextAlignment {
  LEFT = "left",
  CENTER = "center",
  RIGHT = "right",
}

export const TIMELINE_CONFIGURATION = {
  maxMainTrackAssets: 5,
  maxOverlayTrackAssets: 5,
}

export const TimelineAssetModel = types
  .model({
    id: types.identifier,
    type: StringEnum(AssetType),
    extension: types.maybe(types.string),
    duration: types.optional(types.number, 0),
    // Dimension props of the actual asset
    // NOTE: on iPhone and certain devices,
    // the width/height might be reversed with a
    // rotation of 90
    width: types.optional(types.number, 0),
    height: types.optional(types.number, 0),
    rotation: types.optional(types.number, 0),
    // client-only props
    contentUri: types.maybe(types.string),
    lastAddedDate: types.maybe(types.Date),
    fileName: types.maybe(types.string),
  })
  .actions((self) => ({
    setContentUri(contentUri?: string) {
      self.contentUri = contentUri
    },
    setAssetDuration(duration: number) {
      self.duration = duration
    },
  }))
  .views((self) => ({
    get aspectRatio() {
      const flipped = Math.abs(self.rotation) === 90 || Math.abs(self.rotation) === 270
      return flipped ? self.height / self.width : self.width / self.height
    },
  }))

export type TimelineAsset = Instance<typeof TimelineAssetModel>
export type TimelineAssetSnapshot = SnapshotIn<typeof TimelineAssetModel>

export const VideoAssetOptionsModel = types
  .model({
    type: AssetType.Video,
    trimStart: types.number,
    trimEnd: types.number,
    extractedAudio: types.optional(types.boolean, false),
    recordedInApp: types.maybe(types.boolean),
    scriptId: types.maybe(types.string),
  })
  .actions((self) => ({
    // Amount to trim from start in Seconds
    setStartTrim(trimStart: number) {
      self.trimStart = trimStart

      const timeline = getParentOfType(self, TimelineModel)
      timeline.markAsDirty()
    },
    // Amount to trim from end in Seconds
    setEndTrim(trimEnd: number) {
      self.trimEnd = trimEnd
      const timeline = getParentOfType(self, TimelineModel)
      timeline.markAsDirty()
    },
    setExtractedAudio(extractedAudio) {
      self.extractedAudio = extractedAudio
      const timeline = getParentOfType(self, TimelineModel)
      timeline.markAsDirty()
    },
  }))
export type VideoAssetOptions = Instance<typeof VideoAssetOptionsModel>

export const AudioAssetOptionsModel = types
  .model({
    type: AssetType.Audio,
    trimStart: types.number,
    trimEnd: types.number,
    volume: types.optional(types.number, 1),
  })
  .actions((self) => ({
    // Amount to trim from start in Seconds
    setStartTrim(trimStart: number) {
      self.trimStart = trimStart

      const timeline = getParentOfType(self, TimelineModel)
      timeline.markAsDirty()
    },
    // Amount to trim from end in Seconds
    setEndTrim(trimEnd: number) {
      self.trimEnd = trimEnd
      const timeline = getParentOfType(self, TimelineModel)
      timeline.markAsDirty()
    },
  }))
export type AudioAssetOptions = Instance<typeof AudioAssetOptionsModel>

export const ImageAssetOptionsModel = types.model({
  type: AssetType.Image,
})

export type ImageAssetOptions = Instance<typeof ImageAssetOptionsModel>

export const TextAssetOptionsModel = types
  .model({
    type: AssetType.Text,
    text: types.optional(types.string, ""),
    scale: types.optional(types.number, 1),
    color: types.string,
    backgroundColor: types.string,
  })
  .actions((self) => ({
    setText(text: string) {
      self.text = text
      const timeline = getParentOfType(self, TimelineModel)
      timeline.markAsDirty()
    },
    setScale(value: number) {
      self.scale = value
      const timeline = getParentOfType(self, TimelineModel)
      timeline.markAsDirty()
    },
    setColor(value: string) {
      self.color = value
      const timeline = getParentOfType(self, TimelineModel)
      timeline.markAsDirty()
    },
    setBackgroundColor(value: string) {
      self.backgroundColor = value
      const timeline = getParentOfType(self, TimelineModel)
      timeline.markAsDirty()
    },
  }))
export type TextAssetOptions = Instance<typeof TextAssetOptionsModel>

export const ClipModel = types
  .model({
    id: types.identifier,
    asset: types.reference(TimelineAssetModel),
    assetOptions: types.union(
      VideoAssetOptionsModel,
      TextAssetOptionsModel,
      ImageAssetOptionsModel,
      AudioAssetOptionsModel,
    ),
    left: types.optional(types.number, 0),
    top: types.optional(types.number, 0),
    rotation: types.optional(types.number, 0),
    width: types.optional(types.number, 0),
    height: types.optional(types.number, 0),
    // Can be set, but if null becomes computed
    _startPosition: types.maybe(types.number),
    _duration: types.maybe(types.number),
  })
  .actions((self) => ({
    setPosition(left: number, top: number) {
      self.left = left
      self.top = top
      const timeline = getParentOfType(self, TimelineModel)
      timeline.markAsDirty()
    },
    setRotation(rotation: number) {
      self.rotation = rotation
      const timeline = getParentOfType(self, TimelineModel)
      timeline.markAsDirty()
    },
    setSize(width: number, height: number) {
      self.width = width
      self.height = height
      const timeline = getParentOfType(self, TimelineModel)
      timeline.markAsDirty()
    },
    setStartPosition(startPosition: number) {
      self._startPosition = startPosition
      const timeline = getParentOfType(self, TimelineModel)
      timeline.markAsDirty()
    },
    setDuration(duration: number) {
      self._duration = duration
      const timeline = getParentOfType(self, TimelineModel)
      timeline.markAsDirty()
    },
  }))
  .views((self) => ({
    get duration() {
      if (self._duration != null) {
        return self._duration
      } else if (self.assetOptions.type === AssetType.Video) {
        const { trimEnd, trimStart } = self.assetOptions as VideoAssetOptions
        return self.asset.duration - trimEnd - trimStart
      } else if (self.assetOptions.type === AssetType.Audio) {
        const { trimEnd, trimStart } = self.assetOptions as AudioAssetOptions
        return self.asset.duration - trimEnd - trimStart
      } else {
        throw new Error("Duration must always be set for images")
      }
    },
  }))
  .views((self) => ({
    get startPosition() {
      if (self._startPosition != null) {
        return self._startPosition
      }

      const clips = getParent(self) as Clip[]
      if (!clips || !clips.length) {
        return 0
      }
      let startPos = 0
      for (let i = 0; i < clips.length; i++) {
        if (clips[i].id === self.id) {
          return startPos
        } else {
          startPos += clips[i].duration
        }
      }
      return startPos
    },
  }))
  .views((self) => ({
    get endPosition() {
      return self.startPosition + self.duration
    },
  }))

export type Clip = Instance<typeof ClipModel>

export const TimelineCoverModel = types
  .model({
    // For local editing
    contentUri: types.maybe(types.string),
    /** timestamp within the clip's asset */
    assetTimestamp: types.maybe(types.number),
    clip: types.safeReference(ClipModel),
    /** percent of the y coordinate at which to start cropping a square thumbnail */
    yCropPercent: types.optional(types.number, 0),
    // Set by the server on save
    thumbnailAssetId: types.maybe(types.string),
  })
  .views((self) => ({
    get videoTimestamp() {
      if (!self.clip) {
        return 0
      }
      // overall timestamp for the video, needs to be computed
      // in case the clip gets moved around in the timeline
      return (
        self.clip.startPosition +
        (self.assetTimestamp || 0) -
        (self.clip.assetOptions as VideoAssetOptions).trimStart
      )
    },
  }))

export type TimelineCover = Instance<typeof TimelineCoverModel>

export const TrackModel = types
  .model({
    clips: types.array(ClipModel),
  })
  .views((self) => ({
    get trackLength() {
      return self.clips.reduce((len, clip) => {
        return len + clip.duration
      }, 0)
    },
    get firstClip() {
      return self.clips.length ? self.clips[0] : null
    },
    getClipAtPosition(seek: number) {
      return self.clips.find((clip) => {
        return clip.startPosition <= seek && clip.endPosition >= seek
      })
    },
    getClipIndex(clip: Clip | undefined) {
      if (!clip) {
        return -1
      }
      return self.clips.findIndex((c) => c.id === clip.id)
    },
    hasRoomForClip(clip: Clip) {
      // Has room for this clip if it won't overlap any other clips
      // Only applicable to overlay tracks
      return !self.clips.some((existingClip) => {
        const isOverlapping =
          (clip.startPosition > existingClip.startPosition &&
            clip.startPosition < existingClip.endPosition) ||
          (clip.endPosition > existingClip.startPosition &&
            clip.endPosition < existingClip.endPosition) ||
          (existingClip.startPosition > clip.startPosition &&
            existingClip.startPosition < clip.startPosition + clip.duration) ||
          (existingClip.endPosition > clip.startPosition &&
            existingClip.endPosition < clip.startPosition + clip.duration) ||
          (clip.startPosition === existingClip.startPosition &&
            clip.endPosition === existingClip.endPosition)

        const isSameClip = existingClip.id === clip.id
        return isOverlapping && !isSameClip
      })
    },
  }))

export type Track = Instance<typeof TrackModel>

export const TimelineModel = types
  .model("Timeline")
  .props({
    id: types.identifier,
    cover: types.maybe(TimelineCoverModel),
    name: types.maybe(types.string),
    tracks: types.optional(types.array(TrackModel), () => [{ clips: [] }]),
    maxDuration: types.optional(types.number, 45),

    // client-only props
    sync: types.optional(SyncModel, () => ({})),
    assetSyncs: types.map(SyncModel),
  })
  .views((self) => ({
    get allClips(): { [key: string]: Clip } {
      return self.tracks.reduce((allClips, track) => {
        return track.clips.reduce((_, clip) => {
          if (!allClips[clip.id]) {
            allClips[clip.id] = clip
          }
          return allClips
        }, allClips)
      }, {})
    },
    get allAssets(): { [key: string]: TimelineAsset } {
      return self.tracks.reduce((allAssets, track) => {
        return track.clips.reduce((_, clip) => {
          if (!allAssets[clip.asset.id]) {
            allAssets[clip.asset.id] = clip.asset
          }
          return allAssets
        }, allAssets)
      }, {})
    },
  }))
  .views((self) => ({
    get mainTrack() {
      return self.tracks.length ? self.tracks[0] : null
    },
    get overlayTracks() {
      return self.tracks.filter(
        (t, i) =>
          i > 0 &&
          t.clips.some(
            (c) =>
              c.asset.type === AssetType.Video ||
              c.asset.type === AssetType.Image ||
              c.asset.type === AssetType.Text,
          ),
      )
    },
    get audioTracks() {
      return self.tracks.filter(
        (t, i) => i > 0 && t.clips.some((c) => c.asset.type === AssetType.Audio),
      )
    },
    getClipTrack(clip: Clip) {
      for (const track of self.tracks) {
        if (track.clips.some((c) => c.id === clip.id)) {
          return track
        }
      }
      return null
    },
  }))
  .views((self) => ({
    getUniqueAssets(filterFn: (clip: Clip) => boolean): { [key: string]: TimelineAsset } {
      return self.tracks.reduce((allAssets, track) => {
        return track.clips.reduce((_, clip) => {
          if ((filterFn(clip) || !filterFn) && !allAssets[clip.asset.id]) {
            allAssets[clip.asset.id] = clip.asset
          }
          return allAssets
        }, allAssets)
      }, {})
    },
    areClipsOnSameTrack(firstClip: Clip, secondClip: Clip): boolean {
      const track = self.getClipTrack(firstClip)
      return Boolean(track && track.clips.some((c) => c.id === secondClip.id))
    },
    isClipOnMainTrack(clip: Clip): boolean {
      return Boolean(self.mainTrack?.clips.some((c) => c.id === clip.id))
    },
    isClipOnOverlayTrack(clip: Clip): boolean {
      return self.overlayTracks.some((track) => {
        return track.clips.some((c) => c.id === clip.id)
      })
    },
    isClipOnAudioTrack(clip: Clip): boolean {
      return self.audioTracks.some((track) => {
        return track.clips.some((c) => c.id === clip.id)
      })
    },
  }))
  .views((self) => ({
    get mainTrackAssetCount(): number {
      return Object.keys(self.getUniqueAssets(self.isClipOnMainTrack)).length
    },
    get overlayAssetCount(): number {
      return Object.keys(self.getUniqueAssets(self.isClipOnOverlayTrack)).length
    },
    hasCustomCover() {
      return Boolean(self.cover?.thumbnailAssetId)
    },
  }))
  .views((self) => ({
    get reachedMaxMainTrackAssets(): boolean {
      return self.mainTrackAssetCount >= TIMELINE_CONFIGURATION.maxMainTrackAssets
    },
    get reachedMaxOverlayAssets(): boolean {
      return self.overlayAssetCount >= TIMELINE_CONFIGURATION.maxOverlayTrackAssets
    },
    get isSynced() {
      return (
        self.sync.status === SyncStatus.Synced &&
        Array.from(self.assetSyncs.values()).every(
          (assetSync) => assetSync.status === SyncStatus.Synced,
        )
      )
    },
    get isSyncing() {
      return (
        self.sync.syncing ||
        Array.from(self.assetSyncs.values()).some((assetSync) => assetSync.syncing)
      )
    },
    get hasLocalAssets() {
      for (const track of self.tracks) {
        for (const clip of track.clips) {
          // text assets can be blank and should be considered as local assets
          if (!clip.asset.contentUri && clip.asset.type !== AssetType.Text) {
            return false
          }
        }
      }

      return true
    },
    get uploadProgress() {
      const assetSyncs = Array.from(self.assetSyncs.values())
      const syncingAssets = assetSyncs.filter((s) => s.syncing)

      if (syncingAssets.length === 0) {
        return undefined
      }

      const totalProgress = syncingAssets.reduce((acc, s) => (acc += s.progress || 0), 0)
      return (totalProgress + (assetSyncs.length - syncingAssets.length) * 100) / assetSyncs.length
    },
  }))
  .actions((self) => ({
    moveClipToNextAvailableTrack(clip: Clip) {
      const isAudio = clip.asset.type === AssetType.Audio
      const currentTrack = self.getClipTrack(clip)

      let newTrack

      if (isAudio) {
        newTrack = self.audioTracks.find((t) => t.hasRoomForClip(clip))
      } else {
        newTrack = self.overlayTracks.find((t) => t.hasRoomForClip(clip))
      }

      if (!newTrack) {
        self.tracks.push({
          clips: [],
        })
        newTrack = self.tracks[self.tracks.length - 1]
      }

      if (currentTrack && currentTrack !== newTrack) {
        const clipSnapshot = getSnapshot(clip)
        currentTrack.clips.replace(currentTrack.clips.filter((c) => c.id !== clip.id))

        newTrack.clips.push(ClipModel.create(clipSnapshot))
      }
    },

    markAsDirty() {
      self.sync.unsync()

      // Whenever there is a change we should check if the
      // cover photo is still there.
      if (self.cover && !self.cover.thumbnailAssetId) {
        // Make sure the clip the cover was created from
        // is still on the timeline
        // Also, make sure that the part of the asset that it was
        // taken from, is not trimmed out and is still part
        // of this final video
        if (
          !self.cover.clip ||
          (self.cover.clip.assetOptions as VideoAssetOptions).trimStart >
            (self.cover.assetTimestamp || 0) ||
          self.cover.clip.asset.duration -
            (self.cover.clip.assetOptions as VideoAssetOptions).trimEnd <
            (self.cover.assetTimestamp || 0)
        ) {
          self.cover = undefined
        }
      }
    },
  }))

export type Timeline = Instance<typeof TimelineModel>
