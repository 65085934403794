import { cast, clone, flow, types } from "mobx-state-tree"
import { withEnvironment } from "./extensions/with-environment"
import { withOrganizationStore } from "./organization-store"
import { Organization, OrganizationType } from "../models/organization"
import newId from "../utils/new-id"
import { withSessionStore } from "./session-store"
import { AssetAddParams, AssetModel } from "../models/asset"
import { withAssetStore } from "./asset-store"
import { FormModel, createFieldModel, createField } from "../models/form"
import { SyncStatus } from "../models/sync"

const OrganizationFormModel = FormModel.props({
  name: createFieldModel({
    presence: { allowEmpty: false, message: "^Please enter a name" },
    length: { maximum: 256 },
  }),
  logoAssetId: createFieldModel({}),
}).views((self) => ({
  get fields() {
    return [self.name, self.logoAssetId]
  },
}))

export const OrganizationEditorStoreModel = types
  .model("OrganizationEditorStore")
  .props({
    logoAsset: types.maybe(AssetModel),
    coverAsset: types.maybe(AssetModel),
    form: types.maybe(OrganizationFormModel),
    currentOrganizationId: types.maybe(types.string),
  })
  .extend(withEnvironment)
  .extend(withOrganizationStore)
  .extend(withSessionStore)
  .extend(withAssetStore)
  .actions((self) => ({
    setLogo: flow(function* (params: AssetAddParams) {
      self.logoAsset = self.assetStore.createAsset(params)
      self.form?.logoAssetId.setValue(self.logoAsset.id)
    }),
    setLogoAssetId: flow(function* (assetId: string) {
      self.form?.logoAssetId.setValue(assetId)
    }),
    initializeNewOrganization: () => {
      self.currentOrganizationId = undefined
      self.form = cast({
        name: createField("name", ""),
        logoAssetId: createField("logoAssetId", ""),
      })
      self.logoAsset = undefined
      self.coverAsset = undefined
    },
    initializeExistingOrganization: (id?: string) => {
      self.currentOrganizationId = id
      const existingOrg = self.currentOrganizationId
        ? self.organizationStore.organizations.get(self.currentOrganizationId)
        : undefined
      if (!existingOrg) {
        throw new Error("Existing organization not found")
      }
      self.form = cast({
        name: createField("name", existingOrg.name),
        logoAssetId: createField("logoAssetId", existingOrg.logoAssetId),
      })
      if (existingOrg.logoAssetId) {
        self.logoAsset = clone(self.assetStore.toAsset(existingOrg.logoAssetId))
      }
      if (existingOrg.coverAssetId) {
        self.coverAsset = clone(self.assetStore.toAsset(existingOrg.coverAssetId))
      }
    },
    save: flow(function* () {
      // start by uploading profile photo and cover image if one is being provided
      const uploadPromises: Promise<unknown>[] = []
      if (self.logoAsset && self.logoAsset.sync.status === SyncStatus.New) {
        uploadPromises.push(self.assetStore.uploadMobileAsset(self.logoAsset))
      }
      if (self.coverAsset && self.coverAsset.sync.status === SyncStatus.New) {
        uploadPromises.push(self.assetStore.uploadMobileAsset(self.coverAsset))
      }
      yield Promise.all(uploadPromises)

      if (self.currentOrganizationId) {
        const existingOrg = self.organizationStore.organizations.get(self.currentOrganizationId)
        if (!existingOrg) {
          throw new Error("Existing organization not found")
        }
        yield self.organizationStore.saveOrganization({
          ...existingOrg,
          ...self.form?.data,
        })
        return existingOrg.id
      } else {
        // since the user is trying to persist this organization, we will create an id
        const newOrganizationId = newId().id
        yield self.organizationStore.createOrganization({
          ...(self.form?.data as Organization),
          type: OrganizationType.Professional,
          id: newOrganizationId,
        })

        return newOrganizationId
      }
    }),
    reset: () => {
      self.form = undefined
      self.currentOrganizationId = undefined
      self.logoAsset = undefined
      self.coverAsset = undefined
    },
  }))
