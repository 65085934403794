import { Instance, types, flow, toGenerator, clone } from "mobx-state-tree"
import { UserApi } from "../services/api/user-api"
import { withAssetStore } from "./asset-store"
import { Asset, AssetAddParams, AssetModel } from "../models/asset"
import { withEnvironment } from "./extensions/with-environment"
import { createField, createFieldModel, FormModel } from "../models/form"
import { withSessionStore } from "./session-store"
import { PersonalUser } from "../models/personal-user"
import { SyncStatus } from "../models/sync"

const DetailsFormModel = FormModel.props({
  firstName: createFieldModel({
    presence: { allowEmpty: false, message: "^First name cannot be blank" },
  }),
  lastName: createFieldModel({
    presence: { allowEmpty: false, message: "^Last name cannot be blank" },
  }),
  phone: createFieldModel({
    format: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
  }),
  locationCity: createFieldModel({
    conditionalPresence: { other: "locationState", message: "^City cannot be blank" },
  }),
  locationState: createFieldModel({
    conditionalPresence: { other: "locationCity", message: "^State cannot be blank" },
  }),
  locationZip: createFieldModel({
    format: { pattern: /\d{5}(-\d{4})?/, message: "^Zip code is invalid" },
  }),
  title: createFieldModel({}),
  affiliation: createFieldModel({}),
}).views((self) => ({
  get fields() {
    return [
      self.firstName,
      self.lastName,
      self.phone,
      self.locationCity,
      self.locationState,
      self.locationZip,
      self.title,
      self.affiliation,
    ]
  },
}))

export const RegisterStoreModel = types
  .model("RegisterStore")
  .props({
    details: types.maybe(DetailsFormModel),
    avatarAsset: types.maybe(AssetModel),
  })
  .extend(withEnvironment)
  .extend(withSessionStore)
  .extend(withAssetStore)
  .actions((self) => ({
    saveUser: flow(function* (values) {
      const userId = self.sessionStore.currentUser?.id
      const api = new UserApi(self.environment.api)
      const valuesToSave = {
        id: userId,
        ...values,
      }
      yield api.updateCurrentUser(valuesToSave)
      // need to refetch user because registration status may have changed
      yield self.sessionStore.fetchCurrentUser()
    }),
    resetFromUser(user: PersonalUser) {
      self.details = DetailsFormModel.create({
        firstName: createField("firstName", user.firstName),
        lastName: createField("lastName", user.lastName),
        locationCity: createField("locationCity", user.locationCity),
        locationState: createField("locationState", user.locationState),
        phone: createField("phone", user.phone),
        locationZip: createField("locationZip", user.locationZip),
        title: createField("title", user.title),
        affiliation: createField("affiliation", user.affiliation),
      })
      if (user.avatarAssetId) {
        self.avatarAsset = clone(self.assetStore.toAsset(user.avatarAssetId))
      }
    },
    setAffiliation(affiliation: string) {
      self.details?.affiliation.setValue(affiliation)
    },
    setAvatarAsset(asset?: Asset) {
      self.avatarAsset = asset
    },
  }))
  .actions((self) => ({
    setProfilePhoto: function (params: AssetAddParams) {
      const asset = self.assetStore.createAsset(params)
      self.setAvatarAsset(asset)
    },
    clear: () => {
      self.details = undefined
      self.avatarAsset = undefined
    },
    savePhoto: flow(function* () {
      if (self.avatarAsset?.sync.status === SyncStatus.New) {
        yield self.assetStore.uploadMobileAsset(self.avatarAsset)
      }

      return yield* toGenerator(
        self.saveUser({
          avatarAssetId: self.avatarAsset?.id,
        }),
      )
    }),
    saveDetails: flow(function* () {
      // at this point in registration, we don't need to know which org they are joining, so clear any pending codes
      self.sessionStore.clearPendingRegistrationCodeId()
      return self.saveUser({
        firstName: self.details?.firstName.value,
        lastName: self.details?.lastName.value,
        phone: self.details?.phone.value,
        locationCity: self.details?.locationCity.value,
        locationState: self.details?.locationState.value,
        locationZip: self.details?.locationZip.value,
        title: self.details?.title.value?.length !== 0 ? self.details?.title.value : null,
        affiliation:
          self.details?.affiliation.value?.length !== 0 ? self.details?.affiliation.value : null,
      })
    }),
  }))

export type RegisterStore = Instance<typeof RegisterStoreModel>
