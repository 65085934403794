import { flow, Instance, types } from "mobx-state-tree"
import { withEnvironment } from "./extensions/with-environment"
import { ContentReportApi } from "../services/api/content-report-api"
import { ContentReport, ContentReportType } from "../models/content-report"
import { StringEnum } from "../utils/string-enum-type"

export const ContentReportStoreModel = types
  .model("ContentReportStore")
  .props({
    numReportsMade: types.optional(types.number, 0),
    isShowingModal: types.optional(types.boolean, false),

    resourceId: types.maybe(types.string),
    type: types.maybe(StringEnum(ContentReportType)),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    reportContent: flow(function* (report: ContentReport) {
      const contentReportApi = new ContentReportApi(self.environment.api)
      yield contentReportApi.createContentReport(report)
      self.numReportsMade++
    }),
    showContentReportModal: function ({
      type,
      resourceId,
    }: {
      resourceId: string
      type: ContentReportType
    }) {
      self.isShowingModal = true
      self.type = type
      self.resourceId = resourceId
    },
    hideContentReportModal: function () {
      self.isShowingModal = false
    },
  }))

export type ContentReportStore = Instance<typeof ContentReportStoreModel>
