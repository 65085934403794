import { flow, getParent, Instance, IStateTreeNode, toGenerator, types } from "mobx-state-tree"
import { withEnvironment } from "./extensions/with-environment"
import { Playlist, PlaylistModel } from "../models/playlist"
import { withPitchStore } from "./pitch-store"
import { withSessionStore } from "./session-store"
import { withAssetStore } from "./asset-store"
import { RootStoreModel } from "./root-store"
import { withContentPermissionStore } from "./content-permission-store"
import { Program, ProgramModel } from "../models/program"
import { withPlaylistStore } from "./playlist-store"
import { ProgramApi } from "../services/api/program-api"

export const ProgramStoreModel = types
  .model("Program")
  .props({
    programs: types.map(ProgramModel),

    /**
     *  Map of program id => playlists
     */
    programPlaylists: types.map(types.array(types.safeReference(PlaylistModel))),
  })
  .extend(withEnvironment)
  .extend(withPitchStore)
  .extend(withPlaylistStore)
  .extend(withSessionStore)
  .extend(withContentPermissionStore)
  .extend(withAssetStore)
  .actions((self) => ({
    putPrograms(programs: Program[]): Program[] {
      const programModels: Program[] = []
      for (const program of programs) {
        self.programs.put(program)
        const programModel = self.programs.get(program.id)
        if (programModel) {
          programModels.push(programModel)
        }
      }
      return programModels
    },
    putProgramPlaylists: function (programPlaylists: { [programId: string]: Playlist[] }) {
      for (const programId of Object.keys(programPlaylists)) {
        const playlists = programPlaylists[programId]
        self.playlistStore.putPlaylists(playlists)
        self.programPlaylists.set(programId, playlists.map((playlist) => playlist.id) as any)
      }
    },
  }))
  .actions((self) => ({
    fetchProgram: flow(function* (programId: string) {
      const programApi = new ProgramApi(self.environment.api)
      const result = yield* toGenerator(programApi.getProgram(programId))
      return self.putPrograms([result.program])[0]
    }),
    deleteProgram: flow(function* (programId: string) {
      const programApi = new ProgramApi(self.environment.api)
      yield programApi.deleteProgram({ programId })
      self.programs.delete(programId)
    }),
  }))

export type ProgramStore = Instance<typeof ProgramStoreModel>
export const withProgramStore = (self: IStateTreeNode) => ({
  views: {
    get programStore(): ProgramStore {
      return getParent<Instance<typeof RootStoreModel>>(self).programStore
    },
  },
})
