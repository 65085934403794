import logger from "../../logging/logger"

interface UploadErrorResponse {
  body?: string
  code?: number
  originalError?: any
  url: string
  correlationId: string
  isOnline: boolean
  uploadId?: string
}

export class UploadError extends Error {
  readonly uploadResponse: UploadErrorResponse

  constructor(message: string, response: UploadErrorResponse) {
    super(message)
    this.uploadResponse = response
  }
}

export const logUploadError = (e: UploadError) => {
  logger.logError(
    e.message,
    {
      responseBody: e.uploadResponse.body,
      responseCode: e.uploadResponse.code,
      isOnline: e.uploadResponse.isOnline,
      originalError: e.uploadResponse.originalError,
      uploadId: e.uploadResponse.uploadId,
    },
    {
      correlation_id: e.uploadResponse.correlationId,
      url: e.uploadResponse.url,
    },
  )
}
