import LogRocket from "logrocket"
import { ThirdPartyLogger } from "pitch45-common/logging/logger"

export class LogRocketLogger implements ThirdPartyLogger {
  constructor() {
    if (process.env.NEXT_PUBLIC_LOGROCKET_APP_SLUG) {
      LogRocket.init(process.env.NEXT_PUBLIC_LOGROCKET_APP_SLUG, {
        network: {
          requestSanitizer: (request) => {
            if (request.headers.Authorization) {
              request.headers.Authorization = "[REDACTED]"
            }

            return request
          },
        },
      })
    }
  }

  identify(userId?: string): void {
    if (userId) {
      LogRocket.identify(userId)
    }
  }

  track(event: string, eventProperties): void {
    LogRocket.track(event, eventProperties)
  }

  log(): void {
    // logrocket auto captures console logs
  }

  logWarning() {
    // logrocket doesn't differentiate between warnings and errors, so ignore warnings for now
  }

  logError(
    error: string | Error,
    extra?: { [key: string]: any },
    tags?: { [key: string]: string },
  ): void {
    if (typeof error === "string") {
      LogRocket.captureMessage(error, { extra, tags })
    } else if (error instanceof Error) {
      LogRocket.captureException(error, { extra, tags })
    }
  }
}
