import { types, Instance } from "mobx-state-tree"
import { StringEnum } from "../utils/string-enum-type"
import { FormModel, createFieldModel } from "./form"

export enum BillingInterval {
  Month = "month",
  Year = "year",
  Day = "day",
}

export const SubscriptionPlanModel = types.model("SubscriptionPlan").props({
  id: types.identifier,
  name: types.string,
  planType: types.string,
  price: types.number,
  usagePrice: types.number,
  billingInterval: types.maybe(StringEnum(BillingInterval)),
})
export type SubscriptionPlan = Instance<typeof SubscriptionPlanModel>

export const PaymentProfileModel = types.model("PaymentProfile").props({
  id: types.identifier,
  entityId: types.string,
  stripeCustomerId: types.string,
})
export type PaymentProfile = Instance<typeof PaymentProfileModel>

export enum PlanStatus {
  Created = "Created",
  Incomplete = "Incomplete",
  AwaitingPayment = "AwaitingPayment",
  Active = "Active",
  Cancelled = "Cancelled",
}

export const UserSubscriptionModel = types.maybe(
  types.model("UserSubscription").props({
    id: types.identifier,
    entityId: types.string,
    planId: types.string,
    lastEvent: types.string,
    isEntitled: types.boolean,
    isManuallyEntitled: types.boolean,
    entitlementExpirationUtc: types.maybe(types.string),
  }),
)
export type UserSubscription = Instance<typeof UserSubscriptionModel>

export const EntitySubscriptionModel = types.maybe(
  types.model("EntitySubscription").props({
    id: types.identifier,
    entityId: types.string,
    planId: types.string,
    planStatus: types.maybe(StringEnum(PlanStatus)),
    isManuallyEntitled: types.boolean,
    cancelAtUtc: types.maybe(types.string),
  }),
)
export type EntitySubscription = Instance<typeof EntitySubscriptionModel>

export enum PaymentMethodType {
  Card = "card",
  UsBankAccount = "us_bank_account",
}

export const PaymentMethodModel = types.maybe(
  types.model("PaymentMethod").props({
    lastFour: types.string,
    stripePaymentMethodId: types.string,
    type: types.maybe(StringEnum(PaymentMethodType)),
  }),
)
export type PaymentMethod = Instance<typeof PaymentMethodModel>

export const InvoiceModel = types.model("Invoice").props({
  total: types.number,
  createdUtc: types.string,
  periodEnd: types.string,
  billingInterval: types.maybe(StringEnum(BillingInterval)),
  status: types.string,
  invoiceUrl: types.string,
})
export type Invoice = Instance<typeof InvoiceModel>

export const BillingInfoModel = types.maybe(
  types.model("BillingInfo").props({
    name: types.string,
    email: types.string,
    line1: types.maybe(types.string),
    line2: types.maybe(types.string),
    city: types.maybe(types.string),
    state: types.maybe(types.string),
    postalCode: types.maybe(types.string),
  }),
)
export type BillingInfo = Instance<typeof BillingInfoModel>

export const BillingFormModel = FormModel.props({
  name: createFieldModel({
    presence: { allowEmpty: false, message: "^First name cannot be blank" },
    length: { maximum: 256 },
  }),
  email: createFieldModel({
    presence: { allowEmpty: false, message: "^Email cannot be blank" },
    length: { maximum: 256 },
  }),
  line1: createFieldModel({
    presence: { allowEmpty: false, message: "^Address line 1 cannot be blank" },
    length: { maximum: 128 },
  }),
  line2: createFieldModel({
    length: { maximum: 128 },
  }),
  city: createFieldModel({
    presence: { allowEmpty: false, message: "^City cannot be blank" },
    length: { maximum: 128 },
  }),
  state: createFieldModel({
    presence: { allowEmpty: false, message: "^State cannot be blank" },
    length: { maximum: 64 },
  }),
  postalCode: createFieldModel({
    presence: { allowEmpty: false, message: "^Zip code cannot be blank" },
    format: { pattern: /\d{5}(-\d{4})?/, message: "^Zip code is invalid" },
  }),
}).views((self) => ({
  get fields() {
    return [self.name, self.email, self.line1, self.line2, self.city, self.state, self.postalCode]
  },
}))
export interface BillingForm extends Instance<typeof BillingFormModel> {}
