import { Attachment, AttachmentLabel, AttachmentType } from "../models/attachment"

export interface AttachmentLabelData {
  displayName: string
  label: AttachmentLabel
  default?: boolean
}

const descriptionAttachmentLabels = [
  { displayName: "Details", label: AttachmentLabel.Details, default: true },
]

const galleryAttachmentLabels = [
  { displayName: "Gallery", label: AttachmentLabel.Gallery, default: true },
]

const projectAttachmentLabels = [
  { displayName: "Project", label: AttachmentLabel.Project, default: true },
]

const documentLabels = [
  { displayName: "Document", label: AttachmentLabel.Document, default: true },
  { displayName: "Agenda", label: AttachmentLabel.Agenda },
  { displayName: "Agreement", label: AttachmentLabel.Agreement },
  { displayName: "Announcement", label: AttachmentLabel.Announcement },
  { displayName: "Idea", label: AttachmentLabel.Idea },
  { displayName: "Letter", label: AttachmentLabel.Letter },
  { displayName: "One Pager", label: AttachmentLabel.OnePager },
  { displayName: "Proposal", label: AttachmentLabel.Proposal },
  { displayName: "Report", label: AttachmentLabel.Report },
  { displayName: "Resume", label: AttachmentLabel.Resume },
  { displayName: "Deck", label: AttachmentLabel.SlideDeck },
  { displayName: "Summary", label: AttachmentLabel.Summary },
  { displayName: "Update", label: AttachmentLabel.Update },
]

const linkLabels = [
  { displayName: "Link", label: AttachmentLabel.Link },
  { displayName: "Article", label: AttachmentLabel.Article },
  { displayName: "Blog Post", label: AttachmentLabel.BlogPost },
  { displayName: "Calendar", label: AttachmentLabel.Calendar },
  { displayName: "Donation", label: AttachmentLabel.Donation },
  { displayName: "Form", label: AttachmentLabel.Form },
  { displayName: "Guidelines", label: AttachmentLabel.Guidelines },
  { displayName: "Invitation", label: AttachmentLabel.Invitation },
  { displayName: "Listing", label: AttachmentLabel.Listing },
  { displayName: "Portfolio", label: AttachmentLabel.Portfolio },
  { displayName: "Recording", label: AttachmentLabel.Recording },
  { displayName: "Spreadsheet", label: AttachmentLabel.Spreadsheet },
  { displayName: "Website", label: AttachmentLabel.Website, default: true },
]

const allAttachmentLabels = {
  [AttachmentType.Description]: descriptionAttachmentLabels,
  [AttachmentType.Project]: projectAttachmentLabels,
  [AttachmentType.Gallery]: galleryAttachmentLabels,
  [AttachmentType.Resume]: documentLabels,
  [AttachmentType.Website]: linkLabels,
}

export const getAttachmentLabelsByType = function (type: AttachmentType): AttachmentLabelData[] {
  return allAttachmentLabels[type] ?? []
}

export const getDefaultAttachmentTypeDisplayName = (
  viewingAttachmentType: AttachmentType | string,
): string => {
  return allAttachmentLabels[viewingAttachmentType]?.find((a) => a.default)?.displayName ?? ""
}

export const getAttachmentDisplayName = function (attachment: Attachment): string {
  return (
    (attachment.label ? getAttachmentLabelDisplayName(attachment.label) : "") ||
    (attachment.type ? getDefaultAttachmentTypeDisplayName(attachment.type) : "") ||
    "Attachment"
  )
}

export const getAttachmentLabelDisplayName = function (
  attachmentLabel: AttachmentLabel | string | undefined,
): string {
  return (
    Object.values(allAttachmentLabels)
      .flat()
      .find((a) => a.label === attachmentLabel)?.displayName ?? ""
  )
}
