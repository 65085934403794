import { Instance, types } from "mobx-state-tree"
import { DateTime, StringEnum } from "../utils"
import { ResourceType } from "./resource"
import { EntityModel } from "./entity"

export const RecentContentModel = types.model("RecentContent").props({
  resourceId: types.identifier,
  resourceType: StringEnum(ResourceType),
  title: types.string,
  entity: types.maybe(EntityModel),
  thumbnailAssetIds: types.maybe(types.array(types.string)),
  lastViewedUtc: DateTime,
  createdUtc: DateTime,
})

export type RecentContent = Instance<typeof RecentContentModel>
