import { Instance, types, flow, toGenerator } from "mobx-state-tree"
import { withEnvironment } from "./extensions/with-environment"
import { NotificationSettingsApi } from "../services/api/notification-settings-api"
import { withSessionStore } from "./session-store"

export enum NotificationCategory {
  GroupActivity = "GroupActivity",
  Messaging = "Messaging",
  Assignments = "Assignments",
  MyVideos = "MyVideos",
  System = "System",
  ProductInsights = "ProductInsights",
  ProfileVideos = "ProfileVideos",
}

export enum NotificationSetting {
  PushAndEmail = "PushAndEmail",
  PushOnly = "PushOnly",
  EmailOnly = "EmailOnly",
  Off = "Off",
}

export const NotificationSettingsModel = types.model({
  id: types.identifier,
  userId: types.string,
  notificationCategory: types.maybeNull(
    types.enumeration([
      NotificationCategory.GroupActivity,
      NotificationCategory.Messaging,
      NotificationCategory.Assignments,
      NotificationCategory.MyVideos,
      NotificationCategory.ProductInsights,
      NotificationCategory.ProfileVideos,
    ]),
  ),
  organizationId: types.maybe(types.string),
  groupId: types.maybe(types.string),
  sendPush: types.boolean,
  sendEmail: types.boolean,
})

export type NotificationSettings = Instance<typeof NotificationSettingsModel>

export const NotificationSettingsStoreModel = types
  .model("NotificationSettingsStore")
  .props({
    notificationSettings: types.map(NotificationSettingsModel),
  })
  .extend(withEnvironment)
  .extend(withSessionStore)
  .actions((self) => ({
    fetchUserNotificationSettings: flow(function* (notificationCategory: NotificationCategory) {
      const notificationSettingsApi = new NotificationSettingsApi(self.environment.api)
      if (!self.sessionStore.currentUser?.id) {
        throw new Error("fetchUserNotificationSettings: currentUser is not defined")
      }
      const result = yield* toGenerator(
        notificationSettingsApi.getUserNotificationSettings(
          self.sessionStore.currentUser.id,
          notificationCategory,
        ),
      )
      if (result.notificationSettings) {
        self.notificationSettings.put(result.notificationSettings)
      }
      return true
    }),
    fetchAllUserNotificationSettings: flow(function* () {
      const notificationSettingsApi = new NotificationSettingsApi(self.environment.api)
      if (!self.sessionStore.currentUser?.id) {
        throw new Error("fetchAllUserNotificationSettings: currentUser is not defined")
      }
      const result = yield* toGenerator(
        notificationSettingsApi.getAllUserNotificationSettings(self.sessionStore.currentUser.id),
      )

      result.forEach((ns) => {
        self.notificationSettings.put(ns)
      })

      return true
    }),
    createNotificationSettings: flow(function* (
      notificationCategory: NotificationCategory,
      sendEmail: boolean,
      sendPush: boolean,
    ) {
      const notificationSettingsApi = new NotificationSettingsApi(self.environment.api)
      if (!self.sessionStore.currentUser?.id) {
        throw new Error("createNotificationSettings: currentUser is not defined")
      }
      const result = yield* toGenerator(
        notificationSettingsApi.createNotificationSettings(
          self.sessionStore.currentUser.id,
          notificationCategory,
          null,
          null,
          sendEmail,
          sendPush,
        ),
      )

      self.notificationSettings.set(result.notificationSettings.id, result.notificationSettings)
      return true
    }),
    updateNotificationSettings: flow(function* (notificationSettingsId, sendEmail, sendPush) {
      const notificationSettingsApi = new NotificationSettingsApi(self.environment.api)
      const result = yield* toGenerator(
        notificationSettingsApi.updateNotificationSettings(
          notificationSettingsId,
          sendEmail,
          sendPush,
        ),
      )

      self.notificationSettings.set(result.notificationSettings.id, result.notificationSettings)
      return true
    }),
  }))
  .views((self) => ({
    getCurrentUserNotificationSettings(notificationCategory: NotificationCategory) {
      return Array.from(self.notificationSettings.values()).find(
        (ns) =>
          ns?.userId === self.sessionStore.currentUser?.id &&
          ns?.notificationCategory === notificationCategory &&
          !ns?.groupId &&
          !ns?.organizationId,
      )
    },
  }))

export type NotificationSettingsStore = Instance<typeof NotificationSettingsStoreModel>
