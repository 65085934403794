export function stringToEnum<T>(enumObj: T, str: string | undefined): T[keyof T] | undefined {
  const key = str ? str.charAt(0).toUpperCase() + str.slice(1) : ""
  return enumObj[key as keyof T]
}

export function keysToEnum<T>(enumObj: T, obj: any) {
  const newObj: any = {}
  for (const key in obj) {
    const enumValue = stringToEnum(enumObj, key)
    if (enumValue) {
      newObj[enumValue] = obj[key]
    }
  }
  return newObj
}
