import { captureException, captureMessage, setUser, withScope } from "@sentry/nextjs"
import { omit } from "lodash-es"
import LogRocket from "logrocket"
import { ThirdPartyLogger } from "pitch45-common/logging/logger"

export class SentryLogger implements ThirdPartyLogger {
  // initialized in sentry.[server|client|edge].config.ts

  identify(userId: string | null): void {
    setUser(userId ? { id: userId } : null)
  }

  track(): void {}

  log(): void {}

  logWarning(
    error: string | Error,
    extra?: { [key: string]: any },
    tags?: { [key: string]: string },
  ): void {
    this.logError(error, { ...extra, level: "warning" }, tags)
  }

  logError(
    error: string | Error,
    extra?: { [key: string]: any },
    tags?: { [key: string]: string },
  ): void {
    withScope((scope) => {
      scope.setLevel("error") // calls from logWarning() will override this

      // custom handling for ApiErrors
      if (extra?.kind === "timeout" || extra?.kind === "cannot-connect") {
        scope.setFingerprint([extra?.kind])
      } else if (extra?.kind) {
        // don't ever aggregate server-error, bad-request, etc.
        scope.setFingerprint(["{{ default }}", extra.kind])
      }

      if (extra) {
        if (extra.level) {
          scope.setLevel(extra.level)
          extra = omit(extra, "level")
        }
        scope.setExtras(extra)
      }

      if (tags) {
        scope.setTags(tags)
      }

      if (process.env.NEXT_PUBLIC_LOGROCKET_APP_SLUG) {
        const logRocketSessionUrl = LogRocket.sessionURL
        scope.setExtra(
          "LogRocketSessionUrl",
          logRocketSessionUrl ?? "LogRocket configured but sessionUrl unavailable",
        )
      } else {
        scope.setExtra("LogRocketSessionUrl", "LogRocket not configured")
      }

      if (typeof error === "string") {
        captureMessage(error)
      } else if (error instanceof Error) {
        captureException(error)
      } else {
        scope.setExtra("originalError", error)
        captureException(new Error("Invalid error argument"))
      }
    })
  }
}
