import { palette } from "./palette"

/**
 * Roles for colors.  Prefer using these over the palette.  It makes it easier
 * to change things.
 *
 * The only roles we need to place in here are the ones that span through the app.
 *
 * If you have a specific use-case, like a spinner color.  It makes more sense to
 * put that in the <Spinner /> component.
 */
export const color = {
  /**
   * The palette is available to use, but prefer using the name.
   */
  palette,
  /**
   * A helper for making something see-thru. Use sparingly as many layers of transparency
   * can cause older Android devices to slow down due to the excessive compositing required
   * by their under-powered GPUs.
   *
   * Defaults to our background color so that gradient transitions are smooth.
   */
  transparent: "#181d2400",
  halfTransparentWhite: "rgba(255, 255, 255, .5)",
  halfTransparentBlack: "rgba(0, 0, 0, .5)",
  halfTransparentBackground: "#181d2488",
  halfTransparentGray: "rgba(79,88,108,0.8)",
  quarterTransparentBackground: "#181d2444",
  threeQuarterTransparentBackground: "#181d24CC",
  /**
   * Used as the background for popups and other overlays
   * QUESTION: should this really be transparent?
   */
  overlayBackground: "rgba(0, 0, 0, .9)",
  /**
   * The screen background.
   */
  background: palette.charcoalGrey,
  /**
   * The main tinting color, for selection and accents
   */
  primary: palette.red,
  /**
   * The complement to primary color used for hover effects
   */
  primaryHover: palette.crimson,
  /**
  /**
   * The color used for menu hover effects
   */
  menuItemHover: "rgba(191, 205, 216, .2)",
  /**
   * A subtle color used for borders and lines.
   */
  border: palette.silver,
  /**
   * A subtle color used for borders and lines on dark backgrounds.
   */
  darkBorder: palette.slateGrey,
  /**
   * A background for components that site on top of the main background
   */
  backgroundHighlight: palette.ironGrey,
  card: palette.ironGrey,
  partialModal: palette.ironGrey,
  notification: palette.ironGrey,
  thumbnailBackground: palette.slateGrey,
  /**
   * The default color of text in many components.
   */
  text: palette.white,
  /**
   * Secondary Text color
   */
  secondaryText: palette.offWhite,
  /**
   * Text in dimmed components
   */
  dimText: palette.pewterGrey,
  /**
   * Tertiary Text color
   */
  tertiaryText: palette.orange,
  /**
   * Error messages and icons.
   */
  error: palette.angry,
  /**
   * Backdrop opacity for modals or other cases where we want to dim a portion of the screen
   */
  success: "#6cc470",
  /**
   * Color of success toasts
   */
  warning: "#ff9703",
  /**
   * Color of warning toasts
   */
  backdropOpacity: 0.7,

  primaryChart: palette.darkBlue,

  secondaryChart: palette.mediumBlue,

  tertiaryChart: palette.lightBlue,
}
