import { Instance, types } from "mobx-state-tree"
import newId from "../utils/new-id"
import { StringEnum } from "../utils/string-enum-type"

export enum ContentReportType {
  Pitch = "Pitch",
  Playlist = "Playlist",
  UserMessage = "UserMessage",
  UserProfile = "UserProfile",
  OrganizationProfile = "OrganizationProfile",
  GroupProfile = "GroupProfile",
}

export enum ContentReportReason {
  Nudity = "Nudity",
  HateSpeech = "HateSpeech",
  Violence = "Violence",
  Misinformation = "Misinformation",
  Harassment = "Harassment",
  Scam = "Scam",
  Spam = "Spam",
  IntellectualProperty = "IntellectualProperty",
  Other = "Other",
}

export const ContentReportModel = types.model("ContentReport").props({
  id: types.optional(types.string, () => newId().id),
  reporterId: types.maybe(types.string),
  resourceId: types.maybe(types.string),
  explanation: types.maybe(types.string),
  type: types.maybe(StringEnum(ContentReportType)),
  reason: types.maybe(StringEnum(ContentReportReason)),
})

export type ContentReport = Instance<typeof ContentReportModel>
