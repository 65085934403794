// types
import { ApiResponse } from "apisauce"
import { Api } from "./api"
import { ApiError } from "./api-problem"

type BlockedUsersResult = { blockedUserIds: string[]; blockedByUserIds: string[] }

const API_PATH = "api/BlockedUser/"

export class BlockedUserApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async getBlockedUsers(): Promise<BlockedUsersResult> {
    const response: ApiResponse<BlockedUsersResult> = await this.api.apisauce.get(`${API_PATH}`)
    if (!response.ok || !response.data) {
      throw new ApiError("BlockedUserApi.getBlockedUsers", response)
    }
    return response.data
  }

  async blockUser(userId: string): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.post(`${API_PATH}block/${userId}`)
    if (!response.ok) {
      throw new ApiError("BlockedUserApi.blockUser", response)
    }
  }

  async unblockUser(userId: string): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.post(`${API_PATH}unblock/${userId}`)
    if (!response.ok) {
      throw new ApiError("BlockedUserApi.unblockUser", response)
    }
  }
}
