import { ApiResponse } from "apisauce"
import { Api } from "./api"
import { ApiError, getGeneralApiProblem } from "./api-problem"

const API_PATH = "api/PasswordReset/"

export class PasswordResetApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async requestPasswordReset(email): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.post(`${API_PATH}request`, {
      email,
    })

    if (!response.ok) {
      throw new ApiError("PasswordResetApi.requestPasswordReset", response)
    }
  }

  async resetPassword(token, password): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.post(`${API_PATH}reset`, {
      token: encodeURIComponent(token),
      password,
    })

    if (!response.ok) {
      throw new ApiError("PasswordResetApi.resetPassword", response)
    }
  }

  async validateToken(token): Promise<boolean> {
    const response: ApiResponse<any> = await this.api.apisauce.post(`${API_PATH}validate`, {
      token: encodeURIComponent(token),
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        // returns a 400 if the token is invalid (expired or already used)
        if (problem.kind === "bad-request") {
          return false
        }
      }

      throw new ApiError("PasswordResetApi.validateToken", response)
    }

    return true
  }
}
