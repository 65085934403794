export const typography = {
  logo: {
    fontFamily: "Gotham", // "Gotham-Medium",
    fontWeight: "400",
  },

  light: {
    fontFamily: "JosefinSans",
    fontWeight: 300,
  },

  thin: {
    fontFamily: "JosefinSans",
    fontWeight: 100,
  },

  regular: {
    fontFamily: "JosefinSans",
    fontWeight: 300,
  },

  medium: {
    fontFamily: "JosefinSans",
    fontWeight: 500,
  },

  semibold: {
    fontFamily: "JosefinSans",
    fontWeight: 600,
  },

  bold: {
    fontFamily: "JosefinSans",
    fontWeight: "bold",
  },
}
