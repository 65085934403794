import validate from "validate.js"

export function registerCustomValidators() {
  // subset of https://gist.github.com/dperini/729294
  const urlRegExp = new RegExp(
    // protocol (optional)
    "^(?:(?:(?:https?):)\\/\\/)?" +
      // host & domain names, may end with dot
      // can be replaced by a shortest alternative
      "(?![-_])(?:[-\\w\\u00a1-\\uffff]{0,63}[^-_]\\.)+" +
      // TLD identifier name, may end with dot
      "(?:[a-z\\u00a1-\\uffff]{2,}\\.?)" +
      // resource path (optional)
      "(?:[/?#]\\S*)?",
    "i",
  )

  // register global validators
  validate.validators.customUrl = function (value, options, attribute, attributes) {
    if (options.allowBlank && validate.isEmpty(value)) {
      return
    }

    return validate.validators.format(value, { pattern: urlRegExp }, attribute, attributes)
  }

  validate.validators.conditionalPresence = function (value, options, attribute, attributes) {
    const otherAttributeHasValue = Boolean(attributes[options.other])
    if (!otherAttributeHasValue) {
      return
    }

    return validate.validators.presence(
      value,
      { allowEmpty: false, message: options.message },
      attribute,
      attributes,
    )
  }
}
