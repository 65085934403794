import { ApiResponse } from "apisauce"
import logger from "../../logging/logger"

export type GeneralApiProblem =
  /**
   * Times up.
   */
  | { kind: "timeout"; temporary: true }
  /**
   * Cannot connect to the server for some reason.
   */
  | { kind: "cannot-connect"; temporary: true }
  /**
   * This is a 504.
   */
  | { kind: "gateway-timeout"; temporary: true }
  /**
   * The server experienced a problem. Any other 5xx error.
   */
  | { kind: "server-error" }
  /**
   * The client did not submit a valid request. This is a 400.
   */
  | { kind: "bad-request"; errors?: any }
  /**
   * We're not allowed because we haven't identified ourself. This is 401.
   */
  | { kind: "unauthorized" }
  /**
   * We don't have access to perform that request. This is 403.
   */
  | { kind: "forbidden" }
  /**
   * Unable to find that resource. This is a 404.
   */
  | { kind: "not-found" }
  /**
   * Client tried to perform an operation that conflicted with server data. This is a 409.
   */
  | { kind: "conflict" }
  /**
   * Local error that sometimes happens after starting ngrok/API processes. This is a 421.
   */
  | { kind: "misdirected-request"; temporary: true }
  /**
   * All other 4xx series errors.
   */
  | { kind: "rejected" }
  /**
   * Something truly unexpected happened. Most likely can try again. This is a catch all.
   */
  | { kind: "unknown"; temporary: true }
  /**
   * There was a problem unrelated to the actual API call.
   */
  | { kind: "client-error" }
  /**
   * The server returned no data with a 200 status code
   */
  | { kind: "missing-data" }

/**
 * Attempts to get a common cause of problems from an api response.
 *
 * @param response The api response.
 */
export function getGeneralApiProblem(response: ApiResponse<any>): GeneralApiProblem {
  let apiProblem: GeneralApiProblem | undefined
  switch (response.problem) {
    case "CONNECTION_ERROR":
      apiProblem = { kind: "cannot-connect", temporary: true }
      break
    case "NETWORK_ERROR":
      apiProblem = { kind: "cannot-connect", temporary: true }
      break
    case "TIMEOUT_ERROR":
      apiProblem = { kind: "timeout", temporary: true }
      break
    case "SERVER_ERROR":
      switch (response.status) {
        case 504:
          apiProblem = { kind: "gateway-timeout", temporary: true }
          break
        default:
          apiProblem = { kind: "server-error" }
          break
      }
      break
    case "UNKNOWN_ERROR":
      apiProblem = { kind: "unknown", temporary: true }
      break
    case "CLIENT_ERROR":
      switch (response.status) {
        case 400:
          apiProblem = { kind: "bad-request", errors: response.data?.errors }
          break
        case 401:
          apiProblem = { kind: "unauthorized" }
          break
        case 403:
          apiProblem = { kind: "forbidden" }
          break
        case 404:
          apiProblem = { kind: "not-found" }
          break
        case 409:
          apiProblem = { kind: "conflict" }
          break
        case 421:
          apiProblem = { kind: "misdirected-request", temporary: true }
          break
        default:
          apiProblem = { kind: "rejected" }
          break
      }
      break
    case "CANCEL_ERROR":
      break
  }

  if (!apiProblem && !response.data) {
    apiProblem = { kind: "missing-data" }
  }

  return apiProblem || { kind: "client-error" }
}

export function logResponseError(e: Error, response?: ApiResponse<any>) {
  response = response || (e as ApiError).response
  const url = response.config?.baseURL + "/" + response.config?.url

  // we don't need to log 401s as errors because they're a natural consequence of an expired refresh token
  if (response.status === 401) {
    logger.log(`failed to resolve 401 from ${url}`)
  } else {
    const apiProblem = getGeneralApiProblem(response) as any
    const level = apiProblem?.temporary || response.problem === "CLIENT_ERROR" ? "warning" : "error"
    logger.logError(
      e,
      {
        originalError: response.originalError,
        kind: apiProblem?.kind,
        level,
      },
      {
        correlation_id: response.config?.headers?.["X-Correlation-ID"],
        url,
      },
    )
  }
}

export class ApiError extends Error {
  readonly problem: GeneralApiProblem
  readonly response: ApiResponse<any>

  constructor(apiCallName: string, response: ApiResponse<any>) {
    const problem = getGeneralApiProblem(response)
    super(`${apiCallName} returned ${problem.kind}`)
    this.response = response
    this.problem = problem
  }
}
