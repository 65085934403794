import { ApiResponse } from "apisauce"
import { Api } from "./api"
import { ApiError } from "./api-problem"
import { AssignmentUserStatus, AssignmentUser } from "../../models/assignment-user"

const API_PATH = "api/AssignmentUser"

export type AssignmentUsersResult = { assignmentUsers: AssignmentUser[] }

export type AssignmentUserResult = { assignmentUser: AssignmentUser }

export class AssignmentUserApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async getAssignmentUsers(options: {
    assignmentUserStatus?: AssignmentUserStatus
    resourceId?: string
    cursor?: Date
  }): Promise<AssignmentUsersResult> {
    const response: ApiResponse<AssignmentUsersResult> = await this.api.apisauce.get(
      `${API_PATH}`,
      options,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("AssignmentUserApi.getAssignmentUsers", response)
    }
    return response.data
  }

  async getAssignmentUser(assignmentUserId: string): Promise<AssignmentUserResult> {
    const response: ApiResponse<AssignmentUserResult> = await this.api.apisauce.get(
      `${API_PATH}/${assignmentUserId}`,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("AssignmentUserApi.getAssignmentUser", response)
    }
    return response.data
  }

  async hideAssignmentUser(assignmentUserId: string): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.patch(
      `${API_PATH}/${assignmentUserId}/hide`,
    )
    if (!response.ok) {
      throw new ApiError("AssignmentUserApi.hideAssignmentUser", response)
    }
  }

  async unhideAssignmentUser(assignmentUserId: string): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.patch(
      `${API_PATH}/${assignmentUserId}/unhide`,
    )
    if (!response.ok) {
      throw new ApiError("AssignmentUserApi.unhideAssignmentUser", response)
    }
  }

  async completeAssignmentUser(assignmentUserId: string): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.patch(
      `${API_PATH}/${assignmentUserId}/complete`,
    )
    if (!response.ok) {
      throw new ApiError("AssignmentUserApi.completeAssignmentUser", response)
    }
  }

  async uncompleteAssignmentUser(assignmentUserId: string): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.patch(
      `${API_PATH}/${assignmentUserId}/uncomplete`,
    )
    if (!response.ok) {
      throw new ApiError("AssignmentUserApi.uncompleteAssignmentUser", response)
    }
  }
}
