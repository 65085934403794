import { getParent, Instance, IStateTreeNode, types } from "mobx-state-tree"
import { RootStoreModel } from "./root-store"

export const DevStoreModel = types
  .model("DevStore")
  .props({
    showInfoBox: types.optional(types.boolean, false),
    showTimelineHistoryInfo: types.optional(types.boolean, false),
    showCanvasTextImagePreview: types.optional(types.boolean, false),
    showWebContentBoundingBox: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    toggleInfoBox() {
      self.showInfoBox = !self.showInfoBox
    },
    toggleWebContentBoundingBox() {
      self.showWebContentBoundingBox = !self.showWebContentBoundingBox
    },
    toggleTimelineHistory() {
      self.showTimelineHistoryInfo = !self.showTimelineHistoryInfo
    },
    toggleCanvasTextImagePreview() {
      self.showCanvasTextImagePreview = !self.showCanvasTextImagePreview
    },
  }))

export type DevStore = Instance<typeof DevStoreModel>
export const withDevStore = (self: IStateTreeNode) => ({
  views: {
    get devStore(): DevStore {
      return getParent<Instance<typeof RootStoreModel>>(self).devStore
    },
  },
})
