import { flow } from "mobx"
import { Instance, toGenerator, types, cast, getSnapshot, applySnapshot } from "mobx-state-tree"
import { ScriptApi } from "../services/api/script-api"
import newId from "../utils/new-id"
import { withEnvironment } from "./extensions/with-environment"
import { format } from "date-fns"
import { DATE_TIME_FORMAT } from "../utils"
import { Script, ScriptModel, ScriptModelDefaults } from "../models/script"
import { ScriptPrompt, ScriptPromptModel } from "../models/script-prompt"
import { createField, createFieldModel } from "../models/form/string-field"
import { FormModel } from "../models/form"
import { ScriptShareApi } from "../services/api/script-share-api"
import { ScriptShare, ScriptShareModel } from "../models/script-share"

const ScriptFormModel = FormModel.props({
  content: createFieldModel({
    presence: { allowEmpty: false, message: "^Script content can't be blank" },
  }),
  name: createFieldModel({}),
}).views((self) => ({
  get fields() {
    return [self.content, self.name]
  },
}))

export const PitchPrompterStoreModel = types
  .model("PitchPrompterStore")
  .props({
    delay: types.optional(types.number, 3000),
    enabled: types.optional(types.boolean, false),
    scripts: types.map(ScriptModel),
    scriptShares: types.map(ScriptShareModel),
    activeScript: types.maybe(types.safeReference(ScriptModel)),
    scriptForm: types.maybe(ScriptFormModel),
    showTooltip: types.optional(types.boolean, true),
    scriptPrompts: types.optional(types.array(ScriptPromptModel), []),
    isScrolling: types.optional(types.boolean, false),
    // A map of script id to the latest script height in pixels
    // Used to calculate how long the scroll will take
    scriptHeights: types.map(types.number),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    putScriptShare(scriptShare: ScriptShare): ScriptShare {
      return self.scriptShares.put(scriptShare)
    },
    setScriptHeight: (height: number) => {
      if (self.activeScript?.id) {
        self.scriptHeights.set(self.activeScript.id, height)
      }
    },
    setIsScrolling: (isScrolling: boolean) => {
      self.isScrolling = isScrolling
    },
    updateScriptPrompts: (scriptPrompts: ScriptPrompt[]) => {
      self.scriptPrompts.replace(scriptPrompts)
    },
    updateUserScripts: (scripts: Script[]) => {
      self.scripts.replace(new Map(scripts.map((s) => [s.id, s])))
    },
    saveStyleChanges: flow(function* () {
      if (!self.activeScript) {
        throw new Error("No active script to save")
      }

      const originalValues = getSnapshot(self.activeScript)
      self.activeScript.updatedUtc = new Date()

      const scriptApi = new ScriptApi(self.environment.api)
      try {
        yield scriptApi.saveScript(self.activeScript)
      } catch (e) {
        // revert changes
        applySnapshot(self.activeScript, originalValues)
        throw e
      }

      return true
    }),
    saveForm: flow(function* () {
      if (!self.activeScript || !self.scriptForm) {
        throw new Error("No script form to save")
      }

      const errors = self.scriptForm.submit()
      if (errors) {
        throw new Error("Script form has errors")
      }

      const originalValues = getSnapshot(self.activeScript)
      // copy form values
      self.activeScript.name = self.scriptForm.name.value || self.activeScript.defaultName
      self.activeScript.content = self.scriptForm.content.value
      self.activeScript.updatedUtc = new Date()

      const scriptApi = new ScriptApi(self.environment.api)
      try {
        yield scriptApi.saveScript(self.activeScript)
      } catch (e) {
        // revert changes
        applySnapshot(self.activeScript, originalValues)
        throw e
      }

      return true
    }),
  }))
  .actions((self) => ({
    fetchScriptShare: flow(function* (scriptShareId: string) {
      const scriptShareApi = new ScriptShareApi(self.environment.api)
      const result = yield* toGenerator(scriptShareApi.getScriptShare(scriptShareId))
      return self.putScriptShare(result.scriptShare)
    }),
    fetchUserScripts: flow(function* () {
      const scriptApi = new ScriptApi(self.environment.api)
      const result = yield* toGenerator(scriptApi.getUserScripts())

      self.updateUserScripts(result.scripts)
      return true
    }),
    fetchScriptPrompts: flow(function* () {
      const scriptApi = new ScriptApi(self.environment.api)
      const result = yield* toGenerator(scriptApi.getScriptPrompts())

      self.updateScriptPrompts(result.prompts)
      return true
    }),
  }))
  .actions((self) => ({
    resetPitchPrompter: () => {
      self.activeScript = undefined
      self.scriptForm = undefined
      self.enabled = false
    },
    deleteScriptId: flow(function* (scriptId: string) {
      const scriptApi = new ScriptApi(self.environment.api)
      yield scriptApi.deleteScript(scriptId)
      self.updateUserScripts(Array.from(self.scripts.values()).filter((s) => s.id !== scriptId))
      return true
    }),
    deleteNewActiveScript: () => {
      if (self.activeScript && self.activeScript.isNew) {
        const scriptIdToDelete = self.activeScript.id
        // disable current script
        self.activeScript = undefined
        self.enabled = false
        self.scriptForm = undefined
        self.updateUserScripts(
          Array.from(self.scripts.values()).filter((s) => s.id !== scriptIdToDelete),
        )
      }
    },
    setDelay: (delay: number) => {
      self.delay = delay
    },
    setEnabled: (enabled: boolean) => {
      self.enabled = enabled
    },
    startEditing: () => {
      self.scriptForm = cast({
        name: createField("name", self.activeScript?.name),
        content: createField("content", self.activeScript?.content),
      })
    },
    stopEditing: () => {
      self.scriptForm = undefined
    },
    disableScript: () => {
      self.activeScript = undefined
      self.enabled = false
    },
    setShowTooltip: (showTooltip: boolean) => {
      self.showTooltip = showTooltip
    },
    setActiveScriptId: (scriptId: string) => {
      self.activeScript = scriptId as any
      self.enabled = true
    },
    setSpeed: (speed: number) => {
      if (self.activeScript) {
        self.activeScript.speed = speed
      }
    },
    setName: (name) => {
      if (self.scriptForm) {
        self.scriptForm.name.value = name
      }
    },
    setContent: (content) => {
      if (self.scriptForm) {
        self.scriptForm.content.value = content
      }
    },
    setFontSize: (fontSize: number) => {
      if (self.activeScript) {
        self.activeScript.fontSize = fontSize
        self.saveStyleChanges()
      }
    },
    setAlignment: (align) => {
      if (self.activeScript) {
        self.activeScript.align = align
        self.saveStyleChanges()
      }
    },
    setFontColor: (fontColor) => {
      if (self.activeScript) {
        self.activeScript.fontColor = fontColor
        self.saveStyleChanges()
      }
    },
    setScript: (script: Script) => {
      if (script.id) {
        self.scripts.set(script.id, script)
      }
    },
    createNewScript: ({
      name,
      content,
      promptId,
    }: {
      name?: string
      content?: string
      promptId?: string
    } = {}) => {
      const newScript = {
        ...newId(),
        ...ScriptModelDefaults,
        name: name,
        content: content ?? "",
        promptId: promptId ?? "",
        defaultName: format(new Date(), DATE_TIME_FORMAT),
      }

      self.scripts.set(newScript.id, newScript)
      self.activeScript = newScript.id as any
      self.enabled = true
      return newScript.id
    },
  }))
  .views((self) => ({
    get activeScriptValues() {
      if (!self.activeScript) {
        return {}
      }
      return {
        name: self.scriptForm ? self.scriptForm.name.value : self.activeScript.name,
        content: self.scriptForm ? self.scriptForm.content.value : self.activeScript.content,
        defaultName: self.activeScript.defaultName,
        speed: self.activeScript.speed,
        fontSize: self.activeScript.fontSize,
        fontColor: self.activeScript.fontColor,
        align: self.activeScript.align,
      }
    },
    get allScripts() {
      return Array.from(self.scripts.values())
    },
    get isEditingScript() {
      return typeof self.scriptForm !== "undefined"
    },
  }))
  .views((self) => ({
    getPixelSpeed() {
      return Math.pow(self.activeScriptValues.speed || 0, 1.1)
    },
  }))
  .views((self) => ({
    getSpeakingTimeInSeconds(script: Script) {
      if (!script) {
        return 0
      }
      const height = self.scriptHeights.get(script.id)
      if (!height) {
        return 0
      } else {
        return height / self.getPixelSpeed()
      }
    },
    get estimatedSpeakingTimeRange() {
      if (!self.activeScript) {
        return {
          low: 0,
          high: 0,
        }
      }

      const numWords =
        self.scriptForm?.content.value?.split(" ").length ||
        self.activeScript.content?.split(" ").length ||
        0
      // Assuming average speaking rate of 150 words per minute for fast speaker and 90 for slow
      const lowRange = numWords / (170 / 60) // Fast speaker - 150 words per minute
      const highRange = numWords / (130 / 60) // Slow speaker - 90 words per minute

      return {
        low: Math.floor(lowRange),
        high: Math.floor(highRange),
      }
    },
  }))

export type PitchPrompterStore = Instance<typeof PitchPrompterStoreModel>
