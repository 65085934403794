import { Instance, types } from "mobx-state-tree"
import { DateTime } from "../utils/datetime-type"

/**
 * Script Share model.
 */
export const ScriptShareModel = types.model("ScriptShare").props({
  id: types.identifier,
  userId: types.maybe(types.string),
  name: types.maybe(types.string),
  content: types.maybe(types.string),
  createdUtc: types.maybe(DateTime),
})

export type ScriptShare = Instance<typeof ScriptShareModel>
