import { getParent, Instance, IStateTreeNode, types } from "mobx-state-tree"
import logger from "../logging/logger"
import SplitIO from "@splitsoftware/splitio/types/splitio"
import { StringEnum } from "../utils/string-enum-type"
import { RootStoreModel } from "./root-store"

export enum DefaultTreatment {
  On = "on",
  Off = "off",
  Control = "control",
}

export enum MobileFeatureFlags {
  MobileLogRocketEnabled = "mobile-logrocket-enabled",
  MobilePaymentsEnablePayments = "mobile-payments-enablePayments",
  MobilePaymentsEnablePaymentsAndroid = "mobile-payments-enablePaymentsAndroid",
  MobilePreviewShowScheduleAction = "mobile-preview-showScheduleAction",
  MobileSettingsEnableAboutScreen = "mobile-settings-enableAboutScreen",
  MobileStudioAllowImageRotation = "mobile-studio-allowImageRotation",
  MobileStudioEnableNarration = "mobile-studio-enableNarration",
  MobileStudioEnablePitchPrompterEstimatedTime = "mobile-studio-enablePitchPrompterEstimatedTime",
  MobileSubscriptionsEnablePlaylistButton = "mobile-subscriptions-enablePlaylistButton",
  MobilePitchViewerEnableReactionButton = "mobile-pitchViewer-enableReactionButton",
  MobilePitchViewerSkipEndSlate = "mobile-pitchViewer-skipEndSlate",
  MobileGroupMembersProfilePitchPlaylist = "mobile-group-members-profile-pitch-playlist",
  MobileVideoUseExpoCamera = "mobile-video-useExpoCamera",
  MobileHomeShowPinnedPlaylists = "mobile-home-showPinnedPlaylists",
}

export enum WebFeatureFlags {
  WebPitchViewerSkipEndSlate = "web-pitchViewer-skipEndSlate",
  WebLogRocketEnabled = "web-logrocket-enabled",
}

export const FeatureFlagModel = types.model("FeatureFlagModel").props({
  key: types.identifier,
  name: types.maybe(types.string),
  value: types.maybe(StringEnum(DefaultTreatment)),
})

export const FeatureFlagStoreModel = types
  .model("FeatureFlagStore")
  .props({
    mobileFlags: types.map(FeatureFlagModel),
    webFlags: types.map(FeatureFlagModel),
    isConfiguring: types.optional(types.boolean, true),
  })
  .volatile(
    () =>
      ({
        splitFactory: undefined,
        userClient: undefined,
      } as {
        splitFactory?: SplitIO.ISDK
        userClient?: SplitIO.IClient
      }),
  )
  .actions((self) => ({
    setSplitFactory(factory: SplitIO.ISDK) {
      self.splitFactory = factory
    },
    setIsConfiguring(value) {
      self.isConfiguring = value
    },
    clearUserClient() {
      self.userClient = undefined
    },
  }))
  .actions((self) => ({
    updateMobileFeatureFlags(client: SplitIO.IClient, attributes: SplitIO.Attributes) {
      const flagNames = Object.values(MobileFeatureFlags)
      const treatments = client.getTreatments(flagNames, attributes)
      for (const [name, treatment] of Object.entries(treatments)) {
        self.mobileFlags.put({
          name,
          key: name,
          value: treatment as DefaultTreatment, // TODO potentially risky cast
        })
      }
      self.setIsConfiguring(false)
    },
    updateWebFeatureFlags(client: SplitIO.IClient, attributes: SplitIO.Attributes) {
      const flagNames = Object.values(WebFeatureFlags)
      const treatments = client.getTreatments(flagNames, attributes)
      for (const [name, treatment] of Object.entries(treatments)) {
        self.webFlags.put({
          name,
          key: name,
          value: treatment as DefaultTreatment, // TODO potentially risky cast
        })
      }
      self.setIsConfiguring(false)
    },
  }))
  .actions((self) => ({
    configureForUser(
      userId: string | undefined,
      attributes: SplitIO.Attributes = {},
      platform: "mobile" | "web",
    ) {
      self.setIsConfiguring(true)
      // we can add custom attributes about the user in the future, like org id
      logger.log(`configuring split.io for user: ${userId}`)
      if (!self.splitFactory) {
        logger.logWarning("cannot configure user when factory is not initialized")
        return
      }
      if (userId) {
        self.userClient = self.splitFactory.client(userId)
      } else {
        self.userClient = self.splitFactory.client()
      }
      if (!self.userClient) {
        logger.logWarning("cannot configure user when client is not initialized")
        return
      }

      self.userClient.ready().then(() => {
        if (!self.userClient) {
          logger.logWarning("user client is not initialized after ready is called")
          return
        }
        platform === "mobile"
          ? self.updateMobileFeatureFlags(self.userClient, attributes)
          : self.updateWebFeatureFlags(self.userClient, attributes)
      })
      self.userClient.on(self.userClient.Event.SDK_UPDATE, () => {
        if (!self.userClient) {
          logger.logWarning("user client is not initialized SDK_UPDATE event")
          return
        }
        platform === "mobile"
          ? self.updateMobileFeatureFlags(self.userClient, attributes)
          : self.updateWebFeatureFlags(self.userClient, attributes)
      })
    },
    shutdownSplitClient() {
      return self.userClient?.destroy().then(self.clearUserClient)
    },
  }))
  .views((self) => ({
    getMobileFlagValue(key: string) {
      return self.mobileFlags.get(key)?.value || DefaultTreatment.Control
    },
    getWebFlagValue(key: string) {
      return self.webFlags.get(key)?.value || DefaultTreatment.Control
    },
  }))

export type FeatureFlagStore = Instance<typeof FeatureFlagStoreModel>
export const withFeatureFlagStore = (self: IStateTreeNode) => ({
  views: {
    get featureFlagStore(): FeatureFlagStore {
      return getParent<Instance<typeof RootStoreModel>>(self).featureFlagStore
    },
  },
})
