import { flow, getParent, Instance, IStateTreeNode, toGenerator, types } from "mobx-state-tree"
import { withEnvironment } from "./extensions/with-environment"
import newId from "../utils/new-id"
import {
  AssetType,
  Timeline,
  TimelineAsset,
  TimelineAssetModel,
  TimelineAssetSnapshot,
  VideoProperties,
} from "../models/timeline"
import logger from "../logging/logger"
import { undoManager } from "./undo-manager"
import { RootStoreModel } from "./root-store"
import { getErrorMessage } from "../utils/error"

const ThumbnailModel = types
  .model("Thumbnail")
  .props({
    timeStamp: types.number,
    assetId: types.string,
    uri: types.maybe(types.string),
  })
  .actions((self) => ({
    setUri(uri?: string) {
      self.uri = uri
    },
  }))

const timeStampToThumbnailMap = types.optional(types.map(ThumbnailModel), {})

export const TimelineAssetStoreModel = types
  .model("TimelineAssetStore")
  .props({
    timelineAssets: types.map(TimelineAssetModel),
    /**
     * A cache of local thumbnail uris. Mapped by AssetId and then Timestamp
     */
    thumbnails: types.optional(types.map(timeStampToThumbnailMap), {}),
  })
  .extend(withEnvironment)
  .views((self) => ({
    getTimelineAssetPath(timeline: Timeline, asset: TimelineAsset | TimelineAssetSnapshot) {
      let path = `${self.environment.fileSystem.documentDirectory}timeline/${timeline.id}/${asset.id}`

      // ios requires file extensions to preview these local assets
      if (asset.extension) {
        path += `.${asset.extension}`
      }

      return path
    },
  }))
  .actions((self) => ({
    refreshContentUri: flow(function* (
      timeline: Timeline,
      asset: TimelineAsset | TimelineAssetSnapshot,
    ) {
      const fileUri = self.getTimelineAssetPath(timeline, asset)
      if (yield* toGenerator(self.environment.fileSystem.existsAsync(fileUri))) {
        asset.contentUri = yield self.environment.fileSystem.getContentUriAsync(fileUri)
      } else {
        // if the local file is missing, don't claim that we can preview this asset
        asset.contentUri = undefined
        self.thumbnails.delete(asset.id)
      }
    }),
  }))
  .actions((self) => ({
    finalizeAssetCreation: flow(function* (timeline: Timeline, asset: TimelineAssetSnapshot) {
      // create a local copy so that we still have the asset even if the user deletes
      // it from their camera roll.
      const destinationUri = self.getTimelineAssetPath(timeline, asset)
      // it's possible to add a duplicate from the pitch bin, in which case it may already exist
      if (asset.contentUri !== destinationUri && asset.contentUri) {
        yield self.environment.fileSystem
          .copyAsync({ from: asset.contentUri, to: destinationUri })
          .then(() => self.refreshContentUri(timeline, asset))
      }
    }),
  }))
  .actions((self) => ({
    refreshContentUris: flow(function* (timeline: Timeline) {
      for (const asset of Object.values(timeline.allAssets)) {
        self.refreshContentUri(timeline, asset)
      }
    }),
    // Create a new local asset
    createVideoAsset: flow(function* (
      timeline: Timeline,
      contentUri: string,
      videoProperties: VideoProperties,
    ) {
      const asset: TimelineAssetSnapshot = {
        ...newId(),
        type: AssetType.Video,
        contentUri,
        extension: videoProperties.extension,
        duration: videoProperties.duration,
        fileName: videoProperties.fileName,
        height: videoProperties.height,
        width: videoProperties.width,
        rotation: videoProperties.rotation,
        lastAddedDate: new Date(),
      }

      self.timelineAssets.set(asset.id, asset)

      yield self.finalizeAssetCreation(timeline, asset)

      return asset
    }),
    createAudioAsset: flow(function* (
      timeline: Timeline,
      contentUri: string,
      duration: number,
      extension: string,
      fileName: string,
    ) {
      const { id } = newId()
      const asset: TimelineAssetSnapshot = {
        id,
        type: AssetType.Audio,
        contentUri,
        extension,
        duration,
        lastAddedDate: new Date(),
        fileName,
      }

      self.timelineAssets.set(asset.id, asset)
      yield self.finalizeAssetCreation(timeline, asset)

      return asset
    }),
    createTextAsset: flow(function* (
      timeline: Timeline,
      contentUri: string,
      width: number,
      height: number,
    ) {
      const { id } = newId()
      const asset: TimelineAssetSnapshot = {
        id,
        type: AssetType.Text,
        duration: 0,
        lastAddedDate: new Date(),
        extension: "png",
        fileName: id,
        width,
        height,
        contentUri,
        rotation: 0,
      }

      self.timelineAssets.set(asset.id, asset)
      yield self.finalizeAssetCreation(timeline, asset)
      return asset
    }),
    createBlankTextAsset: function () {
      const { id } = newId()
      const asset: TimelineAssetSnapshot = {
        id,
        type: AssetType.Text,
        duration: 0,
        lastAddedDate: new Date(),
        width: 0,
        height: 0,
        rotation: 0,
      }

      self.timelineAssets.set(asset.id, asset)
      return asset
    },
    createImageAsset: flow(function* (
      timeline: Timeline,
      contentUri: string,
      extension: string,
      fileName: string,
      width: number,
      height: number,
    ) {
      // Convert all images to PNGs since mediaconvert requires that for overlays
      if (extension !== "png") {
        try {
          contentUri = yield* toGenerator(self.environment.fileSystem.convertToPngAsync(contentUri))
          extension = "png"
        } catch (e) {
          logger.log("Error converting image to PNG" + getErrorMessage(e))
        }
      }

      const { id } = newId()
      const asset: TimelineAssetSnapshot = {
        id,
        type: AssetType.Image,
        contentUri,
        extension,
        duration: 0,
        lastAddedDate: new Date(),
        fileName,
        width,
        height,
        rotation: 0,
      }

      self.timelineAssets.set(asset.id, asset)

      yield self.finalizeAssetCreation(timeline, asset)

      return asset
    }),
    // Store an asset that was returned from the server
    addExistingAssetFromServer(asset: TimelineAsset, timeline: Timeline) {
      self.timelineAssets.set(asset.id, asset)
      self.refreshContentUri(timeline, asset)
    },
    removeLocalAsset(id: string, timeline: Timeline) {
      const asset = self.timelineAssets.get(id)
      if (asset && self.environment.fileSystem) {
        self.environment.fileSystem.deleteAsync(self.getTimelineAssetPath(timeline, asset))
      }
    },
    removeAssetReference(id: string) {
      self.timelineAssets.delete(id)
      self.thumbnails.delete(id)
    },
    getAssetThumbnail(asset: TimelineAsset, timeStamp = 0) {
      if (!timeStamp) {
        timeStamp = 0
      }

      const cacheKey = `${asset.id}-${timeStamp}`

      undoManager.withoutUndo(() => {
        if (!self.thumbnails.has(asset.id)) {
          self.thumbnails.set(
            asset.id,
            timeStampToThumbnailMap.create({
              [timeStamp.toString()]: ThumbnailModel.create({
                assetId: asset.id,
                timeStamp: timeStamp,
              }),
            }),
          )
        } else if (
          self.thumbnails.has(asset.id) &&
          !self.thumbnails.get(asset.id)?.get(timeStamp.toString())
        ) {
          self.thumbnails
            .get(asset.id)
            ?.set(
              timeStamp.toString(),
              ThumbnailModel.create({ assetId: asset.id, timeStamp: timeStamp }),
            )
        }
      })

      const thumbnail = self.thumbnails.get(asset.id)?.get(timeStamp.toString())
      if (asset.contentUri) {
        self.environment.fileSystem
          ?.getThumbnailAsync(asset.contentUri, cacheKey, timeStamp)
          .then((uri) => {
            thumbnail?.setUri(uri)
          })
          .catch((e) => {
            logger.logError(e)
            // if we can't preview the asset, we probably can't play it, so remove the content uri
            thumbnail?.setUri(undefined)
            asset.setContentUri(undefined)
          })
      }
      return thumbnail
    },
  }))

export type TimelineAssetStore = Instance<typeof TimelineAssetStoreModel>
export const withTimelineAssetStore = (self: IStateTreeNode) => ({
  views: {
    get timelineAssetStore(): TimelineAssetStore {
      return getParent<Instance<typeof RootStoreModel>>(self).timelineAssetStore
    },
  },
})

export type Thumbnail = Instance<typeof ThumbnailModel>
