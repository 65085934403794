import { Instance, flow, toGenerator, types } from "mobx-state-tree"
import { withEnvironment } from "./extensions/with-environment"
import { withAssetStore } from "./asset-store"
import { withSessionStore } from "./session-store"
import { createField, createFieldModel, FormModel } from "../models/form"
import { withGroupStore } from "./group-store"
import { withUserStore } from "./user-store"
import { StringEnum, combinePlaylistVisibility } from "../utils"
import { ProgramType, ProgramWizardStep } from "../models/program"
import { AssignmentApi, PlaylistApi } from "../services"
import newId from "../utils/new-id"
import { ProgramApi } from "../services/api/program-api"
import { AssignmentParams, AssignmentParamsModel, RecurrencePreset } from "../models/assignment"
import { AutoPostType } from "../models/auto-post-type"
import { ContentVisibility } from "../models/content-visibility"

const ProgramNameFormModel = FormModel.props({
  programName: createFieldModel({
    presence: { allowEmpty: false, message: "^Program name cannot be blank" },
    length: { maximum: 256 },
  }),
}).views((self) => ({
  get fields() {
    return [self.programName]
  },
}))

const ProgramPlaylistDetailsFormModel = FormModel.props({
  playlistName: createFieldModel({
    presence: { allowEmpty: false, message: "^Please enter a name for the Playlist." },
    length: { maximum: 256 },
  }),
  playlistDetails: createFieldModel({}),
  playlistCoverAssetId: createFieldModel({}),
}).views((self) => ({
  get fields() {
    return [self.playlistName]
  },
}))

export const ProgramWizardStoreModel = types
  .model("ProgramWizardStore")
  .props({
    programType: types.maybe(StringEnum(ProgramType)),
    programNameForm: types.maybe(ProgramNameFormModel),
    playlistDetailsForm: types.maybe(ProgramPlaylistDetailsFormModel),
    visibility: types.optional(StringEnum(ContentVisibility), ContentVisibility.External),
    autoPostType: types.optional(StringEnum(AutoPostType), AutoPostType.Off),
    pendingAssignment: types.maybe(AssignmentParamsModel),
  })
  .extend(withEnvironment)
  .extend(withAssetStore)
  .extend(withSessionStore)
  .extend(withUserStore)
  .extend(withGroupStore)
  .actions((self) => ({
    setProgramType: (type?: ProgramType) => {
      self.programType = type
    },
    setVisibility: (visibility: ContentVisibility) => {
      self.visibility = visibility
    },
    setAutoPostType: (autoPostType: AutoPostType) => {
      self.autoPostType = autoPostType
    },
    setCoverAssetId: (coverAssetId?: string) => {
      self.playlistDetailsForm?.playlistCoverAssetId.setValue(coverAssetId)
    },
    populateProgramNameForm: () => {
      if (!self.programNameForm) {
        self.programNameForm = ProgramNameFormModel.create({
          programName: createField("programName", ""),
        })
      }
    },
    populatePlaylistDetailsForm: () => {
      if (!self.playlistDetailsForm) {
        self.playlistDetailsForm = ProgramPlaylistDetailsFormModel.create({
          playlistName: createField("playlistName", ""),
          playlistDetails: createField("playlistDetails", ""),
          playlistCoverAssetId: createField("playlistCoverAssetId", ""),
        })
      }
    },
    resetProgramNameForm() {
      self.programNameForm = undefined
    },
    resetPlaylistDetailsForm() {
      self.playlistDetailsForm = undefined
    },
    setPendingAssignment: (assignment: AssignmentParams) => {
      self.pendingAssignment = assignment
    },
    publish: flow(function* (entityId: string) {
      const progamApi = new ProgramApi(self.environment.api)
      const playlistApi = new PlaylistApi(self.environment.api)
      const assignmentApi = new AssignmentApi(self.environment.api)

      if (!self.programNameForm?.programName.value) {
        throw new Error("Playlist name not set")
      }

      if (!self.playlistDetailsForm?.playlistName.value) {
        throw new Error("Playlist name not set")
      }

      if (!self.programType) {
        throw new Error("Program type not set")
      }

      if (!self.pendingAssignment) {
        throw new Error("Assignment not set")
      }

      const programRequest = {
        id: newId().id,
        entityId,
        name: self.programNameForm?.programName.value,
        description: "",
        type: self.programType as ProgramType,
      }

      const playlistRequest = {
        id: newId().id,
        entityId,
        name: self.playlistDetailsForm?.playlistName.value,
        description: self.playlistDetailsForm?.playlistDetails.value,
        coverAssetId: self.playlistDetailsForm?.playlistCoverAssetId.value,
        autoPostType: self.autoPostType as AutoPostType,
        visibility: combinePlaylistVisibility({
          addChildrenVisibility: ContentVisibility.Internal,
          viewChildrenVisibility: self.visibility,
        }),
      }

      yield* toGenerator(progamApi.createProgram(programRequest))

      yield* toGenerator(playlistApi.createPlaylist(playlistRequest))

      yield* toGenerator(
        assignmentApi.createPlaylistAssignment(playlistRequest.id, self.pendingAssignment),
      )

      yield* toGenerator(
        progamApi.addPlaylists({
          programId: programRequest.id,
          playlistIds: [playlistRequest.id],
        }),
      )

      return programRequest.id
    }),
  }))
  .actions((self) => ({
    initializeProgramDefaults(programType: ProgramType) {
      // If this specific program type has some default configurations associated with it,
      // set it up here
      if (programType === ProgramType.Introductions) {
        self.setPendingAssignment({
          id: newId().id,
          assignToCurrentAndFutureMembers: true,
          assignToCurrentMembers: true,
          initialDueDate: undefined,
          recurrencePreset: RecurrencePreset.Once,
          users: [] as any,
          timezoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
          publishOnDeadline: true,
          description: "",
        })
      }
      self.setAutoPostType(AutoPostType.On)
    },
  }))
  .views((self) => ({
    get steps() {
      switch (self.programType) {
        case ProgramType.Updates:
        case ProgramType.Introductions:
          return [
            ProgramWizardStep.Details,
            ProgramWizardStep.Assign,
            ProgramWizardStep.Settings,
            ProgramWizardStep.Review,
          ]
        default:
          return []
      }
    },
  }))
  .views((self) => ({
    getNextStep: (currentStep: ProgramWizardStep) => {
      const index = self.steps.indexOf(currentStep)
      if (index >= 0 && index < self.steps.length - 1) {
        return self.steps[index + 1]
      }
      return undefined
    },
  }))
  .actions((self) => ({
    resetProgramWizard() {
      self.resetProgramNameForm()
      self.resetPlaylistDetailsForm()
      self.pendingAssignment = undefined
      self.autoPostType = AutoPostType.Off
      self.visibility = ContentVisibility.External
      self.programType = undefined
    },
  }))

export type ProgramWizardStore = Instance<typeof ProgramWizardStoreModel>
