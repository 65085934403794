import { flow, getParent, Instance, IStateTreeNode, toGenerator, types } from "mobx-state-tree"
import { EntityThemeApi } from "../services/api/entity-theme-api"
import { pitch45BrandTheme, theme } from "../theme/theme"
import { ThemeColorModel, EntityThemeModel, ThemeTypographyModel } from "../models/entity-theme"
import { withEnvironment } from "./extensions/with-environment"
import { RootStoreModel } from "./root-store"

export const ThemeStoreModel = types
  .model("ThemeStore")
  .props({
    entityThemes: types.map(EntityThemeModel),
    colors: types.maybe(ThemeColorModel),
    typography: types.maybe(ThemeTypographyModel),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    fetchEntityTheme: flow(function* (entityId: string) {
      const entityThemeApi = new EntityThemeApi(self.environment.api)

      const result = yield* toGenerator(entityThemeApi.getEntityTheme({ entityId }))

      if (result.theme) {
        // If a theme was not returned, store the pitch45 brand theme
        // so we stop searching for one
        self.entityThemes.set(entityId, result.theme)
      } else {
        self.entityThemes.set(entityId, pitch45BrandTheme)
      }

      return self.entityThemes.get(entityId)
    }),
  }))
  .views(() => ({
    get darkTheme() {
      return {
        dark: true,
        colors: {
          ...theme.pitch45Dark.colors,
          brandTheme: pitch45BrandTheme,
        },
      }
    },
    get lightTheme() {
      return {
        dark: false,
        colors: {
          ...theme.pitch45Light.colors,
          brandTheme: pitch45BrandTheme,
        },
      }
    },
  }))
  .views((self) => ({
    get currentTheme() {
      return self.darkTheme
    },
  }))

export type ThemeStore = Instance<typeof ThemeStoreModel>
export const withThemeStore = (self: IStateTreeNode) => ({
  views: {
    get themeStore(): ThemeStore {
      return getParent<Instance<typeof RootStoreModel>>(self).themeStore
    },
  },
})
