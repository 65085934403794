import { ApisauceInstance } from "apisauce"

export const authRefresher: {
  /** Is a token refresh in progress? */
  refreshing: boolean
  /** All requests should await this promise in order to make sure their access tokens are up to date */
  refreshCall: Promise<any>
  /** Initiates the token refresh */
  refresh: () => void
  apisauceInstance?: ApisauceInstance
} = {
  refreshing: false,
  refreshCall: Promise.resolve(),
  refresh: () => {},
  apisauceInstance: undefined,
}

export const attachAuthRefreshInterceptors = (
  apisauceInstance: ApisauceInstance,
  refreshCallback: () => Promise<string | undefined>,
) => {
  // expose these properties so that the uploader can also access them
  authRefresher.refreshing = false
  authRefresher.refreshCall = Promise.resolve()
  authRefresher.apisauceInstance = apisauceInstance
  authRefresher.refresh = () => {
    if (!authRefresher.refreshing) {
      authRefresher.refreshing = true
      authRefresher.refreshCall = new Promise<void>((resolve, reject) => {
        refreshCallback()
          .then((newAccessToken) => {
            authRefresher.refreshing = false
            // reset this to a resolved promise so that subsequent requests don't have to await anything
            authRefresher.refreshCall = Promise.resolve()
            if (newAccessToken) {
              resolve()
            } else {
              reject(new Error("No access token"))
            }
          })
          .catch((e) => {
            authRefresher.refreshing = false
            authRefresher.refreshCall = Promise.resolve()
            reject(e)
          })
      })
    }
  }

  apisauceInstance.axiosInstance.interceptors.request.use((request) => {
    // block any requests that are initiated after we've begun refreshing the token
    return (
      authRefresher.refreshCall
        // make sure the headers of every request are up to date
        .then(() => {
          if (apisauceInstance.headers.Authorization) {
            request.headers.Authorization = apisauceInstance.headers.Authorization
          } else {
            request.headers.Authorization = ""
          }
          return request
        })
        .catch(() => {
          // we could import axios and throw a new axios.CancelledError
          // but then we would need to update api-problem to return a problem on CANCEL_ERROR.
          // throw new axios.CancelledError("Refresh call failed")
          return request
        })
    )
  })

  apisauceInstance.axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      const request = error.config
      if (!request) {
        return error
      }

      if (error?.response?.status !== 401) {
        return error
      }

      // if this was a retried request, give up and throw
      if (request.retry) {
        return error
      }

      // if we've gotten a 401 and no other request is currently getting the the refresh token and
      // the auth headers haven't yet been changed, try to get the refresh token
      if (request.headers.Authorization === apisauceInstance.headers.Authorization) {
        authRefresher.refresh()
      }

      // block responses until the refresh token is resolved, then retry
      return authRefresher.refreshCall
        .then(() => {
          if (
            apisauceInstance.headers.Authorization &&
            request.headers.Authorization !== apisauceInstance.headers.Authorization
          ) {
            request.headers.Authorization = apisauceInstance.headers.Authorization
            request.retry = true
            return apisauceInstance.axiosInstance(request)
          } else {
            // if we don't have a new token, then we shouldn't expect a retry to help
            return error
          }
        })
        .catch(() => {
          return error
        })
    },
  )
}
