import { flow, getParent, Instance, IStateTreeNode, toGenerator, types } from "mobx-state-tree"
import { Affiliation, AffiliationModel } from "../models/affiliation"
import { withEnvironment } from "./extensions/with-environment"
import {
  AffiliationApi,
  AffiliationRequest,
  AffiliationSearchQuery,
} from "../services/api/affiliation-api"
import { RootStoreModel } from "./root-store"

export const AffiliationStoreModel = types
  .model("AffiliationStore")
  .props({
    affiliations: types.map(AffiliationModel),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    putAffiliation(affiliation: Affiliation) {
      self.affiliations.put(affiliation)
      return self.affiliations.get(affiliation.id)
    },
    putAffiliations(affiliations: Affiliation[]): Affiliation[] {
      const affiliationModels: Affiliation[] = []
      affiliations.forEach((affiliation) => {
        self.affiliations.put(affiliation)
        const affiliationModel = self.affiliations.get(affiliation.id)
        if (affiliationModel) {
          affiliationModels.push(affiliationModel)
        }
      })
      return affiliationModels
    },
  }))
  .actions((self) => ({
    createAffiliation: flow(function* (request: AffiliationRequest) {
      const affiliationApi = new AffiliationApi(self.environment.api)
      const result = yield* toGenerator(affiliationApi.createAffiliation(request))

      return self.putAffiliation(result.affiliation)
    }),
    search: flow(function* (query: AffiliationSearchQuery) {
      const affiliationApi = new AffiliationApi(self.environment.api)

      const results = yield* toGenerator(affiliationApi.searchAffiliations(query))

      return { ...results, results: self.putAffiliations(results.results) }
    }),
  }))

export type AffiliationStore = Instance<typeof AffiliationStoreModel>
export const withAffiliationStore = (self: IStateTreeNode) => ({
  views: {
    get affiliationStore(): AffiliationStore {
      return getParent<Instance<typeof RootStoreModel>>(self).affiliationStore
    },
  },
})
