import { Instance, types, flow, toGenerator } from "mobx-state-tree"
import { withEnvironment } from "./extensions/with-environment"
import { Attachment, AttachmentModel } from "../models/attachment"
import { AttachmentApi } from "../services/api/attachment-api"
import { Pitch } from "../models/pitch"

export const AttachmentStoreModel = types
  .model("AttachmentStore")
  .props({
    attachments: types.map(types.array(AttachmentModel)),
    // Description is part of the pitch model and not a separate
    // object like the other attachments
    viewedDescriptionPitchIds: types.array(types.string),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    fetchAttachments: flow(function* (pitchId: string) {
      const attachmentApi = new AttachmentApi(self.environment.api)
      const result = yield* toGenerator(
        attachmentApi.getAttachmentsForPitch({
          pitchId,
        }),
      )
      self.attachments.set(pitchId, result.attachments)
      return self.attachments.get(pitchId)
    }),
    fetchOpenGraphData: flow(function* (attachmentId: string) {
      const attachmentApi = new AttachmentApi(self.environment.api)
      const result = yield* toGenerator(attachmentApi.getOpenGraphData(attachmentId))
      return result
    }),
    fetchUrlPreview: flow(function* (url: string) {
      const attachmentApi = new AttachmentApi(self.environment.api)
      const result = yield* toGenerator(attachmentApi.getOpenGraphPreviewByUrl(url))
      return result
    }),
    markAsViewed: flow(function* (attachmentId: string) {
      const attachmentApi = new AttachmentApi(self.environment.api)
      yield attachmentApi.markAttachmentAsViewed({ attachmentId })
    }),
    markAsViewedOnWeb: flow(function* (attachment: Attachment, pitch: Pitch) {
      // Because we can view attachments from a non-authed state, we can't call the api to track
      // the attachment view when on web
      const attachments = self.attachments.get(pitch.id)
      if (attachment) {
        attachments?.forEach((a) => {
          if (a.type === attachment.type) {
            a.lastViewedUtc = new Date()
          }
        })
      }
    }),
    markPitchDescriptionAsViewed(pitchId: string) {
      if (!self.viewedDescriptionPitchIds.includes(pitchId)) {
        self.viewedDescriptionPitchIds.push(pitchId)
      }
    },
  }))
  .views((self) => ({
    getAttachments: (pitchId) => {
      return self.attachments.get(pitchId)
    },
  }))

export type AttachmentStoreType = Instance<typeof AttachmentStoreModel>
