import { Instance, types } from "mobx-state-tree"
import { DateTime } from "../utils/datetime-type"
import { OrganizationUserRole, OrganizationUserRoleModel } from "./organization-user"

export const ORGANIZATION_GUEST_ROLE_ID = "00000000-0000-0000-0000-000000000123"

export const InvitationRoleModel = types.maybe(
  types.model("InvitationRoleModel").props({
    entityId: types.string,
    organizationRoleId: types.string,
  }),
)

export const InvitationFormModel = types.model("InvitationFormModel").props({
  organizationId: types.maybe(types.string),
  organizationRoleId: types.maybe(types.string),
  groupRoles: types.optional(types.array(InvitationRoleModel), []),
  emails: types.optional(types.array(types.string), []),
  invalidEmails: types.optional(types.array(types.string), []),
  duplicateEmails: types.optional(types.array(types.string), []),
})

export const InvitationModel = types
  .model("Invitation")
  .props({
    id: types.identifier,
    organizationId: types.string,
    email: types.maybe(types.string),
    isClaimed: types.maybe(types.boolean),
    createdUtc: types.maybe(DateTime),
    roles: types.maybe(types.array(OrganizationUserRoleModel)),
  })
  .views((self) => ({
    get invitationGroupRoles() {
      return (
        self.roles?.filter((r): r is OrganizationUserRole => r.entityId !== self.organizationId) ||
        []
      )
    },
  }))

export type Invitation = Instance<typeof InvitationModel>
export type InvitationRole = Instance<typeof InvitationRoleModel>
