import { flow, Instance, toGenerator, types } from "mobx-state-tree"
import { withEnvironment } from "./extensions/with-environment"
import { BranchLinkApi } from "../services/api/branch-link-api"
import { ContentVisibility } from "../models/content-visibility"

export const BranchLinkStoreModel = types
  .model("BranchLinkStore")
  .extend(withEnvironment)
  .actions((self) => ({
    fetchPitchViewerLink: flow(function* (pitchId) {
      const branchLinkApi = new BranchLinkApi(self.environment.api)
      return yield* toGenerator(branchLinkApi.getPitchViewerLink({ pitchId }))
    }),
    fetchDraftPitchViewerLink: flow(function* (
      pitchId: string,
      entityId: string,
      visibility: ContentVisibility | string,
    ) {
      const branchLinkApi = new BranchLinkApi(self.environment.api)
      return yield* toGenerator(
        branchLinkApi.getDraftPitchViewerLink({ pitchId, entityId, visibility }),
      )
    }),
    fetchPlaylistViewerLink: flow(function* (playlistId, pitchId) {
      const branchLinkApi = new BranchLinkApi(self.environment.api)
      return yield* toGenerator(branchLinkApi.getPlaylistViewerLink({ playlistId, pitchId }))
    }),
    fetchAppHomeLink: flow(function* () {
      const branchLinkApi = new BranchLinkApi(self.environment.api)
      return yield* toGenerator(branchLinkApi.getAppHomeLink())
    }),
  }))

export type BranchLinkStore = Instance<typeof BranchLinkStoreModel>
