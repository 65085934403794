import { ContentPermissionStore } from "../stores/content-permission-store"
import { Entity, EntityType } from "./entity"
import { GroupStore } from "../stores/group-store"

export enum ContentVisibility {
  External = "External",
  Internal = "Internal",
  Private = "Private",
  InternalAddExternalViewChildren = "InternalAddExternalViewChildren",
  PrivateAddExternalViewChildren = "PrivateAddExternalViewChildren",
  ExternalAddInternalViewChildren = "ExternalAddInternalViewChildren",
  PrivateAddInternalViewChildren = "PrivateAddInternalViewChildren",
  ExternalAddPrivateViewChildren = "ExternalAddPrivateViewChildren",
  InternalAddPrivateViewChildren = "InternalAddPrivateViewChildren",
}

export const externalVisibilityOptions = [
  ContentVisibility.External,
  ContentVisibility.InternalAddExternalViewChildren,
  ContentVisibility.PrivateAddExternalViewChildren,
  ContentVisibility.ExternalAddInternalViewChildren,
  ContentVisibility.ExternalAddPrivateViewChildren,
]

export const internalVisibilityOptions = [
  ContentVisibility.Internal,
  ContentVisibility.InternalAddPrivateViewChildren,
  ContentVisibility.PrivateAddInternalViewChildren,
]

export const privateVisibilityOptions = [ContentVisibility.Private]

export function getVisibilityList(
  entity: Entity,
  groupStore: GroupStore,
  contentPermissionStore: ContentPermissionStore,
) {
  switch (entity.type) {
    case EntityType.User:
      return contentPermissionStore.getUserContentVisibilityOptions()
    case EntityType.Group: {
      const group = groupStore.groups.get(entity.id)
      return contentPermissionStore.getGroupContentVisibilityOptions(group)
    }
    default:
      throw new Error("Invalid entity type")
  }
}

export function getVisibilityOptionsForEntity<T>(
  entity: Entity,
  groupStore: GroupStore,
  contentPermissionStore: ContentPermissionStore,
  getVisibilityLabelProps: (visibility, entity) => T,
) {
  return getVisibilityList(entity, groupStore, contentPermissionStore)
    .map((v) => getVisibilityLabelProps(v, entity))
    .filter((v) => v)
}
