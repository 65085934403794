import { flow, Instance, toGenerator, types } from "mobx-state-tree"
import { withEnvironment } from "./extensions/with-environment"
import { withSessionStore } from "./session-store"
import { AnalyticsApi } from "../services"

export const PitchStatsStoreModel = types
  .model("PitchStatsStore")
  .props({
    pitchViews: types.map(types.number),
  })
  .extend(withEnvironment)
  .extend(withSessionStore)
  .actions((self) => ({
    fetchPitchStats: flow(function* (pitchId: string) {
      const analyticsApi = new AnalyticsApi(self.environment.api)
      const result = yield* toGenerator(analyticsApi.getUserPitchStats({ pitchId }))

      self.pitchViews.set(pitchId, result.viewCount)
      return self.pitchViews.get(pitchId)
    }),
  }))

export type PitchStatsStore = Instance<typeof PitchStatsStoreModel>
