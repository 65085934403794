import { Instance, types, flow } from "mobx-state-tree"
import { PushTokenApi } from "../services/api/push-token-api"
import logger from "../logging/logger"
import { StringEnum } from "../utils/string-enum-type"
import { withEnvironment } from "./extensions/with-environment"

export enum PushTokenType {
  Firebase = "Firebase",
  Apple = "Apple",
}

export const PushTokenStoreModel = types
  .model("PushTokenStore")
  .props({
    token: types.maybe(types.string),
    tokenType: types.maybe(StringEnum(PushTokenType)),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    setToken: ({ token, tokenType }: { token: string; tokenType: PushTokenType }) => {
      self.token = token
      self.tokenType = tokenType
    },
    registerToken: flow(function* (deviceId: string) {
      if (self.token && self.tokenType) {
        logger.log("Registering push token: " + self.token)
        const pushTokenApi = new PushTokenApi(self.environment.api)
        yield pushTokenApi.registerDeviceToken({
          token: self.token,
          tokenType: self.tokenType,
          deviceId,
        })
      }
    }),
    disableToken: flow(function* (userId: string) {
      if (self.token) {
        const pushTokenApi = new PushTokenApi(self.environment.api)
        yield pushTokenApi.disableDeviceToken({
          userId,
          token: self.token,
        })
      }
    }),
  }))
  .views((self) => ({
    get hasToken() {
      return self.token && self.tokenType
    },
  }))

export type PushTokenStore = Instance<typeof PushTokenStoreModel>
