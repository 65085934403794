import React, { ReactNode } from "react"
import { Card } from "@mui/material"
import { CSSObject, useTheme } from "@emotion/react"
import { AppTheme, WebAppTheme } from "pitch45-common/theme/app-theme.types"
import { MoreMenuButton, MoreMenuItem } from "../more-menu-button/more-menu-button"

function getStyles(theme: AppTheme) {
  const styles = {
    CARD: {
      position: "relative",
      backgroundColor: theme.colors.background,
      borderRadius: "20px",
      borderColor: theme.colors.dimText,
    },
    MORE_MENU: {
      position: "absolute",
      top: "5px",
      right: "5px",
    },
  }
  return styles as { [key in keyof typeof styles]: CSSObject }
}

interface ResourceCardProps {
  moreMenuItems?: MoreMenuItem[]
  children: ReactNode
}

export const ResourceCard = ({ moreMenuItems, children }: ResourceCardProps) => {
  const theme = useTheme() as WebAppTheme
  const styles = getStyles(theme)
  return (
    <Card variant="outlined" sx={styles.CARD}>
      {moreMenuItems && moreMenuItems.length > 0 && (
        <MoreMenuButton style={styles.MORE_MENU} menuItems={moreMenuItems} />
      )}
      {children}
    </Card>
  )
}

export default ResourceCard
