import { types } from "mobx-state-tree"

const ColorThemeModel = types.model("ColorTheme").props({
  background: types.string,
  primary: types.string,
  text: types.string,
  card: types.string,
  border: types.string,
  notification: types.string,
})

export const AppThemeModel = types
  .model("Theme")
  .props({ dark: types.boolean, colors: ColorThemeModel })
