import { Api } from "./api"
import { ApiResponse } from "apisauce"
import { ApiError } from "./api-problem"

const API_PATH = "api/Asset/"

export class AssetApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async uploadAsset(assetId: string, localUri: string, token: string): Promise<void> {
    await this.api.upload(localUri, `${this.api.url}/${API_PATH}${assetId}`, {
      token,
      timeout: 60000,
    })
  }

  async uploadWebAsset(assetId: string, file: File): Promise<void> {
    const result: ApiResponse<any> = await this.api.apisauce.put(`${API_PATH}${assetId}`, file)
    if (!result.ok) {
      throw new ApiError("AssetApi.uploadWebAsset", result)
    }
  }
}
