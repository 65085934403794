import { ApiResponse } from "apisauce"
import { Api } from "./api"
import { ApiError } from "./api-problem"
import qs from "qs"

const TOKEN_PATH = "connect/token"
const AUTH_DOMAIN_LOGIN_PATH = "account/weblogin"

export class AuthApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async requestResourceOwnerPasswordGrant({
    email,
    password,
    mfaCode,
  }): Promise<{ accessToken: string; refreshToken: string }> {
    const response: ApiResponse<{
      // eslint-disable-next-line camelcase
      access_token: string
      // eslint-disable-next-line camelcase
      refresh_token: string
    }> = await this.api.apisauce.post(
      TOKEN_PATH,
      qs.stringify({
        client_id: "com.loop45.app",
        grant_type: "password",
        scopes: "https://scopes.loop45.com/api offline_access",
        username: email,
        password,
        mfa_code: mfaCode,
      }),
      {
        headers: { "content-type": "application/x-www-form-urlencoded" },
      },
    )

    if (!response.ok || !response.data) {
      throw new ApiError("AuthApi.requestResourceOwnerPasswordGrant", response)
    }

    const { access_token: accessToken, refresh_token: refreshToken } = response.data

    return { accessToken, refreshToken }
  }

  async supportRequestResourceOwnerPasswordGrant({
    password,
    supportRequestId,
  }): Promise<{ accessToken: string }> {
    const response: ApiResponse<{
      // eslint-disable-next-line camelcase
      access_token: string
      // eslint-disable-next-line camelcase
      refresh_token: string
    }> = await this.api.apisauce.post(
      TOKEN_PATH,
      qs.stringify({
        client_id: "com.loop45.app.support",
        grant_type: "password",
        scopes: `https://scopes.loop45.com/internal_admin https://scopes.loop45.com/api`,
        username: supportRequestId,
        password,
      }),
      {
        headers: { "content-type": "application/x-www-form-urlencoded" },
      },
    )

    if (!response.ok || !response.data) {
      throw new ApiError("AuthApi.supportRequestResourceOwnerPasswordGrant", response)
    }

    const { access_token: accessToken } = response.data

    return { accessToken }
  }

  async authDomainLogin({ email, password, mfaCode }): Promise<void> {
    const response = await this.api.apisauce.post(
      AUTH_DOMAIN_LOGIN_PATH,
      {
        username: email,
        password,
        mfaCode: mfaCode,
      },
      {
        headers: { "content-type": "application/json" },
        // make sure we can get/set third party cookies on this cross-domain post
        withCredentials: true,
      },
    )

    if (!response.ok) {
      throw new ApiError("AuthApi.authDomainLogin", response)
    }
  }

  async authDomainSupportLogin({ email, password, supportRequestId }): Promise<void> {
    const response = await this.api.apisauce.post(
      "account/supportlogin",
      {
        username: email,
        password,
        supportRequestId,
      },
      {
        headers: { "content-type": "application/json" },
        withCredentials: true,
      },
    )

    if (!response.ok) {
      throw new ApiError("AuthApi.authDomainSupportLogin", response)
    }
  }
}
