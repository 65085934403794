import { Api } from "./api"
import { ApiError } from "./api-problem"
import { ApiResponse } from "apisauce"
import { Post } from "../../models/post"

const API_PATH = "api/Post/"

export type PostsResult = { posts: Post[] }

export class PostApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async getEntityPosts(
    entityId,
    options?: {
      postPageCursor?: string
    },
  ): Promise<PostsResult> {
    const response: ApiResponse<PostsResult> = await this.api.apisauce.get(
      `${API_PATH}${entityId}`,
      {
        postPageCursor: options?.postPageCursor,
      },
    )
    if (!response.ok || !response.data) {
      throw new ApiError("PostApi.getEntityPosts", response)
    }
    return response.data
  }

  async createResourcePost({ entityId, resourceType, resourceId }): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.post(
      `${API_PATH}${entityId}/resource`,
      {
        resourceId,
        resourceType,
      },
    )
    if (!response.ok) {
      throw new ApiError("PostApi.createResourcePost", response)
    }
  }

  async deleteResourcePost({ postId }): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.delete(`${API_PATH}${postId}`)
    if (!response.ok) {
      throw new ApiError("PostApi.deleteResourcePost", response)
    }
  }
}
