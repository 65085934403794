import { Instance, types } from "mobx-state-tree"
import { withEnvironment } from "./extensions/with-environment"
import { withPitchStore } from "./pitch-store"

export const HomeStoreModel = types.maybe(
  types
    .model("Home")
    .props({})
    .extend(withEnvironment)
    .extend(withPitchStore)
    .actions(() => ({})),
)

export type HomeStore = Instance<typeof HomeStoreModel>
