import logger from "pitch45-common/logging/logger"
import { AsyncStorage } from "pitch45-common/utils/storage"

/**
 * Loads a string from storage.
 *
 * @param key The key to fetch.
 */
export function loadString(key: string): Promise<string | null> {
  return Promise.resolve(window.localStorage.getItem(key))
}

/**
 * Saves a string to storage.
 *
 * @param key The key to fetch.
 * @param value The value to store.
 */
export function saveString(key: string, value: string): Promise<boolean> {
  window.localStorage.setItem(key, value)
  return Promise.resolve(true)
}

/**
 * Loads something from storage and runs it thru JSON.parse.
 *
 * @param key The key to fetch.
 */
export async function load(key: string): Promise<any | null> {
  try {
    const localStorageValue = window.localStorage.getItem(key)
    if (!localStorageValue) {
      return null
    }
    return JSON.parse(localStorageValue)
  } catch (e) {
    logger.logError(new Error("Failed to JSON.parse value from storage"), { key, e })
    throw e
  }
}

/**
 * Saves an object to storage.
 *
 * @param key The key to fetch.
 * @param value The value to store.
 */
export function save(key: string, value: any): Promise<boolean> {
  window.localStorage.setItem(key, JSON.stringify(value))
  return Promise.resolve(true)
}

/**
 * Removes something from storage.
 *
 * @param key The key to kill.
 */
export function remove(key: string): Promise<void> {
  window.localStorage.removeItem(key)
  return Promise.resolve()
}

/**
 * Burn it all to the ground.
 */
export function clear(): Promise<void> {
  window.localStorage.clear()
  return Promise.resolve()
}

const storage: AsyncStorage = {
  loadString,
  saveString,
  load,
  save,
  remove,
  clear,
}
export default storage
