import React, { CSSProperties, ReactNode } from "react"
import { useTheme } from "@emotion/react"
import { WebAppTheme } from "pitch45-common/theme/app-theme.types"
import { getTextPresets, TextPresets } from "./text.presets"

export const ALIGN_ICON_STYLES: CSSProperties = {
  position: "relative",
  top: "-2px",
}

interface TextProps {
  children: ReactNode
  preset: TextPresets
  truncate?: boolean
}
export function Text(props: TextProps) {
  const { children, preset, truncate, ...rest } = props

  const theme = useTheme() as WebAppTheme
  const presetStyle = getTextPresets(theme, preset)

  const truncateStyle = truncate
    ? { overflow: "hidden", whiteSpace: "nowrap" as const, textOverflow: "ellipsis" }
    : {}

  const styles = [presetStyle, truncateStyle]

  return (
    <span {...rest} css={styles}>
      {children}
    </span>
  )
}

export default Text
