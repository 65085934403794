import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { withEnvironment } from "./extensions/with-environment"

export const FaceDetectionImageSizeModel = types.model("FaceDetectionImageSizeModel").props({
  height: types.maybe(types.number),
  width: types.maybe(types.number),
})

export const FaceDetectionCoordinatesModel = types.model("FaceDetectionCoordinatesModel").props({
  faceWidth: types.maybe(types.number),
  faceHeight: types.maybe(types.number),
  faceX: types.maybe(types.number),
  faceY: types.maybe(types.number),
})

export const FaceDetectionModel = types.model("FaceDetectionModel").props({
  faces: types.array(FaceDetectionCoordinatesModel),
  imageSize: FaceDetectionImageSizeModel,
})

export type FaceDetection = Instance<typeof FaceDetectionModel>
export type FaceDetectionSnapshot = SnapshotOut<typeof FaceDetectionModel>

export const FaceDetectorStoreModel = types
  .model("FaceDetectorStore")
  .props({
    faceDetectorCache: types.map(FaceDetectionModel),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    saveFaceDetectorResult: (uri: string, faceDetectionModel: FaceDetectionSnapshot) => {
      self.faceDetectorCache.set(uri, faceDetectionModel)
    },
  }))
  .views((self) => ({
    getFaceDetectorResult(uri: string): FaceDetectionSnapshot | undefined {
      return self.faceDetectorCache.get(uri)
    },
    getHeightOffset: (
      model: FaceDetectionSnapshot,
      containerHeight: number,
      containerWidth: number,
    ) => {
      if (!model?.imageSize?.height || !model?.imageSize?.width || !model?.faces?.length) {
        return 0
      }
      const scalingRatio = containerWidth / model.imageSize.width

      const newCenter = containerHeight / 2
      const firstFace = model.faces[0]
      if (firstFace.faceY && firstFace.faceHeight) {
        const currentFaceCenter = firstFace.faceY + firstFace.faceHeight / 2
        let diff = currentFaceCenter * scalingRatio - newCenter

        diff = Math.max(0, diff)
        diff = Math.min(diff, model.imageSize.height - containerHeight)
        return diff
      } else {
        return (model.imageSize.height * scalingRatio) / 2 + containerHeight / 2
      }
    },
  }))

export type FaceDetectorStore = Instance<typeof FaceDetectorStoreModel>
