import { flow, Instance, toGenerator, types } from "mobx-state-tree"
import { PaymentsApi } from "../services/api/payments-api"
import { withEnvironment } from "./extensions/with-environment"
import { withGroupStore } from "./group-store"
import { withSessionStore } from "./session-store"
import { UserSubscriptionModel } from "../models/payments"

export const RCEntitlementModel = types.maybe(
  types.model("RCEntitlement").props({
    identifier: types.identifier,
    isActive: types.boolean,
    willRenew: types.boolean,
    periodType: types.union(types.enumeration(["NORMAL", "INTRO", "TRIAL"]), types.string),
    latestPurchaseDate: types.string,
    latestPurchaseDateMillis: types.number,
    originalPurchaseDate: types.string,
    originalPurchaseDateMillis: types.number,
    expirationDate: types.maybeNull(types.string),
    expirationDateMillis: types.maybeNull(types.number),
    store: types.union(
      types.enumeration([
        "PLAY_STORE",
        "APP_STORE",
        "STRIPE",
        "MAC_APP_STORE",
        "PROMOTIONAL",
        "AMAZON",
        "UNKNOWN_STORE",
      ]),
      types.string,
    ),
    productIdentifier: types.string,
    isSandbox: types.boolean,
    unsubscribeDetectedAt: types.maybeNull(types.string),
    unsubscribeDetectedAtMillis: types.maybeNull(types.number),
    billingIssueDetectedAt: types.maybeNull(types.string),
    billingIssueDetectedAtMillis: types.maybeNull(types.number),
    ownershipType: types.union(
      types.enumeration(["FAMILY_SHARED", "PURCHASED", "UNKNOWN"]),
      types.string,
    ),
  }),
)
export type RCEntitlement = Instance<typeof RCEntitlementModel>

export const RCCustomerInfoModel = types.maybe(
  types.model("RCCustomerInfo").props({
    id: types.identifier,
    activeEntitlements: types.array(RCEntitlementModel),
    activeSubscriptions: types.array(types.string),
    originalAppUserId: types.string,
    requestDate: types.string,
    managementUrl: types.maybeNull(types.string),
  }),
)
export type RCCustomerInfo = Instance<typeof RCCustomerInfoModel>

export const UserPaymentsStoreModel = types
  .model("UserPaymentsStore")
  .props({
    rcCustomerInfo: RCCustomerInfoModel,
    userSubscription: UserSubscriptionModel,
  })
  .extend(withEnvironment)
  .extend(withGroupStore)
  .extend(withSessionStore)
  .actions((self) => ({
    setRCCustomerInfo: (customerInfo: RCCustomerInfo) => {
      self.rcCustomerInfo = customerInfo
    },
  }))
  .actions((self) => ({
    fetchUserSubscription: flow(function* () {
      const api = new PaymentsApi(self.environment.api)
      const result = yield* toGenerator(api.getUserSubscription())
      self.userSubscription = result?.userSubscription
      return true
    }),
  }))
  .views((self) => ({
    get isSubscribedToUserPlan() {
      // For now just check they are entitled to anything since we have one subscription
      const maximumPublishedCount =
        self.sessionStore.session?.userEntitlements?.maximumPublishedCount
      return maximumPublishedCount && maximumPublishedCount > 10
    },
    get isManuallyEntitled() {
      return self.userSubscription?.isManuallyEntitled
    },
    get activeEntitlement() {
      return self.rcCustomerInfo?.activeEntitlements.length
        ? self.rcCustomerInfo?.activeEntitlements[0]
        : undefined
    },
    get managementUrl() {
      return self.rcCustomerInfo?.managementUrl
    },
  }))

export type UserPaymentsStore = Instance<typeof UserPaymentsStoreModel>
