import React, { ReactNode } from "react"
import { observer } from "mobx-react-lite"
import Backdrop from "@mui/material/Backdrop"
import CircularProgress from "@mui/material/CircularProgress"
import { useTheme } from "@emotion/react"
import { WebAppTheme } from "pitch45-common/theme"
import { unitSpacing } from "../../theme"

interface LoadableProps {
  /**
   * show the loading spinner instead of the children content
   */
  loading?: boolean

  /**
   * the content of the div if it loaded successfully
   */
  children?: ReactNode

  /**
   * show the loading spinner inline instead of using the full screen back drop
   */
  inline?: boolean
}

/**
 * View that displays either its children or a loading state
 */
export const Loadable = observer(function Loadable(props: LoadableProps) {
  const { loading, inline, children, ...rest } = props
  const theme = useTheme() as WebAppTheme

  if (loading) {
    if (inline) {
      return (
        <div
          css={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            padding: unitSpacing.unit,
          }}
          {...rest}
        >
          <CircularProgress sx={{ color: theme.colors.primary }} />
        </div>
      )
    }
    return (
      <div {...rest}>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    )
  }

  return <div {...rest}>{children}</div>
})
