// types
import { ApiResponse } from "apisauce"
import { Api } from "./api"

import { ApiError } from "./api-problem"
import { Organization, OrganizationVisibilityStatus } from "../../models/organization"
import { OrganizationRole } from "../../models/organization-role"

const API_PATH = "api/Organization/"

export type OrganizationRolesResult = { roles: OrganizationRole[] }

export type OrganizationResult = { organization: Organization }

export type OrganizationsResult = { organizations: Organization[] }

export class OrganizationApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async getOrganization(organizationId: string): Promise<OrganizationResult> {
    const response: ApiResponse<Organization> = await this.api.apisauce.get(
      `${API_PATH}${organizationId}`,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("OrganizationApi.getOrganization", response)
    }
    return { organization: response.data }
  }

  async createOrganization(organization: Organization): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.post(API_PATH, organization)
    if (!response.ok) {
      throw new ApiError("OrganizationApi.createOrganization", response)
    }
  }

  async updateOrganization(organization: Organization): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.patch(
      `${API_PATH}${organization.id}`,
      organization,
    )
    if (!response.ok) {
      throw new ApiError("OrganizationApi.updateOrganization", response)
    }
  }

  async setOrganizationVisibility(
    organizationId: string,
    visibility: OrganizationVisibilityStatus,
  ): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.put(
      `${API_PATH}${organizationId}/visibility/${visibility}`,
    )
    if (!response.ok) {
      throw new ApiError("OrganizationApi.setOrganizationVisibility", response)
    }
  }

  async getOrganizationRoles(organizationId: string): Promise<OrganizationRolesResult> {
    const response: ApiResponse<OrganizationRolesResult> = await this.api.apisauce.get(
      `${API_PATH}${organizationId}/roles`,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("OrganizationApi.getOrganizationRoles", response)
    }
    return response.data
  }
}
