import { flow, Instance, types, toGenerator } from "mobx-state-tree"
import validate from "validate.js"

import { withEnvironment } from "./extensions/with-environment"
import {
  InvitationApi,
  SendGroupInvitationsRequest,
  SendOrganizationInvitationsRequest,
} from "../services/api/invitation-api"
import { withOrganizationStore } from "./organization-store"
import { withGroupStore } from "../stores/group-store"
import {
  InvitationModel,
  InvitationFormModel,
  Invitation,
  InvitationRole,
} from "../models/invitation"
import { ManagedRole } from "../models/organization-role"

export const InvitationStoreModel = types
  .model("InvitationStore")
  .props({
    // TODO how to refactor invitations to be for groups and organizations?
    invitations: types.map(InvitationModel),
    invitationForm: types.maybe(InvitationFormModel),
    currentOrganizationId: types.maybe(types.string),
  })
  .extend(withEnvironment)
  .extend(withOrganizationStore)
  .extend(withGroupStore)
  .actions((self) => ({
    putInvitations: (invitations: Invitation[]) => {
      const invitationModels: Invitation[] = []
      for (const invitation of invitations) {
        self.invitations.put(invitation)
        const invitationModel = self.invitations.get(invitation.id)
        if (invitationModel) {
          invitationModels.push(invitationModel)
        }
      }
      return invitationModels
    },
  }))
  .actions((self) => ({
    initializeInvitationForm: (organizationId) => {
      self.currentOrganizationId = organizationId

      // find default roles or use the first role in the list
      const orgAdminRole =
        self.organizationStore.getRole(organizationId, ManagedRole.OrganizationAdmin) ||
        self.organizationStore.organizationOnlyRoles(organizationId)[0]

      self.invitationForm = InvitationFormModel.create({
        organizationId,
        // default role for organizations should be "Admin"
        organizationRoleId: orgAdminRole.id,
        groupRoles: [],
      })
    },
    initializeInvitationFormForGroup: (organizationId, groupId) => {
      self.currentOrganizationId = organizationId
      const groupAdminRole =
        self.organizationStore
          .groupOnlyRoles(organizationId)
          .find((r) => r.name === ManagedRole.GroupAdmin) ||
        self.organizationStore.groupOnlyRoles(organizationId)[0]

      self.invitationForm = InvitationFormModel.create({
        organizationId,
        organizationRoleId: undefined,
        groupRoles: [
          {
            entityId: groupId,
            organizationRoleId: groupAdminRole.id,
          },
        ],
      })
    },
    setNewInvitationRole: (roleId: string) => {
      if (self.invitationForm) {
        self.invitationForm.organizationRoleId = roleId
      }
    },
    setNewInvitationEmails: (emails: string[]) => {
      if (!self.invitationForm) {
        throw new Error("Invitation form not initialized")
      }
      const newEmails: string[] = []
      const newInvalidEmails: string[] = self.invitationForm?.invalidEmails || []
      const newDuplicateEmails: string[] = self.invitationForm?.duplicateEmails || []
      for (const email of emails) {
        const trimmedEmail = email.trim()
        const errors = validate(
          { email: trimmedEmail },
          {
            email: { presence: true, email: true },
          },
        )

        if (!errors) {
          // look for duplicates
          if (newEmails.includes(trimmedEmail)) {
            newDuplicateEmails.push(trimmedEmail)
          } else {
            newEmails.push(trimmedEmail)
          }
        } else if (trimmedEmail && !newInvalidEmails.includes(trimmedEmail)) {
          newInvalidEmails.push(trimmedEmail)
        }
      }

      // update the form fields
      self.invitationForm.emails.replace(newEmails)
      self.invitationForm.invalidEmails.replace(newInvalidEmails)
      self.invitationForm.duplicateEmails.replace(newDuplicateEmails)
    },
    clearInvalidEmails: () => {
      self.invitationForm?.invalidEmails.replace([])
    },
    clearDuplicateEmails: () => {
      self.invitationForm?.duplicateEmails.replace([])
    },
    updateGroupRoleAtIndex: (index: number, role: InvitationRole) => {
      if (self.invitationForm) {
        self.invitationForm.groupRoles[index] = role
      }
    },
    addGroupRole: (role: InvitationRole) => {
      self.invitationForm?.groupRoles.push(role)
    },
    removeGroupRole: (groupId) => {
      if (!self.invitationForm) {
        return
      }
      const updateGroupRoles = self.invitationForm.groupRoles.filter(
        (groupRole) => groupRole?.entityId !== groupId,
      )
      self.invitationForm.groupRoles.replace(updateGroupRoles)
    },
    removeDeletedInvite: (invitationId: string) => {
      // Deletes an invitation that has been created and issued to a user
      self.invitations.delete(invitationId)
    },
    getPendingOrganizationInvitations: flow(function* (organizationId: string) {
      const invitationApi = new InvitationApi(self.environment.api)
      const result = yield* toGenerator(
        invitationApi.getPendingOrganizationInvitations(organizationId),
      )
      return self.putInvitations(result.invitations)
    }),
    getPendingGroupInvitations: flow(function* (groupId: string) {
      const invitationApi = new InvitationApi(self.environment.api)
      const result = yield* toGenerator(invitationApi.getPendingGroupInvitations(groupId))
      return self.putInvitations(result.invitations)
    }),
    resetInvitationForm: () => {
      self.currentOrganizationId = undefined
      self.invitationForm = undefined
    },
    fetchInvitation: flow(function* (invitationId: string) {
      const invitationApi = new InvitationApi(self.environment.api)
      const result = yield* toGenerator(invitationApi.getInvitation(invitationId))
      const invitation = self.invitations.put(result.invitation)
      self.organizationStore.putOrganizations([result.organization])
      self.groupStore.putGroups(result.groups)
      return invitation
    }),
    claimInvitation: flow(function* (invitationId: string) {
      const invitationApi = new InvitationApi(self.environment.api)
      yield invitationApi.claimInvitation(invitationId)
    }),
    resendInvitation: flow(function* (invitationId: string) {
      const invitationApi = new InvitationApi(self.environment.api)
      yield invitationApi.resendInvitation(invitationId)
    }),
  }))
  .views((self) => ({
    get isInvitationFormValid() {
      if (self.invitationForm) {
        return self.invitationForm.emails.length > 0
      } else {
        return false
      }
    },
  }))
  .actions((self) => ({
    deleteInvitation: flow(function* (invitationId: string) {
      const invitationApi = new InvitationApi(self.environment.api)
      yield invitationApi.deleteInvitation(invitationId)
      self.removeDeletedInvite(invitationId)
    }),
    deleteInvitationRole: flow(function* (invitationId: string, groupId: string) {
      const invitationApi = new InvitationApi(self.environment.api)
      yield invitationApi.deleteInvitationRole(invitationId, groupId)
      self.removeDeletedInvite(invitationId)
    }),
    sendOrganizationInvitations: flow(function* () {
      if (!self.invitationForm) {
        throw new Error("Invitation form not initialized")
      }

      if (!self.currentOrganizationId) {
        throw new Error("currentOrganizationId is not set")
      }

      if (!self.invitationForm?.organizationRoleId) {
        throw new Error("No organization role selected")
      }

      const roles: InvitationRole[] = [
        {
          entityId: self.currentOrganizationId,
          organizationRoleId: self.invitationForm?.organizationRoleId,
        },
      ]

      if (self.invitationForm?.groupRoles) {
        roles.push(...self.invitationForm.groupRoles)
      }

      const request: SendOrganizationInvitationsRequest = {
        organizationId: self.currentOrganizationId,
        invitations: self.invitationForm.emails.map((email) => ({
          email,
          organizationId: self.currentOrganizationId,
          roles,
        })),
      }
      const invitationApi = new InvitationApi(self.environment.api)
      yield invitationApi.sendOrganizationInvitations(request)
    }),
    sendGroupInvitations: flow(function* () {
      if (!self.invitationForm) {
        throw new Error("Invitation form not initialized")
      }

      if (!self.currentOrganizationId) {
        throw new Error("currentOrganizationId is not set")
      }

      const role = self.invitationForm?.groupRoles[0]
      if (!role) {
        throw new Error("No group id found for invitation")
      }
      const request: SendGroupInvitationsRequest = {
        invitations: self.invitationForm.emails.map((email) => ({
          email,
          organizationId: self.currentOrganizationId,
          organizationRoleId: role.organizationRoleId,
        })),
      }
      const invitationApi = new InvitationApi(self.environment.api)
      yield invitationApi.sendGroupInvitations(role.entityId, request)
    }),
  }))

export type InvitationStore = Instance<typeof InvitationStoreModel>
