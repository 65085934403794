import { Api } from "../services/api"
import { ApiConfig } from "../services/api/api-config"
import { AsyncStorage, SecureStorage } from "../utils/storage"
import { FileSystem } from "../utils/file-system"
import { PlatformOSType } from "react-native"
import { RootStore } from "../stores/root-store"

export interface EnvironmentOptions {
  appVersion?: string
  apiConfig: ApiConfig
  storage: AsyncStorage
  persistStores?: boolean
  secureStorage?: SecureStorage
  fileSystem?: FileSystem
  platform: PlatformOSType
  authApiConfig: ApiConfig
  publicAssetBaseUrl: string
  onLogout: (store: RootStore) => void
  onSnapshotError?: (hasAccessToken: boolean) => void
}

/**
 * The environment is a place where services and shared dependencies between
 * models live.  They are made available to every model via dependency injection.
 */
export class Environment {
  private _fileSystem?: FileSystem
  private _appVersion?: string
  private _secureStorage?: SecureStorage

  constructor(options: EnvironmentOptions) {
    this._appVersion = options.appVersion
    this.api = new Api(options.apiConfig)
    this.storage = options.storage
    this._secureStorage = options.secureStorage
    this._fileSystem = options.fileSystem
    this.platform = options.platform
    this.authApi = new Api(options.authApiConfig)
    this.publicAssetBaseUrl = options.publicAssetBaseUrl
    this.onLogout = options.onLogout
    this.onSnapshotError = options.onSnapshotError
  }

  setup() {
    // allow each service to setup
    this.api.setup()
    this.authApi.setup()
  }

  /**
   * The version of the app (semantic versioning)
   */
  get appVersion(): string {
    if (!this._appVersion) {
      throw new Error("App version not available")
    }
    return this._appVersion
  }

  /**
   * Our api.
   */
  api: Api

  /**
   * Local storage with async interface
   */
  storage: AsyncStorage

  /**
   * Secure device storage
   */
  get secureStorage(): SecureStorage {
    if (!this._secureStorage) {
      throw new Error("SecureStorage not available")
    }
    return this._secureStorage
  }

  get hasSecureStorage(): boolean {
    return Boolean(this._secureStorage)
  }

  /**
   * Helper methods for interacting with the native file system
   */
  get fileSystem(): FileSystem {
    if (!this._fileSystem) {
      throw new Error("FileSystem not available")
    }
    return this._fileSystem
  }

  /**
   * What platform are we running on?
   */
  platform: PlatformOSType

  /**
   * Auth API
   */
  authApi: Api

  /**
   * Public Asset Base URL - Base URL for our images
   */
  publicAssetBaseUrl: string

  /**
   * Additional method to call when the session store is logging out
   */
  onLogout: (rootStore: RootStore) => void

  /**
   * Called when failing to restore the MST snapshot from storage
   */
  onSnapshotError?: (hasAccessToken: boolean) => void
}
