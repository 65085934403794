import { ApiResponse } from "apisauce"
import { Api } from "./api"
import { ApiError } from "./api-problem"
import {
  ActivitySubscription,
  NotificationPreference,
} from "../../stores/activity-subscription-store"

const API_PATH = "api/ActivitySubscription/"

enum ActivitySubscriptionType {
  Playlist = "Playlist",
  Organization = "Organization",
  Group = "Group",
}

const RESOURCE_API_PATH = {
  [ActivitySubscriptionType.Playlist]: "playlist",
  [ActivitySubscriptionType.Group]: "group",
}

export type ActivitySubscriptionResult = { activitySubscription: ActivitySubscription }
export type ActivitySubscriptionsResult = { activitySubscriptions: ActivitySubscription[] }

function getApiPath(type: ActivitySubscriptionType) {
  const path = RESOURCE_API_PATH[type]
  if (!path) {
    throw new Error("Unknown activity subscription type")
  }
  return `${API_PATH}${path}`
}

export class ActivitySubscriptionApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async fetchAllActivitySubscriptions(): Promise<ActivitySubscriptionsResult> {
    const response: ApiResponse<ActivitySubscriptionsResult> = await this.api.apisauce.get(
      `${API_PATH}`,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("ActivitySubscriptionApi.fetchAllActivitySubscriptions", response)
    }
    return response.data
  }

  async subscribe(
    type: ActivitySubscriptionType,
    resourceId: string,
    notificationPreference: NotificationPreference,
  ): Promise<ActivitySubscriptionResult> {
    const response: ApiResponse<ActivitySubscriptionResult> = await this.api.apisauce.post(
      `${getApiPath(type)}/${resourceId}`,
      { notificationPreference },
    )

    if (!response.ok || !response.data) {
      throw new ApiError("ActivitySubscriptionApi.subscribe", response)
    }

    return response.data
  }

  async unsubscribe(type: ActivitySubscriptionType, resourceId: string): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.delete(
      `${getApiPath(type)}/${resourceId}`,
    )

    if (!response.ok) {
      throw new ApiError("ActivitySubscriptionApi.unsubscribe", response)
    }
  }

  async updateNotificationPreference(
    activitySubscriptionId: string,
    notificationPreference: NotificationPreference,
  ): Promise<ActivitySubscriptionResult> {
    const response: ApiResponse<ActivitySubscriptionResult> = await this.api.apisauce.post(
      `${API_PATH}${activitySubscriptionId}/preference`,
      { notificationPreference },
    )

    if (!response.ok || !response.data) {
      throw new ApiError("ActivitySubscriptionApi.updateNotificationPreference", response)
    }

    return response.data
  }
}
