import { ApiResponse } from "apisauce"
import { Api } from "./api"
import { ApiError } from "./api-problem"
import { Attachment } from "../../models/attachment"
import { OpenGraphData } from "../../models/opengraph-data"

const API_PATH = "api/Attachment/"

export type OpenGraphDataResult = { openGraphData: OpenGraphData }
export type AttachmentsResult = { attachments: Attachment[] }

export class AttachmentApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async updateAttachments(attachments: Attachment[]): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.post(API_PATH, {
      attachments: attachments.map((a) => {
        return Object.assign({}, a, { data: JSON.stringify(a.data) })
      }),
    })

    if (!response.ok) {
      throw new ApiError("AttachmentApi.updateAttachments", response)
    }
  }

  async getOpenGraphData(attachmentId: string): Promise<OpenGraphDataResult> {
    const response = await this.api.apisauce.get<OpenGraphData>(
      API_PATH + "opengraph/" + attachmentId,
    )

    if (!response.ok || !response.data) {
      throw new ApiError("AttachmentApi.getOpenGraphData", response)
    }

    return { openGraphData: response.data }
  }

  async getOpenGraphPreviewByUrl(url: string): Promise<OpenGraphDataResult> {
    const response = await this.api.apisauce.post<OpenGraphData>(API_PATH + "opengraph/preview", {
      url,
    })

    if (!response.ok || !response.data) {
      throw new ApiError("AttachmentApi.getOpenGraphPreviewByUrl", response)
    }

    return { openGraphData: response.data }
  }

  async getAttachmentsForPitch({ pitchId }): Promise<AttachmentsResult> {
    const response = await this.api.apisauce.get<AttachmentsResult>(API_PATH + "pitch/" + pitchId)

    if (!response.ok || !response.data) {
      throw new ApiError("AttachmentApi.getAttachmentsForPitch", response)
    }

    return response.data
  }

  async markAttachmentAsViewed({ attachmentId }): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.post(
      `${API_PATH}${attachmentId}/viewed`,
    )
    if (!response.ok) {
      throw new ApiError("AttachmentApi.markAttachmentAsViewed", response)
    }
  }
}
