import { Instance, flow, toGenerator, types } from "mobx-state-tree"
import { RecentContent, RecentContentModel } from "../models/recent-content"
import { ResourceViewApi } from "../services"
import { withEnvironment } from "./extensions/with-environment"
import { withPlaylistStore } from "./playlist-store"
import { withPitchStore } from "./pitch-store"
import { ResourceType } from "../models/resource"

export const RecentContentStoreModel = types
  .model("RecentContent")
  .props({
    recentContent: types.map(RecentContentModel),
  })
  .extend(withEnvironment)
  .extend(withPitchStore)
  .extend(withPlaylistStore)
  .actions((self) => ({
    putRecentContent(resources: RecentContent[]) {
      return resources.map((r) => self.recentContent.put(r))
    },
  }))
  .actions((self) => ({
    fetchRecentContent: flow(function* (options: {
      resourceTypes?: (ResourceType | string)[]
      searchQuery?: string
      cursor?: string
    }) {
      const resourceViewApi = new ResourceViewApi(self.environment.api)
      const result = yield* toGenerator(resourceViewApi.getRecentContent(options))
      return self.putRecentContent(result.recentlyViewed)
    }),
    fetchRecentTags: flow(function* () {
      const resourceViewApi = new ResourceViewApi(self.environment.api)
      const result = yield* toGenerator(resourceViewApi.getRecentTags())
      return result.tags
    }),
    fetchPlaylists: flow(function* () {
      const resourceViewApi = new ResourceViewApi(self.environment.api)
      const result = yield* toGenerator(resourceViewApi.getPlaylists())
      return self.playlistStore.putPlaylists(result.playlists)
    }),
    fetchPitches: flow(function* () {
      const resourceViewApi = new ResourceViewApi(self.environment.api)
      const result = yield* toGenerator(resourceViewApi.getPitches())
      return self.pitchStore.putPitches(result.pitches)
    }),
  }))

export type RecentContentStore = Instance<typeof RecentContentStoreModel>
