import React, { ReactNode } from "react"
import { CSSObject } from "@emotion/react"

interface ScrollContainerProps {
  children: ReactNode
}

function getStyles() {
  const styles = {
    OUTER_CONTAINER: {
      position: "relative",
      height: "100%",
      width: "100%",
      overflow: "hidden",
    },
    SCROLLABLE_CONTAINER: {
      position: "absolute",
      height: "100%",
      width: "100%",
      overflowY: "scroll",
      overflowX: "hidden",
    },
  }
  return styles as { [key in keyof typeof styles]: CSSObject }
}

export function ScrollContainer(props: ScrollContainerProps) {
  const { children, ...rest } = props

  const styles = getStyles()

  return (
    <div css={styles.OUTER_CONTAINER} {...rest}>
      <div css={styles.SCROLLABLE_CONTAINER}>{children}</div>
    </div>
  )
}

export default ScrollContainer
