import { Instance, types, flow, toGenerator } from "mobx-state-tree"
import { withEnvironment } from "./extensions/with-environment"
import { PostApi } from "../services/api/post-api"
import { withSessionStore } from "./session-store"
import { NIL as EMPTY_UUID } from "uuid"
import { sortBy } from "lodash-es"
import { withSessionMembershipStore } from "./session-membership-store"
import { Post, PostModel } from "../models/post"
import { ResourceType } from "../models/resource"
import { Permission } from "../models/session"

export const PostStoreModel = types
  .model("PostStore")
  .props({
    posts: types.map(types.map(PostModel)),
  })
  .extend(withEnvironment)
  .extend(withSessionStore)
  .extend(withSessionMembershipStore)
  .views((self) => ({
    getEntityPosts(entityId: string) {
      return self.posts.get(entityId)
    },
  }))
  .actions((self) => ({
    putPosts(entityId: string, posts: Post[]) {
      if (!self.posts.get(entityId)) {
        self.posts.set(entityId, {})
      }
      return posts.map((p) => self.posts.get(entityId)!.put(p))
    },
    removePostById(postId: string, entityId: string) {
      const entityPosts = self.posts.get(entityId)
      if (entityPosts) {
        entityPosts.delete(postId)
      }
    },
  }))
  .actions((self) => ({
    fetchPosts: flow(function* (
      entityId: string,
      options?: {
        postPageCursor?: string
      },
    ) {
      const postApi = new PostApi(self.environment.api)
      const result = yield* toGenerator(postApi.getEntityPosts(entityId, options))
      return self.putPosts(entityId, result.posts)
    }),
    createPost: flow(function* (entityId: string, resourceId: string, resourceType: ResourceType) {
      const postApi = new PostApi(self.environment.api)
      yield postApi.createResourcePost({ entityId, resourceId, resourceType })
    }),
    deletePost: flow(function* (postId: string, entityId: string) {
      const postApi = new PostApi(self.environment.api)
      yield postApi.deleteResourcePost({ postId })
      self.removePostById(postId, entityId)
    }),
  }))
  .views((self) => ({
    getSortedEntityPosts(entityId) {
      return sortBy(
        Array.from(self.getEntityPosts(entityId)?.values() || []),
        (a) => a.createdUtc,
      ).reverse()
    },
  }))
  .views((self) => ({
    hasEntityPostPermissions(entityId: string) {
      if (self.sessionStore.hasPermission(entityId, Permission.ManageInternalContent)) {
        return true
      }

      return false
    },
    hasAnyEntityPostPermissions() {
      if (
        self.sessionMembershipStore.sessionGroupsWithPermission(Permission.ManageInternalContent)
          .length > 0
      ) {
        return true
      }

      return false
    },
    hasUnreadPosts(entityId: string) {
      // TODO unimplemented
      return entityId === EMPTY_UUID
    },
  }))

export type PostStore = Instance<typeof PostStoreModel>
