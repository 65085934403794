import { useCallback, useEffect, useRef, useState } from "react"

export type Loader = {
  loading: boolean
  refreshing: boolean
  succeeded: boolean
  error: any
  load: <T>(promise: Promise<T>, options?: { refresh?: boolean }) => Promise<void | T>
  loadAll: (promises: Promise<any>[], options?: { refresh?: boolean }) => Promise<void | any[]>
  reset: () => void
}

interface LoaderOptions {
  loading?: boolean
  succeeded?: boolean
}

const optionDefaults = {
  loading: true,
  succeeded: false,
}

export const useLoader = (options?: LoaderOptions): Loader => {
  const configuredOptions: LoaderOptions = Object.assign({}, optionDefaults, options)
  const [loading, setLoading] = useState<boolean>(Boolean(configuredOptions.loading))
  const [succeeded, setSucceeded] = useState<boolean>(Boolean(configuredOptions.succeeded))
  const [refreshing, setRefreshing] = useState(false)
  const [error, setError] = useState<any>()
  const mounted = useRef<boolean>()
  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  const load = <T>(promise: Promise<T>, options?: { refresh?: boolean }) => {
    setLoading(true)

    if (options?.refresh) {
      setRefreshing(true)
    }

    return promise
      .then((res) => {
        if (mounted.current) {
          setSucceeded(true)
          setLoading(false)
          setRefreshing(false)
        }

        return res
      })
      .catch((err) => {
        if (mounted.current) {
          setError(err)
          setLoading(false)
          setRefreshing(false)
        }
      })
  }

  const loadAll = (promises: Promise<any>[], options?: { refresh?: boolean }) => {
    return load(Promise.all(promises), options)
  }

  const reset = useCallback(() => {
    setError(undefined)
    setSucceeded(Boolean(configuredOptions.succeeded))
    setLoading(Boolean(configuredOptions.loading))
    setRefreshing(false)
  }, [])

  return {
    loading,
    refreshing,
    succeeded,
    error,
    load: useCallback(load, []),
    loadAll: useCallback(loadAll, []),
    reset,
  }
}
