import { types } from "mobx-state-tree"
import { parseISO, formatISO } from "date-fns"

export const DateTime = types.custom({
  name: "DateTime",
  fromSnapshot: (string) => {
    if (string) {
      return parseISO(string)
    }

    return undefined
  },
  toSnapshot: (date: Date) => {
    if (date instanceof Date && !isNaN(date.getTime())) {
      return formatISO(date)
    }
    return undefined
  },
  isTargetType: (maybeDate) => maybeDate instanceof Date && !isNaN(maybeDate.getTime()),
  getValidationMessage: (snapshot) => {
    if (snapshot === undefined) return ""
    const date = parseISO(snapshot)
    if (!(date instanceof Date) || isNaN(date.getTime())) {
      return `"${snapshot}" is not a valid ISO date`
    }
    return ""
  },
})
