import { ApiResponse } from "apisauce"
import { Api } from "./api"
import { ApiError } from "./api-problem"
import { Pitch } from "../../models/pitch"
import { Playlist } from "../../models/playlist"

const API_PATH = "api/Suggestion/"

export class SuggestionApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async getSuggestedPitches(entityIds?: string[]) {
    const response: ApiResponse<{ pitches: { pitch: Pitch; reason: string }[] }> =
      await this.api.apisauce.get(API_PATH + "pitch", {
        entityIds,
      })
    if (!response.ok || !response.data) {
      throw new ApiError("SuggestionApi.getSuggestedPitches", response)
    }
    return response.data
  }

  async getSuggestedPlaylists(entityIds?: string[]) {
    const response: ApiResponse<{ playlists: { playlist: Playlist; reason: string }[] }> =
      await this.api.apisauce.get(API_PATH + "playlist", {
        entityIds,
      })
    if (!response.ok || !response.data) {
      throw new ApiError("SuggestionApi.getSuggestedPlaylists", response)
    }
    return response.data
  }

  async getSuggestedSubmissionPlaylists(entityIds?: string[]) {
    const response: ApiResponse<{ playlists: { playlist: Playlist; reason: string }[] }> =
      await this.api.apisauce.get(API_PATH + "playlist/submission", {
        entityIds,
      })
    if (!response.ok || !response.data) {
      throw new ApiError("SuggestionsApi.getSuggestedSubmissionPlaylists", response)
    }
    return response.data
  }
}
