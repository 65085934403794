import { EntityType } from "../models/entity"
import { OrganizationVisibilityStatus } from "../models/organization"

export const getEntityVisibilityLabel = ({
  entityType,
  organizationName,
  entityName,
  value,
}: {
  entityType: EntityType
  entityName: string
  organizationName?: string
  value: OrganizationVisibilityStatus
}) => {
  if (entityType === EntityType.Group) {
    switch (value) {
      case OrganizationVisibilityStatus.External:
        return "Public"
      case OrganizationVisibilityStatus.Internal:
        return organizationName ? `Organization (${organizationName})` : "Organization"
      case OrganizationVisibilityStatus.Private:
        return `Group (${entityName})`
      default:
        return null
    }
  } else if (entityType === EntityType.Organization) {
    switch (value) {
      case OrganizationVisibilityStatus.External:
        return "Public"
      case OrganizationVisibilityStatus.Private:
        return "Private"
      default:
        return null
    }
  }
  return null
}
