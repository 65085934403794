import { Instance, types } from "mobx-state-tree"
import { StringEnum } from "../utils/string-enum-type"

export enum StudioTagType {
  CurrentUser = "CurrentUser",
  Archived = "Archived",
  Entity = "Entity",
  Draft = "Draft",
  Live = "Live",
  Scheduled = "Scheduled",
}

export const StudioTagModel = types.model("Tag").props({
  name: types.identifier,
  tagType: types.maybe(StringEnum(StudioTagType)),
})

export type StudioTag = Instance<typeof StudioTagModel>
