import React from "react"
import { CSSObject, useTheme } from "@emotion/react"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import { WebAppTheme } from "pitch45-common/theme/app-theme.types"
import { Text } from "../text/text"
import { typography } from "pitch45-common/theme/typography"
import { textPresets } from "../text/text.presets"
import { semanticSpacing, unitSpacing } from "../../theme/spacing"
import { InputLabel } from "@mui/material"
import { color } from "pitch45-common/theme"
import Icon, { Icons, Loop45Icons } from "../icon/icon"

export interface SelectInputOption {
  label: string | number
  value: string | number
  description?: string
  icon?: Icons | Loop45Icons | null
  disabled?: boolean
}

interface SelectInputProps {
  value?: string | string[] | number
  error?: string
  label?: string
  onChange: (value: string | number) => void
  onBlur: (event: any) => void
  autoFocus?: boolean
  items: SelectInputOption[]
  includeEmpty?: boolean
  disabled?: boolean
  placeholder?: string
}

function getStyles(theme: WebAppTheme) {
  const styles = {
    INPUT: {
      ...typography.regular, // TODO: Figure out why calling theme is causing runtime errors.  Had to remove theme to render font correctly.
      background: theme.colors?.background,
      width: "100%",
      color: theme.colors.text,
      padding: `${unitSpacing.half} ${unitSpacing.quarter}`,
      includeFontPadding: false,
      fontSize: 16,
      border: "none",
      borderBottomStyle: "solid",
      borderBottomWidth: 1,
      borderBottomColor: theme.colors.dimText,
      textAlign: "left",
      outline: "none",
      ".MuiSvgIcon-root ": {
        fill: theme.colors.dimText,
      },
      "& .MuiInputLabel-root": {
        color: theme.colors.dimText,
      },
      "& .MuiSelect-select": {
        WebkitTextFillColor: theme.colors.text,
      },
      "& .MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: theme.colors.dimText,
      },
    },
    CONTAINER: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      margin: semanticSpacing.vertical.narrow,
    },
    INPUT_CONTAINER: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      paddingLeft: unitSpacing.half,
      borderLeftStyle: "solid",
      borderLeftWidth: 4,
      borderLeftColor: theme.colors?.dimText,
    },
    ERROR_MESSAGE: {
      color: theme.colors?.error,
      marginTop: unitSpacing.half,
      paddingLeft: unitSpacing.half,
    },
  }
  return styles as { [key in keyof typeof styles]: CSSObject }
}

export function SelectInput(props: SelectInputProps) {
  const {
    value,
    label,
    error,
    onChange,
    items,
    includeEmpty = false,
    placeholder,
    onBlur,
    disabled = false,
    ...rest
  } = props

  const theme = useTheme() as WebAppTheme
  const { CONTAINER, INPUT, ERROR_MESSAGE, INPUT_CONTAINER } = getStyles(theme)

  const renderItems = () => {
    return items.map((item) => (
      <MenuItem
        key={item.value}
        value={item.value}
        disabled={item.disabled}
        css={{ "&&.MuiMenuItem-root": { ...typography.light } }}
      >
        <div css={{ display: "flex", flexDirection: "row" }}>
          {item.icon && (
            <span
              css={{
                width: "18px",
                paddingTop: unitSpacing.half,
                marginRight: unitSpacing.unit,
                alignSelf: "center",
              }}
            >
              {item.icon && (
                <Icon icon={item.icon} color={color.dimText} size="large" css={{ fontSize: 35 }} />
              )}
            </span>
          )}
          <div
            css={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              maxWidth: "300px",
              marginLeft: unitSpacing.half,
            }}
          >
            <Text preset={textPresets.medium}>{item.label}</Text>
            {item.description && <Text preset={textPresets.tiny}>{item.description}</Text>}
          </div>
        </div>
      </MenuItem>
    ))
  }

  return (
    <div css={CONTAINER} {...rest}>
      <div css={INPUT_CONTAINER}>
        {Boolean(!value) && Boolean(label) && (
          <InputLabel
            htmlFor="select-input"
            css={{
              position: "absolute",
              left: unitSpacing.halfPlus,
              zIndex: 100,
            }}
          >
            <Text preset={textPresets.medium} css={{ color: color.dimText }}>
              {label}
            </Text>
          </InputLabel>
        )}
        <Select
          disableUnderline
          value={value as any}
          label={label}
          onChange={(e) => onChange(e.target.value as string | number)}
          css={[INPUT]}
          variant="standard"
          MenuProps={{ style: { marginTop: unitSpacing.unit, maxHeight: 400 } }}
          displayEmpty={Boolean(placeholder)}
          disabled={disabled}
          onBlur={onBlur}
        >
          {placeholder && (
            <MenuItem key="placeholder" value="" disabled>
              {placeholder}
            </MenuItem>
          )}
          {includeEmpty && (
            <MenuItem key="emptyValue" value="" sx={{ ...typography.regular }}>
              &nbsp;
            </MenuItem>
          )}
          {renderItems()}
        </Select>
      </div>
      {error && (
        <Text preset={textPresets.small} css={ERROR_MESSAGE}>
          {error}
        </Text>
      )}
    </div>
  )
}

export default SelectInput
