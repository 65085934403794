import { ApisauceInstance, create } from "apisauce"
import { Sync } from "../../models/sync"
import { ApiConfig } from "./api-config"
import { v4 as uuidv4 } from "uuid"
import { OAuthProvider } from "../../utils/oauth"

export interface UploaderOptions {
  token?: string
  sync?: Sync
  postParams?: { [key: string]: string }
  timeout?: number
}

/**
 * Manages all requests to the API.
 */
export class Api {
  /**
   * The underlying apisauce instance which performs the requests.
   */
  _apisauce?: ApisauceInstance

  /**
   * Configurable options.
   */
  private config: ApiConfig

  /**
   * Creates the api.
   *
   * @param config The configuration to use.
   */
  constructor(config: ApiConfig) {
    this.config = config
  }

  get apisauce(): ApisauceInstance {
    if (!this._apisauce) {
      throw new Error("Apisauce client not initialized")
    }
    return this._apisauce
  }

  upload(localUri: string, destinationUri: string, options: UploaderOptions) {
    return this.config.uploader(localUri, destinationUri, options)
  }

  /**
   * Sets up the API.  This will be called during the bootup
   * sequence and will happen before the first React component
   * is mounted.
   *
   * Be as quick as possible in here.
   */
  setup() {
    // construct the apisauce instance
    this._apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: {
        Accept: "application/json",
      },
    })

    this._apisauce.addRequestTransform((request) => {
      if (request.headers) {
        request.headers["X-Correlation-ID"] = uuidv4()
      }
    })
  }

  get oauthProvider(): OAuthProvider {
    if (!this.config.oauthProvider) {
      throw new Error("No OAuth provider configured")
    }
    return this.config.oauthProvider
  }

  get url(): string {
    return this.config.url
  }
}
