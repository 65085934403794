import { ApiResponse } from "apisauce"
import { Api } from "./api"
import { ApiError } from "./api-problem"
import { EntityTheme } from "../../models/entity-theme"

const API_PATH = "api/EntityTheme/"

type EntityThemeResult = { theme: EntityTheme | undefined }

export class EntityThemeApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async getEntityTheme({ entityId }): Promise<EntityThemeResult> {
    const response: ApiResponse<EntityTheme | undefined> = await this.api.apisauce.get(
      `${API_PATH}${entityId}`,
    )

    if (!response.ok) {
      throw new ApiError("EntityThemeApi.getEntityTheme", response)
    }

    const theme = response.data

    return { theme }
  }
}
