import { color } from "./shared-colors"
import { AppTheme } from "./app-theme.types"
import { typography } from "./typography"

export const pitch45BrandTheme = {
  fillColor: color.background,
  buttonColor: color.backgroundHighlight,
  buttonTextColor: color.palette.white,
  accentColor: color.primary,
}

export const theme: Record<string, AppTheme> = {
  pitch45Dark: {
    dark: true,
    colors: {
      ...color,
      brandTheme: pitch45BrandTheme,
    },
    typography,
  },
  pitch45Light: {
    dark: false,
    colors: {
      ...color,
      brandTheme: pitch45BrandTheme,
      background: color.palette.white,
      border: color.palette.pewterGrey,
      disabled: color.palette.silver,
      text: color.palette.slateGrey,
    },
    typography,
  },
}
