import { ApiResponse } from "apisauce"
import { Api } from "./api"
import { BasicApiResult } from "./api.types"
import { getGeneralApiProblem, logResponseError } from "./api-problem"
import logger from "pitch45-common/logging/logger"
import { PushTokenType } from "../../stores/push-token-store"

const API_PATH = "api/PushToken/"

interface RegisterDeviceTokenRequest {
  token: string
  deviceId: string
  tokenType: PushTokenType | string
}
export class PushTokenApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async registerDeviceToken(requestBody: RegisterDeviceTokenRequest): Promise<BasicApiResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post(
        API_PATH + "register",
        requestBody,
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          logResponseError(new Error(problem.kind), response)
          return problem
        }
      }

      return { kind: "ok" }
    } catch (e) {
      logger.logError(e)
      return { kind: "client-error" }
    }
  }

  async disableDeviceToken({
    userId,
    token,
  }: {
    userId: string
    token: string
  }): Promise<BasicApiResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post(
        API_PATH + `disable/${userId}/${token}`,
        {},
        // we need to be able to make this call on logout even if the user's access token has expired,
        // so make it anonymous
        { headers: { Authorization: "" } },
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          logResponseError(new Error(problem.kind), response)
          return problem
        }
      }

      return { kind: "ok" }
    } catch (e) {
      logger.logError(e)
      return { kind: "client-error" }
    }
  }
}
