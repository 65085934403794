import pino from "pino"
import { ThirdPartyLogger } from "pitch45-common/logging/logger"

export class PinoLogger implements ThirdPartyLogger {
  logger: pino.Logger
  constructor() {
    this.logger = pino()
  }

  identify(): void {}

  track(event: string, extra?: { [key: string]: any }): void {
    const child = this.logger.child({ ...extra })
    child.info(event)
  }

  log(message: string, extra?: { [key: string]: any }): void {
    const child = this.logger.child({ ...extra })
    child.info(message)
  }

  logWarning(
    message: string,
    extra?: { [key: string]: any },
    tags?: { [key: string]: string },
  ): void {
    const child = this.logger.child({ ...extra, ...tags })
    child.warn(message)
  }

  logError(error: Error, extra?: { [key: string]: any }, tags?: { [key: string]: string }): void {
    const child = this.logger.child({ ...extra, ...tags })
    child.error(error)
  }
}
