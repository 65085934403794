import { Api } from "./api"
import { ApiError } from "./api-problem"
import { ApiResponse } from "apisauce"
import { Activity } from "../../models/activity"

const API_PATH = "api/Activity/"

export type ActivityResult = {
  userActivities: Activity[]
}

export type NewActivityCountsResult = {
  // old version, deprecated
  numNewActivities: number
  countsByType: { [key: string]: number }
}

export class ActivityApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async getUserActivity(cursor?: Date): Promise<ActivityResult> {
    const response: ApiResponse<ActivityResult> = await this.api.apisauce.get(`${API_PATH}user`, {
      cursor,
    })

    if (!response.ok || !response.data) {
      throw new ApiError("ActivityApi.getUserActivity", response)
    }
    return response.data
  }

  async markActivityOpened({ activityId }): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.put(
      `${API_PATH}${activityId}/opened`,
    )

    if (!response.ok) {
      throw new ApiError("ActivityApi.markActivityOpened", response)
    }
  }

  async markActivityUnread({ activityId }): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.put(
      `${API_PATH}${activityId}/unread`,
    )

    if (!response.ok) {
      throw new ApiError("ActivityApi.markActivityUnread", response)
    }
  }

  async fetchNumNewActivities(): Promise<NewActivityCountsResult> {
    const response: ApiResponse<NewActivityCountsResult> = await this.api.apisauce.get(
      `${API_PATH}feed/new`,
    )

    if (!response.ok || !response.data) {
      throw new ApiError("ActivityApi.fetchNumNewActivties", response)
    }

    return response.data
  }

  async markActivityFeedViewed(): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.post(`${API_PATH}feed/viewed`)

    if (!response.ok) {
      throw new ApiError("ActivityApi.markActivityFeedViewed", response)
    }
  }

  async delete(activityIds): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.post(
      `${API_PATH}delete`,
      activityIds,
    )

    if (!response.ok) {
      throw new ApiError("ActivityApi.delete", response)
    }
  }

  async undoDelete(activityIds): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.post(
      `${API_PATH}delete/undo`,
      activityIds,
    )

    if (!response.ok) {
      throw new ApiError("ActivityApi.undoDelete", response)
    }
  }

  async markAllAsRead({ userId }): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.post(
      `${API_PATH}${userId}/open/all`,
    )

    if (!response.ok) {
      throw new ApiError("ActivityApi.markAllAsRead", response)
    }
  }

  async undoMarkAllAsRead({ userId }): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.post(
      `${API_PATH}${userId}/open/all/undo`,
    )

    if (!response.ok) {
      throw new ApiError("ActivityApi.markAllAsRead", response)
    }
  }
}
