import { Instance, types } from "mobx-state-tree"
import { StringEnum } from "../utils/string-enum-type"
import { Entity, EntityType } from "./entity"

export const PUBLIC_ORGANIZATION_ID = "22222222-2222-2222-2222-222222222222"

export enum OrganizationType {
  School = "School",
  Professional = "Professional",
  Nonprofit = "Nonprofit",
  Custom = "Custom",
  Fake = "Fake",
}

export enum OrganizationVisibilityStatus {
  Private = "Private",
  Internal = "Internal",
  External = "External",
}

/**
 * Organization model.
 */
export const OrganizationModel = types
  .model("Organization")
  .props({
    id: types.identifier,
    name: types.maybe(types.string),
    website: types.maybe(types.string),
    logoAssetId: types.maybe(types.string),
    logoWideAssetId: types.maybe(types.string),
    coverAssetId: types.maybe(types.string),
    type: types.maybe(StringEnum(OrganizationType)),
    description: types.maybe(types.string),
    locationCity: types.maybe(types.string),
    locationState: types.maybe(types.string),
    locationZip: types.maybe(types.string),
  })
  .views((self) => ({
    toEntity(): Entity {
      return {
        id: self.id,
        name: self.name || "",
        assetId: self.logoAssetId,
        type: EntityType.Organization,
        parentId: null,
      }
    },
  }))

export type Organization = Instance<typeof OrganizationModel>
