import { ApiResponse } from "apisauce"
import { Api } from "./api"
import { ApiError } from "./api-problem"
import { Reaction, ReactionType } from "../../models/reaction"
import { PublicUser } from "../../models/public-user"

const API_PATH = "api/Reaction/"

export type PitchReactionDataResult = {
  reactionCountsByType: {
    [key in ReactionType]: number
  }
  recentUser?: PublicUser
  userReaction?: Reaction
}

export class ReactionApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async react(reaction: { pitchId: string; reactionType?: ReactionType }): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.post(API_PATH, reaction)
    if (!response.ok) {
      throw new ApiError("ReactionApi.react", response)
    }
  }

  async fetchPitchReactionData({ pitchId }: { pitchId: string }): Promise<PitchReactionDataResult> {
    const response: ApiResponse<PitchReactionDataResult> = await this.api.apisauce.get(
      API_PATH + "pitch/" + pitchId,
    )

    if (!response.ok || !response.data) {
      throw new ApiError("ReactionApi.fetchPitchReactionData", response)
    }

    return response.data
  }
}
