import { ApiResponse } from "apisauce"
import { Api } from "./api"
import { ApiError } from "./api-problem"
import { RegistrationCode } from "../../stores/registration-code-store"
import { Organization } from "../../models/organization"
import { Group } from "../../models/group"
import { ExternalLink } from "../../models/external-link"

type RegistrationCodeResult = {
  registrationCode: RegistrationCode
  organization?: Organization
  group?: Group
}

const API_PATH = "api/RegistrationCode/"

export class RegistrationCodeApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async getRegistrationCodeById(registrationCodeId): Promise<RegistrationCodeResult> {
    const response: ApiResponse<RegistrationCodeResult> = await this.api.apisauce.get(
      `${API_PATH}${registrationCodeId}`,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("RegistrationCodeApi.getRegistrationCodeById", response)
    }
    return response.data
  }

  async getRegistrationCodeByGroup(groupId: string): Promise<RegistrationCode> {
    const response: ApiResponse<RegistrationCode> = await this.api.apisauce.get(
      `${API_PATH}group/${groupId}`,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("RegistrationCodeApi.getRegistrationCodeByGroup", response)
    }
    return response.data
  }

  async getRegistrationCodeLinkByGroup(groupId: string): Promise<ExternalLink | undefined> {
    const response: ApiResponse<ExternalLink | undefined> = await this.api.apisauce.get(
      `${API_PATH}group/${groupId}/link`,
    )
    if (!response.ok) {
      throw new ApiError("RegistrationCodeApi.getRegistrationCodeLinkByGroup", response)
    }
    return response.data
  }

  async regenerateGroupRegistrationCode(groupId: string): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.post(
      `${API_PATH}group/${groupId}/regenerate`,
    )
    if (!response.ok) {
      throw new ApiError("RegistrationCodeApi.regenerateGroupRegistrationCode", response)
    }
  }

  async disableGroupRegistrationCode(groupId: string): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.post(
      `${API_PATH}group/${groupId}/disable`,
    )
    if (!response.ok) {
      throw new ApiError("RegistrationCodeApi.disableGroupRegistrationCode", response)
    }
  }

  async claimRegistration(registrationCodeId: string): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.post(
      `${API_PATH}${registrationCodeId}/claim`,
    )
    if (!response.ok) {
      throw new ApiError("RegistrationCodeApi.claimRegistration", response)
    }
  }
}
