import React, { useRef, useState } from "react"
import { observer } from "mobx-react-lite"
import { CSSObject, useTheme } from "@emotion/react"
import TextField from "@mui/material/TextField"

import { WebAppTheme } from "pitch45-common/theme/app-theme.types"
import { typography } from "pitch45-common/theme/typography"

import { Text } from "../../text/text"
import { textPresets } from "../../text/text.presets"
import { semanticSpacing, unitSpacing } from "../../../theme/spacing"
import Pill from "../../pill/pill"
import { fontSizing } from "../../../theme"

interface TextTagInputProps {
  value: string[]
  error?: string
  label?: string
  onChange: (value: string[]) => void
  onBlur?: (event: any) => void
  autoFocus?: boolean
  disabled?: boolean
  placeholder?: string
}

function getStyles(theme: WebAppTheme) {
  const styles = {
    INPUT: {
      // TODO: Figure out why calling theme is causing runtime errors.  Had to remove theme to render font correctly.
      ...typography.regular,
      background: theme.colors?.background,
      width: "100%",
      color: theme.colors.text,
      padding: `${unitSpacing.half} ${unitSpacing.quarter}`,
      includeFontPadding: false,
      fontSize: fontSizing.medium,
      borderBottomStyle: "solid",
      borderBottomWidth: 1,
      borderBottomColor: theme.colors.dimText,
      textAlign: "left",
      outline: "none",
      ".MuiSvgIcon-root ": {
        fill: theme.colors.dimText,
      },
      "& .MuiInputLabel-root": {
        color: theme.colors.dimText,
      },
      "& .MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: theme.colors.dimText,
      },
      "& .MuiInputBase-input": {
        color: theme.colors.text,
      },
    },
    CONTAINER: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      margin: semanticSpacing.vertical.narrow,
    },
    INPUT_CONTAINER: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      paddingLeft: unitSpacing.half,
      borderLeftStyle: "solid",
      borderLeftWidth: 4,
      borderLeftColor: theme.colors?.dimText,
    },
    ERROR_MESSAGE: {
      color: theme.colors?.error,
      marginTop: unitSpacing.half,
      paddingLeft: unitSpacing.half,
    },
    VALUE_PILL: {
      marginRight: unitSpacing.half,
      marginBottom: unitSpacing.quarter,
    },
  }
  return styles as { [key in keyof typeof styles]: CSSObject }
}

const separatorCharacters = [",", ";", " "]
const separatorKeys = ["Enter", "Tab"].concat(separatorCharacters)

export const TextTagInput = observer(function TextTagInput(props: TextTagInputProps) {
  const { value, label, error, onChange, placeholder, onBlur, disabled = false, ...rest } = props

  const [textValue, setTextValue] = useState("")
  const inputRef = useRef<HTMLInputElement>(null)

  const theme = useTheme() as WebAppTheme
  const { CONTAINER, INPUT, ERROR_MESSAGE, INPUT_CONTAINER, VALUE_PILL } = getStyles(theme)

  const renderStartAdornment = () => {
    return (
      <div css={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        {value.map((item) => (
          <Pill key={item} containerStyleOverrides={VALUE_PILL} label={item} />
        ))}
      </div>
    )
  }

  const commitTextValue = () => {
    if (textValue) {
      // split textValue by separator characters
      const splitValues = textValue.split(new RegExp(`[${separatorCharacters.join("")}]`))
      onChange([...value, ...splitValues])
      setTextValue("")
    }
  }

  const onKeyDown = (e) => {
    if (separatorKeys.includes(e.key)) {
      if (textValue) {
        e.preventDefault()
      }
      commitTextValue()
    } else if (e.key === "Backspace") {
      // if the cursor is at the beginning of the input, remove the last item
      if (!textValue || inputRef?.current?.selectionStart === 0) {
        e.preventDefault()
        onChange(value.slice(0, value.length - 1))
      }
    }
  }

  const onInputBlur = (e) => {
    commitTextValue()
    onBlur && onBlur(e)
  }

  return (
    <div css={CONTAINER} {...rest}>
      <div css={INPUT_CONTAINER}>
        <TextField
          variant="standard"
          multiline
          css={INPUT}
          InputProps={{
            disableUnderline: true,
            startAdornment: renderStartAdornment(),
            placeholder,
            sx: {
              ...typography.regular,
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            },
          }}
          value={textValue}
          onChange={(e) => setTextValue(e.target.value)}
          onKeyDown={onKeyDown}
          inputRef={inputRef}
          onBlur={onInputBlur}
          label={label}
          disabled={disabled}
        />
      </div>
      {error && (
        <Text preset={textPresets.small} css={ERROR_MESSAGE}>
          {error}
        </Text>
      )}
    </div>
  )
})

export default TextTagInput
