import { Instance, types } from "mobx-state-tree"
import { Entity, EntityType } from "./entity"
import { ExternalLinkModel } from "./external-link"

export const PublicUserModel = types
  .model("PublicUserModel")
  .props({
    id: types.identifier,
    name: types.string,
    location: types.maybe(types.string),
    title: types.maybe(types.string),
    affiliation: types.maybe(types.string),
    tagline: types.maybe(types.string),
    bio: types.maybe(types.string),
    website: types.maybe(types.string),
    avatarAssetId: types.maybe(types.string),
    profilePitchId: types.maybe(types.string),
    profileLink: types.maybe(ExternalLinkModel),
  })
  .views((self) => ({
    toEntity(): Entity {
      return {
        id: self.id,
        name: self.name,
        assetId: self.avatarAssetId,
        type: EntityType.User,
        parentId: null,
      }
    },
  }))

export type PublicUser = Instance<typeof PublicUserModel>
