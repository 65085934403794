import { ApiResponse } from "apisauce"
import { Api } from "./api"
import { ApiError } from "./api-problem"
import { ScriptShare } from "../../models/script-share"

const API_PATH = "api/ScriptShare/"

export type ScriptShareResult = { scriptShare: ScriptShare }

export class ScriptShareApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async getScriptShare(scriptShareId: string): Promise<ScriptShareResult> {
    const response: ApiResponse<ScriptShareResult> = await this.api.apisauce.get(
      API_PATH + scriptShareId,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("ScriptShareApi.getScriptShare", response)
    }
    return response.data
  }
}
