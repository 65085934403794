import { Instance, types } from "mobx-state-tree"
import { DateTime } from "../utils/datetime-type"
import { StringEnum } from "../utils/string-enum-type"

export enum ScriptAlignment {
  LEFT = "LEFT",
  CENTER = "CENTER",
  RIGHT = "RIGHT",
}

export const ScriptModelDefaults = {
  speed: 20,
  fontSize: 40,
  fontColor: "#FFFFFF",
  align: ScriptAlignment.LEFT,
}

/**
 * Script model.
 */
export const ScriptModel = types
  .model("Script")
  .props({
    id: types.identifier,

    promptId: types.maybe(types.string),

    name: types.maybe(types.string),
    content: types.maybe(types.string),
    speed: types.optional(types.number, ScriptModelDefaults.speed),

    fontColor: types.optional(types.string, ScriptModelDefaults.fontColor),
    fontSize: types.optional(types.number, ScriptModelDefaults.fontSize),
    bold: types.optional(types.boolean, false),
    underline: types.optional(types.boolean, false),
    italic: types.optional(types.boolean, false),
    align: types.optional(StringEnum(ScriptAlignment), ScriptModelDefaults.align),

    updatedUtc: types.maybe(DateTime),

    // for client-side placeholder
    defaultName: types.maybe(types.string),
  })
  .views((self) => ({
    get isNew() {
      return self.updatedUtc === undefined
    },
  }))

export type Script = Instance<typeof ScriptModel>
