import { Instance, types, flow, cast, toGenerator } from "mobx-state-tree"
import { withEnvironment } from "./extensions/with-environment"
import {
  Attachment,
  AttachmentLabel,
  AttachmentModel,
  AttachmentType,
  GalleryAttachmentData,
  ProjectAttachmentData,
  ResumeAttachmentData,
  WebsiteAttachmentData,
} from "../models/attachment"
import { AttachmentApi } from "../services/api/attachment-api"
import newId from "../utils/new-id"
import { withAssetStore } from "./asset-store"
import { AssetModel } from "../models/asset"
import { Sync, SyncStatus } from "../models/sync"
import logTaggedError from "../utils/log-tagged-error"

/*
  This store can be used to create or edit a pitches attachments
  It may be extended by other stores to provide more specific functionality
*/
export const BaseAttachmentEditorStoreModel = types
  .model("BaseAttachmentEditorStore")
  .props({
    originalAttachments: types.array(AttachmentModel),
    attachments: types.array(AttachmentModel),
    attachmentAssets: types.map(AssetModel),
  })
  .views((self) => ({
    get websiteAttachment() {
      return self.attachments.find((a) => a.type === AttachmentType.Website) as
        | (Attachment & {
            data: WebsiteAttachmentData
          })
        | undefined
    },
    get resumeAttachment() {
      return self.attachments.find((a) => a.type === AttachmentType.Resume) as
        | (Attachment & {
            data: ResumeAttachmentData
          })
        | undefined
    },
    get galleryAttachment() {
      return self.attachments.find((a) => a.type === AttachmentType.Gallery) as
        | (Attachment & {
            data: GalleryAttachmentData
          })
        | undefined
    },
    getAssetSync(assetId) {
      // if we don't have an asset sync, assume we previously synced this asset
      // note that we won't actually be able to use the fake sync, but we shouldn't need to since
      // assets only need to be synced once
      return self.attachmentAssets.get(assetId)?.sync || cast<Sync>({ status: SyncStatus.Synced })
    },
  }))
  .views((self) => ({
    get hasUnsyncedAttachments() {
      return self.attachments.some((a) => self.getAssetSync(a.id).status !== SyncStatus.Synced)
    },
  }))
  .extend(withEnvironment)
  .extend(withAssetStore)
  .actions((self) => ({
    setAttachment({
      type,
      label,
      data,
    }: {
      type: AttachmentType
      label?: AttachmentLabel
      data?:
        | WebsiteAttachmentData
        | ResumeAttachmentData
        | ProjectAttachmentData
        | GalleryAttachmentData
    }) {
      const existingAttachmentIndex = self.attachments.findIndex((a) => a.type === type)
      if (existingAttachmentIndex >= 0) {
        const attachment = self.attachments[existingAttachmentIndex]
        attachment.data = data
        attachment.label = label
        self.attachments.splice(existingAttachmentIndex, 1, attachment)
      } else {
        self.attachments.push({
          ...newId(),
          userId: newId().id,
          type,
          label,
          data,
        })
      }
    },
  }))
  .actions((self) => ({
    removeAttachment({ type }: { type: AttachmentType }) {
      self.attachments.replace(self.attachments.filter((a) => a.type !== type))
    },
  }))
  .actions((self) => ({
    removeGalleryPhoto(assetId: string) {
      self.setAttachment({
        type: AttachmentType.Gallery,
        label: AttachmentLabel.Gallery,
        data: {
          photos: self.galleryAttachment?.data?.photos.filter((p) => p?.assetId !== assetId) as any,
        },
      })
      const galleryAttachment = self.galleryAttachment
      if (galleryAttachment?.data?.photos.length === 0) {
        self.removeAttachment({ type: AttachmentType.Gallery })
      }
    },
    resetAttachments() {
      self.originalAttachments.clear()
      self.attachments.clear()
    },
    fetchAttachments: flow(function* (pitchId: string) {
      const attachmentApi = new AttachmentApi(self.environment.api)
      const result = yield* toGenerator(
        attachmentApi.getAttachmentsForPitch({
          pitchId,
        }),
      )

      self.originalAttachments.replace(result.attachments)
      self.attachments.replace(result.attachments)
      return result.attachments
    }),
    setWebsiteAttachment({ url, label, openGraphData }) {
      self.setAttachment({
        type: AttachmentType.Website,
        data: {
          url,
          openGraphData: { ...openGraphData },
        },
        label,
      })
    },
    setResumeAttachment: flow(function* ({ localPath, type, name, label }) {
      const asset = self.assetStore.createAsset({ localPath })
      self.attachmentAssets.set(asset.id, asset)
      self.setAttachment({
        type: AttachmentType.Resume,
        label: label,
        data: { assetId: asset.id, type, name },
      })

      yield self.assetStore.uploadMobileAsset(asset)
    }),

    setGalleryCaption({ caption, assetId }) {
      const photo = self.galleryAttachment?.data?.photos.find((p) => p?.assetId === assetId)

      if (photo) {
        photo.caption = caption
      }

      if (self.galleryAttachment?.data) {
        self.setAttachment({
          type: AttachmentType.Gallery,
          label: AttachmentLabel.Gallery,
          data: {
            photos: self.galleryAttachment.data.photos,
          },
        })
      }
    },
    setGalleryAttachment({ photos }) {
      self.setAttachment({
        type: AttachmentType.Gallery,
        data: { photos },
        label: AttachmentLabel.Gallery,
      })
    },
  }))
  .actions((self) => ({
    addGalleryPhoto: flow(function* (localPath: string) {
      const asset = self.assetStore.createAsset({ localPath })
      self.attachmentAssets.set(asset.id, asset)

      if (self.galleryAttachment?.data) {
        const galleryAttachmentData = self.galleryAttachment.data
        const numPhotos = galleryAttachmentData.photos.length

        galleryAttachmentData.photos.push({
          assetId: asset.id,
          caption: "",
          ordinal: numPhotos,
        })

        self.setAttachment({
          type: AttachmentType.Gallery,
          label: AttachmentLabel.Gallery,
          data: { photos: galleryAttachmentData.photos },
        })
      } else {
        self.setAttachment({
          type: AttachmentType.Gallery,
          label: AttachmentLabel.Gallery,
          data: { photos: cast([{ assetId: asset.id, caption: "", ordinal: 0 }]) },
        })
      }

      try {
        yield self.assetStore.uploadMobileAsset(asset)
      } catch (e) {
        logTaggedError(e)
        self.removeGalleryPhoto(asset.id)
      }
    }),
  }))

export type BaseAttachmentEditorStore = Instance<typeof BaseAttachmentEditorStoreModel>
