import { Instance, flow, toGenerator, types } from "mobx-state-tree"
import { withEnvironment } from "./extensions/with-environment"
import { AssignmentApi } from "../services"
import { Assignment, AssignmentModel, AssignmentParams } from "../models/assignment"

export const AssignmentStoreModel = types
  .model("AssignmentStore")
  .props({
    assignments: types.map(AssignmentModel),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    putAssignments(assignments: Assignment[]) {
      return assignments.map((assignment) => self.assignments.put(assignment))
    },
  }))
  .actions((self) => ({
    fetchPlaylistAssignments: flow(function* (playlistId: string) {
      const api = new AssignmentApi(self.environment.api)
      const results = yield* toGenerator(api.getAssignment(playlistId))
      return self.putAssignments(results.assignments)
    }),
    createPlaylistAssignment: flow(function* (playlistId: string, assignment: AssignmentParams) {
      assignment.initialDueDate?.setSeconds(0)
      const api = new AssignmentApi(self.environment.api)
      yield* toGenerator(api.createPlaylistAssignment(playlistId, assignment))
    }),
    updateAssignment: flow(function* (assignment: AssignmentParams) {
      const api = new AssignmentApi(self.environment.api)
      yield* toGenerator(api.updateAssignment(assignment))
    }),
    deleteAssignment: flow(function* (assignmentId: string) {
      const api = new AssignmentApi(self.environment.api)
      yield* toGenerator(api.deleteAssignment(assignmentId))
    }),
  }))

export type AssignmentStore = Instance<typeof AssignmentStoreModel>
