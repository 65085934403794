import { flow, getParent, Instance, IStateTreeNode, types, toGenerator } from "mobx-state-tree"

import { withEnvironment } from "./extensions/with-environment"
import { withUserStore } from "./user-store"
import { Group, GroupModel } from "../models/group"
import { GroupApi } from "../services/api/group-api"
import { withOrganizationStore } from "./organization-store"
import { RootStoreModel } from "./root-store"
import { PublicUserModel } from "../models/public-user"

export const GroupStoreModel = types
  .model("GroupStore")
  .props({
    groups: types.map(GroupModel),
    summaries: types.map(
      types.model("GroupSummary").props({
        memberCount: types.integer,
        admins: types.array(types.safeReference(PublicUserModel)),
      }),
    ),
  })
  .extend(withEnvironment)
  .extend(withOrganizationStore)
  .extend(withUserStore)
  .actions((self) => ({
    putGroups: (groups: Group[]) => {
      const groupModels: Group[] = []
      for (const group of groups) {
        self.groups.put(group)
        const groupModel = self.groups.get(group.id)
        if (groupModel) {
          groupModels.push(groupModel)
        }
      }
      return groupModels
    },
    putGroupSummaries: (group: Group, groupMemberCount: number) => {
      self.summaries.set(group.id, { memberCount: groupMemberCount })
    },
  }))
  .actions((self) => ({
    fetchGroup: flow(function* (groupId: string) {
      const groupApi = new GroupApi(self.environment.api)
      const result = yield* toGenerator(groupApi.getGroup(groupId))
      return self.putGroups([result.group])[0]
    }),
    fetchGroups: flow(function* (organizationId: string) {
      const groupApi = new GroupApi(self.environment.api)
      const result = yield* toGenerator(groupApi.getByOrganizationId(organizationId))
      return self.putGroups(result.groups)
    }),
    fetchGroupsForAdmin: flow(function* (organizationId: string) {
      const groupApi = new GroupApi(self.environment.api)
      const result = yield* toGenerator(groupApi.getGroupsForDashboard(organizationId))
      return self.putGroups(result.groups)
    }),
    fetchDashboardGroupSummaries: flow(function* (organizationId: string) {
      const groupApi = new GroupApi(self.environment.api)
      const result = yield* toGenerator(groupApi.getDashboardGroupSummaries(organizationId))
      for (const { groupId, admins, memberCount } of result.groupSummaries) {
        const adminModels = self.userStore.putUsers(admins)
        self.summaries.set(groupId, { admins: adminModels.map((a) => a.id), memberCount })
      }
      return true
    }),
    createGroup: flow(function* (group: Group) {
      const groupApi = new GroupApi(self.environment.api)
      yield groupApi.createGroup(group)
      self.putGroups([group])
      // TODO remember to refetch session after this action to access admin roles to the new group
    }),
    saveGroup: flow(function* (group: Group) {
      const groupApi = new GroupApi(self.environment.api)
      yield groupApi.updateGroup(group)
      self.putGroups([group])
    }),
    saveGroupSettings: flow(function* (group: Group) {
      const groupApi = new GroupApi(self.environment.api)
      yield groupApi.updateGroupSettings(group)
      self.putGroups([group])
    }),
    deleteGroup: flow(function* (group: Group) {
      const groupApi = new GroupApi(self.environment.api)
      yield groupApi.deleteGroup(group.id)
      self.groups.delete(group.id)
    }),
  }))
  .views((self) => ({
    groupsByOrganizationId: (orgId) => {
      // TODO this should be a map of arrays
      return Array.from(self.groups.values()).filter((group) => group.organizationId === orgId)
    },
    getGroupVisibility: (groupId) => {
      return self.groups.get(groupId)?.visibility
    },
    // TODO clean this up once we have an org entity model on group
    getOrganizationNameForGroup: (groupId) => {
      const group = self.groups.get(groupId)
      if (!group) {
        return ""
      }
      const organization = self.organizationStore.organizations.get(group.organizationId)
      if (!organization) {
        return ""
      }
      return organization.name
    },
  }))

export type GroupStore = Instance<typeof GroupStoreModel>
export const withGroupStore = (self: IStateTreeNode) => ({
  views: {
    get groupStore(): GroupStore {
      return getParent<Instance<typeof RootStoreModel>>(self).groupStore
    },
  },
})
