import { ApiResponse } from "apisauce"
import { Api } from "./api"
import { ApiError } from "./api-problem"
import { Group } from "../../models/group"
import { Organization } from "../../models/organization"
import { OrganizationUser, UserMembership } from "../../models/organization-user"
import { PublicUser } from "../../models/public-user"

const API_PATH = "api/OrganizationUser/"

interface OrganizationUserUpdateRequest {
  email?: string
  phone?: string
  titleData?: string
}

export type OrganizationUserResult = { organizationUser: OrganizationUser }

export type OrganizationUsersResult = {
  organizationUsers: OrganizationUser[]
  memberships: UserMembership[]
  organizations: Organization[]
  groups: Group[]
  groupMemberCount: { [groupId: string]: number }
}

export type UserOrganizationsResult = {
  userOrganizations: { [userId: string]: Organization[] }
}

export type OrganizationUsersDashboardResult = {
  organizationUsers: OrganizationUser[]
  users: PublicUser[]
}

export class OrganizationUserApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async getForUser(): Promise<OrganizationUsersResult> {
    const response: ApiResponse<OrganizationUsersResult> = await this.api.apisauce.get(
      `${API_PATH}user`,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("OrganizationUserApi.getForUser", response)
    }
    return response.data
  }

  async updateOrganizationUser(
    orgUserId: string,
    values: OrganizationUserUpdateRequest,
  ): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.patch(
      `${API_PATH}${orgUserId}`,
      values,
    )
    if (!response.ok) {
      throw new ApiError("OrganizationUserApi.updateOrganizationUser", response)
    }
  }

  async updateOrganizationUserRole(
    orgUserId: string,
    roleId: string,
    groupId?: string,
  ): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.patch(
      `${API_PATH}${orgUserId}/roles`,
      {
        groupId,
        organizationRoleIds: [roleId],
      },
    )
    if (!response.ok) {
      throw new ApiError("OrganizationUserApi.updateOrganizationUserRole", response)
    }
  }
}
