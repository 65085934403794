import {
  Instance,
  types,
  flow,
  toGenerator,
  getParent,
  IStateTreeNode,
  detach,
  cast,
} from "mobx-state-tree"
import { withEnvironment } from "./extensions/with-environment"
import newId from "../utils/new-id"
import { MessagingApi } from "../services/api/messaging-api"
import { withUserStore } from "./user-store"
import { Index } from "flexsearch"
import { compareDesc, compareAsc } from "date-fns"
import { StringEnum } from "../utils/string-enum-type"
import { BlockType, withBlockedUserStore } from "./blocked-user-store"
import { difference, sortBy } from "lodash-es"
import { withSessionStore } from "./session-store"
import { RootStoreModel } from "./root-store"
import logger from "../logging/logger"
import { Entity } from "../models/entity"
import {
  MessageDataModel,
  MessageType,
  ConversationModel,
  MessageModel,
  ConversationUserStatus,
  Message,
  Conversation,
  MessageData,
} from "../models/messaging"

const MESSAGE_LIMIT = 10

export enum SortMode {
  NewestToOldest = "NewestToOldest",
  OldestToNewest = "OldestToNewest",
}

export const MessageResourceModel = types.model("MessageResource").props({
  data: MessageDataModel,
  type: StringEnum(MessageType),
})
export type MessageResource = Instance<typeof MessageResourceModel>

export const MessagingStoreModel = types
  .model("MessagingStore")
  .props({
    searchIndex: types.maybe(types.frozen<Index>(null)),
    searchedConversations: types.map(types.boolean),
    conversations: types.map(ConversationModel),
    messages: types.map(MessageModel),
    /**
     * Maps conversationId to an array of messages
     */
    conversationMessages: types.map(types.array(types.safeReference(MessageModel))),
    pendingMessages: types.map(types.boolean),
    failedMessages: types.map(types.boolean),
    searchString: types.optional(types.string, ""),
    sortMode: types.maybe(StringEnum(SortMode)),
    /**
     * Maps conversationId to a draft message
     */
    draftConversationMessages: types.map(types.safeReference(MessageModel)),
    /**
     * Ids that will receive "new" badging in the UI
     */
    newConversationIds: types.array(types.string),
  })
  .extend(withEnvironment)
  .extend(withUserStore)
  .extend(withSessionStore)
  .extend(withBlockedUserStore)
  .views((self) => ({
    get validConversations() {
      return (Array.from(self.conversations.values()) || []).filter(
        (c) =>
          // Remove any conversation where all users except current are blocked. In a group converation
          // where not everyone is blocked, we will have to handle removing those indiviual messages
          // from banned users within the conversation.
          !(
            c.users.filter((u) => self.blockedUserStore.isUserBlocked(BlockType.BlockedByEither, u))
              .length ===
            c.users.length - 1
          ),
      )
    },
    getConversationPendingMessages(conversationId: string) {
      const messageIds = [...self.pendingMessages.keys()]
      const pendingMessageIds = messageIds.filter((id) => self.pendingMessages.get(id))
      return pendingMessageIds
        .filter((id) => self.messages.get(id)?.conversationId === conversationId)
        .map((id) => self.messages.get(id))
    },
    getConversationFailedMessages(conversationId: string) {
      const messageIds = [...self.failedMessages.keys()]
      const failedMessageIds = messageIds.filter((id) => self.failedMessages.get(id))
      return failedMessageIds
        .filter((id) => self.messages.get(id)?.conversationId === conversationId)
        .map((id) => self.messages.get(id))
    },
  }))
  .views((self) => ({
    get sortedConversations() {
      return self.validConversations.slice().sort((a, b) => {
        const aDate = a.lastMessage?.createdUtc ? new Date(a.lastMessage.createdUtc) : 0
        const bDate = b.lastMessage?.createdUtc ? new Date(b.lastMessage.createdUtc) : 0
        return self.sortMode === SortMode.NewestToOldest || !self.sortMode
          ? compareDesc(aDate, bDate)
          : compareAsc(aDate, bDate)
      })
    },
  }))
  .views((self) => ({
    get acceptedConversations() {
      if (self.sessionStore.currentUser) {
        return self.searchString
          ? self.sortedConversations
              .filter((c) => self.searchedConversations.has(c.id))
              .filter((c) => c.userStatus === ConversationUserStatus.Accepted)
          : self.sortedConversations.filter((c) => c.userStatus === ConversationUserStatus.Accepted)
      }
      return []
    },
    get pendingConversations() {
      if (self.sessionStore.currentUser) {
        return self.searchString
          ? self.sortedConversations
              .filter((c) => self.searchedConversations.has(c.id))
              .filter((c) => c.userStatus === ConversationUserStatus.Pending)
          : self.sortedConversations.filter((c) => c.userStatus === ConversationUserStatus.Pending)
      }
      return []
    },
    getOtherConversationUsers(conversationId: string) {
      const conversation = self.conversations.get(conversationId)
      return conversation?.users.filter((u) => u.id !== self.sessionStore.currentUser?.id) || []
    },
    getConversationFromMessage({ messageId }: { messageId: string }) {
      const conversationId = Array.from(self.conversationMessages.keys()).find((k) =>
        self.conversationMessages.get(k)?.find((m) => m?.id === messageId),
      )
      return self.conversations.get(conversationId)
    },
  }))
  .actions((self) => ({
    updateSearchedConversations() {
      if (self.searchString && self.searchString.length && self.searchIndex) {
        self.searchedConversations.replace({})
        self.searchIndex.search(self.searchString).forEach((conversationId) => {
          self.searchedConversations.set(conversationId, true)
        })
      } else {
        self.searchedConversations.replace({})
      }
    },
  }))
  .actions((self) => ({
    updateSearchIndex() {
      self.searchIndex = new Index({ tokenize: "forward" })
      self.conversations.forEach((c) => {
        self.searchIndex.add(c.id, c.users.map((c) => c.name).join(" "))
      })
      self.updateSearchedConversations()
    },
    putMessage(message: Message) {
      return self.messages.put(message)
    },
    putMessages(messages: Message[]) {
      return messages.reduce<Message[]>((messageModels, m) => {
        const messageModel = self.messages.put(m)
        messageModels.push(messageModel)
        return messageModels
      }, [])
    },
  }))
  .actions((self) => ({
    putConversation(conversation: Conversation) {
      const lastMessageModel = conversation.lastMessage
        ? self.putMessage(conversation.lastMessage)
        : null
      delete conversation.lastMessage
      self.conversations.put(conversation)
      const conversationModel = self.conversations.get(conversation.id)
      if (lastMessageModel && conversationModel) {
        conversationModel.lastMessage = lastMessageModel.id as any
      }
      self.updateSearchIndex()
      return self.conversations.get(conversation.id)!
    },
  }))
  .actions((self) => ({
    putConversations(conversations: Conversation[]): Conversation[] {
      const conversationModels = conversations.reduce<Conversation[]>((conversationModels, c) => {
        const conversationModel = self.putConversation(c)
        if (conversationModel) {
          conversationModels.push(conversationModel)
        }
        return conversationModels
      }, [])
      return conversationModels
    },
  }))
  .actions((self) => ({
    createDraftMessage: function ({
      conversationId,
      userId,
    }: {
      conversationId: string
      userId: string
    }) {
      const newMessage: Message = {
        ...newId(),
        type: MessageType.Text,
        textContent: "",
        conversationId,
        userId,
        createdUtc: new Date(),
        updatedUtc: new Date(),
      }

      const messageModel = self.putMessage(newMessage)
      self.draftConversationMessages.set(conversationId, messageModel.id)
      return messageModel
    },
    createDraftConversation: function (users: Entity[]) {
      const userIds = users.map((u) => u.id)
      const existingConversation = Array.from(self.conversations.values()).find(
        (c) =>
          difference(
            c.users.map((u) => u.id),
            userIds,
          ).length === 0,
      )
      if (existingConversation) {
        if (!existingConversation.draft) {
          logger.logWarning("Conversation already exists, but is not a draft")
        }
        return existingConversation
      }

      const newConversation: Conversation = {
        id: newId().id,
        users: cast(users),
        lastMessage: undefined,
        createdUtc: new Date(),
        hasUnreadMessages: false,
        userStatus: ConversationUserStatus.Accepted,
        draft: true,
      }

      return self.putConversation(newConversation)
    },
  }))
  .actions((self) => ({
    setSearchString: (searchString: string) => {
      self.searchString = searchString
      self.updateSearchedConversations()
    },
    setDraftText: function ({
      conversationId,
      textContent,
      userId,
    }: {
      conversationId: string
      textContent: string
      userId: string
    }) {
      if (!self.draftConversationMessages.has(conversationId)) {
        self.createDraftMessage({ conversationId, userId })
      }
      const draftMessage = self.draftConversationMessages.get(conversationId)!
      draftMessage.textContent = textContent
    },
    setDraftData: function ({
      conversationId,
      data,
      type,
      userId,
    }: {
      conversationId: string
      data: MessageData
      type: MessageType
      userId: string
    }) {
      if (!self.draftConversationMessages.has(conversationId)) {
        self.createDraftMessage({ conversationId, userId })
      }
      const draftMessage = self.draftConversationMessages.get(conversationId)!
      draftMessage.data = data
      draftMessage.type = type
    },
    clearDraftData: function ({ conversationId }: { conversationId: string }) {
      const draftMessage = self.draftConversationMessages.get(conversationId)
      if (draftMessage) {
        draftMessage.data = undefined
        draftMessage.type = MessageType.Text
      }
    },
  }))
  .actions((self) => ({
    fetchConversation: flow(function* ({ conversationId }: { conversationId: string }) {
      const messagingApi = new MessagingApi(self.environment.api)
      const result = yield* toGenerator(
        messagingApi.getConversationById({
          conversationId,
        }),
      )
      return self.putConversation(result.conversation)
    }),
    fetchExistingConversation: flow(function* (userIds: string[]) {
      const messagingApi = new MessagingApi(self.environment.api)
      const result = yield* toGenerator(
        messagingApi.getExistingConversation({
          userIds,
        }),
      )
      if (result.conversation) {
        return self.putConversation(result.conversation)
      }
      return null
    }),
    fetchMessages: flow(function* ({
      conversationId,
      cursor,
    }: {
      conversationId: string
      cursor?: string
    }) {
      const messagingApi = new MessagingApi(self.environment.api)
      const result = yield* toGenerator(
        messagingApi.getMessages({
          conversationId,
          limit: MESSAGE_LIMIT,
          cursor,
        }),
      )
      const hasMoreMessages = result.messages.length === MESSAGE_LIMIT
      const messageIds = new Set(result.messages?.map((m) => m.id))
      // reload messages if cursor is not set
      if (!cursor) {
        if (self.conversationMessages.get(conversationId)) {
          detach(self.conversationMessages.get(conversationId))
        }
        const messageModels = self.putMessages(result.messages)
        self.conversationMessages.set(
          conversationId,
          messageModels.map((m) => m.id),
        )
      } else {
        const existingMessages = self.conversationMessages.get(conversationId) || []
        const unchangedMessages = existingMessages.filter((m) => m && !messageIds.has(m?.id))
        existingMessages.filter((m) => m && messageIds.has(m?.id)).forEach(detach)
        const updatedMessages = self.putMessages(result.messages)
        self.conversationMessages.set(conversationId, [
          ...unchangedMessages.map((m) => m?.id),
          ...updatedMessages.map((m) => m.id),
        ])
      }
      const messages =
        self.conversationMessages.get(conversationId)?.filter((m) => m && messageIds.has(m?.id)) ||
        []
      const nextCursor = sortBy(
        self.conversationMessages.get(conversationId),
        (m) => m?.createdUtc,
      )[0]?.id

      return {
        messages,
        nextCursor: hasMoreMessages ? nextCursor : null,
      }
    }),
    fetchNewMessages: flow(function* ({ conversationId }: { conversationId: string }) {
      const messagingApi = new MessagingApi(self.environment.api)
      const result = yield* toGenerator(
        messagingApi.getMessages({
          conversationId,
          limit: MESSAGE_LIMIT,
        }),
      )
      const existingMessages = self.conversationMessages.get(conversationId) || []
      const existingMessageIds = new Set(existingMessages.map((m) => m.id))
      const newMessages = result.messages.filter((m) => !existingMessageIds.has(m.id))
      self.putMessages(newMessages)
      const newMessageIds = new Set(newMessages.map((m) => m.id))
      self.conversationMessages.set(conversationId, [
        ...newMessages.map((m) => m.id),
        ...(self.conversationMessages.get(conversationId)?.map((m) => m?.id) || []),
      ])
      return self.conversationMessages
        .get(conversationId)
        ?.filter((m) => m && newMessageIds.has(m?.id))
    }),
    fetchConversations: flow(function* (entityId?: string) {
      const messagingApi = new MessagingApi(self.environment.api)
      const result = yield* toGenerator(messagingApi.getAllConversations(entityId))
      // treat not having a filter as refreshing all conversations
      if (!entityId) {
        const existingConversationIds = new Set(self.conversations.keys())
        const updatedConversationIds = new Set(result.conversations.map((c) => c.id))
        const conversationIdsToRemove = [...existingConversationIds].filter(
          (id) =>
            !updatedConversationIds.has(id) &&
            // don't remove drafts
            !self.conversations.get(id)?.draft,
        )
        conversationIdsToRemove.forEach((id) => self.conversations.delete(id))
      }
      self.putConversations(result.conversations)
      return self.sortedConversations
    }),
    fetchUnreadConversationIds: flow(function* () {
      const messagingApi = new MessagingApi(self.environment.api)
      const result = yield* toGenerator(messagingApi.getUnreadConversationIds())
      result.conversationIds?.forEach((id: string) => {
        const conversation = self.conversations.get(id)
        if (conversation) {
          conversation.hasUnreadMessages = true
        }
      })
      self.newConversationIds.replace(result.conversationIds)
      return self.newConversationIds
    }),
  }))
  .actions((self) => ({
    sendMessage: flow(function* ({
      conversationId,
      message,
    }: {
      conversationId: string
      message: Omit<Message, "createdUtc" | "updatedUtc" | "setConversationId">
    }) {
      const conversation = self.conversations.get(conversationId)
      if (!conversation) {
        throw new Error("Conversation could not be found")
      }

      // replace the existing message with one that has updated timestamps
      const newMessage: Message = self.messages.put({
        id: message.id,
        type: message.type || MessageType.Text,
        textContent: message.textContent,
        conversationId,
        userId: message.userId,
        createdUtc: new Date(),
        updatedUtc: new Date(),
        data: message.data,
      })

      // add the message to the client-side conversation in a pending state
      self.pendingMessages.set(newMessage.id, true)
      const messages = self.conversationMessages.get(conversationId)
      if (messages) {
        messages.unshift(newMessage.id)
      } else {
        self.conversationMessages.set(conversationId, [newMessage.id])
      }

      // if necessary, reset the draft message
      if (self.draftConversationMessages.get(conversationId)?.id === message.id) {
        self.draftConversationMessages.delete(conversationId)
        self.createDraftMessage({ conversationId, userId: message.userId })
      }

      const previousLastMessageId = conversation.lastMessage?.id
      try {
        conversation.lastMessage = newMessage.id as any
        const messagingApi = new MessagingApi(self.environment.api)
        if (conversation.draft) {
          // attempt to use this message to start a conversation
          const result = yield* toGenerator(
            messagingApi.createConversation(
              conversation.users.map((u) => u.id),
              newMessage,
            ),
          )
          const createdConversation = self.putConversation(result.conversation)
          // it's possible we found a different existing conversation on the server
          if (createdConversation.id !== conversation.id) {
            detach(conversation) // detach because the conversation-screen still refers to it
            newMessage.setConversationId(createdConversation.id)
            self.draftConversationMessages
              .get(conversationId)
              ?.setConversationId(createdConversation.id)
          }
        } else {
          yield messagingApi.sendMessage({
            message: newMessage,
          })
        }
      } catch (e) {
        conversation!.lastMessage = previousLastMessageId as any
        self.failedMessages.set(newMessage.id, true)
        throw e
      } finally {
        self.pendingMessages.set(newMessage.id, false)
      }
    }),
  }))
  .actions((self) => ({
    retrySendMessage: flow(function* ({
      conversationId,
      messageId,
    }: {
      conversationId: string
      messageId: string
    }) {
      const messages = self.conversationMessages.get(conversationId)
      const message = self.messages.get(messageId)
      if (self.failedMessages.get(messageId) && message) {
        self.failedMessages.set(messageId, false)
        self.pendingMessages.set(messageId, true)
        const messagingApi = new MessagingApi(self.environment.api)
        try {
          message.createdUtc = new Date()
          yield messagingApi.sendMessage({
            message,
          })
          // it's possible that the message if failed does not exist in the conversation
          // because of a refetch of the conversation
          if (!messages?.find((m) => m?.id === messageId)) {
            messages?.unshift(message)
          }
        } catch (e) {
          self.failedMessages.set(messageId, true)
          throw e
        } finally {
          self.pendingMessages.set(messageId, false)
        }
      }
    }),
    acceptConversation: flow(function* ({ conversationId }: { conversationId: string }) {
      const messagingApi = new MessagingApi(self.environment.api)
      yield* toGenerator(
        messagingApi.acceptConversation({
          conversationId,
        }),
      )
    }),
    rejectConversation: flow(function* ({ conversationId }: { conversationId: string }) {
      const messagingApi = new MessagingApi(self.environment.api)
      yield* toGenerator(
        messagingApi.rejectConversation({
          conversationId,
        }),
      )
    }),
    updateLastViewedMessage: flow(function* (params: {
      conversationId: string
      lastMessageId: string
    }) {
      const messagingApi = new MessagingApi(self.environment.api)
      const conversation = self.conversations.get(params.conversationId)
      if (!conversation) {
        throw new Error("Conversation not found in store " + params.conversationId)
      }
      try {
        conversation.hasUnreadMessages = false
        self.newConversationIds.remove(params.conversationId)
        yield messagingApi.updateLastViewedMessage(params)
      } catch (e) {
        self.newConversationIds.push(params.conversationId)
        conversation.hasUnreadMessages = true
        throw e
      }
    }),
  }))

export type MessagingStore = Instance<typeof MessagingStoreModel>
export const withMessagingStore = (self: IStateTreeNode) => ({
  views: {
    get messagingStore(): MessagingStore {
      return getParent<Instance<typeof RootStoreModel>>(self).messagingStore
    },
  },
})
