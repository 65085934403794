import { flow, getParent, Instance, IStateTreeNode, types, toGenerator } from "mobx-state-tree"
import { withEnvironment } from "./extensions/with-environment"
import { OrganizationApi } from "../services/api/organization-api"
import { sortBy, indexOf } from "lodash-es"
import { RootStoreModel } from "./root-store"
import {
  Organization,
  OrganizationModel,
  OrganizationVisibilityStatus,
} from "../models/organization"
import { ManagedRole, OrganizationRoleModel, OrganizationRole } from "../models/organization-role"

export const OrganizationStoreModel = types
  .model("OrganizationStore")
  .props({
    organizations: types.map(OrganizationModel),
    organizationRoles: types.map(OrganizationRoleModel),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    putOrganizations: (organizations: Organization[]) => {
      const organizationModels: Organization[] = []
      for (const organization of organizations) {
        self.organizations.put(organization)
        const organizationModel = self.organizations.get(organization.id)
        if (organizationModel) {
          organizationModels.push(organizationModel)
        }
      }
      return organizationModels
    },
    putOrganizationRoles: (organizationRoles: OrganizationRole[]) => {
      const organizationRoleModels: OrganizationRole[] = []
      for (const role of organizationRoles) {
        self.organizationRoles.put(role)
        const organizationRoleModel = self.organizationRoles.get(role.id)
        if (organizationRoleModel) {
          organizationRoleModels.push(organizationRoleModel)
        }
      }
      return organizationRoleModels
    },
  }))
  .actions((self) => ({
    fetchOrganization: flow(function* (organizationId: string) {
      const organizationApi = new OrganizationApi(self.environment.api)
      const result = yield* toGenerator(organizationApi.getOrganization(organizationId))
      return self.putOrganizations([result.organization])
    }),
    createOrganization: flow(function* (org: Organization) {
      const organizationApi = new OrganizationApi(self.environment.api)
      yield organizationApi.createOrganization(org)
      self.organizations.put(org)
      // TODO remember to refetch session after this action to access admin roles to the new org
      return org.id
    }),
    saveOrganization: flow(function* (org: Organization) {
      const organizationApi = new OrganizationApi(self.environment.api)
      yield organizationApi.updateOrganization(org)
      self.organizations.put(org)
    }),
    fetchOrganizationRoles: flow(function* (organizationId: string) {
      const organizationApi = new OrganizationApi(self.environment.api)
      const result = yield* toGenerator(organizationApi.getOrganizationRoles(organizationId))
      return self.putOrganizationRoles(result.roles)
    }),
  }))
  .actions((self) => ({
    setOrganizationVisibility: flow(function* (
      organizationId: string,
      visibility: OrganizationVisibilityStatus,
    ) {
      const organizationApi = new OrganizationApi(self.environment.api)
      yield organizationApi.setOrganizationVisibility(organizationId, visibility)
      self.fetchOrganization(organizationId)
    }),
  }))
  .views((self) => ({
    allRoles: (orgId) => {
      return Array.from(self.organizationRoles.values()).filter(
        (role) => role.organizationId === orgId,
      )
    },
    groupOnlyRoles: (orgId) => {
      return sortBy(
        Array.from(self.organizationRoles.values()).filter(
          (role) => role.organizationId === orgId && role.isGroupRole,
        ),
        (role) => {
          return indexOf(
            // sort by hierarchy, so that UI displays in this order
            [ManagedRole.GroupAdmin, ManagedRole.GroupCreator, ManagedRole.GroupMember],
            role.name,
          )
        },
      )
    },
    organizationOnlyRoles: (orgId) => {
      return sortBy(
        Array.from(self.organizationRoles.values()).filter(
          (role) => role.organizationId === orgId && !role.isGroupRole,
        ),
        (role) => {
          return indexOf(
            [
              ManagedRole.OrganizationOwner,
              ManagedRole.OrganizationAdmin,
              ManagedRole.OrganizationMember,
            ],
            role.name,
          )
        },
      )
    },
  }))
  .views((self) => ({
    getRole: (orgId: string, managedRole: ManagedRole) => {
      const roles = self.allRoles(orgId)
      return roles.find((r) => r.managedRole === managedRole)
    },
  }))

export type OrganizationStore = Instance<typeof OrganizationStoreModel>
export const withOrganizationStore = (self: IStateTreeNode) => ({
  views: {
    get organizationStore(): OrganizationStore {
      return getParent<Instance<typeof RootStoreModel>>(self).organizationStore
    },
  },
})
