import { CSSObject } from "@emotion/react"
import { ButtonProps } from "./button"
import { merge } from "lodash-es"
import { WebAppTheme } from "pitch45-common/theme/app-theme.types"
import { typography } from "pitch45-common/theme/typography"
import { color } from "../../theme/color"
import { semanticSpacing, unitSpacing } from "../../theme/spacing"
import { fontSizing } from "../../theme"

export const MIN_BUTTON_WIDTH = "163px"

const getBaseStyles = function (props: ButtonProps, theme: WebAppTheme): Record<string, CSSObject> {
  return {
    BUTTON: {
      cursor: "pointer",
      minWidth: MIN_BUTTON_WIDTH,
      color: theme.colors?.text, // Was getting runtime errors when using this element; had to add this check to run
      "&:hover": {
        backgroundColor: color.primaryHover,
      },
      paddingTop: unitSpacing.quarter,
      paddingBottom: unitSpacing.quarter,
    },
    BODY: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      padding: semanticSpacing.button.primary,
    },
    LABEL: {
      ...theme.webTypography.medium,
      fontSize: fontSizing.small,
      textTransform: "uppercase",
      color: theme.colors?.text,
      paddingTop: "0.2em",
      marginLeft: !props.icon ? 0 : "5px",
    },
    ICON: {
      color: theme.colors.text,
    },
  }
}

const getPrimaryPreset = function (
  props: ButtonProps,
  theme: WebAppTheme,
): Record<string, CSSObject> {
  return {
    BUTTON: {
      backgroundColor: !props.disabled ? theme.colors?.primary : theme.colors?.disabled,
      borderRadius: 50,
      border: "none",
      "&:hover": {
        backgroundColor: color.primaryHover,
      },
      "&:.Mui-disabled": {
        backgroundColor: theme.colors?.disabled,
      },
    },
    LABEL: {
      ...typography.semibold,
      color: !props.disabled ? color.palette.white : theme.colors?.text,
    },
    ICON: {
      color: !props.disabled ? color.palette.white : theme.colors?.text,
    },
  }
}

const getSecondaryPreset = function (
  props: ButtonProps,
  theme: WebAppTheme,
): Record<string, CSSObject> {
  return {
    BUTTON: {
      backgroundColor: !props.disabled ? color.darkBorder : theme.colors?.disabled,
      borderRadius: 50,
      border: "none",
      "&:hover": {
        backgroundColor: color.backgroundHighlight,
      },
    },
    LABEL: {
      color: !props.disabled ? color.palette.white : theme.colors?.text,
    },
    ICON: {
      color: !props.disabled ? color.palette.white : theme.colors?.text,
    },
  }
}

const getClearPreset = function (): Record<string, CSSObject> {
  return {
    BUTTON: {
      backgroundColor: "transparent",
      minWidth: 0, // MUI button sets minWidth to 64px
      border: "none",
      padding: unitSpacing.half,
      "&:hover": {
        backgroundColor: color.menuItemHover,
      },
    },
    BODY: {
      padding: 0,
    },
  }
}

const getQuickActionPreset = function (): Record<string, CSSObject> {
  return {
    BUTTON: {
      backgroundColor: "transparent",
      border: "none",
      padding: 0,
      "&:hover": {
        backgroundColor: color.palette.offWhite,
      },
    },
    BODY: {
      flexDirection: "column",
    },
    LABEL: {
      marginLeft: 0,
    },
  }
}

const getOutlinedPreset = function (
  props: ButtonProps,
  theme: WebAppTheme,
): Record<string, CSSObject> {
  return {
    BUTTON: {
      backgroundColor: theme.colors.background,
      minWidth: "30px",
      borderRadius: "30px",
      justifyContent: "center",
      border: `1px solid ${theme.colors.border}`,
      "&:hover": {
        backgroundColor: theme.colors.background,
      },
    },
    LABEL: {
      ...typography.semibold,
    },
  }
}

const getNarrowPreset = function (
  props: ButtonProps,
  theme: WebAppTheme,
): Record<string, CSSObject> {
  return {
    BODY: {
      paddingLeft: props.icon ? unitSpacing.quarter : unitSpacing.half,
      paddingRight: unitSpacing.half,
    },
    BUTTON: {
      backgroundColor: !props.disabled
        ? theme.colors.halfTransparentBackground
        : theme.colors.dimText,
      borderRadius: 50,
      border: "none",
      "&:hover": {
        backgroundColor: theme.colors.primary,
      },
      padding: "3px 4px",
    },
    LABEL: {
      textTransform: "capitalize",
      color: !props.disabled ? theme.colors.text : theme.colors.dimText,
    },
  }
}

const getLinkPreset = function (props: ButtonProps, theme: WebAppTheme): Record<string, CSSObject> {
  return {
    BUTTON: {
      padding: 0,
      textTransform: "none",
    },
    BODY: { display: "inline" },
    LABEL: {
      ...typography.semibold,
      color: theme.colors.primary,
      lineHeight: "inherit",
      fontSize: fontSizing.medium,
    },
  }
}

export const getStyles = function (
  props: ButtonProps,
  theme: WebAppTheme,
): Record<string, CSSObject> {
  if (props.preset === "link") {
    return getLinkPreset(props, theme)
  }

  const baseStyles = getBaseStyles(props, theme)
  let presetStyles
  switch (props.preset) {
    case "quick-action":
      presetStyles = getQuickActionPreset()
      break
    case "clear":
      presetStyles = getClearPreset()
      break
    case "primary":
      presetStyles = getPrimaryPreset(props, theme)
      break
    case "secondary":
      presetStyles = getSecondaryPreset(props, theme)
      break
    case "outlined":
      presetStyles = getOutlinedPreset(props, theme)
      break
    case "narrow":
      presetStyles = getNarrowPreset(props, theme)
      break
    default:
      presetStyles = {}
  }
  return merge(baseStyles, presetStyles)
}
