import { ApiResponse } from "apisauce"
import { Api } from "./api"
import { ApiError } from "./api-problem"
import { Invitation, InvitationRole } from "../../models/invitation"
import { Organization } from "../../models/organization"
import { Group } from "../../models/group"

type InvitationsResult = { invitations: Invitation[] }
type InvitationResult = { invitation: Invitation; organization: Organization; groups: Group[] }

export type OrganizationInvitationRequest = {
  email: string
  name?: string
  roles: InvitationRole[]
}

export type GroupInvitationRequest = {
  email: string
  name?: string
  organizationRoleId: string
}

export type SendOrganizationInvitationsRequest = {
  organizationId: string
  invitations: OrganizationInvitationRequest[]
}

export type SendGroupInvitationsRequest = {
  invitations: GroupInvitationRequest[]
}

const API_PATH = "api/Invitation/"

export class InvitationApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async sendOrganizationInvitations(request: SendOrganizationInvitationsRequest): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.post(`${API_PATH}send`, request)
    if (!response.ok) {
      throw new ApiError("InvitationApi.sendOrganizationInvitations", response)
    }
  }

  async sendGroupInvitations(groupId: string, request: SendGroupInvitationsRequest): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.post(
      `${API_PATH}send/${groupId}`,
      request,
    )
    if (!response.ok) {
      throw new ApiError("InvitationApi.sendGroupInvitations", response)
    }
  }

  async getPendingOrganizationInvitations(organizationId: string): Promise<InvitationsResult> {
    const response: ApiResponse<InvitationsResult> = await this.api.apisauce.get(
      `${API_PATH}organization/${organizationId}/pending`,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("InvitationApi.getPendingOrganizationInvitations", response)
    }
    return response.data
  }

  async getPendingGroupInvitations(groupId: string): Promise<InvitationsResult> {
    const response: ApiResponse<InvitationsResult> = await this.api.apisauce.get(
      `${API_PATH}group/${groupId}/pending`,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("InvitationApi.getPendingGroupInvitations", response)
    }
    return response.data
  }

  async getInvitation(invitationId: string): Promise<InvitationResult> {
    const response: ApiResponse<InvitationResult> = await this.api.apisauce.get(
      `${API_PATH}${invitationId}`,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("InvitationApi.getInvitation", response)
    }
    return response.data
  }

  async claimInvitation(invitationId: string): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.put(
      `${API_PATH}${invitationId}/claim`,
    )
    if (!response.ok) {
      throw new ApiError("InvitationApi.claimInvitation", response)
    }
  }

  async resendInvitation(invitationId: string): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.post(
      `${API_PATH}${invitationId}/resend`,
    )
    if (!response.ok) {
      throw new ApiError("InvitationApi.resendInvitation", response)
    }
  }

  async deleteInvitation(invitationId: string): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.delete(`${API_PATH}${invitationId}`)
    if (!response.ok) {
      throw new ApiError("InvitationApi.deleteInvitation", response)
    }
  }

  async deleteInvitationRole(invitationId: string, groupId: string): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.delete(
      `${API_PATH}${invitationId}/group/${groupId}`,
    )
    if (!response.ok) {
      throw new ApiError("InvitationApi.deleteInvitationRole", response)
    }
  }
}
