import { Instance, types } from "mobx-state-tree"
import { withEnvironment } from "./extensions/with-environment"
import { withTimelineStore } from "./timeline-store"

export const LayoutModel = types.model("LayoutModel").props({
  x: types.number,
  y: types.number,
  height: types.number,
  width: types.number,
  id: types.identifier,
})

export enum CanvasId {
  Main = "main",
  TextEditor = "text-editor",
  CoverSelector = "cover-selector",
}

export const OVERLAY_TRACK_HEIGHT = 40
export const AUDIO_TRACK_HEIGHT = 30
export const MAIN_TRACK_HEIGHT = 80
export const TRACK_BORDER_WIDTH = 2

export const TimelineLayoutStoreModel = types
  .model("TimelineLayoutStoreModel")
  .props({
    canvasLayouts: types.map(LayoutModel),
  })
  .extend(withEnvironment)
  .extend(withTimelineStore)
  .actions((self) => ({
    setCanvasLayout(id: CanvasId, canvasLayout) {
      self.canvasLayouts.put({ id, ...canvasLayout })
    },
  }))
  .views((self) => ({
    getCanvasLayout(canvasId: CanvasId) {
      return self.canvasLayouts.get(canvasId)
    },
  }))

export type TimelineLayoutStore = Instance<typeof TimelineLayoutStoreModel>
