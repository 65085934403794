import { RecentContent } from "../../models/recent-content"
import { Api } from "./api"
import { ApiError } from "./api-problem"
import { ApiResponse } from "apisauce"
import { Pitch } from "../../models/pitch"
import { Playlist } from "../../models/playlist"
import { ResourceType } from "../../models/resource"

const API_PATH = "api/ResourceView"

export type RecentContentResult = { recentlyViewed: RecentContent[] }

export class ResourceViewApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async setLastViewed(resourceIds: string[]): Promise<void> {
    const response: ApiResponse<unknown> = await this.api.apisauce.patch(
      `${API_PATH}/viewed`,
      resourceIds,
    )
    if (!response.ok) {
      throw new ApiError("ResourceViewApi.setLastViewed", response)
    }
  }

  async getRecentContent(options: {
    resourceTypes?: (ResourceType | string)[]
    searchQuery?: string
    cursor?: string
  }): Promise<RecentContentResult> {
    const response: ApiResponse<RecentContentResult> = await this.api.apisauce.get(
      `${API_PATH}/recent`,
      {
        resourceTypes: options?.resourceTypes,
        query: options?.searchQuery,
        cursor: options?.cursor,
      },
    )
    if (!response.ok || !response.data) {
      throw new ApiError("ResourceViewApi.getRecentContent", response)
    }
    return response.data
  }

  async getRecentTags(): Promise<{ tags: ResourceType[] }> {
    const response: ApiResponse<{ tags: ResourceType[] }> = await this.api.apisauce.get(
      `${API_PATH}/tags`,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("ResourceViewApi.getRecentTags", response)
    }
    return response.data
  }

  async getPlaylists() {
    const response: ApiResponse<{ playlists: Playlist[] }> = await this.api.apisauce.get(
      `${API_PATH}/playlist`,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("ResourceViewApi.getPlaylists", response)
    }
    return { playlists: response.data.playlists }
  }

  async getPitches() {
    const response: ApiResponse<{ pitches: Pitch[] }> = await this.api.apisauce.get(
      `${API_PATH}/pitch`,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("ResourceViewApi.getPitches", response)
    }
    return { pitches: response.data.pitches }
  }
}
