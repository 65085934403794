import { ApiResponse } from "apisauce"
import { Api } from "./api"
import { ApiError } from "./api-problem"
import { Timeline } from "../../models/timeline"
import { toJS } from "mobx"

const API_PATH = "api/Timeline/"

export type TimelineResult = { timeline: Timeline }

export type TimelinesResult = { timelines: Timeline[] }

export type MissingTimelineAssetPostParams = {
  [assetId: string]: { url: string; fields: { [key: string]: string } }
}

export type MissingTimelineAssetUrlsResult = {
  uploadPostParams: MissingTimelineAssetPostParams
}

export function toServerTimeline(timeline: Timeline): any {
  // Convert timeline with toJS before sending to API so we get the full assets
  // of the reference instead of just the id's.

  // The video timestamp is computed, but we want to make sure to send it to the server
  const videoTimestamp = timeline.cover?.videoTimestamp
  // The clip is a reference, but the server only needs to store the Guid
  const clipId = timeline.cover?.clip?.id

  const serverTimeline = JSON.parse(JSON.stringify(toJS(timeline)))
  if (serverTimeline.cover) {
    serverTimeline.cover.clip = clipId
    serverTimeline.cover.videoTimestamp = videoTimestamp
  }

  // We have a couple properties that are computed right now
  // and don't need to be saved on the server in certain cases.
  // For example duration on video clips is computed, and start
  // position on the main track is computed. If those values were
  // explicitly set then send them to the server. Definitely some
  // room here for refactoring / improvement
  serverTimeline.tracks.forEach((track) => {
    // removing clips for text overlays that are blank
    track.clips = track.clips.filter((c) => c.assetOptions?.text?.trim() !== "")
    track.clips.forEach((clip) => {
      Object.keys(clip).forEach((keyName) => {
        if (keyName.length && clip[keyName] && keyName[0] === "_") {
          const val = clip[keyName]
          delete clip[keyName]
          clip[keyName.substring(1)] = val
        }
      })
    })
  })
  return serverTimeline
}

export class TimelineApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async getTimelineByPitch(pitchId: string): Promise<TimelineResult> {
    const response: ApiResponse<Timeline> = await this.api.apisauce.get(
      API_PATH + "pitch/" + pitchId,
    )

    if (!response.ok || !response.data) {
      throw new ApiError("TimelineApi.getTimelineByPitch", response)
    }

    return { timeline: response.data }
  }

  async getUserTimelines(): Promise<TimelinesResult> {
    const response: ApiResponse<TimelinesResult> = await this.api.apisauce.get(API_PATH)

    if (!response.ok || !response.data) {
      throw new ApiError("TimelineApi.getUserTimelines", response)
    }

    return response.data
  }

  async deleteTimeline(timelineId: string): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.delete(API_PATH + `${timelineId}`)

    if (!response.ok) {
      throw new ApiError("TimelineApi.deleteTimeline", response)
    }
  }

  async resetStreamableStatus(timelineId: string): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.post(
      API_PATH + `${timelineId}/resetStreamableStatus`,
    )

    if (!response.ok) {
      throw new ApiError("TimelineApi.resetStreamableStatus", response)
    }
  }

  async getMissingAssetUploadUrls(timelineId: string): Promise<MissingTimelineAssetUrlsResult> {
    const response: ApiResponse<MissingTimelineAssetPostParams> = await this.api.apisauce.get(
      API_PATH + `${timelineId}/missingAssetsPostParams`,
    )

    if (!response.ok || !response.data) {
      throw new ApiError("TimelineApi.getMissingAssetUploadUrls", response)
    }

    return { uploadPostParams: response.data }
  }
}
