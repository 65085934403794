import { CSSObject } from "@emotion/react"
import { ToastProps } from "./toast"
import { merge } from "lodash-es"
import { color } from "../../theme/color"
import { unitSpacing } from "../../theme/spacing"
import { fontSizing } from "../../theme"

const getBaseStyles = function (): Record<string, CSSObject> {
  return {
    CONTAINER: {
      marginBottom: unitSpacing.unitPlus,
    },
    ALERT: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      padding: `${unitSpacing.half} ${unitSpacing.unit}`,
      backgroundColor: color.halfTransparentBlack,
      color: color.text,
      fontSize: fontSizing.medium,
      borderRadius: "50px",
      "& .MuiAlert-message": { textAlign: "center", width: "100%" },
      "& .MuiAlert-icon": { textAlign: "center" },
      "& .MuiAlert-action": { paddingTop: 0 },
    },
  }
}

const getSuccessPreset = function (): Record<string, CSSObject> {
  return {
    ALERT: {
      backgroundColor: color.success,
    },
  }
}

const getErrorPreset = function (): Record<string, CSSObject> {
  return {
    ALERT: {
      backgroundColor: color.error,
    },
  }
}

const getWarningPreset = function (): Record<string, CSSObject> {
  return {
    ALERT: {
      backgroundColor: color.warning,
    },
  }
}

const getInfoPreset = function (): Record<string, CSSObject> {
  return {
    ALERT: {
      backgroundColor: color.palette.slateGrey,
    },
  }
}

export const getStyles = function (props: ToastProps): Record<string, CSSObject> {
  const baseStyles = getBaseStyles()
  let presetStyles
  switch (props.options?.type) {
    case "success":
      presetStyles = getSuccessPreset()
      break
    case "error":
      presetStyles = getErrorPreset()
      break
    case "warning":
      presetStyles = getWarningPreset()
      break
    case "info":
      presetStyles = getInfoPreset()
      break
    default:
      presetStyles = {}
  }
  return merge(baseStyles, presetStyles)
}
