import { Instance, types } from "mobx-state-tree"

export enum ExternalLinkType {
  PitchViewer = "PitchViewer",
  PitchEditor = "PitchEditor",
  Playlist = "Playlist",
  PlaylistViewer = "PlaylistViewer",
  UserProfile = "UserProfile",
  GroupMemberProfileVideos = "GroupMemberProfileVideos",
  ScriptShare = "ScriptShare",
}

export const ExternalLinkModel = types.model("ExternalLink").props({
  id: types.maybe(types.string),
  url: types.string,
  qrCodeAssetId: types.maybe(types.string),
})

export type ExternalLink = Instance<typeof ExternalLinkModel>
