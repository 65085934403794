import { Api } from "./api"
import { ApiError } from "./api-problem"
import { ApiResponse } from "apisauce"

const API_PATH = "api/SsoConfig/"

export type SsoLoginUrlResponse = { loginRedirectUrl: string }

export class SsoConfigApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async getSsoLoginUrl({ email }: { email: string }): Promise<SsoLoginUrlResponse> {
    const response: ApiResponse<SsoLoginUrlResponse> = await this.api.apisauce.post(
      `${API_PATH}login`,
      {
        email,
      },
    )

    if (!response.ok || !response.data) {
      throw new ApiError("SsoConfig.getSsoLoginUrl", response)
    }

    return response.data
  }
}
