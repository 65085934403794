import { Instance, types, flow, toGenerator } from "mobx-state-tree"
import { withEnvironment } from "./extensions/with-environment"
import { ActivitySubscriptionApi } from "../services/api/activity-subscription-api"
import { StringEnum } from "../utils/string-enum-type"
import { withSessionStore } from "./session-store"

export enum ActivitySubscriptionType {
  Playlist = "Playlist",
  Organization = "Organization",
  Group = "Group",
}

export enum NotificationPreference {
  All = "All",
  ActivityOnly = "ActivityOnly",
  ActivityWithPush = "ActivityWithPush",
  ActivityWithEmail = "ActivityWithEmail",
  None = "None",
}

export const ActivitySubscriptionModel = types.model({
  id: types.identifier,
  userId: types.string,
  resourceId: types.string,
  type: types.maybe(StringEnum(ActivitySubscriptionType)),
  notificationPreference: types.maybe(StringEnum(NotificationPreference)),
  resourceName: types.maybe(types.string),
  resourceAssetId: types.maybe(types.string),
  entityName: types.maybe(types.string),
  entityAssetId: types.maybe(types.string),
})

export type ActivitySubscription = Instance<typeof ActivitySubscriptionModel>

export const ActivitySubscriptionStoreModel = types
  .model("ActivitySubscriptionStore")
  .props({
    activitySubscriptions: types.map(ActivitySubscriptionModel),
  })
  .extend(withEnvironment)
  .extend(withSessionStore)
  .actions((self) => ({
    fetchActivitySubscriptions: flow(function* () {
      const activitySubscriptionApi = new ActivitySubscriptionApi(self.environment.api)
      const result = yield* toGenerator(activitySubscriptionApi.fetchAllActivitySubscriptions())
      self.activitySubscriptions.replace(
        new Map(result.activitySubscriptions.map((s) => [s.id, s])),
      )
      return true
    }),
    subscribe: flow(function* (
      type: ActivitySubscriptionType,
      resourceId: string,
      notificationPreference: NotificationPreference = NotificationPreference.All,
    ) {
      const activitySubscriptionApi = new ActivitySubscriptionApi(self.environment.api)
      const result = yield* toGenerator(
        activitySubscriptionApi.subscribe(type, resourceId, notificationPreference),
      )

      self.activitySubscriptions.put(result.activitySubscription)
    }),
    unsubscribe: flow(function* (type: ActivitySubscriptionType, resourceId: string) {
      const activitySubscriptionApi = new ActivitySubscriptionApi(self.environment.api)
      yield activitySubscriptionApi.unsubscribe(type, resourceId)
      const subscriptionId = Array.from(self.activitySubscriptions.values()).find(
        (a) => a.resourceId === resourceId,
      )?.id
      if (subscriptionId) {
        self.activitySubscriptions.delete(subscriptionId)
      }
    }),
  }))
  .actions((self) => ({
    subscribeToPlaylistActivity: flow(function* (playlistId: string) {
      return self.subscribe(ActivitySubscriptionType.Playlist, playlistId)
    }),
    unsubscribeFromPlaylistActivity: flow(function* (playlistId: string) {
      return self.unsubscribe(ActivitySubscriptionType.Playlist, playlistId)
    }),
    updateNotificationPreference: flow(function* (
      activitySubscriptionId: string,
      notificationPreference: NotificationPreference,
    ) {
      const activitySubscriptionApi = new ActivitySubscriptionApi(self.environment.api)
      const result = yield* toGenerator(
        activitySubscriptionApi.updateNotificationPreference(
          activitySubscriptionId,
          notificationPreference,
        ),
      )
      const existing = self.activitySubscriptions.get(result.activitySubscription.id)
      if (existing) {
        existing.notificationPreference = notificationPreference
      } else {
        self.activitySubscriptions.put(result.activitySubscription)
      }
    }),
  }))
  .views((self) => ({
    getSubscriptionByResourceId(resourceId) {
      const subscription = Array.from(self.activitySubscriptions.values()).find(
        (a) => a.resourceId === resourceId && a.userId === self.sessionStore.currentUser?.id,
      )
      return subscription
    },
  }))
  .views((self) => ({
    // TODO - ideally we'd have a Map of resourceId->boolean for faster lookups
    // but MobX requires a map on an object to use it's identifier as the key.
    // We could maintain a separate map or store things differently, but I think
    // it should be quite rare for this to grow large enough for a user where it
    // would make a difference, so I opted for simplicity in the design
    isSubscribed(resourceId) {
      const subscription = self.getSubscriptionByResourceId(resourceId)
      return Boolean(
        subscription && subscription.notificationPreference !== NotificationPreference.None,
      )
    },
    hasExistingSubscription(resourceId) {
      return Boolean(self.getSubscriptionByResourceId(resourceId))
    },
    getSubscriptionsByType(type: ActivitySubscriptionType) {
      const subscriptions = Array.from(self.activitySubscriptions.values()).filter(
        (a) => a.type === type && a.userId === self.sessionStore.currentUser?.id,
      )
      return subscriptions
    },
    getNotificationPreference(resourceId) {
      const subscription = self.getSubscriptionByResourceId(resourceId)
      return subscription?.notificationPreference
    },
  }))

export type ActivitySubscriptionStore = Instance<typeof ActivitySubscriptionStoreModel>
