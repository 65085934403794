import { Instance, types } from "mobx-state-tree"
import { StringEnum } from "../utils/string-enum-type"
import { Entity, EntityType } from "./entity"
import { OrganizationVisibilityStatus } from "./organization"
import { DateTime } from "../utils"

export enum MembershipRequestMode {
  Disabled = "Disabled",
  ApprovalRequired = "ApprovalRequired",
  Open = "Open",
}

export enum EnforceOrganizationMembershipSetting {
  Standard = "Standard",
  Guest = "Guest",
}

export enum ContentPageType {
  Disabled = "Disabled",
  ManualCuration = "ManualCuration",
  Feed = "Feed",
}

export enum DiscoverabilityStatus {
  None = "None",
  Organization = "Organization",
  Public = "Public",
}

/**
 * Group model.
 */
export const GroupModel = types
  .model("Group")
  .props({
    id: types.identifier,
    organizationId: types.string,
    name: types.maybe(types.string),
    website: types.maybe(types.string),
    logoAssetId: types.maybe(types.string),
    coverAssetId: types.maybe(types.string),
    description: types.maybe(types.string),
    locationCity: types.maybe(types.string),
    locationState: types.maybe(types.string),
    locationZip: types.maybe(types.string),
    visibility: types.maybe(StringEnum(OrganizationVisibilityStatus)),
    discoverability: types.maybe(StringEnum(DiscoverabilityStatus)),
    membershipRequestMode: types.maybe(StringEnum(MembershipRequestMode)),
    contentPageType: types.maybe(StringEnum(ContentPageType)),
    defaultInvitationLinkId: types.maybe(types.string),
    enforceOrganizationMembership: types.maybe(types.boolean),
    defaultRoleId: types.maybe(types.string),
    type: EntityType.Group,
    createdUtc: DateTime,
  })
  .views((self) => ({
    toEntity(): Entity {
      return {
        id: self.id,
        name: self.name || "",
        assetId: self.logoAssetId,
        type: EntityType.Group,
        parentId: self.organizationId,
      }
    },
  }))

export type Group = Instance<typeof GroupModel>
