import { Instance, types } from "mobx-state-tree"
import { DateTime } from "../utils/datetime-type"
import { StringEnum } from "../utils/string-enum-type"
import { EntityModel } from "./entity"

export enum MembershipRequestStatus {
  Active = "Active",
  Accepted = "Accepted",
  Rejected = "Rejected",
}

export const MembershipRequestModel = types
  .model("MembershipRequest")
  .props({
    id: types.identifier,
    userId: types.string,
    organizationId: types.string,
    groupId: types.maybe(types.string),
    status: types.maybe(StringEnum(MembershipRequestStatus)),
    createdUtc: types.maybe(DateTime),
    userEntity: types.maybe(EntityModel),
  })
  .views((self) => ({
    get entityId() {
      return self.groupId ?? self.organizationId
    },
  }))

export type MembershipRequest = Instance<typeof MembershipRequestModel>
