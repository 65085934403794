// types
import { ApiResponse } from "apisauce"
import { Api } from "./api"
import { ApiError } from "./api-problem"
import { ExternalLink } from "../../models/external-link"
import { ContentVisibility } from "../../models/content-visibility"

const API_PATH = "api/BranchLink/"

export enum ShareResourceType {
  Pitch = "Pitch",
  Playlist = "Playlist",
}

interface PitchViewerLinkRequest {
  pitchId: string
  title?: string
  description?: string
}

interface DraftPitchViewerLinkRequest {
  pitchId: string
  title?: string
  description?: string
  entityId: string
  visibility: ContentVisibility | string
}

interface PlaylistViewerLinkRequest {
  playlistId: string
  pitchId: string
  title?: string
  description?: string
}

export class BranchLinkApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async getPitchViewerLink({ pitchId }: PitchViewerLinkRequest): Promise<ExternalLink> {
    const response: ApiResponse<ExternalLink> = await this.api.apisauce.post(
      `${API_PATH}pitch/${pitchId}`,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("BranchLinkApi.getPitchViewerLink", response)
    }
    return response.data
  }

  async getDraftPitchViewerLink({
    pitchId,
    entityId,
    visibility,
  }: DraftPitchViewerLinkRequest): Promise<ExternalLink> {
    const response: ApiResponse<ExternalLink> = await this.api.apisauce.post(
      `${API_PATH}pitch/${pitchId}?entityId=${entityId}&visibility=${visibility}`,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("BranchLinkApi.getDraftPitchViewerLink", response)
    }
    return response.data
  }

  async getPlaylistViewerLink({
    playlistId,
    pitchId,
  }: PlaylistViewerLinkRequest): Promise<ExternalLink> {
    const queryParams = pitchId ? `pitchId=${pitchId}` : ""
    const response: ApiResponse<ExternalLink> = await this.api.apisauce.post(
      `${API_PATH}playlist/${playlistId}/watch?${queryParams}`,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("BranchLinkApi.getPlaylistViewerLink", response)
    }
    return response.data
  }

  async getAppHomeLink(): Promise<ExternalLink> {
    const response: ApiResponse<ExternalLink> = await this.api.apisauce.get(
      `${API_PATH}screen/home`,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("BranchLinkApi.getAppHomeQrCode", response)
    }
    return response.data
  }
}
