import { flow, getParent, Instance, IStateTreeNode, toGenerator, types } from "mobx-state-tree"
import { withEnvironment } from "./extensions/with-environment"
import { PublicUser, PublicUserModel } from "../models/public-user"
import { UserApi } from "../services/api/user-api"
import { BlockType, withBlockedUserStore } from "./blocked-user-store"
import { SearchApi, SearchFilterType } from "../services/api/search-api"
import { RootStoreModel } from "./root-store"

export const UserStoreModel = types
  .model("UserStore")
  .props({
    users: types.map(PublicUserModel),
  })
  .extend(withEnvironment)
  .extend(withBlockedUserStore)
  .actions((self) => ({
    putUser(user: PublicUser) {
      self.users.put(user)
      return self.users.get(user.id)
    },
    putUsers(users: PublicUser[]): PublicUser[] {
      const userModels: PublicUser[] = []
      users.forEach((user) => {
        self.users.put(user)
        const userModel = self.users.get(user.id)
        if (userModel) {
          userModels.push(userModel)
        }
      })
      return userModels
    },
    getUnblockedUsers(users: PublicUser[]): PublicUser[] {
      return users.filter((u) => !self.blockedUserStore.isUserBlocked(BlockType.BlockedByEither, u))
    },
  }))
  .actions((self) => ({
    fetchUser: flow(function* (userId: string) {
      const api = new UserApi(self.environment.api)
      const result = yield* toGenerator(api.getUser(userId))
      return self.putUser(result.user)
    }),
    searchUsers: flow(function* (searchString: string, entityId?: string) {
      const searchApi = new SearchApi(self.environment.api)

      // short circuit the empty string
      if (searchString === "") {
        return []
      }

      const request: {
        query: string
        pageNumber: number
        pageSize: number
        filters: { type: SearchFilterType; value: any }[]
      } = {
        query: searchString,
        pageNumber: 1,
        pageSize: 10,
        filters: [],
      }

      if (entityId) {
        request.filters = [{ type: SearchFilterType.ByGroup, value: entityId }]
      }

      request.filters.push({ type: SearchFilterType.ExcludeSelf, value: true })

      const result = yield* toGenerator(searchApi.searchUsers(request))

      const searchResults = self.putUsers(result.results)
      return self.getUnblockedUsers(searchResults)
    }),
  }))

export type UserStore = Instance<typeof UserStoreModel>
export const withUserStore = (self: IStateTreeNode) => ({
  views: {
    get userStore(): UserStore {
      return getParent<Instance<typeof RootStoreModel>>(self).userStore
    },
  },
})
