import React from "react"
import Image from "next/legacy/image"

interface LogoProps {
  size: "small" | "medium" | "large" | "fill" | "infinity"
  lightTheme?: boolean
}

function Loop45Logo({ size, lightTheme = false }: LogoProps) {
  let width: number | undefined
  let height: number | undefined
  let src: string
  switch (size) {
    case "small":
      width = 75
      height = 30
      src = lightTheme ? "/imgs/Loop45_Final_Black_sm.png" : "/imgs/Loop45_Final_White_sm.png"
      break
    case "medium":
      width = 120
      height = 48
      src = lightTheme ? "/imgs/Loop45_Final_Black_sm.png" : "/imgs/Loop45_Final_White_sm.png"
      break
    case "large":
      width = 900
      height = 361
      src = lightTheme ? "/imgs/Loop45_Final_Black_sm.png" : "/imgs/Loop45_Final_White.png"
      break
    case "fill":
      src = lightTheme ? "/imgs/Loop45_Final_Black_sm.png" : "/imgs/Loop45_Final_White.png"
      break
    case "infinity":
      width = 44
      height = 44
      src = "/imgs/Loop45Infinity_White.png"
      break
  }
  if (!width || !height) {
    throw new Error("Invalid Loop45Logo size")
  }
  return (
    <Image
      src={src}
      width={width}
      height={height}
      layout={!width && !height ? "fill" : undefined}
      alt="Loop :45 Logo"
    />
  )
}

export default Loop45Logo
