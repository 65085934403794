import { ApiResponse } from "apisauce"
import { Api } from "./api"
import { ApiError } from "./api-problem"
import { Session } from "../../models/session"

const API_PATH = "api/Session/"

export type LoginResult = { token: string }

export type SessionResult = { session: Session }

export class SessionApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async login({ email, password }): Promise<LoginResult> {
    const response: ApiResponse<LoginResult> = await this.api.apisauce.post(`${API_PATH}login`, {
      email,
      password,
    })
    if (!response.ok || !response.data) {
      throw new ApiError("SessionApi.login", response)
    }
    return response.data
  }

  async signup({
    firstName,
    lastName,
    email,
    password,
    pendingInviteId,
    pendingRegistrationCodeId,
  }): Promise<LoginResult> {
    const response: ApiResponse<LoginResult> = await this.api.apisauce.post(`${API_PATH}signup`, {
      firstName,
      lastName,
      email,
      password,
      pendingInviteId,
      pendingRegistrationCodeId,
    })
    if (!response.ok || !response.data) {
      throw new ApiError("SessionApi.signup", response)
    }
    return response.data
  }

  async getLoggedIn(): Promise<SessionResult> {
    const response: ApiResponse<Session> = await this.api.apisauce.get(`${API_PATH}logged_in`)
    if (!response.ok || !response.data) {
      throw new ApiError("SessionApi.getLoggedIn", response)
    }
    return { session: response.data }
  }
}
