import { Instance, types } from "mobx-state-tree"
import { validate } from "validate.js"
import { BooleanField } from "./boolean-field"
import { StringField } from "./string-field"

export const FormModel = types
  .model("Form")
  .views(() => ({
    get fields(): (BooleanField | StringField)[] {
      return []
    },
  }))
  .views((self) => ({
    get touchedFields() {
      return self.fields.filter((f) => f.touched)
    },
    get isValid() {
      return self.fields.every((f) => f.isValid)
    },
    get isDirty() {
      return self.fields.some((f) => f.isDirty)
    },
    get data() {
      const data = {}
      for (const field of self.fields) {
        data[field.name] = field.value
      }
      return data
    },
  }))
  .actions((self) => ({
    validate() {
      const touchedFields = self.fields.filter((f) => f.touched)
      const data = {}
      const rules = {}
      for (const field of touchedFields) {
        data[field.name] = field.value
        rules[field.name] = field.rules
      }

      const errors = validate(data, rules)

      for (const field of touchedFields) {
        const fieldErrors = errors ? errors[field.name] : undefined
        field.setError(fieldErrors ? fieldErrors[0] : undefined)
      }

      return errors
    },
  }))
  .actions((self) => ({
    submit() {
      for (const field of self.fields) {
        field.setTouched(true)
      }

      return self.validate()
    },
    reset() {
      for (const field of self.fields) {
        field.reset()
      }
    },
  }))

export type Form = Instance<typeof FormModel>
