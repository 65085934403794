import { Instance, types } from "mobx-state-tree"
import { DateTime } from "../utils/datetime-type"
import { StringEnum } from "../utils/string-enum-type"
import { EntityModel } from "./entity"

export enum ProgramType {
  Updates = "Updates",
  Introductions = "Introductions",
}

export enum ProgramWizardStep {
  Select = "Select",
  Details = "Details",
  Assign = "Assign",
  Settings = "Settings",
  Review = "Review",
}

export const ProgramModel = types.model("Program").props({
  id: types.identifier,
  entity: EntityModel,
  name: types.maybe(types.string),
  description: types.maybe(types.string),
  playlistCount: types.optional(types.number, 0),
  type: types.maybe(StringEnum(ProgramType)),
  createdUtc: types.maybe(DateTime),
  updatedUtc: types.maybe(DateTime),
})

export type Program = Instance<typeof ProgramModel>
