import { ApiResponse } from "apisauce"
import { Api } from "./api"
import { ApiError } from "./api-problem"
import { ContentReport } from "../../models/content-report"

const API_PATH = "api/ContentReport/"

export class ContentReportApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async createContentReport(contentReport: ContentReport): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.post(API_PATH, contentReport)
    if (!response.ok) {
      throw new ApiError("ContentReportApi.createContentReport", response)
    }
  }
}
