import React, { ReactNode, useState } from "react"
import { Button, Icons } from "../../ui"
import { CSSObject } from "@emotion/react"
import { mediaQueries } from "pitch45-common/theme"
import { useSpringCarousel } from "react-spring-carousel"
import { color, unitSpacing } from "../../theme"

function getStyles() {
  const styles = {
    CONTAINER: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
    },
    BUTTON_CONTAINER: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      [mediaQueries.MOBILE]: {
        display: "none",
      },
    },
    CAROUSEL_CONTAINER: {
      width: "100%",
      position: "relative",
      overflow: "hidden",
    },
    DOTS_CONTAINER: {
      position: "absolute",
      bottom: unitSpacing.unit,
      display: "flex",
      flexDirection: "row",
      zIndex: 1,
    },
    CAROUSEL_DOT: {
      width: "10px",
      height: "10px",
      borderRadius: "10px",
      backgroundColor: color.palette.white,
      margin: unitSpacing.half,
      cursor: "pointer",
    },
  }
  return styles as { [key in keyof typeof styles]: CSSObject }
}

export interface CarouselProps {
  slides: { id: string; renderItem: ReactNode }[]
  itemsPerSlide?: number
  carouselContainerCss?: CSSObject
  containerCss?: CSSObject
  renderOverlay?: () => ReactNode
  hideArrows?: boolean
  removeArrows?: boolean
  dotsContainerCss?: CSSObject
}

export function Carousel(props: CarouselProps) {
  const {
    slides = [],
    carouselContainerCss = {},
    containerCss = {},
    renderOverlay,
    hideArrows = false,
    itemsPerSlide = 1,
    dotsContainerCss = {},
  } = props
  const styles = getStyles()

  const {
    carouselFragment,
    useListenToCustomEvent,
    slideToPrevItem,
    slideToNextItem,
    slideToItem,
  } = useSpringCarousel({
    itemsPerSlide,
    items: slides,
    gutter: 0,
  })

  const [showPrev, setShowPrev] = useState(false)
  const [showNext, setShowNext] = useState(slides.length > 1)
  const [activeIndex, setActiveIndex] = useState(0)

  const carouselStyle = { ...styles.CAROUSEL_CONTAINER, ...carouselContainerCss }

  const handleSlideChange = (id, index) => {
    setActiveIndex(index)
    slideToItem(id)
  }

  useListenToCustomEvent((event) => {
    if (event.eventName === "onSlideStartChange") {
      setActiveIndex(event.nextItem.index)
    }
    // Triggered when the slide animation is completed
    if (event.eventName === "onSlideChange") {
      setShowPrev(event.currentItem.index !== 0)
      setShowNext(event.currentItem.index + 1 < slides.length)
    }
  })

  const renderCarouselIndicatorDots = () => {
    return (
      <div css={{ ...styles.DOTS_CONTAINER, ...dotsContainerCss }}>
        {slides.map((slide, index) => (
          <div
            key={slide.id}
            css={[
              styles.CAROUSEL_DOT,
              index === activeIndex
                ? { backgroundColor: color.primary }
                : { backgroundColor: color.border },
            ]}
            onClick={() => handleSlideChange(slide.id, index)}
          />
        ))}
      </div>
    )
  }

  return (
    <div css={[styles.CONTAINER, containerCss]}>
      {slides.length > 1 && renderCarouselIndicatorDots()}
      {!hideArrows && (
        <div css={[styles.BUTTON_CONTAINER, { marginRight: unitSpacing.unit }]}>
          <Button
            preset="clear"
            onClick={slideToPrevItem}
            icon={Icons.ChevronLeft}
            containerStyle={{
              borderRadius: 40,
              opacity: showPrev ? 1 : 0,
            }}
          />
        </div>
      )}
      <div css={carouselStyle}>
        {renderOverlay && renderOverlay()}
        {carouselFragment}
      </div>
      {!hideArrows && (
        <div css={styles.BUTTON_CONTAINER}>
          <Button
            preset="clear"
            onClick={slideToNextItem}
            icon={Icons.ChevronRight}
            containerStyle={{
              borderRadius: 40,
              opacity: showNext ? 1 : 0,
            }}
          />
        </div>
      )}
    </div>
  )
}
