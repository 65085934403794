import { Instance, cast, clone, flow, types } from "mobx-state-tree"
import { withEnvironment } from "./extensions/with-environment"
import { withGroupStore } from "./group-store"
import newId from "../utils/new-id"
import { withSessionStore } from "./session-store"
import { AssetAddParams, AssetModel } from "../models/asset"
import { withAssetStore } from "./asset-store"
import { FormModel, createFieldModel, createField } from "../models/form"
import { withSessionMembershipStore } from "./session-membership-store"
import { SyncStatus } from "../models/sync"
import { withOrganizationStore } from "./organization-store"
import { Group } from "../models/group"

const GroupFormModel = FormModel.props({
  name: createFieldModel({
    presence: { allowEmpty: false, message: "^Please enter a name" },
    length: { maximum: 256 },
  }),
  description: createFieldModel({ presence: { allowEmpty: true }, length: { maximum: 1024 } }),
  logoAssetId: createFieldModel({}),
  coverAssetId: createFieldModel({}),
}).views((self) => ({
  get fields() {
    return [self.name, self.description, self.logoAssetId, self.coverAssetId]
  },
}))

export type GroupForm = Instance<typeof GroupFormModel>

const GroupSettingsFormModel = FormModel.props({
  visibility: createFieldModel({}),
  membershipRequestMode: createFieldModel({}),
  contentPageType: createFieldModel({}),
  discoverability: createFieldModel({}),
  defaultRoleId: createFieldModel({}),
}).views((self) => ({
  get fields() {
    return [
      self.visibility,
      self.membershipRequestMode,
      self.contentPageType,
      self.discoverability,
      self.defaultRoleId,
    ]
  },
}))

export const GroupEditorStoreModel = types
  .model("GroupEditorStore")
  .props({
    logoAsset: types.maybe(AssetModel),
    coverAsset: types.maybe(AssetModel),
    form: types.maybe(GroupFormModel),
    settingsForm: types.maybe(GroupSettingsFormModel),
    currentProfileFormGroupId: types.maybe(types.string),
    currentSettingsFormGroupId: types.maybe(types.string),
    organizationId: types.maybe(types.string),
    currentShareGroupId: types.maybe(types.string),
    showDownloadAppModal: types.maybe(types.boolean),
  })
  .extend(withEnvironment)
  .extend(withGroupStore)
  .extend(withOrganizationStore)
  .extend(withSessionMembershipStore)
  .extend(withSessionStore)
  .extend(withAssetStore)
  .actions((self) => ({
    setLogo: flow(function* (params: AssetAddParams) {
      self.logoAsset = self.assetStore.createAsset(params)
      self.form?.logoAssetId.setValue(self.logoAsset.id)
    }),
    setCoverImage: flow(function* (params: AssetAddParams) {
      self.coverAsset = self.assetStore.createAsset(params)
      self.form?.coverAssetId.setValue(self.coverAsset.id)
    }),
    setLogoAssetId: flow(function* (assetId: string) {
      self.form?.logoAssetId.setValue(assetId)
    }),
    setCoverImageAssetId: flow(function* (assetId: string) {
      self.form?.coverAssetId.setValue(assetId)
    }),
    setCurrentShareGroupId: (groupId?: string) => {
      self.currentShareGroupId = groupId
    },
    initializeNewGroup: (organizationId?: string) => {
      self.currentProfileFormGroupId = undefined
      self.organizationId = organizationId
      self.form = cast({
        name: createField("name", ""),
        description: createField("description", ""),
        logoAssetId: createField("logoAssetId", ""),
        coverAssetId: createField("coverAssetId", ""),
      })
    },
    initializeExistingGroup: (id?: string) => {
      self.currentProfileFormGroupId = id
      const existingGroup = self.currentProfileFormGroupId
        ? self.groupStore.groups.get(self.currentProfileFormGroupId)
        : undefined
      if (!existingGroup) {
        throw new Error("Existing group not found")
      }
      self.organizationId = existingGroup.organizationId
      self.form = cast({
        name: createField("name", existingGroup.name),
        description: createField("description", existingGroup.description),
        logoAssetId: createField("logoAssetId", existingGroup.logoAssetId),
        coverAssetId: createField("coverAssetId", existingGroup.coverAssetId),
      })
      if (existingGroup.logoAssetId) {
        self.logoAsset = clone(self.assetStore.toAsset(existingGroup.logoAssetId))
      }
      if (existingGroup.coverAssetId) {
        self.coverAsset = clone(self.assetStore.toAsset(existingGroup.coverAssetId))
      }
    },
    initializeSettingsForm: (id: string) => {
      self.currentSettingsFormGroupId = id
      const existingGroup = self.groupStore.groups.get(self.currentSettingsFormGroupId)
      if (!existingGroup) {
        throw new Error("initializeSettingsForm: Existing group not found")
      }
      self.organizationId = existingGroup.organizationId
      self.settingsForm = cast({
        visibility: createField("visibility", existingGroup.visibility),
        contentPageType: createField("contentPageType", existingGroup.contentPageType),
        discoverability: createField("discoverability", existingGroup.discoverability),
        membershipRequestMode: createField(
          "membershipRequestMode",
          existingGroup.membershipRequestMode,
        ),
        defaultRoleId: createField("defaultRoleId", existingGroup.defaultRoleId),
      })
    },
    save: flow(function* () {
      // start by uploading profile photo and cover image if one is being provided
      const uploadPromises: Promise<unknown>[] = []
      if (self.logoAsset && self.logoAsset.sync.status === SyncStatus.New) {
        uploadPromises.push(self.assetStore.uploadMobileAsset(self.logoAsset))
      }
      if (self.coverAsset && self.coverAsset.sync.status === SyncStatus.New) {
        uploadPromises.push(self.assetStore.uploadMobileAsset(self.coverAsset))
      }
      yield Promise.all(uploadPromises)

      if (self.currentProfileFormGroupId) {
        const existingGroup = self.groupStore.groups.get(self.currentProfileFormGroupId)
        if (!existingGroup) {
          throw new Error("save: Existing group not found")
        }
        yield self.groupStore.saveGroup({
          ...existingGroup,
          ...self.form?.data,
        })

        return existingGroup.id
      } else {
        // since the user is trying to persist this group, we will create an id
        const newGroupId = newId().id
        if (!self.organizationId) {
          throw new Error("Existing organization not found")
        }
        yield self.groupStore.createGroup({
          // TODO: form.data is not typed
          ...(self.form?.data as Group),
          organizationId: self.organizationId,
          id: newGroupId,
        })

        return newGroupId
      }
    }),
    resetProfileForm: () => {
      self.form = undefined
      self.currentProfileFormGroupId = undefined
      self.logoAsset = undefined
      self.coverAsset = undefined
    },
    resetSettingsForm: () => {
      self.settingsForm = undefined
      self.currentSettingsFormGroupId = undefined
    },
  }))
  .actions((self) => ({
    saveSettings: flow(function* () {
      if (!self.currentSettingsFormGroupId) {
        throw new Error("cannot save settings for unknown group")
      }
      const existingGroup = self.groupStore.groups.get(self.currentSettingsFormGroupId)
      if (!existingGroup) {
        throw new Error("saveSettings: Existing group not found")
      }
      yield self.groupStore.saveGroupSettings({
        ...existingGroup,
        ...self.settingsForm?.data,
      })
      // refresh settings form
      self.initializeSettingsForm(self.currentSettingsFormGroupId)
      return existingGroup.id
    }),
    toggleSettingsForm: (id) => {
      if (self.currentSettingsFormGroupId === id) {
        self.resetSettingsForm()
      } else {
        self.initializeSettingsForm(id)
      }
    },
    toggleShareForm: (id) => {
      if (self.currentShareGroupId === id) {
        self.currentShareGroupId = undefined
      } else {
        self.currentShareGroupId = id
      }
    },
    toggleDownloadAppModal: () => {
      self.showDownloadAppModal = !self.showDownloadAppModal
    },
  }))
  .views((self) => ({
    get activeProfileGroup() {
      return self.currentProfileFormGroupId
        ? self.groupStore.groups.get(self.currentProfileFormGroupId)
        : undefined
    },
    get activeSettingsGroup() {
      return self.currentSettingsFormGroupId
        ? self.groupStore.groups.get(self.currentSettingsFormGroupId)
        : undefined
    },
  }))
