import { Instance, flow, toGenerator, types } from "mobx-state-tree"
import { withPitchStore } from "./pitch-store"
import {
  AnalyticsApi,
  AnalyticsFilterRequest,
  TimeBucket,
  TimeSeriesResult,
  VideoAnalyticsFilterRequest,
} from "../services"
import { withEnvironment } from "./extensions/with-environment"
import { Pitch } from "../models/pitch"
import { addDays, addMonths, format, parseISO, startOfDay, startOfMonth } from "date-fns"
import { timeDay, timeMonth } from "d3-time"

export function generateSequentialDates(start: Date, end: Date, timeBucket: TimeBucket) {
  switch (timeBucket) {
    case TimeBucket.Month:
      return timeMonth.range(startOfMonth(start), startOfMonth(addMonths(end, 1)))
    case TimeBucket.Day:
    default:
      return timeDay.range(startOfDay(start), startOfDay(addDays(end, 1)))
  }
}

export function generateSequentialValues(
  start: Date,
  end: Date,
  timeseriesResult: TimeSeriesResult,
) {
  const sequentialDates = generateSequentialDates(
    start,
    end,
    timeseriesResult.timeSeriesData.timeBucket,
  )

  const seriesData = sequentialDates.map((date) => {
    const datum = timeseriesResult.timeSeriesData.data.find(
      (point) => parseISO(point.time).getTime() === date.getTime(),
    )
    return {
      time: format(date, "yyyy-MM-dd"),
      value: datum?.value ?? 0,
    }
  })

  return {
    ...timeseriesResult,
    timeSeriesData: {
      ...timeseriesResult.timeSeriesData,
      data: seriesData,
    },
  }
}

export function generateCumulativeSequentialValues(
  start: Date,
  end: Date,
  timeseriesResult: TimeSeriesResult,
): TimeSeriesResult {
  const sequentialValues = generateSequentialValues(start, end, timeseriesResult)

  // fill 0 values in data with previous value
  const filledData: { time: string; value: number }[] = []
  for (let i = 0; i < sequentialValues.timeSeriesData.data.length; i++) {
    const d = sequentialValues.timeSeriesData.data[i]
    filledData.push({
      time: d.time,
      value: d.value > 0 ? d.value : filledData[i - 1]?.value ?? 0,
    })
  }

  return {
    ...timeseriesResult,
    timeSeriesData: {
      ...timeseriesResult.timeSeriesData,
      data: filledData,
    },
  }
}

export const AnalyticsStoreModel = types
  .model("ActivityStore")
  .props({
    splitByViewType: types.maybe(types.boolean),
  })
  .extend(withPitchStore)
  .extend(withEnvironment)
  .actions((self) => ({
    setSplitByViewType(splitByViewType: boolean) {
      self.splitByViewType = splitByViewType
    },
    fetchGroupAnalytics: flow(function* (filter: AnalyticsFilterRequest) {
      const analyticsApi = new AnalyticsApi(self.environment.api)
      const result = yield* toGenerator(analyticsApi.getGroupAnalytics(filter))
      self.pitchStore.putPitches(
        [
          result.mostViewedAllTime?.pitch,
          result.mostViewedLast30Days?.pitch,
          result.mostViewedLast7Days?.pitch,
          result.mostViewedThisYear?.pitch,
        ].filter((p): p is Pitch => Boolean(p)),
      )
      return {
        ...result,
        ...(result.mostViewedAllTime
          ? {
              mostViewedAllTime: {
                ...result.mostViewedAllTime,
                pitch: self.pitchStore.pitches.get(result.mostViewedAllTime.pitch.id)!,
              },
            }
          : {}),
        ...(result.mostViewedLast30Days
          ? {
              mostViewedLast30Days: {
                ...result.mostViewedLast30Days,
                pitch: self.pitchStore.pitches.get(result.mostViewedLast30Days.pitch.id)!,
              },
            }
          : {}),
        ...(result.mostViewedLast7Days
          ? {
              mostViewedLast7Days: {
                ...result.mostViewedLast7Days,
                pitch: self.pitchStore.pitches.get(result.mostViewedLast7Days.pitch.id)!,
              },
            }
          : {}),
        ...(result.mostViewedThisYear
          ? {
              mostViewedThisYear: {
                ...result.mostViewedThisYear,
                pitch: self.pitchStore.pitches.get(result.mostViewedThisYear.pitch.id)!,
              },
            }
          : {}),
      }
    }),
    fetchVideoAnalytics: flow(function* (filter: VideoAnalyticsFilterRequest) {
      const analyticsApi = new AnalyticsApi(self.environment.api)
      const result = yield* toGenerator(analyticsApi.getVideoAnalytics(filter))
      self.pitchStore.putPitches(result.videoViews.mostViewedPitches.map((p) => p.pitch))
      self.pitchStore.putPitches(result.videosPublished.latestPublishedPitches ?? [])
      const valueMapper = (timeSeriesResult?: TimeSeriesResult) => {
        if (!timeSeriesResult) {
          return
        }
        return filter.isCumulative
          ? generateCumulativeSequentialValues(filter.startUtc, filter.endUtc, timeSeriesResult)
          : generateSequentialValues(filter.startUtc, filter.endUtc, timeSeriesResult)
      }
      return {
        videoViews: {
          videoViewsChart: valueMapper(result.videoViews.videoViewsChart),
          appVideoViewsChart: valueMapper(result.videoViews.appVideoViewsChart),
          webVideoViewsChart: valueMapper(result.videoViews.webVideoViewsChart),
          mostViewedPitches: result.videoViews.mostViewedPitches.map((p) => ({
            ...p,
            pitch: self.pitchStore.pitches.get(p.pitch.id)!,
          })),
        },
        videosPublished: {
          videosPublishedChart: valueMapper(result.videosPublished.videosPublishedChart),
          latestPublishedPitches: result.videosPublished.latestPublishedPitches.map(
            (p) => self.pitchStore.pitches.get(p.id)!,
          ),
        },
      }
    }),
  }))

export type AnalyticsStore = Instance<typeof AnalyticsStoreModel>
